import React, { useEffect, useState } from "react";
import theme from "../../theme/theme";
import Button from "../../elements/Button";
import useTranslation from "../../localization/translations";
import { useNavigate } from "react-router-dom";
import SourceIcon from "../../assets/SourceIcon.svg";
import DestinationIcon from "../../assets/DestinationIcon.svg";
import ArrowIcon from "../../assets/ArrowIcon.svg";
import WifiIcon from "../../assets/wifiIcon.svg";
import WifiDisconnected from "../../assets/wifiDisconnected.svg";
import PalletsIcon from "../../assets/PalletsIcon.svg";
import CurrentLocationIcon from "../../assets/CurrentLocationIcon.svg";
import StoresIcon from "../../assets/StoresIcon.svg";
import CalendarIcon from "../../assets/CalendarIcon.svg";
import BatteryIcon from "../../assets/BatteryIcon.svg";
import TemperatureIcon from "../../assets/TemperatureIcon.svg";
import UtilizationIcon from "../../assets/UtilizationIcon.svg";
import useStyles from "./styles";

interface dashboardListProps {
  selectedTabButton: number;
  data: any;
  handleExpandListItem: any;
  index: number;
  selectedNotification: number;
}

const DashboardListItems: React.FC<any> = (props: any) => {
  const {
    data,
    data: {
      title,
      id,
      currentTimeStamp,
      itemList,
      connectedStatus,
      source,
      destination,
      currentLocation,
      pallets,
      stores,
      deliveryDate,
      area,
      name,
      utilization,
      battery,
      temperature,
      category,
    },
    handleExpandListItem,
    index,
    selectedNotification,
    notificationTimeStamp,
    tabIndex,
    selectedTabButton,
    handleViewDetail,
    refObject,
  } = props;

  const navigate = useNavigate();

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);

  const {
    listItemName1,
    listItemName2,
    listItemName3,
    listItemButtonName,
    Status,
    Pallets,
    Stores,
    CurrentLocation,
    BoxId,
    TrackerId,
    EquipmentId,
    Battery,
    Temperature,
    Utilization,
    LocationText,
  } = useTranslation();

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  const {
    rootContainer,
    itemTitleStyle,
    itemAreaStyle,
    expandedListClass,
    collapsedListClass,
    displayFlex,
    itemListCount,
    itemListName,
    selectedButtonStyles,
    timeStampStyle,
    trailerAreaStyle,
    trailerLocationStyle,
    trailerArrowStyle,
    trailerLocationIconStyle,
    trailerLocationTextStyle,
    itemListIconStyle,
    itemListStyle,
    itemTitleStyleNotification,
  } = useStyles(appTheme);

  return (
    <>
    <div
      ref={refObject}
        className={rootContainer}
        onClick={() => handleExpandListItem(index, id, currentTimeStamp)} >
      <div>
        <div className={expandedListClass}>
          <div className={itemTitleStyle}>
              {name} |{" "}
              {itemList?.warehouseCategory?.type === "equipment"
              ? "Equipment ID"
              : itemList?.warehouseCategory?.type === "box"
              ? "BOX ID"
              : itemList?.warehouseCategory?.type === "tracking"
              ? "TID"
              : "Pallet Id "}{" "}
              :{" "}
              {itemList?.warehouseCategory
              ? itemList?.warehouseCategory?.id
              : itemList?.palletId}
          </div>
          <div className={itemAreaStyle}>
              {itemList?.warehouseCategory?.type === "equipment"
              ? "Type"
              : category === "trailers"
              ? "Last Location "
              : "Location "}
              -{" "}
              {itemList?.warehouseCategory
              ? itemList?.warehouseCategory?.locationType
              : itemList?.currentLocation}{" "}
              {title}, {area}
          </div>
          <div className={displayFlex}>
              <div>
              <div className={itemListCount}>
                  <img
                  className={itemListIconStyle}
                  src={
                      itemList?.connectedStatus?.toLowerCase() ===
                      "connected"
                      ? WifiIcon
                      : WifiDisconnected
                  }
                  alt=""
                  />
                  {itemList?.connectedStatus}
              </div>
              <div className={itemListName}>{Status}</div>
              </div>
              {itemList?.utilization && (
              <div>
                  <div className={itemListCount}>
                  <img
                      className={itemListIconStyle}
                      src={UtilizationIcon}
                      alt="UtilizationIcon"
                  />
                  {itemList?.utilization}
                  </div>
                  <div className={itemListName}>{Utilization}</div>
              </div>
              )}
              <div>
              <div className={itemListCount}>
                  <img
                  className={itemListIconStyle}
                  src={BatteryIcon}
                  alt=""
                  />
                  {itemList?.battery}
              </div>
              <div className={itemListName}>{Battery}</div>
              </div>
              <div>
              <div className={itemListCount}>
                  <img
                  className={itemListIconStyle}
                  src={TemperatureIcon}
                  alt=""
                  />
                  {itemList?.temperature}
              </div>
              <div className={itemListName}>{Temperature}</div>
              </div>
          </div>
          <div className={timeStampStyle}>{currentTimeStamp}</div>
        </div>
      </div>
    </div>
    </>
  );

}
export default DashboardListItems;
