import { useState, useEffect, createRef } from "react";
import Grid from "@mui/material/Grid";
import Tabs from "../../elements/Tabs";
import SearchBox from "../../elements/SearchBox";
import theme from "../../theme/theme";
import useTranslation from "../../localization/translations";
import { useLanguageContext } from "../../localization/languageContext";
import Button from "../../elements/Button";
import SearchIcon from "../../assets/searchIcon.svg";
import CloseIcon from "../../assets/closeIcon.svg";
import LogisticsUXDLoaderAnimation from "../../assets/Logistics-UXD-Loader-Animation-Updated.gif";
import TreeView from "elements/TreeView";
import TreeViewListConfigured from "elements/TreeViewConfigured";
import useStyles from "./styles";

const ConfigurationLeftPanel: React.FC<any> = (props) => {
  const {
    tabIndex,
    setTabIndex,
    handleOnDrag,
    notConfiguredData,
    draggingCategory,
    isLoaded,
    draggingSubCategory,
    handleWareHouseLabelClick,
    handleWareHouseSubListLabelClick,
    handleWareHouseInnerSubListLabelClick,
    handleConfiguredList,

    setIsConfiguredItemSelected,
    searchValue,
    setSearchValue,
    handleTabs,
    showInfoDialogue,
    seletedTrackerListItemId,
    seletedEquipmentListItemId,
    isItemDragged,
    newDraggingSubCategory,
  } = props;

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const [searchExpanded, setSearchExpanded] = useState<any>([]);
  const [searchSelected, setSearchSelected] = useState<any>([]);

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  const {
    searchClass,
    searchBoxContainer,
    customNotificationTabs,
    tabSection,
    loaderStyle,
  } = useStyles(appTheme);

  const {} = useTranslation();

  const tabsList = [
    { name: "Not Configured", val: 0 },
    { name: "Configured", val: 1 },
  ];

  // const handleTabs = (index: number) => {
  //   setTabIndex(index);
  //   setIsConfiguredItemSelected(false);
  // };

  const handleSearch = (searchValue: any) => {
    const newSearchID: any = [];
    const newSelectedID: any = [];
    let level;

    if (searchValue?.length >= 1) {
      for (let i = 0; i < notConfiguredData.length; i++) {
        if (
          searchValue.toString()?.toLowerCase() ===
          notConfiguredData[i].title.toString()?.toLowerCase()
        ) {
          level = 0;
          newSearchID.push("0");
          newSearchID.push(notConfiguredData[i].searchId);
          newSelectedID.push(notConfiguredData[i].searchId);
        }
      }

      if (level != 0) {
        for (let i = 0; i < notConfiguredData.length; i++) {
          for (let j = 0; j < notConfiguredData[i]?.subList.length; j++) {
            if (notConfiguredData[i].subList[j].category === "tracker") {
              for (
                let k = 0;
                k < notConfiguredData[i]?.subList[j].list.length;
                k++
              ) {
                if (
                  searchValue.toString()?.toLowerCase() ===
                    notConfiguredData[i].subList[j].title
                      .toString()
                      ?.toLowerCase() &&
                  ((notConfiguredData[i].subList[j].list[k].configured &&
                    tabIndex === 1) ||
                    (!notConfiguredData[i].subList[j].list[k].configured &&
                      tabIndex === 0))
                ) {
                  level = 1;
                  newSearchID.push("0");
                  newSearchID.push(notConfiguredData[i].searchId);
                  newSearchID.push(notConfiguredData[i].subList[j].searchId);
                  newSelectedID.push(notConfiguredData[i].subList[j].searchId);
                }
              }
            }
            if (notConfiguredData[i].subList[j].category === "equipment") {
              for (
                let k = 0;
                k < notConfiguredData[i]?.subList[j].list.length;
                k++
              ) {
                for (
                  let l = 0;
                  l <
                  notConfiguredData[i]?.subList[j]?.list[k]?.subListItem
                    ?.length;
                  l++
                ) {
                  if (
                    searchValue.toString()?.toLowerCase() ===
                      notConfiguredData[i].subList[j].title
                        .toString()
                        ?.toLowerCase() &&
                    ((notConfiguredData[i].subList[j].list[k].subListItem[l]
                      .configured &&
                      tabIndex === 1) ||
                      (!notConfiguredData[i].subList[j].list[k].subListItem[l]
                        .configured &&
                        tabIndex === 0))
                  ) {
                    level = 1;
                    newSearchID.push("0");
                    newSearchID.push(notConfiguredData[i].searchId);
                    newSearchID.push(notConfiguredData[i].subList[j].searchId);
                    newSelectedID.push(
                      notConfiguredData[i].subList[j].searchId
                    );
                  }
                }
              }
            }
          }
        }
      }

      if (level != 0 && level != 1) {
        for (let i = 0; i < notConfiguredData.length; i++) {
          for (let j = 0; j < notConfiguredData[i]?.subList.length; j++) {
            if (notConfiguredData[i].subList[j].category === "tracker") {
              for (
                let k = 0;
                k < notConfiguredData[i]?.subList[j].list.length;
                k++
              ) {
                if (
                  searchValue.toString()?.toLowerCase() ===
                    notConfiguredData[i].subList[j].list[k].title
                      .toString()
                      ?.toLowerCase() &&
                  ((notConfiguredData[i].subList[j].list[k].configured &&
                    tabIndex === 1) ||
                    (!notConfiguredData[i].subList[j].list[k].configured &&
                      tabIndex === 0))
                ) {
                  level = 2;
                  newSearchID.push("0");
                  newSearchID.push(notConfiguredData[i].searchId);
                  newSearchID.push(notConfiguredData[i].subList[j].searchId);
                  newSearchID.push(
                    notConfiguredData[i].subList[j].list[k].searchId
                  );
                  newSelectedID.push(
                    notConfiguredData[i].subList[j].list[k].searchId
                  );
                }
              }
            }
            if (notConfiguredData[i].subList[j].category === "equipment") {
              for (
                let k = 0;
                k < notConfiguredData[i]?.subList[j].list.length;
                k++
              ) {
                for (
                  let l = 0;
                  l <
                  notConfiguredData[i]?.subList[j]?.list[k]?.subListItem
                    ?.length;
                  l++
                ) {
                  if (
                    searchValue.toString()?.toLowerCase() ===
                      notConfiguredData[i].subList[j].list[k].title
                        .toString()
                        ?.toLowerCase() &&
                    ((notConfiguredData[i].subList[j].list[k].subListItem[l]
                      .configured &&
                      tabIndex === 1) ||
                      (!notConfiguredData[i].subList[j].list[k].subListItem[l]
                        .configured &&
                        tabIndex === 0))
                  ) {
                    level = 2;
                    newSearchID.push("0");
                    newSearchID.push(notConfiguredData[i].searchId);
                    newSearchID.push(notConfiguredData[i].subList[j].searchId);
                    newSearchID.push(
                      notConfiguredData[i].subList[j].list[k].searchId
                    );
                    newSelectedID.push(
                      notConfiguredData[i].subList[j].list[k].searchId
                    );
                  }
                }
              }
            }
          }
        }
      }

      if (level != 0 && level != 1 && level != 2) {
        for (let i = 0; i < notConfiguredData.length; i++) {
          for (let j = 0; j < notConfiguredData[i]?.subList?.length; j++) {
            for (
              let k = 0;
              k < notConfiguredData[i]?.subList[j]?.list?.length;
              k++
            ) {
              for (
                let l = 0;
                l <
                notConfiguredData[i]?.subList[j]?.list[k]?.subListItem?.length;
                l++
              ) {
                if (
                  searchValue.toString()?.toLowerCase() ===
                    notConfiguredData[i]?.subList[j]?.list[k]?.subListItem[
                      l
                    ]?.title
                      .toString()
                      ?.toLowerCase() &&
                  ((notConfiguredData[i].subList[j].list[k].subListItem[l]
                    .configured &&
                    tabIndex === 1) ||
                    (!notConfiguredData[i].subList[j].list[k].subListItem[l]
                      .configured &&
                      tabIndex === 0))
                ) {
                  level = 3;
                  newSearchID.push("0");
                  newSearchID.push(notConfiguredData[i].searchId);
                  newSearchID.push(notConfiguredData[i].subList[j].searchId);
                  newSearchID.push(
                    notConfiguredData[i].subList[j].list[k].searchId
                  );
                  newSearchID.push(
                    notConfiguredData[i].subList[j].list[k].subListItem[l]
                      .searchId
                  );
                  newSelectedID.push(
                    notConfiguredData[i].subList[j].list[k].subListItem[l]
                      .searchId
                  );
                }
              }
            }
          }
        }
      }

      setSearchExpanded(newSearchID);
      setSearchSelected(newSelectedID);
    } else if (searchValue?.length > 0) {
      setSearchExpanded([]);
      setSearchSelected([]);
    } else {
      setSearchExpanded(["0"]);
      setSearchSelected([]);
    }
  };

  useEffect(() => {
    setSearchExpanded(["0"]);
    setSearchSelected([]);
  }, []);

  useEffect(() => {
    setSearchExpanded(["0"]);
    setSearchSelected([]);
  }, [tabIndex]);

  const handleToggle = (nodeIds: any) => {
    setSearchExpanded(nodeIds);
  };

  const handleToggleConfigured = (nodeIds: any) => {
    setSearchExpanded(nodeIds);
  };

  const handleCloseIcon = () => {
    setSearchExpanded(["0"]);
    setSearchSelected([]);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div className={searchBoxContainer}>
            <SearchBox
              searchInput={searchClass}
              placeHolder={"Search"}
              handleSearch={handleSearch}
              borderRadius={2}
              borderColor={"1px solid #DCDADA"}
              // searchIsOpenOne={true}
              fontColor={"#9C9C9C"}
              handleCloseIcon={handleCloseIcon}
              tabIndex={tabIndex}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={tabSection}>
            <Tabs
              initialIndex={0}
              tabsList={tabsList}
              handleTabs={handleTabs}
              dashboardNotificationClassName={customNotificationTabs}
            />
          </div>
        </Grid>
        {isLoaded ? (
          <Grid item xs={12}>
            {/* <TreeView
              data={notConfiguredData}
              handleOnDrag={handleOnDrag}
              draggingCategory={draggingCategory}
              draggingSubCategory={draggingSubCategory}
             
            /> */}
            {tabIndex === 0 ? (
              <TreeView
                data={searchValue}
                handleOnDrag={handleOnDrag}
                draggingCategory={draggingCategory}
                draggingSubCategory={draggingSubCategory}
                tabIndex={tabIndex}
                handleWareHouseLabelClick={handleWareHouseLabelClick}
                handleWareHouseSubListLabelClick={
                  handleWareHouseSubListLabelClick
                }
                handleWareHouseInnerSubListLabelClick={
                  handleWareHouseInnerSubListLabelClick
                }
                searchExpanded={searchExpanded}
                handleToggle={handleToggle}
                isItemDragged={isItemDragged}
                newDraggingSubCategory={newDraggingSubCategory}
                searchSelected={searchSelected}
              />
            ) : (
              <TreeViewListConfigured
                data={searchValue}
                handleOnDrag={handleOnDrag}
                draggingCategory={draggingCategory}
                draggingSubCategory={draggingSubCategory}
                tabIndex={tabIndex}
                handleConfiguredList={handleConfiguredList}
                searchExpanded={searchExpanded}
                handleToggleConfigured={handleToggleConfigured}
                seletedTrackerListItemId={seletedTrackerListItemId}
                seletedEquipmentListItemId={seletedEquipmentListItemId}
                searchSelected={searchSelected}
              />
            )}
          </Grid>
        ) : (
          <Grid item xs={12}>
            <div className={loaderStyle}>
              <img src={LogisticsUXDLoaderAnimation} width={70} />
            </div>
          </Grid>
        )}
      </Grid>
    </>
  );
};
export default ConfigurationLeftPanel;
