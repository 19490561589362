export const formattedCardListData = (data: any, tabIndex: number) => {
  const dataList: any = [];
  let notficationListData: any = [];
  switch (tabIndex) {
    case 0:
      notficationListData = data?.tracking && data?.tracking[tabIndex]?.list;
      break;
    case 1:
      notficationListData = data?.tracking && data?.tracking[tabIndex]?.list;
      break;
    case 2:
      notficationListData = data?.tracking && data?.tracking[tabIndex]?.list;
      break;
    default:
      break;
  }

  const filteredList =
    notficationListData &&
    notficationListData.length > 0 &&
    notficationListData?.map((item: any) => {
      dataList.push({
        id: item?.id,
        category: item?.category,
        title: item?.title,
        area: item?.area,
        timeStamp: item?.timeStamp,
        listItemValue1: item?.goodsTracked,
        listItemValue2: item?.equippmentTracked,
        listItemValue3: item?.accesspoint,
        listItemValue: [
          item?.goodsTracked,
          item?.equippmentTracked,
          item?.accesspoint,
        ],
      });
    });

  return dataList;
};

export const formattedDashboardListData = (tabIndex: number, data: any) => {
  let warehouseDataArray: any = [];
  let trailersDataArray: any = [];
  let storesDataArray: any = [];
  data?.map((value: any, index: number) => {
    if (value?.category === "wareHouses") {
      warehouseDataArray.push(value);
    } else if (value?.category === "trailers") {
      trailersDataArray.push(value);
    } else if (value?.category === "stores") {
      storesDataArray.push(value);
    }
  });

  let listData: any;
  switch (tabIndex) {
    case 0:
      listData = warehouseDataArray;
      break;
    case 1:
      listData = trailersDataArray;
      break;
    case 2:
      listData = storesDataArray;
      break;
    default:
      break;
  }
  return listData;
};

export const formattedViewDetailData = (tabIndex: number, data: any) => {
  let goodsDataArray: any = [];
  let equipmentsDataArray: any = [];
  let notificationDataArray: any = [];
  data?.map((value: any, index: number) => {
    if (value?.type === "goods") {
      goodsDataArray.push(value);
    } else if (value?.type === "equipments") {
      equipmentsDataArray.push(value);
    } else if (value?.type === "notifications") {
      notificationDataArray.push(value);
    }
  });

  let listData: any;
  switch (tabIndex) {
    case 0:
      listData = goodsDataArray;
      break;
    case 1:
      listData = equipmentsDataArray;
      break;
    case 2:
      listData = notificationDataArray;
      break;
    default:
      break;
  }
  return listData;
};

export const formattedViewDetailList = (
  tabIndex: number,
  data: any,
  chipButtonIndex: number
) => {
  let goodsDataArray: any = [];
  let equipmentsDataArray: any = [];
  let notificationDataArray: any = [];
  let notiEventArray: any = [];
  let notiIncidentArray: any = [];
  let notiOprAlertArray: any = [];
  data?.map((value: any, index: number) => {
    if (value?.type === "goods") {
      goodsDataArray.push(value);
      if (value?.notificationType === "events") {
        notiEventArray.push({ ...value, type: "notifications" });
      } else if (value?.notificationType === "incidents") {
        notiIncidentArray.push({ ...value, type: "notifications" });
      } else if (value?.notificationType === "oprAlerts") {
        notiOprAlertArray.push({ ...value, type: "notifications" });
      }
    } else if (value?.type === "equipments") {
      equipmentsDataArray.push(value);

      if (value?.notificationType === "events") {
        notiEventArray.push({ ...value, type: "notifications" });
      } else if (value?.notificationType === "incidents") {
        notiIncidentArray.push({ ...value, type: "notifications" });
      } else if (value?.notificationType === "oprAlerts") {
        notiOprAlertArray.push({ ...value, type: "notifications" });
      }
    } else if (value?.type === "notifications") {
      notificationDataArray.push(value);
    }
  });

  let listData: any;

  const getCount = (label: any) => {
    switch (label) {
      case "events":
        return notiEventArray?.length;
      case "incidents":
        return notiIncidentArray?.length;
      case "oprAlerts":
        return notiOprAlertArray?.length;
    }
  };

  if (tabIndex === 0) {
    listData = goodsDataArray;
  } else if (tabIndex === 1) {
    listData = equipmentsDataArray;
  } else if (tabIndex === 2) {
    if (chipButtonIndex === 1) {
      listData = notiIncidentArray;
    } else if (chipButtonIndex === 0) {
      listData = notiEventArray;
    } else if (chipButtonIndex === 2) {
      listData = notiOprAlertArray;
    }
  }

  return listData;
};

export const formattedDashboardNotificationList = (
  tabIndex?: number,
  data?: any,
  chipButtonIndex?: number,
  label?: any
) => {
  let goodsDataArray: any = [];
  let equipmentsDataArray: any = [];
  let notificationDataArray: any = [];
  let notiEventArray: any = [];
  let notiIncidentArray: any = [];
  let notiOprAlertArray: any = [];
  data?.map((value: any, index: number) => {
    if (value?.type === "events") {
      notiEventArray.push({ ...value, tabType: "notifications" });
    } else if (value?.type === "incidents") {
      notiIncidentArray.push({ ...value, tabType: "notifications" });
    } else if (value?.type === "oprAlerts") {
      notiOprAlertArray.push({ ...value, tabType: "notifications" });
    }
  });

  let listData: any;

  const getCount = (label: any) => {
    switch (label) {
      case "events":
        return notiEventArray?.length;
      case "incidents":
        return notiIncidentArray?.length;
      case "oprAlerts":
        return notiOprAlertArray?.length;
    }
  };

  switch (chipButtonIndex) {
    case 0:
      listData = notiEventArray;
      break;
    case 1:
      listData = notiIncidentArray;
      break;
    case 2:
      listData = notiOprAlertArray;
      break;
    default:
      break;
  }
  return listData;
};

export const chipDataCount = (data: any, label: string) => {
  let notiEventArray: any = [];
  let notiIncidentArray: any = [];
  let notiOprAlertArray: any = [];
  data?.map((value: any, index: number) => {
    if (value?.type === "events" || value?.notificationType === "events") {
      notiEventArray.push({ ...value, tabType: "notifications" });
    } else if (
      value?.type === "incidents" ||
      value?.notificationType === "incidents"
    ) {
      notiIncidentArray.push({ ...value, tabType: "notifications" });
    } else if (
      value?.type === "oprAlerts" ||
      value?.notificationType === "oprAlerts"
    ) {
      notiOprAlertArray.push({ ...value, tabType: "notifications" });
    }
  });

  switch (label) {
    case "events":
      return notiEventArray?.length;
    case "incidents":
      return notiIncidentArray?.length;
    case "oprAlerts":
      return notiOprAlertArray?.length;
  }
};
