import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import {
  RootContainer,
  Label,
  InputContainer,
  InputPrefixSuffix,
  Input,
  FlexSpace,
  ControlButtonsContainer,
} from "./styles";

const NumberInput = (props: any) => {
  const {
    direction,
    variant,
    size,
    label,
    value,
    valuePrefix,
    valueUnit,
    onChange,
    isActive,
    pageName,
    isEditable,
  } = props;

  const onIncrement = () => {
    if(valueUnit === '%' && value === 100) return null;
    onChange(parseInt(value) + 1);
  };

  const onDecrement = () => {
    if(value === 0) return null;
    onChange(parseInt(value) - 1);
  };

  


  return (
    <RootContainer
      direction={direction}
      isActive={
        pageName === "configRightPanel" ? isActive && isEditable : isActive
      }
      pageName={pageName}
    >
      {pageName !== "popupConfig" && <Label variant={variant}>{label}</Label>}
      <InputContainer variant={variant} size={size}>
        {pageName !== "popupConfig" && (
          <InputPrefixSuffix variant={variant}>{valuePrefix}</InputPrefixSuffix>
        )}
        <Input
          disabled={!isActive && !isEditable}
          onChange={(evt) =>
            pageName === "configRightPanel"
              ? isActive && isEditable
                ? onChange(
                    evt.target.value
                      ? parseInt(evt.target.value)
                      : evt.target.value
                  )
                : null
              : onChange(
                  evt.target.value
                    ? parseInt(evt.target.value)
                    : evt.target.value
                )
          }
          value={value}
          variant={variant}
          length={value.toString().length}
        />
        {pageName !== "popupConfig" && (
          <InputPrefixSuffix variant={variant}>{valueUnit}</InputPrefixSuffix>
        )}
        <FlexSpace />
        {isEditable && (
          <ControlButtonsContainer>
            <KeyboardArrowUp
              fontSize="small"
              sx={{ cursor: "pointer", marginBottom: "-3px" }}
              onClick={() => (pageName === "configRightPanel" ? isActive && isEditable ? onIncrement() : null : onIncrement())}
            />
            <KeyboardArrowDown
              fontSize="small"
              sx={{ cursor: "pointer", marginTop: "-3px" }}
              onClick={() => (pageName === "configRightPanel" ? isActive && isEditable ? onDecrement() : null : onIncrement())}
            />
          </ControlButtonsContainer>
        )}
      </InputContainer>
    </RootContainer>
  );
};

export default NumberInput;
