const wareHouseAnalytics = {
  analytics: [
    {
      id: "overallwarehouseAnalytics",
      category: "overallwarehouseAnalytics",
      graphAnalytics: [
        {
          id: "overallwarehouseDeliveredAnalyticsGraph1",
          title: "Delivered",
          category: "delivered",
          analytics: {
            day: {
              success: {
                value: "+1.0",
                observation: "up",
              },
              wrong: {
                value: "-0.5",
                observation: "down",
              },
              delayed: {
                value: "-0.4",
                observation: "down",
              },
              successAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                  success: "",
                },
              ],
              wrongAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                  wrong: "",
                },
                
              ],
              delayedAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                  delayed: "",
                },
              ],
            },
            weekly: {
              success: {
                value: "+1.0",
                observation: "up",
              },
              wrong: {
                value: "-0.5",
                observation: "down",
              },
              delayed: {
                value: "-0.4",
                observation: "down",
              },
              successAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                  success: "",
                },
              ],
              wrongAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                  wrong: "",
                },
              ],
              delayedAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                  delayed: "",
                },
              ],
            },
            monthly: {
              success: {
                value: "+1.0",
                observation: "up",
              },
              wrong: {
                value: "-0.5",
                observation: "down",
              },
              delayed: {
                value: "-0.4",
                observation: "down",
              },
              successAnalytics: [
               
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 83.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 75.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                  success: "",
                },
              ],
              wrongAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                  wrong: "",
                },
              ],
              delayedAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                  delayed: "",
                },
              ],
            },
            yearly: {
              success: {
                value: "+1.0",
                observation: "up",
              },
              wrong: {
                value: "-0.5",
                observation: "down",
              },
              delayed: {
                value: "-0.4",
                observation: "down",
              },
              successAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                  success: "",
                },
              ],
              wrongAnalytics: [
               
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                  wrong: "",
                },
              ],
              delayedAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                  delayed: "",
                },
              ],
            },
          },
        },
        {
          id: "overallwarehouseDeliveredAnalyticsGraph2",
          title: "Notifications",
          category: "notifications",
          analytics: {
            day: {
              events: 250,
              incidents: 170,
              opralerts: 80,
              totalNotifications: 500,
            },
            weekly: {
              events: 100,
              incidents: 200,
              opralerts: 250,
              totalNotifications: 550,
            },
            monthly: {
              events: 150,
              incidents: 250,
              opralerts: 300,
              totalNotifications: 700,
            },
            yearly: {
              events: 200,
              incidents: 300,
              opralerts: 350,
              totalNotifications: 850,
            },
          },
        },
        {
          id: "overallwarehouseDeliveredAnalyticsGraph3",
          title: "Total Deliveries",
          category: "totalDeliveries",
          analytics: {
            day: {
              analyticsDataUpcoming: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
              ],
              analyticsDataInprogress: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
              ],
              analyticsDataCompleted: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
              ],
            },
            weekly: {
              analyticsDataUpcoming: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
              ],
              analyticsDataInprogress: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
              ],
              analyticsDataCompleted: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
              ],
            },
            monthly: {
              analyticsDataUpcoming: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
              analyticsDataInprogress: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
              analyticsDataCompleted: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
            },
            yearly: {
              analyticsDataUpcoming: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
              analyticsDataInprogress: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
              analyticsDataCompleted: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
        {
          id: "overallwarehouseDeliveredAnalyticsGraph4",
          title: "Equipment Utilization",
          category: "equipmentUtilization",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
              ],
            },
          },
        },
        {
          id: "overallwarehouseDeliveredAnalyticsGraph5",
          title: "Total Goods",
          category: "totalGoods",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "warehouse1Analytics",
      category: "warehouse1Analytics",
      graphAnalytics: [
        {
          id: "warehouse1DeliveredAnalyticsGraph1",
          title: "Delivered",
          category: "delivered",
          analytics: {
            day: {
              success: {
                value: "+1.0",
                observation: "up",
              },
              wrong: {
                value: "-0.5",
                observation: "down",
              },
              delayed: {
                value: "-0.4",
                observation: "down",
              },
              successAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                  success: "",
                },
              ],
              wrongAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                  wrong: "",
                },
                
              ],
              delayedAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                  delayed: "",
                },
              ],
            },
            weekly: {
              success: {
                value: "+1.0",
                observation: "up",
              },
              wrong: {
                value: "-0.5",
                observation: "down",
              },
              delayed: {
                value: "-0.4",
                observation: "down",
              },
              successAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 35.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                  success: "",
                  
                },
              ],
              wrongAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                  
                  wrong: "",
                  
                },
              ],
              delayedAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                  
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                  
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                  
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                  
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                  
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                  
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                  
                  delayed: "",
                },
              ],
            },
            monthly: {
              success: {
                value: "+1.0",
                observation: "up",
              },
              wrong: {
                value: "-0.5",
                observation: "down",
              },
              delayed: {
                value: "-0.4",
                observation: "down",
              },
              successAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 83.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 75.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                  success: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                  success: "",
                  
                },
              ],
              wrongAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  
                  wrong: "",
                  
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                  
                  wrong: "",
                  
                },
              ],
              delayedAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                  delayed: "",
                },
              ],
            },
            yearly: {
              success: {
                value: "+1.0",
                observation: "up",
              },
              wrong: {
                value: "-0.5",
                observation: "down",
              },
              delayed: {
                value: "-0.4",
                observation: "down",
              },
              successAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                  success: "",
                },
              ],
              wrongAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                  wrong: "",
                },
              ],
              delayedAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                  delayed: "",
                },
              ],
            },
          },
        },
        {
          id: "warehouse1DeliveredAnalyticsGraph2",
          title: "Notifications",
          category: "notifications",
          analytics: {
            day: {
              events: 450,
              incidents: 270,
              opralerts: 40,
              totalNotifications: 300,
            },
            weekly: {
              events: 200,
              incidents: 300,
              opralerts: 150,
              totalNotifications: 450,
            },
            monthly: {
              events: 150,
              incidents: 250,
              opralerts: 300,
              totalNotifications: 700,
            },
            yearly: {
              events: 200,
              incidents: 300,
              opralerts: 350,
              totalNotifications: 850,
            },
          },
        },
        {
          id: "warehouse1DeliveredAnalyticsGraph3",
          title: "Total Deliveries",
          category: "totalDeliveries",
          analytics: {
            day: {
              analyticsDataUpcoming: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
              ],
              analyticsDataInprogress: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
              ],
              analyticsDataCompleted: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
              ],
            },
            weekly: {
              analyticsDataUpcoming: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
              ],
              analyticsDataInprogress: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
              ],
              analyticsDataCompleted: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
              ],
            },
            monthly: {
              analyticsDataUpcoming: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
              analyticsDataInprogress: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
              analyticsDataCompleted: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
            },
            yearly: {
              analyticsDataUpcoming: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
              analyticsDataInprogress: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
              analyticsDataCompleted: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
        {
          id: "warehouse1DeliveredAnalyticsGraph4",
          title: "Equipment Utilization",
          category: "equipmentUtilization",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
              ],
            },
          },
        },
        {
          id: "warehouse1DeliveredAnalyticsGraph5",
          title: "Total Goods",
          category: "totalGoods",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "warehouse2Analytics",
      category: "warehouse2Analytics",
      graphAnalytics: [
        {
          id: "warehouse2DeliveredAnalyticsGraph1",
          title: "Delivered",
          category: "delivered",
          analytics: {
            day: {
              success: {
                value: "+1.0",
                observation: "up",
              },
              wrong: {
                value: "-0.5",
                observation: "down",
              },
              delayed: {
                value: "-0.4",
                observation: "down",
              },
              successAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                  success: "",
                },
              ],
              wrongAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                  wrong: "",
                },
                
              ],
              delayedAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                  delayed: "",
                },
              ],
            },
            weekly: {
              success: {
                value: "+1.0",
                observation: "up",
              },
              wrong: {
                value: "-0.5",
                observation: "down",
              },
              delayed: {
                value: "-0.4",
                observation: "down",
              },
              successAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 95.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                  success: "",
                },
              ],
              wrongAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                  wrong: "",
                },
              ],
              delayedAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                  delayed: "",
                },
              ],
            },
            monthly: {
              success: {
                value: "+1.0",
                observation: "up",
              },
              wrong: {
                value: "-0.5",
                observation: "down",
              },
              delayed: {
                value: "-0.4",
                observation: "down",
              },
              successAnalytics: [
               
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 83.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 75.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                  success: "",
                },
              ],
              wrongAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                  wrong: "",
                },
              ],
              delayedAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                  delayed: "",
                },
              ],
            },
            yearly: {
              success: {
                value: "+1.0",
                observation: "up",
              },
              wrong: {
                value: "-0.5",
                observation: "down",
              },
              delayed: {
                value: "-0.4",
                observation: "down",
              },
              successAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                  success: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                  success: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                  success: "",
                },
              ],
              wrongAnalytics: [
               
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                  wrong: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                  wrong: "",
                },
              ],
              delayedAnalytics: [
                
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                  delayed: "",
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                  delayed: "",
                },
              ],
            },
          },
        },
        {
          id: "warehouse2DeliveredAnalyticsGraph2",
          title: "Notifications",
          category: "notifications",
          analytics: {
            day: {
              events: 250,
              incidents: 170,
              opralerts: 80,
              totalNotifications: 500,
            },
            weekly: {
              events: 160,
              incidents: 270,
              opralerts: 150,
              totalNotifications: 550,
            },
            monthly: {
              events: 150,
              incidents: 250,
              opralerts: 300,
              totalNotifications: 700,
            },
            yearly: {
              events: 200,
              incidents: 300,
              opralerts: 350,
              totalNotifications: 850,
            },
          },
        },
        {
          id: "warehouse2DeliveredAnalyticsGraph3",
          title: "Total Deliveries",
          category: "totalDeliveries",
          analytics: {
            day: {
              analyticsDataUpcoming: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
              ],
              analyticsDataInprogress: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
              ],
              analyticsDataCompleted: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 22.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 19.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
              ],
            },
            weekly: {
              analyticsDataUpcoming: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
              ],
              analyticsDataInprogress: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
              ],
              analyticsDataCompleted: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
              ],
            },
            monthly: {
              analyticsDataUpcoming: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
              analyticsDataInprogress: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
              analyticsDataCompleted: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
            },
            yearly: {
              analyticsDataUpcoming: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
              analyticsDataInprogress: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
              analyticsDataCompleted: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
        {
          id: "warehouse2DeliveredAnalyticsGraph4",
          title: "Equipment Utilization",
          category: "equipmentUtilization",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
              ],
            },
          },
        },
        {
          id: "warehouse2DeliveredAnalyticsGraph5",
          title: "Total Goods",
          category: "totalGoods",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
      ],
    },
  ],
};

export default wareHouseAnalytics;
