import { useState, useEffect, createRef } from "react";
import Grid from "@mui/material/Grid";
import Tabs from "../../elements/Tabs";
import SearchBox from "../../elements/SearchBox";
import theme from "../../theme/theme";
import useTranslation from "../../localization/translations";
import { useLanguageContext } from "../../localization/languageContext";
import SearchIcon from "../../assets/searchIcon.svg";
import CloseIcon from "../../assets/closeIcon.svg";
import dragIcon from "../../assets/configurations/drag.svg";
import ConfigRightPanelAddIcon from "../../assets/configurations/ConfigRightPanelAddIcon.svg";
import { Chip } from "@mui/material";
import ConfigurationListItem from "../ConfigurationList/ConfigurationList";
import Button from "../../elements/Button";
import useStyles from "./styles";
import PopUpConfigInfoDialog from "components/PopUpConfigInfoDialog";
// @ts-ignore
import { doc, setDoc, getDoc } from "firebase/firestore/lite";
// @ts-ignore
import { db } from "services/firebase";
// @ts-ignore
import { toast } from "react-toastify";

const CONFIGURED_DATA = {
  trackers: [
    {
      id: "tr1",
      alerts: [
        {
          name: "Events",
          active: true,
          list: [
            {
              name: "Back to Geofence",
              checked: false,
            },
            {
              name: "Pallet Delivered",
              checked: true,
            },
            {
              name: "Delivery Complete",
              checked: true,
            },
            {
              name: "Left Warehouse",
              checked: true,
            },
          ],
        },
        {
          name: "Incidents",
          active: true,
          list: [
            {
              name: "Back to Geofence",
              checked: true,
            },
            {
              name: "Pallet Delivered",
              checked: true,
            },
            {
              name: "Delivery Complete",
              checked: true,
            },
            {
              name: "Left Warehouse",
              checked: true,
            },
          ],
        },
        {
          name: "Ops Alerts",
          active: true,
          list: [
            {
              name: "Back to Geofence",
              checked: true,
            },
            {
              name: "Pallet Delivered",
              checked: true,
            },
            {
              name: "Delivery Complete",
              checked: true,
            },
            {
              name: "Left Warehouse",
              checked: true,
            },
          ],
        },
      ],
      configs: [
        {
          title: "Operating Temperature Threshold",
          isActive: true,
          values: [
            {
              label: "Low Temperature",
              prefix: "<",
              unit: " °F",
              value: 27,
            },
            {
              label: "High Temperature",
              prefix: ">",
              unit: " °F",
              value: 250,
            },
          ],
        },
        {
          title: "Battery Threshold",
          isActive: true,
          values: [
            {
              label: "Low Battery",
              prefix: "<",
              unit: "%",
              value: 20,
            },
          ],
        },
        {
          title: "Humidity Threshold",
          isActive: true,
          values: [
            {
              label: "Low Humidity",
              prefix: "<",
              unit: "%",
              value: 20,
            },
          ],
        },
      ],
    },
  ],
};

const ConfigurationRightPanel: React.FC<any> = (props) => {
  const {
    draggableList,
    handleOnDrop,
    handleDragOver,
    handleChipDelete,
    tabIndex,
    handleSendConfiguration,
    isResetClicked,
    setIsResetClicked,
    rightPanelTabIndex,
    setRightPanelTabIndex,
    isConfiguredItemSelected,
    configuredSelectedCategory,
    configurationIndexArray,
    dbResponseDataToConfig,
  } = props;

  const [firebaseData, setFirebaseData] = useState<any>([]);
  const [backupData, setBackupData] = useState<any>(null);
  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const [isEquipmentCongig, setIsEquipmentCongig] = useState<boolean>(false);

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  const {
    uploadSection,
    dragDeviceSection,
    dragContent,
    dragButton,
    innerDragSection,
    dragImageSection,
    muiChipStyle,
    innerDragSectionConfigured,
    uploadSectionConfigured,
    dragButtonActive,
    tabSection,
    customNotificationTabs,
    configuredRightPanelSection,
    resetButtonClass,
    cancelUpdateButtonContainer,
    cancelButtonClass,
    updateButtonClass,
    addIconStyle,
    rightPanelList,
    insideRightPanelList,
    configurationsRight,
  } = useStyles(appTheme);

  const {} = useTranslation();

  const rightPanelTabsList = [
    { name: "Alerts", val: 0 },
    {
      name:
        configuredSelectedCategory === "tracker"
          ? "Tracker Configuration"
          : "Equipment Configuration",
      val: 1,
    },
  ];

  const alertsDataFormatter = (object: any) => {
    let formattedItems = [];
    if (object.alertType === "events") {
      formattedItems = object.eventItem.map((item: any) => ({
        name: item?.label || item?.name,
        isChecked: item?.isChecked,
      }));
    } else if (object.alertType === "incidents") {
      formattedItems = object.incidentsItem.map((item: any) => ({
        name: item?.label || item?.name,
        isChecked: item?.isChecked ? true : item?.checked ? true : false,
      }));
    } else if (object.alertType === "opsAlert") {
      formattedItems = object.opsAlertItem.map((item: any) => ({
        name: item?.label || item?.name,
        isChecked: item?.isChecked ? true : false,
      }));
    }

    return {
      name: object.title,
      list: formattedItems,
      isDisabled: object.isDisabled,
    };
  };

  const handleRightPanelTabs = (index: number) => {
    setRightPanelTabIndex(index);
    setIsResetClicked(false);
  };

  const handleResetButton = () => {
    setIsResetClicked(true);
  };
  const handleUpdateButton = () => {
    setIsResetClicked(false);
  };
  const handleCancelButton = () => {
    setIsResetClicked(false);
  };

  const [showInfoDialogue, setShowInfoDialogue] = useState<any>(false);

  const onToggleSwitchChange = (index: number, type: any, value: boolean) => {
    const data = JSON.parse(JSON.stringify(firebaseData));
    const category =
      data.wareHouses[configurationIndexArray[0]].subList[
        configurationIndexArray[1]
      ].category;

    if (category === "equipment") {
      if (type === "tracker") {
        console.log("TRACKER DATA UPDATED", configurationIndexArray, data);
        data.wareHouses[configurationIndexArray[0]].subList[
          configurationIndexArray[1]
        ].list[configurationIndexArray[2]].subListItem[
          configurationIndexArray[3]
        ].trackerConfiguration[index].isDisabled = !value;
      } else {
        data.wareHouses[configurationIndexArray[0]].subList[
          configurationIndexArray[1]
        ].list[configurationIndexArray[2]].subListItem[
          configurationIndexArray[3]
        ].alertConfiguredList[index].isDisabled = !value;
      }
    } else {
      if (type === "tracker") {
        data.wareHouses[configurationIndexArray[0]].subList[
          configurationIndexArray[1]
        ].list[configurationIndexArray[2]].trackerConfiguration[
          index
        ].isDisabled = !value;
      } else {
        data.wareHouses[configurationIndexArray[0]].subList[
          configurationIndexArray[1]
        ].list[configurationIndexArray[2]].alertConfiguredList[
          index
        ].isDisabled = !value;
      }
    }
    // if (!isResetClicked) setBackupData(firebaseData);
    setFirebaseData(data);
    // setIsResetClicked(true);

    const notConfiguredListRef = doc(db, "configuration", "notConfiguredList");
    setDoc(notConfiguredListRef, data, { merge: true })
      .then((success: any) => {
        setBackupData(null);
        toast.success("Changed Saved Successfully");
        setIsResetClicked(false);
        getDoc(notConfiguredListRef)
          .then((response: any) => {
            const dbResponse = response?.data();
            setShowInfoDialogue(false);
            setFirebaseData(dbResponse);
          })
          .catch((error) => {
            toast.success("Oops! Something went wrong");
          });
      })
      .catch((error: any) => {
        toast.success("Oops! Something went wrong");
      });
  };

  const onFirebaseDataSave = () => {};

  const updateAlert = (index: number, object: any, type: string) => {
    const oldFirebaseData = { ...firebaseData };
    const category =
      oldFirebaseData.wareHouses[configurationIndexArray[0]].subList[
        configurationIndexArray[1]
      ].category;

    if (!backupData) {
      setBackupData(JSON.parse(JSON.stringify(oldFirebaseData)));
    }

    if (category === "equipment") {
      if (type === "alert") {
        const alertType =
          oldFirebaseData.wareHouses[configurationIndexArray[0]].subList[
            configurationIndexArray[1]
          ].list[configurationIndexArray[2]].subListItem[
            configurationIndexArray[3]
          ].alertConfiguredList[index].alertType;
        if (alertType === "events") {
          oldFirebaseData.wareHouses[configurationIndexArray[0]].subList[
            configurationIndexArray[1]
          ].list[configurationIndexArray[2]].subListItem[
            configurationIndexArray[3]
          ].alertConfiguredList[index].eventItem = object;
        } else if (alertType === "incidents") {
          oldFirebaseData.wareHouses[configurationIndexArray[0]].subList[
            configurationIndexArray[1]
          ].list[configurationIndexArray[2]].subListItem[
            configurationIndexArray[3]
          ].alertConfiguredList[index].incidentsItem = object;
        } else {
          oldFirebaseData.wareHouses[configurationIndexArray[0]].subList[
            configurationIndexArray[1]
          ].list[configurationIndexArray[2]].subListItem[
            configurationIndexArray[3]
          ].alertConfiguredList[index].opsAlertItem = object;
        }
      } else {
        oldFirebaseData.wareHouses[configurationIndexArray[0]].subList[
          configurationIndexArray[1]
        ].list[configurationIndexArray[2]].subListItem[
          configurationIndexArray[3]
        ].trackerConfiguration[index].values = object;
      }
    } else {
      if (type === "alert") {
        const alertType =
          oldFirebaseData.wareHouses[configurationIndexArray[0]].subList[
            configurationIndexArray[1]
          ].list[configurationIndexArray[2]].alertConfiguredList[index]
            .alertType;
        if (alertType === "events") {
          oldFirebaseData.wareHouses[configurationIndexArray[0]].subList[
            configurationIndexArray[1]
          ].list[configurationIndexArray[2]].alertConfiguredList[
            index
          ].eventItem = object;
        } else if (alertType === "incidents") {
          oldFirebaseData.wareHouses[configurationIndexArray[0]].subList[
            configurationIndexArray[1]
          ].list[configurationIndexArray[2]].alertConfiguredList[
            index
          ].incidentsItem = object;
        } else {
          oldFirebaseData.wareHouses[configurationIndexArray[0]].subList[
            configurationIndexArray[1]
          ].list[configurationIndexArray[2]].alertConfiguredList[
            index
          ].opsAlertItem = object;
        }
      } else {
        oldFirebaseData.wareHouses[configurationIndexArray[0]].subList[
          configurationIndexArray[1]
        ].list[configurationIndexArray[2]].trackerConfiguration[index].values =
          object;
      }
    }

    // const newData = oldFirebaseData.wareHouses.map((warehouse: any) => {
    //   const newSublist = warehouse.subList.map((sublist: any) => {
    //     const newList = sublist.list.map((list: any) => {
    //       if (sublist.category === "equipment") {
    //         const newSublistItem = list.subListItem.map((subListItem: any) => {
    //           if (type === "alert") {
    //             if (!subListItem.alertConfiguredList) {
    //               return subListItem;
    //             }
    //             const newAlerts = [...subListItem.alertConfiguredList];
    //             if (newAlerts[index].alertType === "events") {
    //               newAlerts[index].eventItem = object;
    //               return { ...subListItem, alertConfiguredList: newAlerts };
    //             } else if (newAlerts[index].alertType === "incidents") {
    //               newAlerts[index].incidentsItem = object;
    //               return { ...subListItem, alertConfiguredList: newAlerts };
    //             } else {
    //               newAlerts[index].opsAlertItem = object;
    //               return { ...subListItem, alertConfiguredList: newAlerts };
    //             }
    //           } else {
    //             if (!subListItem.alertConfiguredList) {
    //               return subListItem;
    //             }
    //             const newConfig = [...subListItem.trackerConfiguration];
    //             newConfig[index].values = object;
    //             return { ...subListItem, trackerConfiguration: newConfig };
    //           }
    //         });
    //         return { ...list, subListItem: newSublistItem };
    //       } else {
    //         if (type === "alert") {
    //           if (!list.alertConfiguredList) {
    //             return list;
    //           }
    //           const newAlerts = [...list.alertConfiguredList];
    //           if (newAlerts[index].alertType === "events") {
    //             newAlerts[index].eventItem = object;
    //             return { ...list, alertConfiguredList: newAlerts };
    //           } else if (newAlerts[index].alertType === "incidents") {
    //             newAlerts[index].incidentsItem = object;
    //             return { ...list, alertConfiguredList: newAlerts };
    //           } else {
    //             newAlerts[index].opsAlertItem = object;
    //             return { ...list, alertConfiguredList: newAlerts };
    //           }
    //         } else {
    //           if (!list.alertConfiguredList) {
    //             return list;
    //           }
    //           const newConfig = [...list.trackerConfiguration];
    //           newConfig[index].values = object;
    //           return { ...list, trackerConfiguration: newConfig };
    //         }
    //       }
    //     });
    //     return { ...sublist, list: newList };
    //   });
    //   return { ...warehouse, subList: newSublist };
    // });
    setFirebaseData(oldFirebaseData);
    // const notConfiguredListRef = doc(db, "configuration", "notConfiguredList");
    // setDoc(notConfiguredListRef, { wareHouses: newData }, { merge: true })
    //   .then((success: any) => {
    //     getDoc(notConfiguredListRef)
    //       .then((response: any) => {
    //         const dbResponse = response?.data();
    //         setShowInfoDialogue(false);
    //         setFirebaseData(dbResponse);
    //       })
    //       .catch((error) => console.log(error.message));
    //   })
    //   .catch((error: any) => console.log(error));
  };

  const addAlert = (name: string) => {
    const oldFirebaseData = { ...firebaseData };
    const category =
      oldFirebaseData.wareHouses[configurationIndexArray[0]].subList[
        configurationIndexArray[1]
      ].category;
    
    if (category === "equipment") {
      if (showInfoDialogue === "Events") {
        oldFirebaseData.wareHouses[configurationIndexArray[0]].subList[
          configurationIndexArray[1]
        ].list[configurationIndexArray[2]].subListItem[
          configurationIndexArray[3]
        ].alertConfiguredList[0].eventItem.push({
          label: name,
          isChecked: true,
        });
      } else if (showInfoDialogue === "Incidents") {
        oldFirebaseData.wareHouses[configurationIndexArray[0]].subList[
          configurationIndexArray[1]
        ].list[configurationIndexArray[2]].subListItem[
          configurationIndexArray[3]
        ].alertConfiguredList[1].incidentsItem.push({
          label: name,
          isChecked: true,
        });
      } else if (showInfoDialogue === "Ops Alert") {
        oldFirebaseData.wareHouses[configurationIndexArray[0]].subList[
          configurationIndexArray[1]
        ].list[configurationIndexArray[2]].subListItem[
          configurationIndexArray[3]
        ].alertConfiguredList[2].opsAlertItem.push({
          label: name,
          isChecked: true,
        });
      } else {
        oldFirebaseData.wareHouses[configurationIndexArray[0]].subList[
          configurationIndexArray[1]
        ].list[configurationIndexArray[2]].subListItem[
          configurationIndexArray[3]
        ].trackerConfiguration.push(name);
      }
    } else {
      if (showInfoDialogue === "Events") {
        oldFirebaseData.wareHouses[configurationIndexArray[0]].subList[
          configurationIndexArray[1]
        ].list[
          configurationIndexArray[2]
        ].alertConfiguredList[0].eventItem.push({
          label: name,
          isChecked: true,
        });
      } else if (showInfoDialogue === "Incidents") {
        oldFirebaseData.wareHouses[configurationIndexArray[0]].subList[
          configurationIndexArray[1]
        ].list[
          configurationIndexArray[2]
        ].alertConfiguredList[1].incidentsItem.push({
          label: name,
          isChecked: true,
        });
      } else if (showInfoDialogue === "Ops Alert") {
        oldFirebaseData.wareHouses[configurationIndexArray[0]].subList[
          configurationIndexArray[1]
        ].list[
          configurationIndexArray[2]
        ].alertConfiguredList[2].opsAlertItem.push({
          label: name,
          isChecked: true,
        });
      } else {
        oldFirebaseData.wareHouses[configurationIndexArray[0]].subList[
          configurationIndexArray[1]
        ].list[configurationIndexArray[2]].trackerConfiguration.push(name);
      }
    }

    const notConfiguredListRef = doc(db, "configuration", "notConfiguredList");
    setDoc(notConfiguredListRef, oldFirebaseData, { merge: true })
      .then((success: any) => {
        setBackupData(null);
        toast.success("Changed Saved Successfully");
        setIsResetClicked(false);
        getDoc(notConfiguredListRef)
          .then((response: any) => {
            const dbResponse = response?.data();
            setShowInfoDialogue(false);
            setFirebaseData(dbResponse);
          })
          .catch((error) => {
            toast.success("Oops! Something went wrong");
          });
      })
      .catch((error: any) => {
        toast.success("Oops! Something went wrong");
      });

    // setFirebaseData(oldFirebaseData);
    // setIsResetClicked(true);
    // setShowInfoDialogue(false);
  };

  const onDataSave = () => {
    const notConfiguredListRef = doc(db, "configuration", "notConfiguredList");
    setDoc(notConfiguredListRef, firebaseData, { merge: true })
      .then((success: any) => {
        setBackupData(null);
        toast.success("Changed Saved Successfully");
        setIsResetClicked(false);
        getDoc(notConfiguredListRef)
          .then((response: any) => {
            const dbResponse = response?.data();
            setShowInfoDialogue(false);
            setFirebaseData(dbResponse);
          })
          .catch((error) => {
            toast.success("Oops! Something went wrong");
          });
      })
      .catch((error: any) => {
        toast.success("Oops! Something went wrong");
      });
  };

  const onDataCancel = () => {
      

    const notConfiguredListRef = doc(db, "configuration", "notConfiguredList");
    getDoc(notConfiguredListRef)
          .then((response: any) => {
            const dbResponse = response?.data();            
            setFirebaseData(dbResponse);
          })
          .catch((error) => {
            toast.success("Oops! Something went wrong");
          });

          if (!backupData) {
            // setFirebaseData(backupData);
            setBackupData(null);
          }
          
          setIsResetClicked(false);
  };

  useEffect(() => {
    const notConfiguredListRef = doc(db, "configuration", "notConfiguredList");
    getDoc(notConfiguredListRef)
      .then((response: any) => {
        const dbResponse = response?.data();
        setFirebaseData(dbResponse);
      })
      .catch((error) => console.log(error.message));
  }, [dbResponseDataToConfig]);

  return (
    <>
      {tabIndex === 0 ? (
        <div className={uploadSection}>
          <div className={dragDeviceSection}>
            <div className={innerDragSection}>
              <div className={dragImageSection}>
                {" "}
                <img src={dragIcon} />
              </div>
              <div
                className={dragContent}
                onDrop={(e) => handleOnDrop(e)}
                onDragOver={(e) => handleDragOver(e)}
              >
                {draggableList && draggableList?.length > 0
                  ? draggableList.map((item: any, index: any) => {
                      return (
                        <Chip
                          label={item?.title}
                          onDelete={() => handleChipDelete(item, item?.id)}
                          style={{
                            background: "#9083D1",
                            color: "white",
                            margin: 5,
                          }}
                          className={muiChipStyle}
                        />
                      );
                    })
                  : " Drag device from the sidebar and drop here to start"}
              </div>

              <div
                className={
                  draggableList?.length > 0 ? dragButtonActive : dragButton
                }
              >
                <Button
                  contained="variant"
                  handleClick={() => handleSendConfiguration(draggableList)}
                >
                  Send for Configuration
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : !isConfiguredItemSelected ? (
        <div className={configurationsRight}>
          {" "}
          <div className={uploadSectionConfigured}>
            <div className={innerDragSectionConfigured}>
              <div>
                <div>Select device and view the configuration</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={configuredRightPanelSection}>
          <div className={tabSection}>
            <Tabs
              initialIndex={rightPanelTabIndex}
              tabsList={rightPanelTabsList}
              handleTabs={handleRightPanelTabs}
              dashboardNotificationClassName={customNotificationTabs}
            />
            {!isResetClicked ? (
              <>
                {rightPanelTabIndex === 1 && (
                  <div
                    className={addIconStyle}
                    onClick={() => setShowInfoDialogue(true)}
                  >
                    <img
                      src={ConfigRightPanelAddIcon}
                      alt="ConfigRightPanelAddIcon"
                    />
                  </div>
                )}

                <div className={resetButtonClass}>
                  <Button contained="variant" handleClick={handleResetButton}>
                    Reset
                  </Button>
                </div>
              </>
            ) : (
              <div className={cancelUpdateButtonContainer}>
                <div className={cancelButtonClass}>
                  <Button contained="variant" handleClick={onDataCancel}>
                    Cancel
                  </Button>
                </div>
                <div className={updateButtonClass}>
                  <Button contained="variant" handleClick={onDataSave}>
                    Update
                  </Button>
                </div>
              </div>
            )}
          </div>
          <div className={rightPanelList}>
            {rightPanelTabIndex === 0 ? (
              <div className={insideRightPanelList}>
                {firebaseData.wareHouses[configurationIndexArray[0]].subList[
                  configurationIndexArray[1]
                ].list[configurationIndexArray[2]]?.alertConfiguredList ? (
                  firebaseData.wareHouses[configurationIndexArray[0]].subList[
                    configurationIndexArray[1]
                  ].list[configurationIndexArray[2]].alertConfiguredList.map(
                    (alert: any, id: number) => {
                      const formattedObject = alertsDataFormatter(alert);
                      return (
                        <ConfigurationListItem
                          id={id}
                          title={formattedObject.name}
                          list={formattedObject.list}
                          isEnabled={formattedObject.isDisabled}
                          tab={rightPanelTabIndex}
                          onChange={(item: any) =>
                            updateAlert(id, item, "alert")
                          }
                          isDisabled={formattedObject.isDisabled}
                          onToggleSwitchChange={onToggleSwitchChange}
                          isEditable={isResetClicked}
                          variant="light"
                          onHeaderRightButtonClick={() =>
                            setShowInfoDialogue(formattedObject.name)
                          }
                          pageName={"configRightPanel"}
                        />
                      );
                    }
                  )
                ) : configurationIndexArray.length === 4 ? (
                  firebaseData.wareHouses[configurationIndexArray[0]].subList[
                    configurationIndexArray[1]
                  ].list[configurationIndexArray[2]].subListItem[
                    configurationIndexArray[3]
                  ].alertConfiguredList.map((alert: any, id: number) => {
                    const formattedObject = alertsDataFormatter(alert);
                    return (
                      <ConfigurationListItem
                        id={id}
                        title={formattedObject.name}
                        list={formattedObject.list}
                        tab={rightPanelTabIndex}
                        onChange={(item: any) => updateAlert(id, item, "alert")}
                        isEditable={isResetClicked}
                        onToggleSwitchChange={onToggleSwitchChange}
                        isDisabled={formattedObject.isDisabled}
                        variant="light"
                        onHeaderRightButtonClick={() =>
                          setShowInfoDialogue(formattedObject.name)
                        }
                        pageName={"configRightPanel"}
                      />
                    );
                  })
                ) : (
                  <div>
                    {" "}
                    <div className={uploadSectionConfigured}>
                      <div className={innerDragSectionConfigured}>
                        <div>
                          <div>Select device and view the configuration</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className={insideRightPanelList}>
                {!firebaseData.wareHouses[configurationIndexArray[0]].subList[
                  configurationIndexArray[1]
                ].list[configurationIndexArray[2]].subListItem ? (
                  firebaseData.wareHouses[configurationIndexArray[0]].subList[
                    configurationIndexArray[1]
                  ].list[configurationIndexArray[2]].trackerConfiguration.map(
                    (config: any, id: number) => {
                      console.log("CONFIG", config, configurationIndexArray)
                      console.log("firebase Data", firebaseData.wareHouses[configurationIndexArray[0]].subList[
                        configurationIndexArray[1]
                      ].list[configurationIndexArray[2]])
                      return (
                      <ConfigurationListItem
                        isEditable={isResetClicked}
                        hideHeaderRightButton={true}
                        id={id}
                        title={config.title}
                        list={config.values}
                        tab={tabIndex}
                        isDisabled={config.isDisabled}
                        onToggleSwitchChange={onToggleSwitchChange}
                        onChange={(item: any) =>
                          updateAlert(id, item, "config")
                        }
                        variant="light"
                        pageName={"configRightPanel"}
                      />
                    )}
                  )
                ) : configurationIndexArray.length === 4 ? (
                  firebaseData.wareHouses[configurationIndexArray[0]].subList[
                    configurationIndexArray[1]
                  ].list[configurationIndexArray[2]].subListItem[
                    configurationIndexArray[3]
                  ].trackerConfiguration.map((config: any, id: number) => {
                    console.log("Config", config);
                    return (
                      <ConfigurationListItem
                        isEditable={isResetClicked}
                        hideHeaderRightButton={true}
                        id={id}
                        title={config.title}
                        list={config.values}
                        onToggleSwitchChange={onToggleSwitchChange}
                        tab={tabIndex}
                        isDisabled={config.isDisabled}
                        onChange={(item: any) => updateAlert(id, item, "config")}
                        variant="light"
                        pageName={"configRightPanel"}
                      />
                  )})
                ) : (
                  <div>
                    {" "}
                    <div className={uploadSectionConfigured}>
                      <div className={innerDragSectionConfigured}>
                        <div>
                          <div>Select device and view the configuration</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      <div>
        {showInfoDialogue && (
          <PopUpConfigInfoDialog
            alertType={showInfoDialogue}
            isEquipmentCongig={showInfoDialogue === true}
            setShowInfoDialogue={setShowInfoDialogue}
            onSave={addAlert}
            configurationIndexArray={configurationIndexArray.length}
            allAlert={configurationIndexArray.length === 4 ?firebaseData.wareHouses[configurationIndexArray[0]].subList[
              configurationIndexArray[1]
            ].list[configurationIndexArray[2]].subListItem[
              configurationIndexArray[3]
            ].alertConfiguredList:firebaseData.wareHouses[configurationIndexArray[0]].subList[
              configurationIndexArray[1]
            ].list[configurationIndexArray[2]].alertConfiguredList}
            allAlerts={configurationIndexArray.length === 4 ?firebaseData.wareHouses[configurationIndexArray[0]].subList[
              configurationIndexArray[1]
            ].list[configurationIndexArray[2]].subListItem[
              configurationIndexArray[3]
            ].alertConfiguredList:firebaseData.wareHouses[configurationIndexArray[0]].subList[
              configurationIndexArray[1]
            ].list[configurationIndexArray[2]].alertConfiguredList}
            allConfigurations={configurationIndexArray.length === 4 ?firebaseData.wareHouses[configurationIndexArray[0]].subList[
              configurationIndexArray[1]
            ].list[configurationIndexArray[2]].subListItem[
              configurationIndexArray[3]
            ].trackerConfiguration:firebaseData.wareHouses[configurationIndexArray[0]].subList[
              configurationIndexArray[1]
            ].list[configurationIndexArray[2]].trackerConfiguration}
          />
        )}
      </div>
    </>
  );
};
export default ConfigurationRightPanel;
