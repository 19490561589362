import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import muiTheme from "theme/muiTheme";

const useStyles = makeStyles(
  () => ({
    root: (props: any) => ({
      flexGrow: 1,
    }),

    stepperSx: (props: any) => ({
      "& .MuiStepConnector-root": {
        left: "calc(-50% + 14px)",
        right: "calc(50% + 14px)",
      },

      "& .MuiStepConnector-line": {
        marginTop: "44px", // To positio5n the line lower
        border: `3px solid ${props?.palette?.stepperComponent?.colorWhite}`,
        // borderBottom: "3px dashed #456EFF",
        // borderTop: "0px",
      },
      "& .MuiStepLabel-alternativeLabel": {
        color: `${props?.palette?.stepperComponent?.colorWhite}!important`,
        paddingBottom: "4px",
        [muiTheme.breakpoints.down(1437)]: {
          fontSize: " 10px !important",
          fontFamily: `'Nunito Sans', sans-serif !important`,
        },
      },
    }),

    // Solution 2
    typographySx: (props: any) => ({
      align: "center",
      padding: "8px 0px",
      fontSize: "14px !important",
      fontFamily: `'Nunito Sans', sans-serif !important`,
      [muiTheme.breakpoints.down(1437)]: {
        fontSize: " 10px !important",
        minHeight: "30px !important",
      },
    }),
  }),
  { index: 1 }
);

export default useStyles;

export const ColorlibStepIconRoot = styled("div")<
  { paletteColor: string; paletteColor1: string; greenShade: string } | any
>(({ theme, ownerState, purpleShades, colorWhite, greenShade }) => ({
  zIndex: 1,
  color: colorWhite,

  width: 30,
  height: 30,
  display: "flex",
  borderRadius: "50%",
  border: `5px solid ${colorWhite}`,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: purpleShades,
  ...(ownerState.active && {
    backgroundColor: greenShade,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundColor: purpleShades,
  }),
  [muiTheme.breakpoints.down(1437)]: {
    width: 15,
    height: 15,
    border: `2px solid ${colorWhite}`,
  },
}));
