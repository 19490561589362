import { useState } from "react";
import { Typography, Box } from "@mui/material";
import {
  InfoWindow,
  OverlayViewF,
  OverlayViewProps,
  InfoWindowF,
  MarkerF,
} from "@react-google-maps/api";
import ProgressBar from "react-customizable-progressbar";
import MarkerPng from "assets/dummy_marker.png";
import markerIcons from "assets/marker-icons";
import theme from "theme/theme";
import MarkerInfoWindow from "../InfoWindow";
import { RootContainer, MarkerImage, MarkerBackground } from "./styles";
import { useEffect } from "react";

enum Notification {
  EVENTS = "events",
  INCIDENTS = "incidents",
  OPALERTS = "oprAlerts",
}

enum Category {
  STORE = "stores",
  WAREHOUSE = "wareHouses",
  TRANSIT = "trailers",
}

type MarkerProps = {
  id: string;
  location: OverlayViewProps["position"];
  direction?: string | undefined;
  progress?: number | undefined;
  label?: Notification | string;
  category?: Category | string;
  currentFocusedMarker?: string;
  onClick: (id: string) => void;
  marker?: any;
  notificationTimeStamp?: any;
  setSelectedNotification?: any;
  handleMarkerClose?: any;
  setViewDetailActive?: any;
  selectedNotification: any;
  selectedTabButton?: any;
  setSelectedTabButton?: any;
  buttonIndex?: any;
  movingMarker?: boolean;
  tabIndex?: number;
  selectedItem?: any;
  setShowInfoDialogue?: any;
  showInfoDialogue?: any;
  handleButtonClick?:any;
};

const Marker = (props: MarkerProps) => {
  const {
    id,
    location,
    direction,
    progress,
    label,
    category,
    currentFocusedMarker,
    onClick,
    marker,
    notificationTimeStamp,
    setSelectedNotification,
    handleMarkerClose,
    setViewDetailActive,
    selectedNotification,
    selectedTabButton,
    setSelectedTabButton,
    buttonIndex,
    movingMarker,
    tabIndex,
    selectedItem,
    setShowInfoDialogue,
    showInfoDialogue,
    handleButtonClick,
  } = props;

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const [movingLocation, setMovingLocation] = useState<any>(location);

  useEffect(() => {
    if (marker?.category === "trailers") {
      if (marker?.id === "MID#213") {
        setInterval(() => {
          setMovingLocation((prev: any) => ({
            lat: prev.lat - 0.03,
            lng: prev.lng + 0.03,
          }));
        }, 1000);
      } else if (marker?.id === "MID#188") {
        setInterval(() => {
          setMovingLocation((prev: any) => ({
            lat: prev.lat + 0.03,
            lng: prev.lng - 0.03,
          }));
        }, 1000);
      } else if (marker?.id === "MID#464") {
        setInterval(() => {
          setMovingLocation((prev: any) => ({
            lat: prev.lat + 0.03,
            lng: prev.lng + 0.03,
          }));
        }, 1000);
      } else if (marker?.id === "tr-321") {
        setInterval(() => {
          setMovingLocation((prev: any) => ({
            lat: prev.lat + 0.03,
            lng: prev.lng + 0.03,
          }));
        }, 1000);
      } else {
        setInterval(() => {
          setMovingLocation((prev: any) => ({
            lat: prev.lat - 0.03,
            lng: prev.lng + 0.03,
          }));
        }, 1000);
      }
    }
  }, [location]);

  const getMarkerStyles = (label: string | undefined) => {
    switch (category) {
      case Category.TRANSIT: {
        switch (label) {
          case Notification.EVENTS:
            return {
              marker: markerIcons.transitEvent,
              color: appTheme.palette.map.markerProgressGreen,
            };
          case Notification.INCIDENTS:
            return {
              marker: markerIcons.transitIncident,
              color: appTheme.palette.map.markerProgressRed,
            };
          case Notification.OPALERTS:
            return {
              marker: markerIcons.transitOpAlert,
              color: appTheme.palette.map.markerProgressYellow,
            };
          default:
            return {
              marker: markerIcons.transitEvent,
              color: appTheme.palette.map.markerProgressGreen,
            };
        }
      }
      case Category.WAREHOUSE: {
        switch (label) {
          case Notification.EVENTS:
            return {
              icon: markerIcons.warehouseEvent,
              color: appTheme.palette.map.markerProgressGreen,
            };
          case Notification.INCIDENTS:
            return {
              icon: markerIcons.warehouseIncident,
              color: appTheme.palette.map.markerProgressRed,
            };
          case Notification.OPALERTS:
            return {
              icon: markerIcons.warehouseOpAlert,
              color: appTheme.palette.map.markerProgressYellow,
            };
          default:
            return {
              icon: markerIcons.warehouseEvent,
              color: appTheme.palette.map.markerProgressGreen,
            };
        }
      }
      case Category.STORE: {
        switch (label) {
          case Notification.EVENTS:
            return { icon: markerIcons.storeEvent, color: "" };
          case Notification.INCIDENTS:
            return { icon: markerIcons.storeIncident, color: "" };
          case Notification.OPALERTS:
            return { icon: markerIcons.storeOpAlert, color: "" };
          default:
            return { icon: markerIcons.storeEvent, color: "" };
        }
      }
    }
  };

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  });

  if (category !== Category.TRANSIT) {
    return (
      <MarkerF
        position={location || { lat: 39.0902, lng: -100.35 }}
        icon={getMarkerStyles(label)?.icon}
        onClick={() => onClick(id)}
      >
        {currentFocusedMarker === id ? (
          <InfoWindowF>
            <MarkerInfoWindow
              data={marker?.id}
              marker={marker}
              notificationTimeStamp={notificationTimeStamp}
              setSelectedNotification={setSelectedNotification}
              handleMarkerClose={handleMarkerClose}
              setViewDetailActive={setViewDetailActive}
              pageName={"dashboard"}
              selectedNotification={selectedNotification}
              selectedTabButton={selectedTabButton}
              setSelectedTabButton={setSelectedTabButton}
              tabIndex={tabIndex}              
              handleButtonClick={handleButtonClick}
            />
          </InfoWindowF>
        ) : null}
      </MarkerF>
    );
  }

  const getRotationAngle = (direction?: string) => {
    switch (direction) {
      case "N":
        return "0deg";
      case "NE":
        return "45deg";
      case "SE":
        return "135deg";
      case "NW":
        return "-45deg";
      default:
        return "0deg";
    }
  };

  return (
    <OverlayViewF mapPaneName="overlayMouseTarget" position={movingLocation}>
      <RootContainer
        onClick={() => onClick(marker)}
        movingMarker={movingMarker}
      >
        {movingMarker && <MarkerBackground />}
        <ProgressBar
          progress={progress || 0}
          radius={35}
          strokeWidth={10}
          trackStrokeWidth={10}
          strokeColor={getMarkerStyles(label)?.color}
          trackStrokeColor={appTheme.palette.map.progressTrack}
        />
        <MarkerImage
          angle={getRotationAngle(direction)}
          src={getMarkerStyles(label)?.marker}
        />
      </RootContainer>
      {currentFocusedMarker === id ? (
        <InfoWindowF
          position={movingLocation}
          options={{ pixelOffset: new google.maps.Size(0, -20) }}
        >
          <MarkerInfoWindow
            data={marker?.id}
            marker={marker}
            notificationTimeStamp={notificationTimeStamp}
            setSelectedNotification={setSelectedNotification}
            handleMarkerClose={handleMarkerClose}
            setViewDetailActive={setViewDetailActive}
            pageName={"dashboard"}
            selectedNotification={selectedNotification}
            selectselectedTabButton={selectedTabButton}
            buttonIndex={buttonIndex}
            tabIndex={tabIndex}
            
            handleButtonClick={handleButtonClick}
          />
        </InfoWindowF>
      ) : null}
    </OverlayViewF>
  );
};
export default Marker;
