import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  paginationSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& .rowsperpage": {
      fontFamily: "Nunito Sans",
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "16px",
      color: " #777777",
      fontStyle: "italic",
      marginRight: 12,
    },
  },

  customPaginationSection: (props: any) => ({
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "end",
    background: "#FFFFFF",
    border: "0.3px solid #F0F0F0",
    boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    padding: 4,
  }),
  totalRecords: (props: any) => ({
    fontFamily: `'Nunito Sans', sans-serif !important`,
    fontStyle: "italic",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    color: "#777777",
    background: "#FBFBFF",
    border: "0.5px solid #DADAFA",
    borderRadius: "4px",
    padding: "6px 10px",
  }),
  arrowBox: (props: any) => ({
    color: "#626262",
    background: " #FBFBFF",
    border: " 0.5px solid #DADAFA",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 28,
    height: 28,
    marginLeft: 11,
    cursor: "pointer",
  }),
  arrowDisableBox: (props: any) => ({
    color: "#626262",
    background: "lightgrey",
    border: " 0.5px solid #DADAFA",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 28,
    height: 28,
    marginLeft: 11,
    pointerEvents: "none",
  }),
  selectItem: (props: any) => ({
    color: "#dfdfdf",
    background: " #FBFBFF",
    border: " 0.5px solid #DADAFA",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 28,
    height: 28,
    marginLeft: 11,
  }),
  countNum: (props: any) => ({
    fontFamily: `'Nunito Sans', sans-serif !important`,
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    color: "#626262",
    marginLeft: 11,
    display: "flex",
    alignItems: "center",
  }),
  arrowNumberBox: (props: any) => ({
    color: "#ffffff",
    background: "#38385A",
    border: " 0.5px solid #DADAFA",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 28,
    height: 28,
    marginLeft: 11,
    cursor: "pointer",
  }),
  countNumRow: (props: any) => ({
    display: "flex",
    alignItems: "center",
  }),
  pageNumSection: (props: any) => ({
    width: 115,
    marginLeft: 10,
    "& .MuiInputBase-input": {
      padding: "6px 8px !important",
      fontSize: 12,
      lineHeight: "17px",
    },
    "& .MuiFormLabel-root": {
      fontSize: 12,
      fontFamily: `'Nunito Sans', sans-serif !important`,
      lineHeight: "13px",
      left: "-3px",
      position: "absolute",
      top: "-6px",
    },
  }),
});
export default useStyles;
