import { makeStyles } from "@mui/styles";
import { padding } from "@mui/system";
import muiTheme from "theme/muiTheme";

const useStyles = makeStyles(() => ({
  addAlertsContentContainer: (props: any) => ({
    marginBottom: "40px",
  }),

  labelTextArea: (props: any) => ({
    marginRight: "30px",
    background: "#2E2E2E",
    padding: "15px 0px 15px 15px",
    border: "0.5px solid #DDDDDD",
    borderRadius: "6px",
  }),

  inputTextArea: (props: any) => ({
    // marginRight: "30px",
    background: "#2E2E2E",
    marginRight: 30,
    marginBottom: 10,
  }),
  inputStyle: (props: any) => ({
    background: "#000000",
    color: "#fff",
    width: "100%",
    margin: 0,
    fontSize: "16px",
    boxSizing: "border-box",
    border: "0.5px solid #DDDDDD",
    borderRadius: "6px",
    padding: "16px",
    fontFamily: `'Nunito Sans', sans-serif !important`,
  }),
  inputStyleErrored: {
    background: "#000000",
    color: "#fff",
    width: "100%",
    margin: 0,
    fontSize: "16px",
    boxSizing: "border-box",
    border: "0.5px solid #F50303",
    borderRadius: "6px",
    padding: "16px",
    fontFamily: `'Nunito Sans', sans-serif !important`,
  },
  additionIconsContainer: (props: any) => ({
    display: "flex",
    justifyContent: "space-evenly",
  }),

  addIconStyle: (props: any) => ({
    display: "flex",
  }),

  minusIconStyle: (props: any) => ({
    display: "flex",
  }),
}));

export default useStyles;
