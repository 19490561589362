import { makeStyles } from "@mui/styles";
import { padding } from "@mui/system";
import muiTheme from "theme/muiTheme";

const useStyles = makeStyles(() => ({
  customNotificationTabs: (props: any) => ({
    "& .MuiTabs-flexContainer": {
      // borderBottom: "2px solid #3e3e3e",
    },

    "& .MuiButtonBase-root": {
      marginRight: "0 !important",
      minWidth: "64px !important",
      fontSize: "18px",
      fontFamily: "Nunito Sans",
      fontWeight: 800,
      lineHeight: " 25px",
      color: "#77B77C",
    },

    "& .MuiTab-root": {
      "&:first-child": {
        marginRight: "0 !important",
        minWidth: "64px !important",
        color: `${props?.palette?.infoDialogueComponent?.darkGrayShades}!important`,
      },
      "&:first-child.Mui-selected": {
        color: `${props?.palette?.infoDialogueComponent?.greenBlackShades}!important`,
      },
      "&:nth-child(2)": {
        marginRight: "0 !important",
        minWidth: "64px !important",
        color: `${props?.palette?.infoDialogueComponent?.darkGrayShades}!important`,
      },
      "&:nth-child(2).Mui-selected": {
        color: `${props?.palette?.infoDialogueComponent?.greenBlackShades}!important`,
      },
    },
    "& .MuiTabs-root .MuiTabs-indicator": {
      background: props?.palette?.infoDialogueComponent?.greenBlackShades,
      height: "3px !important",
      borderRadius: "1.5px",
    },
  }),
  innerAlertContainer: (props: any) => ({
    flex: 1,
  }),
  alertsContainerStyle: (props: any) => ({
    height: "calc(100vh - 360px)",
    overflow: "auto",
    "& :last-child": {
      marginBottom: "0 !important",
    },
    "& .MuiTypography-root": {
      fontSize: "16px",
      fontFamily: "Nunito Sans",
      fontWeight: 600,
      lineHeight: " 22px",
      color: "#fff",
      width: "130px",
      textOverflow: "ellipsis",
      overflow: " hidden",
      whiteSpace: "nowrap",
      [muiTheme.breakpoints.down(1921)]: {
        fontSize: "13px",
        lineHeight: " 19px",
      },
    },
  }),

  addAlertsContainerStyle: (props: any) => ({
    padding: "10px 10px 0px 90px",
    maxHeight: "calc(100vh - 410px)",
    overflow: "auto",
    [muiTheme.breakpoints.down(1025)]: {
      paddingLeft: 0,
    },
  }),

  trackerConfigContainer: (props: any) => ({
    height: "calc(100vh - 400px)",
    overflow: "auto",
  }),

  trackerConfigAddButtonContainer: {
    position: "absolute",
    right: "44px",
    top: "84px",
  },

  trackerConfigAddButton: {
    color: "#9486DA",
    backgroundImage:
      "radial-gradient(#FFFFFF 50%, rgba(0, 0, 0, 0) 55%, rgba(0, 0, 0, 0) 100%)",
    borderRadius: "50%",
    cursor: "pointer",
  },

  headerStyle: (props: any) => ({
    display: "flex",
    fontWeight: 700,
    fontSize: 20,
    fontFamily: `'Nunito Sans', sans-serif !important`,
    lineHeight: "27px",
    textTransform: "uppercase",
    marginBottom: 50,
  }),

  eventsContainer: (props: any) => ({
    // background: "#4E1DE0",
    marginBottom: "38px !important",
  }),

  eventHeader: (props: any) => ({
    background: "#1E1E1E",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 20px",
    alignItems: "center",
  }),

  eventBody: (props: any) => ({
    background: "#272727",
    display: "flex",
    padding: "30px 5px",
    maxHeight: "66px",
    overflow: "auto",
    flexWrap: "wrap",
  }),

  addIconStyle: (props: any) => ({
    display: "flex",
  }),

  footerStyle: (props: any) => ({
    background: "#2B2A2A",
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0,
    display: "flex",
    justifyContent: "flex-end",
    padding: "25px",
  }),

  saveButtonStyles: (props: any) => ({
    backgroundColor: `#7E6ECC !important`,
    borderRadius: "15px !important",
    width: "90px !important",
    fontSize: "15px !important",
    fontFamily: `'Nunito Sans', sans-serif !important`,
    lineHeight: "20px !important",
    boxShadow: "none !important",
    fontWeight: "600 !important",
    marginLeft: "15px !important",
  }),

  cancelButtonStyles: (props: any) => ({
    color: "#CBCBCB !important",
    border: "1px solid #CBCBCB !important",
    backgroundColor: `none !important`,
    borderRadius: "15px !important",
    width: "90px !important",
    fontSize: "15px !important",
    fontFamily: `'Nunito Sans', sans-serif !important`,
    lineHeight: "20px !important",
    boxShadow: "none !important",
    fontWeight: "500 !important",
    marginLeft: "15px !important",
  }),

  AddAlertsBackButoonStyle: (props: any) => ({
    display: "flex",
    marginRight: "30px",
  }),

  addAlertsLabelStyle: (props: any) => ({
    marginBottom: "10px !important",
  }),

  addAlertsItemStyle: (props: any) => ({}),

  alertTypeLableStyle: (props: any) => ({
    paddingLeft: "5px",
  }),
  alertNameLableStyle: (props: any) => ({
    paddingLeft: "5px",
  }),
}));

export default useStyles;
