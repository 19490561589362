const manifestPallets = [
    {
        id: "",
        name: "PALLET#1",
    },
    {
        id: "",
        name: "PALLET#2",
    },
    {
        id: "",
        name: "PALLET#3",
    },
    {
        id: "",
        name: "PALLET#4",
    },
    {
        id: "",
        name: "PALLET#5",
    },
];

export default manifestPallets;