import { makeStyles } from "@mui/styles";
import muiTheme from "theme/muiTheme";
import waterMark from "../../assets/configurations/water-mark.svg";

const useStyles = makeStyles({
  rootContainer: (props: any) => ({
    fontFamily: `'Nunito Sans', sans-serif !important`,
  }),
  configrationContent: (props: any) => ({
    minHeight: "calc(100vh - 84px) !important",
    marginTop: "84px !important",
    "& .google-map-code": {
      height: "100%",
    },
    [muiTheme.breakpoints.down(960)]: {
      height: "calc(100vh - 136px)",
      marginTop: "136px !important",
    },
  }),
  configurationsLeft: (props: any) => ({
    background: "#E9E6FF",
    boxShadow: "0px 4px 2px 5px rgba(0, 0, 0, 0.05)",
  }),
  configurationBg: (props: any) => ({
    background: "#F3F0FC",
  }),
  configurationsRight: (props: any) => ({
    background: "#F3F0FC",
    position: "relative",
    "&::before": {
      width: 164,
      height: 120,
      content: '""',
      position: "absolute",
      background: `url(${waterMark})`,
      right: 0,
      bottom: 0,
    },
  }),

  // dragDeviceSection: (props: any) => ({
  //   padding: "36px 71px",
  //   background: "#E9E5FF",
  //   borderRadius: "6px",
  // }),

  uploadSection: (props: any) => ({
    display: "flex",
    alignItems: " center",
    justifyContent: "center",
    height: "100%",
  }),
  dragContent: (props: any) => ({
    fontFamily: "Nunito Sans",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "19px",
    color: "#8978DC",
    background: "#F3F0FC",
    border: "1px dashed #C8C0ED",
    borderRadius: "5px",
    marginBottom: 37,
    cursor: "pointer",
    minWidth: 448,
    minHeight: 116,
    display: "flex",
    alignItems: " center",
    justifyContent: "center",
  }),
  dragButton: (props: any) => ({
    "& .MuiButtonBase-root": {
      fontFamily: "Nunito Sans !important",
      fontWeight: "700 !important",
      fontSize: "14px !important",
      lineHeight: "19px !important",
      color: "#FFFFFF !important",
      background: "#C8C1ED !important",
      borderRadius: "6px !important",
      border: "1px solid #c8c1ed !important",
      padding: "14px 30px",
      marginTop: "0 !important",
    },
  }),
  innerDragSection: (props: any) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: " center",
    justifyContent: "center",
    height: "100%",
  }),
  dragImageSection: (props: any) => ({
    marginBottom: 20,
  }),
});
export default useStyles;
