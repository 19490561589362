export const trailerData = {
  GET_TRAILER_LIST: "GET_TRAILER_LIST",
  SET_TRAILER_LIST: "SET_TRAILER_LIST",
};

export const getTrailerData = (payload: any) => ({
  type: trailerData.GET_TRAILER_LIST,
  payload: payload,
});

export const setTrailerData = (trailerDataValue: any) => ({
  type: trailerData.SET_TRAILER_LIST,
  trailerDataValue,
});

export default trailerData;
