import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import useStyles from "./styles";
import theme from "../../theme/theme";
import MenifestInfoDialog from "components/MenifestInfoDialog";
import MenifestContainer from "components/MenifestContainer";
import TrailersInfoDialog from "components/TrailersInfoDialog";

const Manifests = () => {
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const { rootContainer, meniFestsSection } = useStyles(appTheme);

  const [showManifestDialogue, setShowManifestDialogue] = useState<any>(false);
  const [tabIndex, setTabIndex] = useState<number>(1);
  const [isInfoDialogueOpen, setIsInfoDialogueOpen] = useState<boolean>(false);
  const [selectedTableRowManifestId, setSelectedTableRowManifestId] =
    useState<string>();

  return (
    <>
      <Grid container className={rootContainer}>
        <Grid item xs={12} className={meniFestsSection}>
          <MenifestContainer
            isInfoDialogueOpen={isInfoDialogueOpen}
            setIsInfoDialogueOpen={setIsInfoDialogueOpen}
            selectedTableRowManifestId={selectedTableRowManifestId}
            setSelectedTableRowManifestId={setSelectedTableRowManifestId}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            setShowManifestDialogue={setShowManifestDialogue}
            showManifestDialogue={showManifestDialogue}
          />
        </Grid>
      </Grid>
      <div>
        {isInfoDialogueOpen && (
          <TrailersInfoDialog
            selectedItemID={selectedTableRowManifestId}
            setShowInfoDialogue={setIsInfoDialogueOpen}
            pageName={"manifest"}
            parentTabIndex={tabIndex}
          />
        )}
        {/* {showManifestDialogue && (
          <MenifestInfoDialog
            setShowManifestDialogue={setShowManifestDialogue}
            actionName={"create"}
          />
        )} */}
      </div>
    </>
  );
};
export default Manifests;
