import { makeStyles } from "@mui/styles";
import muiTheme from "../../theme/muiTheme";

const useStyles = makeStyles({
  listItemContainer: (props: any) => ({
    padding: " 15px",
    lineHeight: "30px",
    background: props?.palette?.infoAlertListItemComponent?.colorBlack,
    margin: "10px 10px",
    border: `1px solid ${props?.palette?.infoAlertListItemComponent?.colorWhite}`, //lightBlue2
    borderRadius: "7px",
    cursor: "pointer",
    fontFamily: "Nunito Sans",
    [muiTheme.breakpoints.down(1601)]: {
      fontSize: 15,
    },
    [muiTheme.breakpoints.down(1025)]: {
      fontSize: 15,
    },
  }),
  listItemTitle: (props: any) => ({
    fontSize: 18,
    color: props?.palette?.infoAlertListItemComponent?.colorWhite, //darkRedBlack
    fontFamily: "Nunito Sans",
    [muiTheme.breakpoints.down(1601)]: {
      fontSize: 13,
    },
    [muiTheme.breakpoints.down(1025)]: {
      fontSize: 13,
    },
  }),
  areaItem: (props: any) => ({
    fontFamily: "Nunito Sans",
  }),
  listItemLocation: (props: any) => ({
    // fontWeight: 400,
    fontSize: 14,
    color: props?.palette?.infoAlertListItemComponent?.colorWhite, // lightRedBlack
    display: "flex",
    fontFamily: "Nunito Sans",
    [muiTheme.breakpoints.down(1601)]: {
      fontSize: 13,
    },
    [muiTheme.breakpoints.down(1025)]: {
      fontSize: 13,
    },
  }),

  collapsedListItem: (props: any) => ({}),

  listItemFooterStyle: (props: any) => ({
    display: "flex",
    justifyContent: "space-between",
    color: props?.palette?.dashboardList?.white,
    fontSize: 14,
    fontFamily: "Nunito Sans",
    [muiTheme.breakpoints.down(1601)]: {
      fontSize: 13,
    },
    [muiTheme.breakpoints.down(1025)]: {
      fontSize: 13,
    },
  }),
  listItemTimeStyle: (props: any) => ({
    color: props?.palette?.dashboardList?.white,
    fontSize: 14,
    fontFamily: `'Nunito Sans', sans-serif !important`,
    // fontWeight: 400,
    [muiTheme.breakpoints.down(1025)]: {
      fontSize: 11,
    },
  }),
});
export default useStyles;
