export const dashboardList = {
  GET_DAHSBOARD_LIST: "GET_DASHBOARD_LIST",
  SET_DASHBOARD_LIST: "SET_DASHBOARD_LIST",
};

export const getDashboardList = (payload: any) => ({
  type: dashboardList.GET_DAHSBOARD_LIST,
  payload: payload,
});

export const setDashboardList = (dashboardListData: any) => ({
  type: dashboardList.SET_DASHBOARD_LIST,
  dashboardListData,
});

export default dashboardList;
