import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import useTranslation from "../../localization/translations";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import InfoSubList from "../InfoSubList";
import CustomizedSteppers from "../Stepper";
import ChipButton from "../../elements/ChipButton";
import Tabs from "../../elements/Tabs";
import { SearchIconInfo } from "../../assets/buttonIcons";
import theme from "../../theme/theme";
import colorCodes from "../../theme/colors";
import Geofence from "components/Geofence";
import FloorMap from "components/Map/FloorMap";
import Map from "components/Map";
import useStyles from "./styles";
import InfoNotificationListItem from "components/InfoNotificationListItem";
import SearchBox from "elements/SearchBox";
import { CloseIconImg } from "../../assets/icons";
import SwitchBox from "elements/Switchbox";
import CheckBox from "elements/Checkbox";
import { AddIcon, AddAlertsBackButoon } from "../../assets/icons";
import Button from "elements/Button";
import AddAlertsConfigInfoDialog from "components/AddAlertsConfigInfoDialog";
import ConfigurationListItem from "components/ConfigurationList/ConfigurationList";
import AddTrackerConfig from "components/AddTrackerConfig/AddTrackerConfig";
// @ts-ignore
import { doc, setDoc, getDoc, onSnapShot } from "firebase/firestore/lite";
// @ts-ignore
import { db } from "services/firebase";
import muiTheme from "theme/muiTheme";

const TrackerConfigurationInitialState = [
  {
    title: "Operating Temperature Threshold",
    isActive: true,
    values: [
      {
        label: "Low Temperature",
        prefix: "<",
        unit: " °F",
        value: 27,
      },
      {
        label: "High Temperature",
        prefix: ">",
        unit: " °F",
        value: 250,
      },
    ],
  },
  {
    title: "Battery Threshold",
    isActive: true,
    values: [
      {
        label: "Low Battery",
        prefix: "<",
        unit: "%",
        value: 20,
      },
    ],
  },
  {
    title: "Humidity Threshold",
    isActive: true,
    values: [
      {
        label: "Low Humidity",
        prefix: "<",
        unit: "%",
        value: 20,
      },
    ],
  },
];

const DialogWrapper = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiBackdrop-root": {
    marginTop: "84px !important",
  },
  "& .MuiPaper-root": {
    maxHeight: "calc(100% - 110px)",
    minHeight: "calc(100% - 110px)",
    width: "80vw",
    maxWidth: "1772px",
    background: `${colorCodes.colorBlack} !important`,
    color: colorCodes.colorWhite,
    padding: 24,
    [muiTheme.breakpoints.down(963)]: {
      maxHeight: "calc(100% - 118px)",
      minHeight: "calc(100% - 210px)",
    },
  },
  "& .MuiDialog-container": {
    marginTop: "60px !important",
    background: "rgb(0 0 0 / 0%)",
    backdropFilter: "blur(10px)",
    height: "calc(100vh - 65px) !important",
  },
}));

const SendConfigInfoDialog: React.FC<any> = (props) => {
  const {
    setShowInfoDialogue,
    selectedDragableList,
    treeLableExpandedTitles,
    firebaseResponseData,
    setDraggableList,
    getNotConfiguredData,
    category,
    treeViewTabIndex,
  } = props;

  const [isSwitchChecked, setIsSwitchChecked] = useState<any>({
    events: true,
    incidents: true,
    opsAlert: true,
  });
  const [firebaseCopyObj, setFirebaseCopyObj] = useState<any>([]);
  useEffect(() => {
    const newUpdatedObject = firebaseResponseData;

    newUpdatedObject?.wareHouses?.map((warehouseItem: any) => {
      warehouseItem.subList.map((sublistItem: any) => {
        if (sublistItem.category === "tracker") {
          sublistItem.list.map((listItem: any) => {
            selectedDragableList?.map((item: any, index: number) => {
              if (index === 0) {
                if (listItem.title === item?.title) {
                  setFirebaseCopyObj(listItem?.alertConfiguredList);
                }
              }
            });
          });
        } else if (sublistItem.category === "equipment") {
          sublistItem.list.map((listItem: any) => {
            listItem.subListItem.map((listItem: any) => {
              selectedDragableList?.map((item: any, index: number) => {
                if (index === 0) {
                  if (listItem.title === item?.title) {
                    setFirebaseCopyObj(listItem?.alertConfiguredList);
                  }
                }
              });
            });
          });
        }
      });
    });
  }, [firebaseResponseData, selectedDragableList]);

  const dataIntegrationInParentObject = (
    draggedItem: any,
    callingFrom?: string,
    trackerConfiguration?: any
  ) => {
    if (!isSwitchChecked.events) firebaseCopyObj[0].isDisabled = true;
    if (!isSwitchChecked.incidents) firebaseCopyObj[1].isDisabled = true;
    if (!isSwitchChecked.opsAlert) firebaseCopyObj[2].isDisabled = true;

    const newUpdatedObject = firebaseResponseData;
    // Find the object with id="tr1" and update the alertConfiguredList property value
    newUpdatedObject?.wareHouses?.map((warehouseItem: any) => {
      warehouseItem.subList.map((sublistItem: any) => {
        if (sublistItem.category === "tracker") {
          sublistItem.list.map((listItem: any) => {
            if (listItem.title === draggedItem) {
              listItem.configured = callingFrom === "alertSave" ? true : false;
              listItem.alertConfiguredList = firebaseCopyObj;
              if (trackerConfiguration)
                listItem.trackerConfiguration = trackerConfiguration;
            }
          });
        } else if (sublistItem.category === "equipment") {
          sublistItem.list.map((listItem: any) => {
            listItem.subListItem.map((listItem: any) => {
              if (listItem.title === draggedItem) {
                listItem.configured = callingFrom === "alertSave" ? true : false;
                listItem.alertConfiguredList = firebaseCopyObj;
                if (trackerConfiguration)
                  listItem.trackerConfiguration = trackerConfiguration;
              }
            });
          });
        }
        if (treeViewTabIndex === 0) {
          for (let i = 0; i < warehouseItem?.subList?.length; i++) {
            for (let j = 0; j < warehouseItem?.subList[i]?.list?.length; j++) {
              for (
                let k = 0;
                k < warehouseItem?.subList[i]?.list[j].subListItem?.length;
                k++
              ) {
                if (
                  warehouseItem?.subList[i]?.list[j]?.subListItem[k]?.configured
                ) {
                  warehouseItem.subList[i].configured = true;
                } else {
                  warehouseItem.subList[i].configured = false;
                }
              }
            }
          }
        }
      });
    });
   
    const ref = doc(db, "configuration", "notConfiguredList");
    setDoc(ref, newUpdatedObject)
      .then((success: any) => {
        callingFrom && getNotConfiguredData();
      })
      .catch((error) => console.log(error));
  };

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const [tabIndex, setTabIndex] = useState(0);
  const [trackerConfiguration, setTrackerConfiguration] = useState(
    TrackerConfigurationInitialState
  );
  const [firebaseData, setFirebaseData] = useState<any>({});
  const {
    headerStyle,
    customNotificationTabs,
    eventsContainer,
    eventHeader,
    eventBody,
    addIconStyle,
    alertsContainerStyle,
    footerStyle,
    saveButtonStyles,
    cancelButtonStyles,
    AddAlertsBackButoonStyle,
    addAlertsContainerStyle,
    addAlertsItemStyle,
    addAlertsLabelStyle,
    alertTypeLableStyle,
    alertNameLableStyle,
    trackerConfigContainer,
    trackerConfigAddButtonContainer,
    trackerConfigAddButton,
    innerAlertContainer,
  } = useStyles(appTheme);

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(!open);
    setShowInfoDialogue(false);
  };

  const tabsList = [
    { name: "Alert", val: 0 },
    { name: `${category} Configuration `, val: 1 },
  ];

  const AddAlertstabsList = [{ name: "Add Alerts", val: 0 }];
  const AddTrackerConfigurationtabsList = [
    { name: `Add ${category} Configuration`, val: 1 },
  ];

  const handleTabs = (index: number) => {
    setTabIndex(index);
  };

  const [isChecked, setIsChecked] = useState<any>({ rerender: false });
  const { rerender } = isChecked;
  const { events, incidents, opsAlert } = isSwitchChecked;
  const [isAddAlertsClicked, setIsAddAlertsClicked] = useState<boolean>(false);
  const [isAddConfigClicked, setIsAddConfigClicked] = useState<boolean>(false);
  useEffect(() => {
    if (firebaseCopyObj) {
      firebaseCopyObj?.length > 0 &&
        firebaseCopyObj?.map((item: any, index: number) => {
          item[
            index === 0
              ? "eventItem"
              : index === 1
              ? "incidentsItem"
              : "opsAlertItem"
          ]?.map((item: any) => {
            const inputString = item?.label || item?.name;

            const itemNamesInCamelCase = inputString
              ?.toLowerCase()
              .replace(/[^a-zA-Z0-9]/g, "_")
              .split("_")
              .map((word: any, index: any) => {
                if (index === 0) {
                  return word;
                }
                return word.charAt(0).toUpperCase() + word.slice(1);
              })
              .join("");

            isChecked[itemNamesInCamelCase] = true;
            setIsChecked({ ...isChecked, rerender: !rerender });
          });
        });
    }
  }, [isAddAlertsClicked, firebaseCopyObj]);

  useEffect(() => {
    const notConfiguredListRef = doc(db, "configuration", "notConfiguredList");
    getDoc(notConfiguredListRef)
      .then((response: any) => {
        const dbResponse = response?.data();
        setFirebaseData(dbResponse);
        setTrackerConfiguration(
          dbResponse.wareHouses[0].subList[0].list[0].trackerConfiguration
        );
      })
      .catch((error) => console.log(error.message));
  }, []);

  const handleCheckBox = (event: any, label: any) => {
    setIsChecked({ ...isChecked, [label]: !isChecked[label] });
  };

  const handleSwitch = (index: number) => {
    switch (index) {
      case 0:
        setIsSwitchChecked({ ...isSwitchChecked, events: !events });
        break;
      case 1:
        setIsSwitchChecked({ ...isSwitchChecked, incidents: !incidents });
        break;
      case 2:
        setIsSwitchChecked({ ...isSwitchChecked, opsAlert: !opsAlert });
        break;
    }
  };

  const [selectedAlertForAddition, setSelectedAlertForAddition] =
    useState<string>();
  const [additionDeletionActivations, setAdditionDeletionActivations] =
    useState<any>({
      additionIconActivation: false,
      deletionIconActivation: false,
    });
  const [countAlertsListArray, setCountAlertsListArray] = useState<any>([
    { alertName: "", alertType: "" },
  ]);
  const [triggerCountIncrement, setTriggerCountIncrement] =
    useState<boolean>(true);

  const handleAddIconEvents = (index: number) => {
    setSelectedAlertForAddition(
      index === 0 ? "Events" : index === 1 ? "Incidents" : "Ops Alert"
    );
    setCountAlertsListArray([{ alertName: "", alertType: "" }]);
    setIsAddAlertsClicked(!isAddAlertsClicked);
  };

  const handleInputText = (
    event: any,
    index: number,
    selectedAlertForAddition: any
  ) => {
    const enteredText = event?.target?.value;

    countAlertsListArray[index].alertName = enteredText;
    countAlertsListArray[index].alertType = selectedAlertForAddition;
    setTriggerCountIncrement(!triggerCountIncrement);
    if (event.target.value && index === 0) {
      // countAlertsListArray[index].addIconActivation = true;
      setAdditionDeletionActivations({
        ...additionDeletionActivations,
        additionIconActivation: true,
      });
    } else if (index === 0) {
      // countAlertsListArray[index].addIconActivation = false;
      setAdditionDeletionActivations({
        ...additionDeletionActivations,
        additionIconActivation: false,
      });
    }
    const countAlertsListArrayWithoutErrors = countAlertsListArray.map(
      (alert: any) => ({ ...alert, error: "" })
    );
    setCountAlertsListArray(countAlertsListArrayWithoutErrors);
  };

  const handleAddAlertsBackbutton = () => {
    setIsAddAlertsClicked(false);
    setIsAddConfigClicked(false);

    setAdditionDeletionActivations({
      ...additionDeletionActivations,
      additionIconActivation: false,
    });

    setCountAlertsListArray([{ alertName: "", alertType: "" }]);
  };

  const handleAlertsAddition = (
    count: number,
    selectedAlertForAddition: any
  ) => {
    const elementPosition = count + 1;
    countAlertsListArray.splice(elementPosition, 0, {
      alertName: "",
      alertType: selectedAlertForAddition,
    });
    if (count === 0) {
      setAdditionDeletionActivations({
        ...additionDeletionActivations,
        deletionIconActivation: true,
      });
    } else {
      setTriggerCountIncrement(!triggerCountIncrement);
    }
  };

  const handleAlertsdelete = (count: number, selectedAlertForAddition: any) => {
    countAlertsListArray.splice(count, 1);
    if (countAlertsListArray.length < 2) {
      setAdditionDeletionActivations({
        ...additionDeletionActivations,
        deletionIconActivation: false,
      });
    } else {
      setTriggerCountIncrement(!triggerCountIncrement);
    }
  };

  const handleSaveButtonClick = () => {
    let isErrored = false;
    const pattern = /^[a-zA-Z0-9\s]+$/;

    const countAlertsArrayWithErrors = countAlertsListArray?.map(
      (alert: any) => {
        let isNotUnique = false;
        const combinedAlerts = [...firebaseCopyObj[0].eventItem, ...firebaseCopyObj[1].incidentsItem, ...firebaseCopyObj[2].opsAlertItem]
        isNotUnique = combinedAlerts.some((alertItem: any) => {
          return alertItem.label.toLowerCase().replaceAll(' ', '') === alert.alertName.toLowerCase().replaceAll(' ', '')
        })

          const isNotUniqueInternal = countAlertsListArray.reduce((total: number, alertItem: any) => {
            if (alert.alertName.toLowerCase().replaceAll(' ', '') === alertItem.alertName.toLowerCase().replaceAll(' ', '')) {
              return total + 1
            }
            return total + 0
          }, 0)

        if (!alert?.alertName || !pattern.test(alert?.alertName)) {
          isErrored = true;
          return { ...alert, error: "*Please Enter a Valid Alert Name" };
        } else if(isNotUnique) {
          isErrored = true;
          return { ...alert, error: "*Alert Already Exists" };
        } else if(isNotUniqueInternal >= 2) {
          isErrored = true;
          return { ...alert, error: "*Duplicate Entry" };
        }
      }
    );

    if (isErrored) {
      setCountAlertsListArray(countAlertsArrayWithErrors);
      return null;
    }

    if (countAlertsListArray[0]?.alertType === "Events") {
      countAlertsListArray?.map((item: any) => {
        firebaseCopyObj?.length > 0 &&
          firebaseCopyObj[0]?.eventItem?.push({
            label: item?.alertName,
            isChecked: true,
          });
      });
    } else if (countAlertsListArray[0]?.alertType === "Incidents") {
      countAlertsListArray?.map((item: any) => {
        firebaseCopyObj?.length > 0 &&
          firebaseCopyObj[1]?.incidentsItem?.push({
            label: item?.alertName,
            isChecked: true,
          });
      });
    } else if (countAlertsListArray[0]?.alertType === "Ops Alert") {
      countAlertsListArray?.map((item: any) => {
        firebaseCopyObj?.length > 0 &&
          firebaseCopyObj[2]?.opsAlertItem?.push({
            label: item?.alertName,
            isChecked: true,
          });
      });
    }
    selectedDragableList?.map((item: any) => {
      dataIntegrationInParentObject(item?.title);
    });
    setIsAddAlertsClicked(!isAddAlertsClicked);
  };

  const handleCancelButtonClick = () => {
    setIsAddAlertsClicked(!isAddAlertsClicked);
    setCountAlertsListArray([{ alertName: "", alertType: "" }]);
  };

  // const combinedAlerts = [...firebaseCopyObj[0].eventItem, ...firebaseCopyObj[1].incidentsItem, ...firebaseCopyObj[2].opsAlertItem]

  const handleAlertCancelButtonClick = () => {
    setShowInfoDialogue(false);
    let isErrored = false;
    const pattern = /^[a-zA-Z0-9 ]+$/;

    const countAlertsArrayWithErrors = countAlertsListArray.map(
      (alert: any) => {
        if (!alert.alertName || !pattern.test(alert.alertName)) {
          isErrored = true;
          return { ...alert, error: "*Please Enter a Valid Alert Name" };
        }
      }
    );

    if (isErrored) {
      setCountAlertsListArray(countAlertsArrayWithErrors);
      return null;
    }

    if (countAlertsListArray[0]?.alertType === "Events") {      
      countAlertsListArray?.map((item: any) => {
        const index = firebaseCopyObj[0]?.eventItem?.indexOf(item?.alertName);
        firebaseCopyObj?.length > 0 && firebaseCopyObj[0]?.eventItem?.splice(index, 1);
      });
    } else if (countAlertsListArray[0]?.alertType === "Incidents") {
      countAlertsListArray?.map((item: any) => {
        const index = firebaseCopyObj[1]?.incidentsItem?.indexOf(item?.alertName);
        firebaseCopyObj?.length > 0 &&
          firebaseCopyObj[1]?.incidentsItem?.splice(index, 1);
      });
    } else if (countAlertsListArray[0]?.alertType === "Ops Alert") {
      countAlertsListArray?.map((item: any) => {
        const index = firebaseCopyObj[2]?.opsAlertItem?.indexOf(item?.alertName);
        firebaseCopyObj?.length > 0 &&
          firebaseCopyObj[2]?.opsAlertItem?.splice(index, 1);
      });
    }
    selectedDragableList?.map((item: any) => {
      dataIntegrationInParentObject(item?.title);
    });


    
  };

  const handleTrackerConfigChange = (id: any, item: any) => {
    setTrackerConfiguration((prev) => {
      const newList = [...prev];
      newList[id].values = item;

      return newList;
    });
  };

  const handleTrackerConfigToggleChange = (
    id: any,
    type: any,
    value: boolean
  ) => {
    if (type === "tracker") {
      setTrackerConfiguration((prev: any) => {
        const newList = [...prev];
        newList[id].isDisabled = !value;
        return newList;
      });
    } else {
    }
  };

  const onTrackerConfigSave = (config: any) => {
    setIsAddConfigClicked(false);
    const firebaseOldData = firebaseData;
    const combinedTrackerConfig = [...trackerConfiguration, ...config];

    const newWarehouseData = firebaseOldData.wareHouses.map(
      (warehouse: any) => {
        const newsubList = warehouse.subList.map((subListItem: any) => {
          const newList = subListItem.list.map((listItem: any) => {
            return { ...listItem, trackerConfiguration: combinedTrackerConfig };
          });
          return { ...subListItem, list: newList };
        });
        return { ...warehouse, subList: newsubList };
      }
    );

    const notConfiguredListRef = doc(db, "configuration", "notConfiguredList");
    setDoc(
      notConfiguredListRef,
      { wareHouses: newWarehouseData },
      { merge: true }
    )
      .then((success: any) => {
        getDoc(notConfiguredListRef)
          .then((response: any) => {
            const dbResponse = response?.data();

            setFirebaseData(dbResponse);
            setTrackerConfiguration(
              dbResponse.wareHouses[0].subList[0].list[0].trackerConfiguration
            );
          })
          .catch((error) => console.log(error.message));
      })
      .catch((error: any) => console.log(error));
  };

  const handleAlertSaveButtonClick = () => {
    firebaseCopyObj?.length > 0 &&
      firebaseCopyObj?.map((item: any, index: number) => {
        item[
          index === 0
            ? "eventItem"
            : index === 1
            ? "incidentsItem"
            : "opsAlertItem"
        ]?.map((item: any) => {
          const itemTitle = item?.label || item?.name;
          const itemNamesInCamelCase = itemTitle
            ?.toLowerCase()
            .replace(/[^a-zA-Z0-9]/g, "_")
            .split("_")
            .map((word: any, index: any) => {
              if (index === 0) {
                return word;
              }
              return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join("");

          Object.entries(isChecked).forEach(([key, value]) => {
            if (itemNamesInCamelCase === key) {
              item.isChecked = value;
            }
          });
        });
      });

    selectedDragableList?.map((item: any) => {
      dataIntegrationInParentObject(
        item?.title,
        "alertSave",
        trackerConfiguration
      );
    });
    setDraggableList([]);
    setShowInfoDialogue(false);

    // const firebaseOldData = firebaseData;
    // const newWarehouseData = firebaseOldData.wareHouses.map(
    //   (warehouse: any) => {
    //     const newsubList = warehouse.subList.map((subListItem: any) => {
    //       const newList = subListItem.list.map((listItem: any) => {
    //         console.log("EQUIPMENT CONDITION", listItem)
    //         return { ...listItem, trackerConfiguration };
    //       });
    //       return { ...subListItem, list: newList };
    //     });
    //     return { ...warehouse, subList: newsubList };
    //   }
    // );

    // const notConfiguredListRef = doc(db, "configuration", "notConfiguredList");
    // setDoc(
    //   notConfiguredListRef,
    //   { wareHouses: newWarehouseData },
    // )
    // .then((success: any) => {
    //   console.log("SUCCESS", success)
    //   getDoc(notConfiguredListRef)
    //     .then((response: any) => {
    //       const dbResponse = response?.data();

    //       setFirebaseData(dbResponse);
    //       setTrackerConfiguration(
    //         dbResponse.wareHouses[0].subList[0].list[0].trackerConfiguration
    //       );
    //     })
    //     .catch((error) => console.log(error.message));
    // })
    // .catch((error: any) => console.log(error));
  };

  return (
    <>
      <DialogWrapper open={open} sx={{ top: "22px" }}>
        <div className={headerStyle}>
          {(isAddAlertsClicked || isAddConfigClicked) && (
            <div
              className={AddAlertsBackButoonStyle}
              onClick={handleAddAlertsBackbutton}
            >
              <img src={AddAlertsBackButoon} />
            </div>
          )}
          <Tabs
            initialIndex={0}
            tabsList={
              isAddAlertsClicked
                ? AddAlertstabsList
                : isAddConfigClicked
                ? AddTrackerConfigurationtabsList
                : tabsList
            }
            handleTabs={handleTabs}
            dashboardNotificationClassName={customNotificationTabs}
            pageName={"sendConfig"}
          />
        </div>
        {isAddConfigClicked ? (
          <AddTrackerConfig
            existingTrackers={trackerConfiguration}
            onChangesSave={onTrackerConfigSave}
            onCancel={() => setIsAddConfigClicked(false)}
          />
        ) : !isAddAlertsClicked ? (
          tabIndex === 0 ? (
            <>
              <div>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    right: 15,
                    top: 12,
                    color: appTheme.palette.infoDialogueComponent.colorWhite,
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>

              <Grid
                container
                className={alertsContainerStyle}
                direction="row"
                justifyContent="space-between"
              >
                <div className={innerAlertContainer}>
                  {firebaseCopyObj?.length > 0 &&
                    firebaseCopyObj?.map((item: any, index: number) => {
                      return (
                        <Grid className={eventsContainer} item xs={12}>
                          <div className={eventHeader}>
                            <div>
                              <SwitchBox
                                checked={
                                  index === 0
                                    ? events
                                    : index === 1
                                    ? incidents
                                    : opsAlert
                                }
                                titleName={item?.title}
                                handleSwitch={() => handleSwitch(index)}
                              />
                            </div>
                            <div
                              className={addIconStyle}
                              onClick={() =>
                                (index === 0
                                  ? events
                                  : index === 1
                                  ? incidents
                                  : opsAlert) && handleAddIconEvents(index)
                              }
                            >
                              <img src={AddIcon} />
                            </div>
                          </div>

                          <div className={eventBody}>
                            {item[
                              index === 0
                                ? "eventItem"
                                : index === 1
                                ? "incidentsItem"
                                : "opsAlertItem"
                            ]?.map((item: any) => {
                              const itemTitle = item?.label || item?.name;
                              const itemNamesInCamelCase = itemTitle
                                ?.toLowerCase()
                                .replace(/[^a-zA-Z0-9]/g, "_")
                                .split("_")
                                .map((word: any, index: any) => {
                                  if (index === 0) {
                                    return word;
                                  }
                                  return (
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                  );
                                })
                                .join("");

                              return (
                                <CheckBox
                                  id={itemNamesInCamelCase}
                                  name={itemNamesInCamelCase}
                                  label={item?.label || item?.name}
                                  isChecked={
                                    Object.keys(isChecked).length &&
                                    isChecked[itemNamesInCamelCase]
                                  }
                                  color={"#D2CCF2"}
                                  handleCheck={(e: any) =>
                                    handleCheckBox(e, itemNamesInCamelCase)
                                  }
                                  disabled={
                                    index === 0
                                      ? !events
                                      : index === 1
                                      ? !incidents
                                      : !opsAlert
                                  }
                                />
                              );
                            })}
                          </div>
                        </Grid>
                      );
                    })}
                </div>
              </Grid>
              <div className={footerStyle}>
                <Button
                  variant="outlined"
                  handleClick={() => handleAlertCancelButtonClick()}
                  buttonStyles={cancelButtonStyles}
                >
                  {"Cancel"}
                </Button>
                <Button
                  variant="contained"
                  handleClick={() => handleAlertSaveButtonClick()}
                  buttonStyles={saveButtonStyles}
                >
                  {"Save"}
                </Button>
              </div>
            </>
          ) : (
            <>
              <div
                className={trackerConfigAddButtonContainer}
                onClick={() => setIsAddConfigClicked(true)}
              >
                <AddCircleRoundedIcon
                  fontSize="large"
                  className={trackerConfigAddButton}
                />
              </div>
              <div className={trackerConfigContainer}>
                {trackerConfiguration.map((config: any, id) => (
                  <ConfigurationListItem
                    id={id}
                    title={config?.title || ""}
                    list={config?.values || []}
                    tab={tabIndex}
                    onChange={(item: any) =>
                      handleTrackerConfigChange(id, item)
                    }
                    isDisabled={config?.isDisabled}
                    onToggleSwitchChange={(id: any, type: any, value: any) =>
                      handleTrackerConfigToggleChange(id, type, value)
                    }
                    variant="dark"
                  />
                ))}
              </div>
              <div className={footerStyle}>
                <Button
                  variant="outlined"
                  handleClick={() => handleAlertCancelButtonClick()}
                  buttonStyles={cancelButtonStyles}
                >
                  {"Cancel"}
                </Button>
                <Button
                  variant="contained"
                  handleClick={() => handleAlertSaveButtonClick()}
                  buttonStyles={saveButtonStyles}
                >
                  {"Save"}
                </Button>
              </div>
            </>
          )
        ) : (
          <>
            <Grid container className={addAlertsContainerStyle}>
              <Grid item xs={12} className={addAlertsLabelStyle}>
                <Grid container>
                  <Grid item xs={5}>
                    <div className={alertTypeLableStyle}>Alert Type</div>
                  </Grid>
                  <Grid item xs={7}>
                    <div className={alertNameLableStyle}>Alert Name</div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} className={addAlertsItemStyle}>
                {countAlertsListArray?.map((item: any, index: number) => {
                  return (
                    <AddAlertsConfigInfoDialog
                      key={index}
                      item={item}
                      selectedAlertForAddition={selectedAlertForAddition}
                      handleInputText={handleInputText}
                      additionDeletionActivations={additionDeletionActivations}
                      handleAlertsAddition={handleAlertsAddition}
                      handleAlertsdelete={handleAlertsdelete}
                      indexValue={index}
                    />
                  );
                })}
              </Grid>
            </Grid>
            <div className={footerStyle}>
              <Button
                variant="outlined"
                handleClick={() => handleCancelButtonClick()}
                buttonStyles={cancelButtonStyles}
              >
                {"Cancel"}
              </Button>
              <Button
                variant="contained"
                handleClick={() => handleSaveButtonClick()}
                buttonStyles={saveButtonStyles}
              >
                {"Save"}
              </Button>
            </div>
          </>
        )}
      </DialogWrapper>
    </>
  );
};

export default SendConfigInfoDialog;
