import { useState, useEffect, useContext } from "react";
import theme from "../../theme/theme";
import useTranslation from "../../localization/translations";
import FloorMap from "components/Map/FloorMap";
import useStyles from "./styles";

const DashboardFloorMap: React.FC<any> = (props) => {
  const {
    tabIndex,
    setTabIndex,
    selectedNotification,
    setSelectedNotification,
    searchOpen,
    setSearchOpen,
    notificationTimeStamp,
    setViewDetailActive,
    setChipButtonIndex,
    notificationData,
    chipButtonIndex,
    viewDetailData,
    toggleButtonStatus,
    isListExpanded,
    setIsListExpanded,
    selectedId,
    handleTabChange,
    setRightPanelActive,
    setIsMarkerClicked,
  } = props;

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const {} = useStyles(appTheme);
  const { dashboardMapContainer } = useStyles(appTheme);

  const {} = useTranslation();

  return (
    <div className={dashboardMapContainer}>
      <div>
        <FloorMap
          markerArray={viewDetailData}
          setSelectedNotification={setSelectedNotification}
          selectedNotification={selectedNotification}
          toggleButtonStatus={toggleButtonStatus}
          setTabIndex={setTabIndex}
          tabIndex={tabIndex}
          pageName={"dashboardViewDetails"}
          isListExpanded={isListExpanded}
          setIsListExpanded={setIsListExpanded}
          selectedId={selectedId}
          handleTabChange={handleTabChange}
          setRightPanelActive={setRightPanelActive}
          setIsMarkerClicked={setIsMarkerClicked}
          setChipButtonIndex={setChipButtonIndex}
        />
      </div>
    </div>
  );
};
export default DashboardFloorMap;
