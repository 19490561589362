import { makeStyles } from "@mui/styles";
import muiTheme from "../../theme/muiTheme";

const useStyles = makeStyles(
  {
    infoIconMainClass: (props: any) => ({
      margin: 10,
    }),
    infoBoxClass: (props: any) => ({
      display: "inline-flex",
      "& .MuiFab-root": {
        [muiTheme.breakpoints.down(1181)]: {
          width: "50px !important",
          height: "50px !important",
        },
      },
    }),
    infoTextContent: (props: any) => ({
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }),
    infoTextH1: (props: any) => ({
      margin: 0,
      padding: 0,
      color: props?.palette?.dashboardList?.white,
      fontWeight: 600,
      fontSize: 22,
      fontFamily: `'Nunito Sans', sans-serif !important`,
      lineHeight: "40.85px",
      [muiTheme.breakpoints.down(1181)]: {
        fontWeight: 600,
        fontSize: 18,
        lineHeight: "30px !important",
      },
    }),
    infoTextH4: (props: any) => ({
      margin: 0,
      padding: 0,
      color: props?.palette?.dashboardList?.white,
      fontWeight: 400,
      fontSize: 14,
      fontFamily: `'Nunito Sans', sans-serif !important`,
      lineHeight: "19.07px",
      [muiTheme.breakpoints.down(1181)]: {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: "16px !important",
      },
      [muiTheme.breakpoints.down(1025)]: {
        fontWeight: 400,
        fontSize: 8,
        lineHeight: "16px !important",
      },
    }),
    imageWidth: (props: any) => ({
      [muiTheme.breakpoints.down(1181)]: {
        width: "46px !important",
        height: "26px !important",
      },
      [muiTheme.breakpoints.down(1025)]: {
        width: "46px !important",
        height: "26px !important",
      },
    }),
  },
  { index: 1 }
);
export default useStyles;
