import { useState, useEffect, Fragment } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import theme from "../../theme/theme";
import DashboardMap from "../DashboardMap";
import DashboardList from "../DashboardList";
import moment from "moment";
import BlueWarehouse from "../../assets/markers/blueWarehouse.svg";
import GreenStoreIcon from "../../assets/markers/greenStore.svg";
import OrangeStoreIcon from "../../assets/markers/orangeStore.svg";
import RedStoreIcon from "../../assets/markers/redStore.svg";
import RightArrow from "../../assets/rightArrow.svg";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";

const DashboardContainer: React.FC<any> = (props) => {
  const {
    dashboardList,
    setViewDetailActive,
    selectedNotification,
    setSelectedNotification,
    notificationTimeStamp,
    setNotificationTimeStamp,
    setSelectedTitle,
    setSelectedArea,
    setSelectedID,
  } = props;
  const [selectedMarker, setSelectedMarker] = useState<any>();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [selectedTabButton, setSelectedTabButton] = useState<any>(0);
  const [searchOpen, setSearchOpen] = useState<any>(false);
  const [isMarkerClicked, setIsMarkerClicked] = useState<any>(false);
  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const { dashboardContent, arrowIconStyle, positionRelative } =
    useStyles(appTheme);
  const [rightPanelActive, setRightPanelActive] = useState(true);
  const [dashboardArray, setDashboardArray] = useState([]);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [chipButtonIndex, setChipButtonIndex] = useState<number>(1);
  const [showInfoDialogue, setShowInfoDialogue] = useState<boolean>(false);

  useEffect(() => {
    let markerListArray: any = [];
    dashboardList?.tracking?.map((item: any, index: number) =>
      item.list.map((val: any, index: number) => {
        markerListArray.push({
          location: val?.location,
          category: val?.category,
          manifestId: val?.manifestId,
          type: val?.type,
          id: val?.id,
          title: val?.title,
          area: val?.area,
          itemList: val,
          index: index,
          source: val?.source,
          destination: val?.destination,
          currentLocation: val?.currentLocation,
          name: val?.name,
          icon:
            val?.type === "events"
              ? GreenStoreIcon
              : val?.type === "oprAlerts"
              ? OrangeStoreIcon
              : val?.type === "incidents"
              ? RedStoreIcon
              : BlueWarehouse,
        });
      })
    );
    setDashboardArray(markerListArray);
  }, [dashboardList]);

  let currentTimeStampValue;
  let timeArrayNew: any = [];
  for (let i = 0; i < dashboardArray?.length; i++) {
    currentTimeStampValue = moment()
      .subtract({
        hours: i === 0 ? i : i > 20 ? 20 : i + 1,
        minutes: i + 59,
        seconds: i + 49,
      })
      .format("MM-DD-YYYY | h:mm A");
    timeArrayNew.push({ currentTimeStamp: currentTimeStampValue });
  }

  let combinedData = timeArrayNew?.map((item: any, i: any) =>
    Object.assign({}, item, dashboardArray[i])
  );

  useEffect(() => {
    if (selectedNotification) {
      for (let i = 0; i <= combinedData?.length; i++) {
        if (combinedData[i]?.id === selectedNotification) {
          setNotificationTimeStamp(combinedData[i]?.currentTimeStamp);
          setSelectedTitle(combinedData[i]?.title);
          setSelectedArea(
            combinedData[i]?.category === "wareHouses"
              ? combinedData[i]?.area
              : combinedData[i]?.source
          );
          setSelectedID(combinedData[i]?.id);
        }
      }
    }
  }, [selectedNotification]);

  //Redux
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "GET_WAREHOUSE_TAB",
      payload: {},
    });
    dispatch({
      type: "GET_TRAILER_TAB",
      payload: {},
    });
    dispatch({
      type: "GET_STORE_TAB",
      payload: {},
    });
  }, []);

  const warehouseDbResponse = useSelector(
    (state: any) => state?.warehouseTab?.warehouseTabValue
  );
  const trailerDbResponse = useSelector(
    (state: any) => state?.trailerTab?.trailerTabValue
  );
  const storeDbResponse = useSelector(
    (state: any) => state?.storeTab?.storeTabValue
  );

  const handleExpandListItem = (id: any, data: any) => {
    setSelectedItem(selectedNotification === id ? "" : data);
  };

  const handleRightArrowIcon = () => {
    setRightPanelActive(true);
    setSelectedNotification("");
    setTabIndex(0);
  };

  return (
    <Fragment>
      <Box sx={{ flexGrow: 1 }}>
        {/* {rightPanelActive ? ( */}
        <Grid container className={dashboardContent}>
          <Grid
            item
            xs={12}
            sm={12}
            md={rightPanelActive ? 8 : 12}
            lg={rightPanelActive ? 8 : 12}
            xl={rightPanelActive ? 9 : 12}
          >
            <DashboardMap
              dashboardList={dashboardList}
              markerArray={combinedData}
              setSelectedNotification={setSelectedNotification}
              selectedNotification={selectedNotification}
              setSelectedTabButton={setSelectedTabButton}
              selectedTabButton={selectedTabButton}
              notificationTimeStamp={notificationTimeStamp}
              setIsMarkerClicked={setIsMarkerClicked}
              isMarkerClicked={isMarkerClicked}
              setViewDetailActive={setViewDetailActive}
              selectedItem={selectedItem}
              setChipButtonIndex={setChipButtonIndex}
              chipButtonIndex={chipButtonIndex}
              tabIndex={tabIndex}
              setRightPanelActive={setRightPanelActive}
              rightPanelActive={rightPanelActive}
              searchOpen={searchOpen}
              
            />
          </Grid>
          {!rightPanelActive && (
            <img
              src={RightArrow}
              alt="RightArrow"
              onClick={handleRightArrowIcon}
              // onClick={() =>
              className={arrowIconStyle}
            />
          )}

          {rightPanelActive && (
            <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
              {combinedData.length > 0 && (
                <DashboardList
                  dashboardList={dashboardList}
                  selectedMarker={selectedMarker}
                  markerArray={combinedData}
                  setTabIndex={setTabIndex}
                  tabIndex={tabIndex}
                  setSelectedNotification={setSelectedNotification}
                  selectedNotification={selectedNotification}
                  setSelectedTabButton={setSelectedTabButton}
                  selectedTabButton={selectedTabButton}
                  searchOpen={searchOpen}
                  setSearchOpen={setSearchOpen}
                  notificationTimeStamp={notificationTimeStamp}
                  setIsMarkerClicked={setIsMarkerClicked}
                  isMarkerClicked={isMarkerClicked}
                  setRightPanelActive={setRightPanelActive}
                  setViewDetailActive={setViewDetailActive}
                  handleExpandListItem={handleExpandListItem}
                  setChipButtonIndex={setChipButtonIndex}
                  chipButtonIndex={chipButtonIndex}
                  rightPanelActive={rightPanelActive}
                  showInfoDialogue={showInfoDialogue}
                  setShowInfoDialogue={setShowInfoDialogue}
                />
              )}
            </Grid>
          )}
        </Grid>
      </Box>
    </Fragment>
  );
};
export default DashboardContainer;
