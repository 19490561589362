import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  customTextField: (props: any) => ({
    marginTop: "5px",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0 !important",
      borderColor: "unset !important",
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
      border: "1px solid #DDDDDD",
      borderRadius: "6px",
      background: "#000000",

      "& .MuiInputBase-input": {
        fontFamily: `'Nunito Sans', sans-serif !important`,
        color: "#FFFFFF",
        "-webkit-text-fill-color": "#FFFFFF",
      },
    },
    "& .MuiInputLabel-root": {
      color: "white !important",
    },
    "& .MuiOutlinedInput-input": {
      padding: "15px !important",
      color: "white !important",
    },
    "& .MuiIconButton-root": {
      color: "white !important",
    },
  }),
}));

export default useStyles;
