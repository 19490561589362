import { makeStyles } from "@mui/styles";
import muiTheme from "../../theme/muiTheme";

const useStyles = makeStyles({
  root: (props: any) => ({
    background: props?.palette?.dashboardList?.pageBackgroundColor,
  }),
  tabSection: (props: any) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: " 8px 16px 0 16px",
    borderBottom: `2px solid ${props?.palette?.dashboardList?.lightGrey}`,
    "& .MuiButtonBase-root": {
      marginRight: "0 !important",
    },
    "& .MuiTabs-root": {
      marginTop: "6px !important",
    },
    "& .MuiTab-root": {
      fontSize: 18,
      fontFamily: `'Nunito Sans', sans-serif !important`,
      padding: "0px !important",
      paddingBottom: "8px !important",
      fontWeight: 800,
      [muiTheme.breakpoints.down(1181)]: {
        fontSize: 14,
      },
      [muiTheme.breakpoints.down(1025)]: {
        fontSize: 14,
      },
    },
    "& .MuiTab-root.Mui-selected": {
      color: props?.palette?.dashboardList?.darkGrey,
    },
    "& .MuiTabs-indicator": {
      backgroundColor: props?.palette?.dashboardList?.darkGrey,
      borderRadius: "0px 0px 50px 50px !important",
      transform: "rotate(-180deg)",
      height: 4,
      left: "114.0938px",
    },
  }),

  tabButtonSection: (props: any) => ({
    display: "flex",
    justifyContent: "space-between",
    margin: "10px",
    width: "100%",
    "& .MuiButton-root": {
      flex: 1,
      borderRadius: "10px !important",
      marginRight: 13,
      fontSize: "15px !important",
      lineHeight: "32px !important",
      fontFamily: `'Nunito Sans', sans-serif !important`,
      [muiTheme.breakpoints.down(1181)]: {
        fontSize: "12px !important",
        lineHeight: "18px !important",
        borderRadius: "6px !important",
      },
      [muiTheme.breakpoints.down(1025)]: {
        fontSize: "12px !important",
        lineHeight: "18px !important",
        borderRadius: "6px !important",
      },
    },
    "& :last-child": {
      marginRight: 0,
    },
  }),
  chipButtonSection: (props: any) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "14px",
  }),
  customNotificationTabs: (props: any) => ({
    "& .MuiButtonBase-root": {
      marginRight: "25px !important",
      minWidth: "64px !important",
      [muiTheme.breakpoints.down(1181)]: {
        marginRight: "15px !important",
        minWidth: "54px !important",
      },
      [muiTheme.breakpoints.down(1025)]: {
        marginRight: "10px !important",
        minWidth: "44px !important",
      },
    },
  }),
  selectedButtonStyles: (props: any) => ({
    backgroundColor: `${props?.palette?.dashboardList?.darkGreen} !important`,
    borderRadius: "5px !important",
    fontFamily: `'Nunito Sans', sans-serif !important`,
    fontSize: "15px !important",
    lineHeight: "20px !important",
    boxShadow: "none !important",
    fontWeight: "700 !important",
    [muiTheme.breakpoints.down(1181)]: {
      fontSize: "12px !important",
      lineHeight: "14px !important",
    },
    [muiTheme.breakpoints.down(1025)]: {
      fontSize: "12px !important",
      lineHeight: "14px !important",
    },
  }),
  unSelectedButtonStyles: (props: any) => ({
    backgroundColor: `${props?.palette?.dashboardList?.lightPurple} !important`,
    color: `${props?.palette?.dashboardList?.darkGrey2} !important`,
    borderRadius: "5px !important",
    fontFamily: `'Nunito Sans', sans-serif !important`,
    fontSize: "15px !important",
    lineHeight: "20px !important",
    boxShadow: "none !important",
    fontWeight: "700 !important",
    [muiTheme.breakpoints.down(1181)]: {
      fontSize: "12px !important",
      lineHeight: "14px !important",
    },
    [muiTheme.breakpoints.down(1025)]: {
      fontSize: "12px !important",
      lineHeight: "14px !important",
    },
  }),
  dashboardListSection: (props: any) => ({
    height: "calc(100vh - 148px)",
    overflow: "auto",
  }),
  searchIconStyle: (props: any) => ({
    cursor: "pointer",
    marginRight: 10,
  }),
  rightArrowStyle: (props: any) => ({
    cursor: "pointer",
  }),
  searchClass: (props: any) => ({
    border: `1px solid ${props?.palette?.dashboardList?.lightGrey}`,
    background: props?.palette?.dashboardList?.white,
    color: props?.palette?.dashboardList?.lightGrey3,
    borderRadius: 6,
    height: "40px",
    "& .MuiIconButton-root": {
      marginRight: 7,
    },
    "& .MuiInputBase-root": {
      // fontWeight: 500,
    },
  }),

  noResultStyle: (props: any) => ({
    margin: 10,
  }),
  searchContainerStyle: (props: any) => ({
    margin: 10,
    width: "100%",
  }),
});
export default useStyles;
