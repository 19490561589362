import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  infoIconContainer: (props: any) => ({
    position: "absolute",
    width: "98%",
    bottom: 0,
    background: "rgba(21, 21, 21, 0.9)",
    margin: "10px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-between",
    backdropFilter: "blur(5px)",
  }),

  dashboardMapContainer: (props: any) => ({
    width: "100%",
    position: "relative",
  }),
});
export default useStyles;
