import { useState, useEffect } from "react";
import Select from "elements/Select";
import InputField from "elements/TextField";
import {
  TextField,
  Chip,
  ListItem,
  ListItemButton,
  ListItemText,
  Autocomplete,
} from "@mui/material";
import Grid from "@mui/material/Grid";

import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import Calendar from "elements/Calendar";
import InputSearch from "elements/InputSearch";
import AddIcon from "assets/addIcon.svg";
import RemoveCircleIcon from "assets/RemoveCircleIcon.svg";
import AddActiveIcon from "assets/AddActiveIcon.svg";
import RemoveCircularActiveIcon from "assets/RemoveCircularActiveIcon.svg";
import theme from "theme/theme";
import manifestStorePallets from "mockdata/Firebase-formats/manifestStores";
import manifestPallets from "mockdata/Firebase-formats/manifestPallets";
import Button from "elements/Button";
import useStyles from "./styles";

const PalletList: React.FC<any> = (props) => {
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const {
    customTextFieldItem,
    dateTimePickerStyle,
    rootContainer,
    addIcon,
    palletListContainer,
    footer,
    cancelButton,
    dashedLine,
    palletListItemContainer,
    palletList,
    searchContainer,
    palletListItems,
    palletListData,
    saveButton,
    listSpace,
    removeIcon,
    inputSearchItem,
  } = useStyles(appTheme);

  const obj = [
    {
      name: "",
      location: "",
      deliveryDate: "",
      deliveryTime: "",
      searchValue: "",
      pallets: [],
    },
  ];

  const [multiplePallets, setMultiplePallets] = useState<any>(obj);

  const [searchData, setSearchData] = useState<any>();
  const [searchPalletsData, setSearchPalletsData] = useState<any>();

  useEffect(() => {
    setSearchPalletsData(manifestPallets);
  }, [manifestPallets]);

  let times: any[] = [],
    periods = ["AM", "PM"],
    hours = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    prop = null,
    hour = null,
    min = null;

  for (prop in periods) {
    for (hour in hours) {
      for (min = 0; min < 60; min += 60) {
        times.push(
          ("0" + hours[hour]).slice(-2) +
            ":" +
            ("0" + min).slice(-2) +
            " " +
            periods[prop]
        );
      }
    }
  }

  let values: any[] = [];
  manifestStorePallets?.stores?.map((item: any) => {
    values.push({
      label: item?.name,
      value: item?.name,
    });
  });
  // values.unshift({ label: "Select Store", value: "Select Store" });

  const handleClick = () => {
    setMultiplePallets([...multiplePallets, ...obj]);
  };

  const handleRemove = (index: number) => {
    if (multiplePallets?.length > 1) {
      setMultiplePallets((prev: any) => {
        return prev.filter((item: any, i: number) => i !== index);
      });
    }
  };

  const handleSelect = (value: string, index: number) => {
    const data = manifestStorePallets?.stores?.find(
      (item: any) => item.name === value
    );
    const pallets = [...multiplePallets];
    pallets[index]["name"] = value;
    pallets[index]["location"] =
      // value === "Select Store"
      //   ? "" :
      `${data?.location?.lat}, ${data?.location?.lng}`;
    setMultiplePallets(pallets);
  };

  const handleDelete = (key: string, index: number) => () => {
    const deletedData = manifestPallets?.filter(
      (item: any) => item?.name === key
    );
    const palletsData = [...multiplePallets];
    const selectedPalletsData = palletsData[index]["pallets"];
    const availableData = selectedPalletsData.filter(
      (chip: any) => chip.key !== key
    );
    palletsData[index]["pallets"] = availableData;
    setMultiplePallets(palletsData);
    setSearchPalletsData([...searchPalletsData, ...deletedData]);
    setSearchData([...searchData, ...deletedData]);
  };

  const handleSearch = (e: any, index: number) => {
    const palletsData = [...multiplePallets];
    palletsData[index]["searchValue"] = e.target?.value;
    setMultiplePallets(palletsData);

    let searchResult: { id: string | undefined; name: string }[] | undefined =
      [];

    searchResult = searchPalletsData?.filter((data: any) => {
      return data?.name
        ?.toString()
        .toLowerCase()
        .includes(e.target.value?.toString().toLowerCase());
    });

    setSearchData(searchResult);
  };

  const handleClose = (e: any, index: number) => {
    const palletsData = [...multiplePallets];
    palletsData[index]["searchValue"] = "";
    setMultiplePallets(palletsData);
  };

  const handleListItemClick = (e: any, value: string, index: number) => {
    let data: any[] = [];
    data.push({
      key: value,
      label: value,
    });

    const palletsData = [...multiplePallets];
    palletsData[index]["pallets"] = [...palletsData[index]["pallets"], ...data];
    setMultiplePallets(palletsData);
    setSearchPalletsData((prev: any) => {
      return prev.filter((item: any) => item?.name !== value);
    });
    setSearchData((prev: any) => {
      return prev.filter((item: any) => item?.name !== value);
    });
  };

  const handleSelectedDate = (value: any, index: number) => {
    const pallets = [...multiplePallets];
    pallets[index]["deliveryDate"] = value;
    setMultiplePallets(pallets);
  };

  const handleSelectedTime = (e: any, index: number) => {
    const pallets = [...multiplePallets];
    pallets[index]["deliveryTime"] = e.target.innerText;
    setMultiplePallets(pallets);
  };

  const handleCancel = () => {
    props.handleCancel();
  };

  const handleSave = () => {
    props.handleSave(multiplePallets);
  };

  return (
    <div>
      <div className={rootContainer}>
        <div>Stores & Pallet List</div>
        <div className={addIcon} onClick={handleClick}>
          <img
            src={
              multiplePallets[0]?.name &&
              multiplePallets[0].deliveryDate &&
              multiplePallets[0].deliveryTime &&
              multiplePallets[0].pallets?.length > 0
                ? AddActiveIcon
                : AddIcon
            }
          />
        </div>
      </div>

      <div className={palletListContainer}>
        {multiplePallets?.map((item: any, index: number) => {
          return (
            <>
              <div className={palletListItemContainer}>
                <Grid container>
                  <Grid item xs={2.9} className={listSpace}>
                    <Select
                      selectList={values}
                      handleSelect={(val: string) => handleSelect(val, index)}
                      customWidth={"100%"}
                      customHeight={"60px"}
                      drowpDownTextColor={"#fff"}
                      dropDownBgColor={"#000000"}
                      dropDownSelectedBgColor={"#9486DA"}
                      selectedValue={item.name}
                      placeholder={"Select Store"}
                    />
                  </Grid>
                  <Grid item xs={2.9} className={customTextFieldItem}>
                    <InputField
                      value={item?.location}
                      type={"text"}
                      disabled={true}
                      placeholder={"Lat, Long"}
                      fullWidth={false}
                    />
                  </Grid>
                  <Grid item xs={2.9} className={dateTimePickerStyle}>
                    <Calendar
                      placeholder={"Delivery Date"}
                      selectedDate={item?.deliveryDate}
                      handleSelectedDate={(e: any) =>
                        handleSelectedDate(e, index)
                      }
                      dateType={"deliveryDate"}
                      selectedStartDate={props.selectedStartDate}
                    />
                  </Grid>
                  <Grid item xs={2.9} className={listSpace}>
                    <div className={dateTimePickerStyle}>
                      <Autocomplete
                        id="disabled-options-demo"
                        disableClearable
                        options={times}
                        value={item?.deliveryTime}
                        onChange={(e: any) => handleSelectedTime(e, index)}
                        sx={{
                          width: "100%",
                          color: "white !important",
                          border: "1px solid white",
                          borderRadius: 1,
                          marginLeft: 2,
                        }}
                        popupIcon={<AccessTimeOutlinedIcon />}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Delivery Time"
                            sx={{
                              "::placeholder": { color: "white !important" },
                            }}
                            InputLabelProps={{
                              style: { color: "#fff" },
                            }}
                          />
                        )}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={0.3}>
                    <div
                      className={removeIcon}
                      style={{
                        cursor:
                          multiplePallets?.length > 1 ? "pointer" : "default",
                      }}
                      onClick={() => handleRemove(index)}
                    >
                      <img
                        src={
                          multiplePallets?.length > 1
                            ? RemoveCircularActiveIcon
                            : RemoveCircleIcon
                        }
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className={searchContainer}>
                <InputSearch
                  variant="filled"
                  customTextField={inputSearchItem}
                  searchValue={item?.searchValue}
                  handleSearch={(e: any) => handleSearch(e, index)}
                  handleClose={(e: any) => handleClose(e, index)}
                  type={"search"}
                />
                {item?.searchValue?.length > 0 && (
                  <div className={palletListData}>
                    {searchData?.length > 0 ? (
                      searchData?.map((item: any, idx: number) => {
                        return (
                          <ListItem
                            key={idx}
                            component="div"
                            disablePadding
                            onClick={(e: any) =>
                              handleListItemClick(e, item?.name, index)
                            }
                          >
                            <ListItemButton>
                              <ListItemText primary={item?.name} />
                            </ListItemButton>
                          </ListItem>
                        );
                      })
                    ) : item?.searchValue?.length > 0 ? (
                      <ListItem component="div" disablePadding>
                        <ListItemButton>
                          <ListItemText primary={"No Records Found"} />
                        </ListItemButton>
                      </ListItem>
                    ) : null}
                  </div>
                )}
              </div>
              <div className={searchContainer}>
                {item?.pallets?.length === 0 ? (
                  <div className={palletList}>Select Pallets</div>
                ) : (
                  <div className={palletListItems}>
                    {item?.pallets.map((data: any) => {
                      return (
                        <div style={{ paddingRight: "15px" }}>
                          <Chip
                            label={data?.label}
                            onDelete={handleDelete(data?.key, index)}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              {multiplePallets?.length > 1 && (
                <div className={dashedLine}></div>
              )}
            </>
          );
        })}
      </div>
      <div className={footer}>
        <div className={cancelButton}>
          <Button variant="outlined" handleClick={handleCancel}>
            Cancel
          </Button>
        </div>
        <div className={saveButton}>
          <Button variant="contained" handleClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PalletList;
