import React, { Fragment, useState, useEffect, forwardRef } from "react";
import theme from "../../theme/theme";
// @ts-ignore
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import useStyles from "./styles";

type ChildProps = {
  ref: any;
  value: any;
  onClick: any;
};

type ChildComponentRef = {
  value: any;
  onClick: any;
};

const Calendar: React.FC<any> = (props) => {
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);
  const { datePickerStyle } = useStyles(appTheme);
  const {
    placeholder,
    selectedDate,
    handleSelectedDate,
    newStartDate,
    isSelectedDateChanged,
    dateType,
    selectedStartDate,
  } = props;
  const [style, setStyle] = useState({ display: "none" });
  const [startDate, setStartDate] = useState<any>(null);

  let minDate: any;

  useEffect(() => {
    setStartDate(selectedDate);
  }, [selectedDate]);

  if (dateType === "deliveryDate" && selectedStartDate) {
    minDate = null;
    if (selectedStartDate) {
      minDate = new Date(selectedStartDate);

      minDate.setDate(minDate.getDate() + 1);
    }
  }

  const handleCloseIcon = () => {
    setStartDate(null);
  };
  const ExampleCustomInput: any = forwardRef<ChildComponentRef, ChildProps>(
    ({ value, onClick }, ref: any) => (
      <>
        <OutlinedInput
          type="text"
          value={!isSelectedDateChanged && newStartDate ? newStartDate : value}
          // value={value}
          placeholder={placeholder ? placeholder : "Start Date"}
          // onMouseEnter={(e) => {
          //   setStyle({ display: "block" });
          // }}
          // onMouseLeave={(e) => {
          //   setStyle({ display: "none" });
          // }}
          endAdornment={
            <InputAdornment position="end">
              {startDate !== null && (
                <IconButton aria-label="toggle password visibility" edge="end">
                  <CloseIcon style={style} onClick={handleCloseIcon} />
                </IconButton>
              )}

              <IconButton edge="end">
                <CalendarTodayIcon onClick={onClick} ref={ref} />
              </IconButton>
            </InputAdornment>
          }
        />
      </>
    )
  );

  const today = new Date();

  return (
    <>
      <div className={datePickerStyle}>
        <DatePicker
          selected={startDate}
          onChange={handleSelectedDate}
          dateFormat="dd-MM-yyyy"
          customInput={<ExampleCustomInput />}
          minDate={dateType === "deliveryDate" ? minDate : today}
        />
      </div>
    </>
  );
};
export default Calendar;
