import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import useTranslation from "../../localization/translations";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InfoSubList from "../InfoSubList";
import CustomizedSteppers from "../Stepper";
import ChipButton from "../../elements/ChipButton";
import Tabs from "../../elements/Tabs";
import { SearchIconInfo } from "../../assets/buttonIcons";
import theme from "../../theme/theme";
import colorCodes from "../../theme/colors";
import Geofence from "components/Geofence";
import FloorMap from "components/Map/FloorMap";
import Map from "components/Map";

import InfoNotificationListItem from "components/InfoNotificationListItem";
import SearchBox from "elements/SearchBox";
import { CloseIconImg } from "../../assets/icons";
import SwitchBox from "elements/Switchbox";
import CheckBox from "elements/Checkbox";
import {
  AddAlertsPluseIcon,
  AddAlertsPluseIconInactive,
  AddAlertsMinusIcon,
  AddAlertsMinusIconInactive,
} from "../../assets/icons";
import Button from "elements/Button";
import useStyles from "./styles";

const AddAlertsConfigInfoDialog: React.FC<any> = (props) => {
  const {
    selectedAlertForAddition,
    handleInputText,
    additionDeletionActivations,
    handleAlertsAddition,
    handleAlertsdelete,
    indexValue,
    item,
  } = props;

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const {
    addAlertsContentContainer,
    labelTextArea,
    inputTextArea,
    inputStyle,
    additionIconsContainer,
    addIconStyle,
    minusIconStyle,
    inputStyleErrored,
  } = useStyles(appTheme);

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  return (
    <>
      <Grid container className={addAlertsContentContainer}>
        <Grid item xs={5}>
          <div className={labelTextArea}>
            <span>{selectedAlertForAddition}</span>
          </div>
        </Grid>
        <Grid item xs={5}>
          <div className={inputTextArea}>
            <input
              className={item?.error? inputStyleErrored : inputStyle}
              value={item?.alertName}
              type="text"
              placeholder="Enter alert name"
              onChange={(event) =>
                handleInputText(event, indexValue, selectedAlertForAddition)
              }
            />
          </div>
          <label style={{color:"#F50303", marginTop: "10px"}}>{item?.error}</label>
        </Grid>
        <Grid item xs={2} className={additionIconsContainer}>
          <div className={minusIconStyle}
          onClick={() => {
            additionDeletionActivations?.deletionIconActivation &&
              handleAlertsdelete(indexValue, selectedAlertForAddition);
          }}
          >
            <img src={additionDeletionActivations?.deletionIconActivation ? AddAlertsMinusIcon : AddAlertsMinusIconInactive} />
          </div>
          <div
            className={addIconStyle}
            onClick={() => {
              additionDeletionActivations?.additionIconActivation &&
                handleAlertsAddition(indexValue, selectedAlertForAddition);
            }}
          >
            <img
              src={
                additionDeletionActivations?.additionIconActivation
                  ? AddAlertsPluseIcon
                  : AddAlertsPluseIconInactive
              }
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default AddAlertsConfigInfoDialog;
