import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import dashboardListReducer from "./dashboardListReducer";
import trailerReducer from "./trailerReducer";


const rootReducer = combineReducers({
  login: loginReducer,
  dashboardList: dashboardListReducer,
  trailerList: trailerReducer,
  
});

export default rootReducer;
