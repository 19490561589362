import { makeStyles } from "@mui/styles";
import muiTheme from "../../theme/muiTheme";

const useStyles = makeStyles(
  () => ({
    root: (props: any) => ({
      flexGrow: 1,
    }),

    logoImg: (props: any) => ({
      display: "flex",
      alignItems: "center",
      color: props?.palette?.header?.colorWhite,
      "& :first-child": {
        cursor: "pointer",
        marginRight: "13px",
        [muiTheme.breakpoints.down(963)]: {
          width: 30,
        },
      },
      "& :last-child": {
        [muiTheme.breakpoints.down(963)]: {
          fontSize: "14px !important",
          fontFamily: `'Nunito Sans', sans-serif !important`,
        },
      },
      // "& span": {
      //   fontFamily: `'Nunito Sans', sans-serif`,
      //   fontWeight: 900,
      //   fontSize: "18px",
      //   lineHeight: "25px",
      //   color: props?.palette?.header?.colorWhite,
      //   letterSpacing: "0.1em",
      //   textTransform: "uppercase",
      // },
    }),
    header: (props: any) => ({
      alignItems: "center",
      background: props?.palette?.header?.darkBlueHeader,
      boxShadow: "0px 4px 4px 3px rgba(0, 0, 0, 0.05)",
      padding: "14px 0 14px 14px",
      zIndex: 1500,
      top: 0,
      position: "fixed",
    }),
    headerRight: (props: any) => ({
      display: "flex",
      justifyContent: "flex-end",
    }),
    avatharSection: (props: any) => ({
      display: "flex",
      alignItems: "center",
      marginRight: 2,
      color: `${props?.palette?.header?.colorWhite}!important`,
      "& .MuiIconButton-root": {
        zIndex: 1500,
        color: `${props?.palette?.header?.colorWhite}!important`,
      },
    }),
    avatharBackground: (props: any) => ({
      backgroundColor: `${props?.palette?.header?.avatarBgColorViolet}!important`,
    }),
    logoSection: (props: any) => ({
      display: "flex",
      alignItems: "center",
    }),

    avatharName: (props: any) => ({
      marginLeft: 13,
      marginRight: 16,
      "& p": {
        margin: 0,
      },
      "& :first-child": {
        fontFamily: `'Nunito Sans', sans-serif`,
        fontWeight: 700,
        lineHeight: "18px",
        fontSize: "13px",
        color: props?.palette?.header?.colorWhite,
        textTransform: "capitalize",
      },
      "& :last-child": {
        fontFamily: `'Nunito Sans', sans-serif`,
        fontSize: "10px",
        lineHeight: "14px",
        fontWeight: 600,
        color: props?.palette?.header?.colorWhite,
      },
    }),
    avatharIcon: (props: any) => ({
      "&.MuiIconButton-root": {
        color: props?.palette?.header?.colorWhite,
      },
    }),

    logoutSection: (props: any) => ({
      display: "flex",
      alignItems: "center",
    }),
    logoutImg: (props: any) => ({
      width: 20,
      marginRight: 7,
    }),
    logoutText: (props: any) => ({
      fontSize: "14px !important",
      fontWeight: "700 !important",
      fontFamily: `'Nunito Sans', sans-serif !important`,
      color: props?.palette?.header?.darkGrayLogoutText,
    }),
    customMenu: (props: any) => ({
      zIndex: " 1501 !important",
      left: "11px !important",
      marginTop: "46px !important",
      "& .MuiMenu-list": {
        background: `${props?.palette?.header?.colorWhite}!important`,
        marginTop: "10px !important",
        border: `1px solid ${props?.palette?.header?.lightGrayLogoutText} !important`,
        borderRadius: "4px !important",
        marginLeft: 26,
      },
      "& .MuiPaper-root": {
        background: "none !important",
        boxShadow: "none !important",
        minWidth: 200,
      },
      "& .MuiList-root": {
        "&::before": {
          width: 10,
          height: 10,
          content: '""',
          transform: "rotate(225deg)",
          boxSizing: "border-box",
          backgroundColor: props?.palette?.header?.colorWhite,
          position: "absolute",
          right: 17,
          top: -6,
          border: `1px solid ${props?.palette?.header?.lightGrayLogoutText}`,
          borderStyle: "solid",
          borderWidth: "0px 1px 1px 0px",
        },
      },
      "& .MuiButtonBase-root": {
        padding: "6px 14px !important",
        "&:hover": {
          background: "transparent !important",
        },
      },
    }),

    tabStyle: (props: any) => ({
      display: "flex",
      justifyContent: "space-between",
      marginRight: "30px",
      marginTop: "10px",
      "& :last-child": {
        marginRight: 0,
      },
    }),

    selectedButtonStyles: (props: any) => ({
      backgroundColor: `${props?.palette?.header?.darkGreenHeaderButton} !important`,
      borderRadius: "15px !important",
      fontFamily: `'Nunito Sans', sans-serif !important`,
      fontSize: "15px !important",
      lineHeight: "20px !important",
      boxShadow: "none !important",
      fontWeight: "700 !important",
      [muiTheme.breakpoints.down(1181)]: {
        fontSize: "12px !important",
        lineHeight: "14px !important",
      },
      [muiTheme.breakpoints.down(1025)]: {
        fontSize: "12px !important",
        lineHeight: "14px !important",
      },
    }),
    unSelectedButtonStyles: (props: any) => ({
      backgroundColor: "transparent !important",
      color: `${props?.palette?.header?.disableGrayHeaderButtonText} !important`,
      borderRadius: "15px !important",
      fontFamily: `'Nunito Sans', sans-serif !important`,
      fontSize: "15px !important",
      lineHeight: "20px !important",
      boxShadow: "none !important",
      fontWeight: "700 !important",
      [muiTheme.breakpoints.down(1181)]: {
        fontSize: "12px !important",
        lineHeight: "14px !important",
      },
      [muiTheme.breakpoints.down(1025)]: {
        fontSize: "12px !important",
        lineHeight: "14px !important",
      },
    }),
    buttonStyle: (props: any) => ({
      marginRight: "49px",
      [muiTheme.breakpoints.down(1181)]: {
        marginRight: "18px",
      },
    }),
    btnTextClass: (props: any) => ({
      marginLeft: "5px",
      textTransform: "none",
    }),
    personIconClass: (props: any) => ({
      marginRight: "4px",
    }),
  }),
  { index: 1 }
);

export default useStyles;
