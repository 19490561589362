const maniFestCompletedViewDetails = {
    manifestCompletedList: [
        {
  trailerId: "123456",
  mainfestId: "MID#123",
  totalPallets: "20",
  deliveryStores: "02",
  startLocation: "Idaho Falls, IDHAO, US",
  endLocation: "Idaho Falls, IDHAO, US",
  stores: [
    {
      name: "STORE#1",
      area: "Nebraska, 80831, US",
      count: "04",
      pallets: [
        {
          id: "",
          name: "PID#34522",
          trackerId: "TID#3421",
          palletId: "PID#34522",
          status: "In Transit",
          dispatchedDate: "03-13-2023 | 9:00 AM",
          deliveredDate: "13-03-2023 | 11:00 AM",
          temparature: "68°C",
          humidity: "24%",
          pressure: "987 kPa",
          palletShipmentData: [
            {
              id: "",
              packageStage: "Pallet Ready For Shipment",
              timeStamp: "1-11-2023 9.00AM",
              status: "Completed",
            },
            {
              id: "",
              packageStage: "Left Grocery Warehouse#1",
              timeStamp: "1-11-2023 10.30AM",
              status: "Completed",
            },
            {
              id: "",
              packageStage: "In Transit",
              timeStamp: "1-11-2023 12.30PM",
              status: "Completed",
            },
            {
              id: "",
              packageStage: "Store#1",
              timeStamp: "1-11-2023 1.30PM",
              status: "Completed",
            },
          ],
        },
        {
          id: "",
          name: "PID#34523",
          trackerId: "TID#3421",
          palletId: "PID#34523",
          status: "In Transit",
          dispatchedDate: "03-13-2023 | 9:00 AM",
          deliveredDate: "13-03-2023 | 11:00 AM",
          temparature: "68°C",
          humidity: "24%",
          pressure: "987 kPa",
          palletShipmentData: [
            {
              id: "",
              packageStage: "Pallet Ready For Shipment",
              timeStamp: "1-11-2023 9.00AM",
              status: "Completed",
            },
            {
              id: "",
              packageStage: "Left Grocery Warehouse#1",
              timeStamp: "1-11-2023 10.30AM",
              status: "Completed",
            },
            {
              id: "",
              packageStage: "In Transit",
              timeStamp: "1-11-2023 12.30PM",
              status: "Completed",
            },
            {
              id: "",
              packageStage: "Store#1",
              timeStamp: "1-11-2023 1.30PM",
              status: "Completed",
            },
          ],
        },
        {
          id: "",
          name: "PID#34524",
          trackerId: "TID#3421",
          palletId: "PID#34524",
          status: "In Transit",
          dispatchedDate: "03-13-2023 | 9:00 AM",
          deliveredDate: "13-03-2023 | 11:00 AM",
          temparature: "68°C",
          humidity: "24%",
          pressure: "987 kPa",

          palletShipmentData: [
            {
              id: "",
              packageStage: "Pallet Ready For Shipment",
              timeStamp: "1-11-2023 9.00AM",
              status: "Completed",
            },
            {
              id: "",
              packageStage: "Left Grocery Warehouse#1",
              timeStamp: "1-11-2023 10.30AM",
              status: "Completed",
            },
            {
              id: "",
              packageStage: "In Transit",
              timeStamp: "1-11-2023 12.30PM",
              status: "Completed",
            },
            {
              id: "",
              packageStage: "Store#1",
              timeStamp: "1-11-2023 1.30PM",
              status: "Completed",
            },
          ],
        },
        {
          id: "",
          name: "PID#34525",
          trackerId: "TID#3421",
          palletId: "PID#34525",
          status: "In Transit",
          dispatchedDate: "03-13-2023 | 9:00 AM",
          deliveredDate: "13-03-2023 | 11:00 AM",
          temparature: "68°C",
          humidity: "24%",
          pressure: "987 kPa",
          palletShipmentData: [
            {
              id: "",
              packageStage: "Pallet Ready For Shipment",
              timeStamp: "1-11-2023 9.00AM",
              status: "Completed",
            },
            {
              id: "",
              packageStage: "Left Grocery Warehouse#1",
              timeStamp: "1-11-2023 10.30AM",
              status: "Completed",
            },
            {
              id: "",
              packageStage: "In Transit",
              timeStamp: "1-11-2023 12.30PM",
              status: "Completed",
            },
            {
              id: "",
              packageStage: "Store#1",
              timeStamp: "1-11-2023 1.30PM",
              status: "Completed",
            },
          ],
        },
      ],
    },
    {
      name: "STORE#2",
      area: "Missouri, 80831, US",
      count: "04",
      pallets: [
        {
          id: "",
          name: "PID#34524",
          trackerId: "TID#3421",
          palletId: "PID#34524",
          status: "In Transit",
          dispatchedDate: "03-13-2023 | 9:00 AM",
          deliveredDate: "13-03-2023 | 11:00 AM",
          temparature: "68°C",
          humidity: "24%",
          pressure: "987 kPa",
          palletShipmentData: [
            {
              id: "",
              packageStage: "Pallet Ready For Shipment",
              timeStamp: "1-11-2023 9.00AM",
              status: "Completed",
            },
            {
              id: "",
              packageStage: "Left Grocery Warehouse#1",
              timeStamp: "1-11-2023 10.30AM",
              status: "Completed",
            },
            {
              id: "",
              packageStage: "In Transit",
              timeStamp: "1-11-2023 12.30PM",
              status: "Completed",
            },
            {
              id: "",
              packageStage: "Store#1",
              timeStamp: "1-11-2023 1.30PM",
              status: "Completed",
            },
          ],
        },
        {
          id: "",
          name: "PID#34525",
          trackerId: "TID#3421",
          palletId: "PID#34525",
          status: "In Transit",
          dispatchedDate: "03-13-2023 | 9:00 AM",
          deliveredDate: "13-03-2023 | 11:00 AM",
          temparature: "68°C",
          humidity: "24%",
          pressure: "987 kPa",
          palletShipmentData: [
            {
              id: "",
              packageStage: "Pallet Ready For Shipment",
              timeStamp: "1-11-2023 9.00AM",
              status: "Completed",
            },
            {
              id: "",
              packageStage: "Left Grocery Warehouse#1",
              timeStamp: "1-11-2023 10.30AM",
              status: "Completed",
            },
            {
              id: "",
              packageStage: "In Transit",
              timeStamp: "1-11-2023 12.30PM",
              status: "Completed",
            },
            {
              id: "",
              packageStage: "Store#1",
              timeStamp: "1-11-2023 1.30PM",
              status: "Completed",
            },
          ],
        },
        {
          id: "",
          name: "PID#34526",
          trackerId: "TID#3421",
          palletId: "PID#34526",
          status: "In Transit",
          dispatchedDate: "03-13-2023 | 9:00 AM",
          deliveredDate: "13-03-2023 | 11:00 AM",
          temparature: "68°C",
          humidity: "24%",
          pressure: "987 kPa",
          palletShipmentData: [
            {
              id: "",
              packageStage: "Pallet Ready For Shipment",
              timeStamp: "1-11-2023 9.00AM",
              status: "Completed",
            },
            {
              id: "",
              packageStage: "Left Grocery Warehouse#1",
              timeStamp: "1-11-2023 10.30AM",
              status: "Completed",
            },
            {
              id: "",
              packageStage: "In Transit",
              timeStamp: "1-11-2023 12.30PM",
              status: "Completed",
            },
            {
              id: "",
              packageStage: "Store#1",
              timeStamp: "1-11-2023 1.30PM",
              status: "Completed",
            },
          ],
        },
        {
          id: "",
          name: "PID#34527",
          trackerId: "TID#3421",
          palletId: "PID#34527",
          status: "In Transit",
          dispatchedDate: "03-13-2023 | 9:00 AM",
          deliveredDate: "13-03-2023 | 11:00 AM",
          temparature: "68°C",
          humidity: "24%",
          pressure: "987 kPa",
          palletShipmentData: [
            {
              id: "",
              packageStage: "Pallet Ready For Shipment",
              timeStamp: "1-11-2023 9.00AM",
              status: "Completed",
            },
            {
              id: "",
              packageStage: "Left Grocery Warehouse#1",
              timeStamp: "1-11-2023 10.30AM",
              status: "Completed",
            },
            {
              id: "",
              packageStage: "In Transit",
              timeStamp: "1-11-2023 12.30PM",
              status: "Completed",
            },
            {
              id: "",
              packageStage: "Store#1",
              timeStamp: "1-11-2023 1.30PM",
              status: "Completed",
            },
          ],
        },
      ],
    },
  ],
  trailerRoutes: [
    {
      name: "WAREHOUSE#1",
      status: "In progress",
      area: "Idaho Falls, IDHAO, US ",
      
      timeStamp: "02-13-2023 | 9:00 AM",
    },
    {
      name: "STORE#1",
      status: "Completed",
      area: "San Carlos, Costa Rica",
      
      timeStamp: "02-13-2023 | 9:00 AM",
    },
    {
      name: "STORE#2",
      status: "Completed",
      area: "Nebraska, 80831, US ",
      
      timeStamp: "02-13-2023 | 9:00 AM",
    },
  ],
},
    ]
};

export default maniFestCompletedViewDetails;
