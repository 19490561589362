import { useState, Fragment, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import theme from "../../theme/theme";
import Tabs from "elements/Tabs";
import Select from "elements/Select";
import AnalyticsEquipment from "components/AnalyticsEquipment";
import AnalyticsWarehouse from "components/AnalyticsWarehouse";
import wareHouseAnalytics from "mockdata/wareHouseAnalytics";
import equipmentAnalytics from "mockdata/equipmentAnalytics";
import useStyles from "./styles";

const Analytics: React.FC<any> = (props) => {
  const { buttonStyles } = props;
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const {
    analyticsContent,
    tabSection,
    customNotificationTabs,
    customSelectDropDown,
    selectSection,
    analyticsInnerContainer,
  } = useStyles(appTheme);

  const analyticsWarehouseData = wareHouseAnalytics;

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [selectedValue, setSelectedValue] = useState<string>("ALL");
  const[selectedEquipmentWareHouseValue, setSelectedEquipmentWareHouseValue]=useState<any>("ALL")

  const[selectedWarehouseGraphObjData, setSelectedWarehouseGraphObjData]=useState<any>(analyticsWarehouseData?.analytics[0])
  const[selectedEquipmentGraphObjData, setSelectedEquipmentGraphObjData]=useState<any>(equipmentAnalytics?.analytics[0])

  const tabsList = [
    { name: "Warehouse", val: 0 },
    { name: "Equipment", val: 1 },
  ];

  const handleTabs = (index: number) => {
    setTabIndex(index);
  };

  const selectList = [
    { label: "ALL", value: "ALL" },
    { label: "WAREHOUSE#1", value: "WAREHOUSE#1" },
    { label: "WAREHOUSE#2", value: "WAREHOUSE#2" },
  ];

  const selectListEquipmentWareHouse = [
    { label: "ALL", value: "ALL" },
    { label: "Forklift#1", value: "Forklift#1" },
    { label: "Forklift#2", value: "Forklift#2" },
    { label: "Pallet Jack#1", value: "Pallet Jack#1" },
    { label: "Pallet Cart#1", value: "Pallet Cart#1" },
    { label: "Pallet Truck#1", value: "Pallet Truck#1" },
    
  ];

  const handleSelect = (val: string) => {
    setSelectedValue(val);
  };

  useEffect(()=>{
    setTabIndex(0)
  },[selectedValue])
 

  const handleSelectEquipmentWareHouse = (val: string) => {
    setSelectedEquipmentWareHouseValue(val);
  };

useEffect(()=>{
  setSelectedEquipmentWareHouseValue("ALL");
},[tabIndex])

  
  useEffect(()=>{
    if(selectedValue && tabIndex===0){
      analyticsWarehouseData?.analytics?.map((item:any)=>{
        if(item?.category === "overallwarehouseAnalytics" && selectedValue === "ALL"){
          
          setSelectedWarehouseGraphObjData(item)
        }else if(item?.category === "warehouse1Analytics" && selectedValue === "WAREHOUSE#1"){
          
          setSelectedWarehouseGraphObjData(item)
        }else if(item?.category === "warehouse2Analytics" && selectedValue === "WAREHOUSE#2"){
          
          setSelectedWarehouseGraphObjData(item)
        }
      })
    }

    if(tabIndex===1 && selectedEquipmentWareHouseValue){
      equipmentAnalytics?.analytics?.map((item:any)=>{
        if(item?.category === "overallEquipmentAnalytics" && selectedEquipmentWareHouseValue === "ALL"){          
          setSelectedEquipmentGraphObjData(item)
        }else if(item?.category === "equipmentAnalyticsForklift1" && selectedEquipmentWareHouseValue === "Forklift#1"){          
          setSelectedEquipmentGraphObjData(item)
        }else if(item?.category === "equipmentAnalyticsForklift2" && selectedEquipmentWareHouseValue === "Forklift#2"){          
          setSelectedEquipmentGraphObjData(item)
        }else if(item?.category === "equipmentAnalyticsPalletJack1" && selectedEquipmentWareHouseValue === "Pallet Jack#1"){          
          setSelectedEquipmentGraphObjData(item)
        }else if(item?.category === "equipmentAnalyticsPalletCart1" && selectedEquipmentWareHouseValue === "Pallet Cart#1"){          
          setSelectedEquipmentGraphObjData(item)
        }else if(item?.category === "equipmentAnalyticsPalletTruck1" && selectedEquipmentWareHouseValue === "Pallet Truck#1"){          
          setSelectedEquipmentGraphObjData(item)
        }
      })
    }
  },[selectedValue, tabIndex, selectedEquipmentWareHouseValue])

  return (
    <>
      <Fragment>
        <Box sx={{ flexGrow: 1 }} className={analyticsContent}>
          <div className={selectSection}>
            <Select
              selectList={selectList}
              handleSelect={handleSelect}
              customWidth={"40%"}
              customHeight={"50px"}
              customSelectCustom={customSelectDropDown}
              pageName={"analyticsPage"}
              drowpDownTextColor={"#837DAB"}
              dropDownBgColor={"#EEE9FF"}
              dropDownSelectedBgColor={"#9087C8"}
              dropDownSelectedTextColor={"#FFF"}
              selectedDropDownValue={selectedValue}
              // placeholder={"Select Trailer"}
            />
          </div>

          <div className={tabSection}>
            <Tabs
              initialIndex={tabIndex}
              tabsList={tabsList}
              handleTabs={handleTabs}
              dashboardNotificationClassName={customNotificationTabs}
              isEquipmentDropdown={tabIndex === 1 ? true : false}
              pageName={"analytics"}
              handleSelectEquipmentWareHouse={handleSelectEquipmentWareHouse}
              selectListEquipmentWareHouse={selectListEquipmentWareHouse}
            />
          </div>

          <div className={analyticsInnerContainer}>
            {tabIndex === 0 ? (
              <AnalyticsWarehouse                
                selectedWarehouseGraphObjData={selectedWarehouseGraphObjData}
              />
            ) : (
              <AnalyticsEquipment
              selectedEquipmentGraphObjData={selectedEquipmentGraphObjData}
              selectedEquipmentWareHouseValue={selectedEquipmentWareHouseValue}
              />
            )}
          </div>
        </Box>
      </Fragment>
    </>
  );
};

export default Analytics;
