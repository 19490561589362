import { useState, useEffect } from "react";
import {
  RootContainer,
  ItemContainer,
  ElementContainer,
  ElementLabel,
  ElementInput,
  ButtonWrapper,
  ButtonContainer,
  FlexSpace,
  Footer,
  SaveButton,
  CancelButton,
} from "./styles";

const AddTrackerConfig = (props: any) => {
  const { onChangesSave, onCancel, existingTrackers } = props;
  const [addedList, setAddedList] = useState<any>([]);
  const [showErrors, setShowErrors] = useState<any>(false);
  const [inputErrors, setInputErrors] = useState<any>([]);

  const handleChange = (index: number, key: string, value: any) => {
    if (!value) {
      setShowErrors(false);
      setInputErrors([]);
      const list = [...addedList];
      list[index][key] = "";
      return null;
    }
    setShowErrors(false);
    setInputErrors([]);
    const list = [...addedList];
    list[index][key] = value;
    setAddedList(list);
  };

  const getUnit = (configName: string) => {
    const formattedName = configName.toLowerCase();
    if (formattedName.includes("pressure")) {
      return "Pa";
    } else if (
      formattedName.includes("dioxide") ||
      formattedName.includes("battery") ||
      formattedName.includes("humidity") ||
      formattedName.includes("usage") ||
      formattedName.includes("efficiency")
    ) {
      return "%";
    } else if (formattedName.includes("temperature")) {
      return "°F";
    } else if (
      formattedName.includes("disconnected") ||
      formattedName.includes("connected")
    ) {
      return "Minutes";
    } else if (formattedName.includes("power")) {
      return "kW";
    } else if (formattedName.includes("hour")) {
      return "hours";
    } else {
      return "";
    }
  };

  const addNewObject = (index: number) => {
    const list = [...addedList];

    const newObject = {
      configurationType: "",
      minValue: 0,
      maxValue: 0,
    };
    list.splice(index + 1, 0, newObject);

    setAddedList(list);
  };

  const deleteObject = (index: number) => {
    const list = [...addedList];
    list.splice(index, 1);
    setAddedList(list);
  };

  const onSave = () => {
    const textPattern = /^[a-zA-Z0-9\s]+/;
    const numericPattern = /^[0-9 ]+$/;
    const spacePattern = /\s/;
    let isErrored = false;
    const erroredArrayList: any = [];
    const formattedList = addedList.map((item: any) => {
      console.log("item value comparison", item.minValue, item.maxValue, item.minValue >= item.maxValue)
      if (
        !item.configurationType ||
        (!item.minValue &&
        !item.maxValue) || (item.minValue == 0 && item.maxValue == 0) || (item.minValue && item.maxValue && parseInt(item.minValue) >= parseInt(item.maxValue))
      ) {
        setShowErrors(true);
        isErrored = true;
        erroredArrayList.push({
          configurationType:
            !item.configurationType ||
            !textPattern.test(item.configurationType)
              ? "*Please Enter a Valid Configuration Type"
              : null,
          minValue:
            (!item.minValue && !item.maxValue) || (item.minValue == 0 && item.maxValue == 0)
            ?
            "Both Minimum and Maximum Values cannot be empty" 
            :
            item.minValue && !numericPattern.test(item.minValue)
              ? "*Please Enter a Valid Minimum Value"
              :item.minValue && item.maxValue &&  parseInt(item.minValue) >= parseInt(item.maxValue) ? "Minimum Value cannot exceed Maximum Value" : null,
          maxValue:
            !numericPattern.test(item.maxValue)
              ? "*Please Enter a Valid Maximum Value"
              : null,
        });
        return null;
      }

      if(getUnit(item.configurationType) === '%' && item.maxValue > 100) {
        setShowErrors(true);
        isErrored = true;
        erroredArrayList.push({
          maxValue: "Maximum value cannot exceed 100 for this Unit"
        });
        return null;
      }

      if(getUnit(item.configurationType) === '%' && item.minValue > 100) {
        setShowErrors(true);
        isErrored = true;
        erroredArrayList.push({
          minValue: "Minimum value cannot exceed 100 for this Unit"
        });
        return null;
      }

      let isNotUnique = existingTrackers.some((tracker: any) => {
        let reformedString = tracker.title.toLowerCase().replaceAll(' threshold', '').replaceAll(' ', '')
        return reformedString === item.configurationType.toLowerCase().replaceAll(' threshold', '').replaceAll(' ', '')
      })

      let isNotUniqueInternal = addedList.reduce((total: any, tracker: any) => {
        if(tracker.configurationType.toLowerCase().replaceAll(' threshold', '').replaceAll(' ', '') === item.configurationType.toLowerCase().replaceAll(' threshold', '').replaceAll(' ', '')) {
          return total + 1
        }

        return total;
      }, 0)

      if(isNotUnique) {
        setShowErrors(true);
        isErrored = true;
        erroredArrayList.push({
          configurationType: "Configuration Already Exists"
        });
        return null;
      }

      if(isNotUniqueInternal >= 2) {
        setShowErrors(true);
        isErrored = true;
        erroredArrayList.push({
          configurationType: "Duplicate Entry"
        });
        return null;
      }
      erroredArrayList.push(null);
      const formatted: any = {
        title: `${item.configurationType}`,
        isActive: true,
        values: []
      }

      if(item.minValue) {
        formatted.values.push({
          label: `Low ${item.configurationType}`,
          prefix: "<",
          unit: ` ${getUnit(item.configurationType)}`,
          value: item.minValue,
        })
      }

      if(item.maxValue) {
        formatted.values.push({
          label: `High ${item.configurationType}`,
          prefix: ">",
          unit: ` ${getUnit(item.configurationType)}`,
          value: item.maxValue,
        })
      }
      return formatted
    });
    setInputErrors(erroredArrayList);

    if (!formattedList.length || isErrored) {
      // alert("The list is Empty")
      return null;
    }

    onChangesSave(formattedList);
  };

  useEffect(() => {
    const newObject = {
      configurationType: "",
      minValue: 0,
      maxValue: 0,
    };
    setAddedList((prev: any) => [...prev, newObject]);
  }, []);

  return (
    <RootContainer>
      <ItemContainer>
        <ElementContainer>
          <ElementLabel>Configuration Type</ElementLabel>
        </ElementContainer>
        <ElementContainer>
          <ElementLabel>Minimum Value</ElementLabel>
        </ElementContainer>
        <ElementContainer>
          <ElementLabel>Maximum Value</ElementLabel>
        </ElementContainer>
        <ButtonWrapper />
        <ButtonWrapper />
      </ItemContainer>
      {addedList.map((item: any, index: number) => (
        <>
          <ItemContainer>
            <ElementContainer>
              <ElementInput
                type="text"
                value={item.configurationType}
                errored={showErrors && inputErrors[index]?.configurationType}
                onChange={(evt) =>
                  handleChange(index, "configurationType", evt.target.value)
                }
              />
            </ElementContainer>
            <ElementContainer>
              <ElementInput
                type="number"
                value={item.minValue}
                errored={showErrors && inputErrors[index]?.minValue}
                onChange={(evt) =>
                  handleChange(index, "minValue", evt.target.value)
                }
              />
            </ElementContainer>
            <ElementContainer>
              <ElementInput
                type="number"
                value={item.maxValue}
                errored={showErrors && inputErrors[index]?.maxValue}
                onChange={(evt) =>
                  handleChange(index, "maxValue", evt.target.value)
                }
              />
            </ElementContainer>
            <ButtonWrapper>
              <ButtonContainer
                isActive={addedList.length > 1}
                onClick={() =>
                  addedList.length > 1 ? deleteObject(index) : null
                }
              >
                -
              </ButtonContainer>
            </ButtonWrapper>
            <ButtonWrapper>
              <ButtonContainer
                isActive={true}
                onClick={() => addNewObject(index)}
              >
                +
              </ButtonContainer>
            </ButtonWrapper>
          </ItemContainer>
          <ItemContainer>
            <ElementContainer noMargin>
              {showErrors && inputErrors[index]?.configurationType && (
                <label style={{ color: "#F50303" }}>
                  {inputErrors[index]?.configurationType}
                </label>
              )}
            </ElementContainer>
            <ElementContainer noMargin>
              {showErrors && inputErrors[index]?.minValue && (
                <label style={{ color: "#F50303" }}>
                  {inputErrors[index]?.minValue}
                </label>
              )}
            </ElementContainer>
            <ElementContainer noMargin>
              {showErrors && inputErrors[index]?.maxValue && (
                <label style={{ color: "#F50303" }}>
                  {inputErrors[index]?.maxValue}
                </label>
              )}
            </ElementContainer>
            <ButtonWrapper style={{ opacity: 0 }}></ButtonWrapper>
            <ButtonWrapper style={{ opacity: 0 }}></ButtonWrapper>
          </ItemContainer>
        </>
      ))}
      <FlexSpace />
      <Footer>
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
        <SaveButton onClick={onSave}>Save</SaveButton>
      </Footer>
    </RootContainer>
  );
};

export default AddTrackerConfig;
