import { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
// @ts-ignore
import Grid from "@mui/material/Grid";
// @ts-ignore
import Box from "@mui/material/Box";
import theme from "../../theme/theme";
import useStyles from "./styles";
import Tabs from "elements/Tabs";
import Table from "elements/Table";
import tablePlusIcon from "../../assets/tablePlusIcon.svg";
import tableExportIcon from "../../assets/tableExportIcon.svg";
import MenifestInfoDialog from "components/MenifestInfoDialog";
import manifestUpcoming from "mockdata/Firebase-formats/manifestUpcoming";
import manifestCompleted from "mockdata/Firebase-formats/manifestCompleted";
import manifestInprogress from "mockdata/Firebase-formats/manifestInprogress";
import SearchBox from "elements/SearchBox";
import UploadInfoDialog from "components/UploadInfoDialog";
import MenifestEditInfoDialog from "components/MenifestEditInfoDialog";
// @ts-ignore
import { doc, setDoc, getDoc } from "firebase/firestore/lite";
// @ts-ignore
import { db } from "services/firebase";
import LoaderIcon from "../../assets/Loading_icon.gif";
import Pagination from "elements/Pagination";
// import { getManifestViewEditList } from "redux/actions/manifestViewEditAction";
// import { getManifestList } from "redux/actions/manifestActions";

const MenifestContainer: React.FC<any> = (props) => {
  const {
    isInfoDialogueOpen,
    setIsInfoDialogueOpen,
    selectedTableRowManifestId,
    setSelectedTableRowManifestId,
    setTabIndex,
    tabIndex,
    setShowManifestDialogue,
    showManifestDialogue,
  } = props;

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const {
    innerSection,
    customNotificationTabs,
    customTable,
    searchBoxSection,
    tableTabsSection,
    searchClass,
    loaderStyle,
  } = useStyles(appTheme);

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);

  const [previousTab, setPreviousTab] = useState<number>();

  const dispatch = useDispatch();

  const upcomingData = useSelector(
    (state: any) => state?.manifestList?.manifestData
  );

  const manifestViewEditData = useSelector(
    (state: any) => state?.manifestViewEditList?.manifestViewEditData
  );

  const tableHeadInprogressData: { title: string; id: number }[] = [
    { title: "Manifest ID", id: 0 },
    { title: "Start Date and Time", id: 1 },
    { title: "Trailer ID", id: 2 },
    { title: "Source Warehouse", id: 3 },
    { title: "Delivery Stores", id: 4 },
    { title: "Number of Pallets", id: 5 },
    { title: "End Location", id: 6 },
    { title: "End Date and Time", id: 7 },
    // { title: "Actions", id: 8 },
  ];

  const tableHeadCompletedData: { title: string; id: number }[] = [
    { title: "Manifest ID", id: 0 },
    { title: "Start Date and Time", id: 1 },
    { title: "Trailer ID", id: 2 },
    { title: "Source Warehouse", id: 3 },
    { title: "Delivery Stores", id: 4 },
    { title: "Number of Pallets", id: 5 },
    { title: "End Location", id: 6 },
    { title: "End Date and Time", id: 7 },
    { title: "Wrong Delivery", id: 8 },
  ];

  const tableHeadUpcomingData: { title: string; id: number }[] = [
    { title: "Manifest ID", id: 0 },
    { title: "Start Date and Time", id: 1 },
    { title: "Trailer ID", id: 2 },
    { title: "Source Warehouse", id: 3 },
    { title: "Delivery Stores", id: 4 },
    { title: "Number of Pallets", id: 5 },
    { title: "End Location", id: 6 },
    { title: "End Date and Time", id: 7 },
    { title: "Actions", id: 8 },
  ];
  const tabsList = [
    { name: "Upcoming", val: 0 },
    { name: "In Progress", val: 1 },
    { name: "Completed", val: 2 },
  ];

  const handleTabs = (index: number) => {
    setTabIndex(index);
    setDeleteConfirmation(false);
  };

  const [manifestInProgressData, setManifestInProgressData] = useState([]);
  const [manifestUpcomingData, setManifestUpcomingData] = useState([]);
  const [manifestCompletedData, setManifestCompletedData] = useState([]);
  const [combinedData, setCombinedData] = useState<any>([]);
  const [alertDialogOpen, setAlertDialogOpen] = useState<boolean>(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  const [showEditManifestWindow, setShowEditManifestWindow] =
    useState<any>(false);

  const [isDataLoaded, setIsDataLoaded] = useState<any>(false);
  const [selectedTableRowIndex, setSelectedTableRowIndex] = useState<number>();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [searchPageNo, setSearchPageNo] = useState("");

  const getTableData = () => {
    const manifestCompletedRef = doc(db, "manifestCompleted", "completedData");
    getDoc(manifestCompletedRef)
      .then((response: any) => {
        const dbResponse = response.data();
        setManifestCompletedData(dbResponse.data);
        setIsDataLoaded(true);
      })
      .catch((error: any) => console.log(error.message));

    const manifestUpcomingRef = doc(db, "manifestUpcoming", "upcomingData");
    getDoc(manifestUpcomingRef)
      .then((response: any) => {
        const dbResponse = response.data();
        setManifestUpcomingData(dbResponse.data);
        setIsDataLoaded(true);
      })
      .catch((error: any) => console.log(error.message));

    const manifestInProgressRef = doc(
      db,
      "manifestInProgress",
      "inProgressData"
    );
    getDoc(manifestInProgressRef)
      .then((response: any) => {
        const dbResponse = response.data();
        setManifestInProgressData(dbResponse.data);
        setIsDataLoaded(true);
      })
      .catch((error: any) => console.log(error.message));
  };

  useEffect(() => {
    getTableData();
  }, []);

  useEffect(() => {
    const manifestInProgressRef = doc(
      db,
      "manifestInProgress",
      "inProgressData"
    );
    getDoc(manifestInProgressRef)
      .then((response: any) => {
        const dbResponse = response.data();
        setManifestInProgressData(dbResponse.data);
        setTableData(dbResponse.data);
        setSearchValue(dbResponse.data);
        setIsDataLoaded(true);
      })
      .catch((error: any) => console.log(error.message));
  }, []);

  useEffect(() => {
    getTableData();
  }, [showUploadDialog]);

  // useEffect(() => {
  //   getTableData();
  // }, [showUploadDialog]);

  const [tableData, setTableData] = useState(manifestInProgressData);
  const [searchValue, setSearchValue] = useState(manifestInProgressData);
  const [searchOpen, setSearchOpen] = useState(false);

  useEffect(() => {
    switch (tabIndex) {
      case 0:
        if (!deleteConfirmation) {
          const manifestUpcomingRef = doc(
            db,
            "manifestUpcoming",
            "upcomingData"
          );
          getDoc(manifestUpcomingRef)
            .then((response: any) => {
              const dbResponse = response.data();
              setTableData(dbResponse.data);
              setIsDataLoaded(true);
            })
            .catch((error: any) => console.log(error.message));
        }
        break;
      case 1:
        if (manifestInProgressData?.length > 0) {
          setTableData(manifestInProgressData);
        }
        break;
      case 2:
        setTableData(manifestCompletedData);
        break;
      default:
        if (manifestInProgressData?.length > 0) {
          setTableData(manifestInProgressData);
        }
        break;
    }
  }, [tabIndex, deleteConfirmation]);

  useEffect(() => {
    setSearchValue(tableData);
  }, [tabIndex, tableData]);

  const handleExportIcon = () => {
    setShowUploadDialog(true);
    setTabIndex(0);
  };

  const handleSearch = (searchValue: any) => {
    if (tableData?.length > 0) {
      let searchResult = tableData?.filter((value: any) => {
        return (
          value?.manifestId
            ?.toLowerCase()
            .includes(searchValue?.toString()?.toLowerCase()) ||
          value?.trailerId
            ?.toLowerCase()
            .includes(searchValue?.toString()?.toLowerCase()) ||
          value?.sourceWarehouse
            ?.toLowerCase()
            .includes(searchValue?.toString()?.toLowerCase()) ||
          value?.endLocation
            ?.toLowerCase()
            .includes(searchValue?.toString()?.toLowerCase())
        );
      });
      setSearchValue(searchResult);
    }

    setSearchOpen(true);
    // setSelectedNotification("");
  };

  const handleAddIcon = () => {
    setShowManifestDialogue(true);
    setPreviousTab(tabIndex);
    setTabIndex(0);
  };

  const handleTableRow = (manfestItem: string) => {
    setIsInfoDialogueOpen(!isInfoDialogueOpen);
    setSelectedTableRowManifestId(manfestItem);
  };

  // useEffect(() => {
  //   // if (tableData.length < combinedData.length && !deleteConfirmation) {
  //   const ref = doc(db, "manifestInProgress", "inProgressData");
  //   setDoc(ref, { data: manifestInprogress.tableList }, { merge: true })
  //     .then((success: any) => console.log("success"))
  //     .catch((error: any) => console.log(error));
  //   const ref2 = doc(db, "manifestCompleted", "completedData");
  //   setDoc(ref2, { data: manifestCompleted.tableList }, { merge: true })
  //     .then((success: any) => console.log("success"))
  //     .catch((error: any) => console.log(error));
  //   const ref3 = doc(db, "manifestUpcoming", "upcomingData");
  //   setDoc(ref3, { data: manifestUpcoming.tableList }, { merge: true })
  //     .then((success: any) => console.log("success"))
  //     .catch((error: any) => console.log(error));
  //   // }
  // }, []);

  const handleChangePage = (newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (data: any) => {
    setPage(0);
    setSearchPageNo("");
    setRowsPerPage(parseInt(data));
  };
  const handleNextChange = () => {
    setPage(page + 1);
    setSearchPageNo("");
  };

  const handlePreviousChange = () => {
    setPage(page - 1);
    setSearchPageNo("");
  };

  const handlePageNoChange = (value: any) => {
    setPage(parseInt(value) - 1);
    setSearchPageNo(value);
  };

  return (
    <>
      <Fragment>
        <Box className={innerSection} sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={12} className={tableTabsSection}>
              <Tabs
                initialIndex={tabIndex}
                tabsList={tabsList}
                handleTabs={handleTabs}
                dashboardNotificationClassName={customNotificationTabs}
              />

              <div className={searchBoxSection}>
                <img
                  src={tablePlusIcon}
                  onClick={isDataLoaded && handleAddIcon}
                />
                <img
                  src={tableExportIcon}
                  onClick={isDataLoaded && handleExportIcon}
                />
                <SearchBox
                  searchInput={searchClass}
                  placeHolder={"Search"}
                  handleSearch={handleSearch}
                  borderRadius={2}
                  borderColor={"1px solid #DCDADA"}
                  // searchIsOpenOne={true}
                  fontColor={"#9486DA"}
                  disabled={!isDataLoaded && true}
                  tabIndex={tabIndex}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={customTable}>
                <Table
                  tableHeadData={
                    tabIndex === 1
                      ? tableHeadInprogressData
                      : tabIndex === 2
                      ? tableHeadCompletedData
                      : tableHeadUpcomingData
                  }
                  tableData={searchValue}
                  tabIndex={tabIndex}
                  handleTableRow={handleTableRow}
                  setIsInfoDialogueOpen={setIsInfoDialogueOpen}
                  setTableData={setTableData}
                  setAlertDialogOpen={setAlertDialogOpen}
                  alertDialogOpen={alertDialogOpen}
                  deleteConfirmation={deleteConfirmation}
                  setDeleteConfirmation={setDeleteConfirmation}
                  setShowEditManifestWindow={setShowEditManifestWindow}
                  showEditManifestWindow={showEditManifestWindow}
                  isDataLoaded={isDataLoaded}
                  setIsDataLoaded={setIsDataLoaded}
                  getTableData={getTableData}
                  selectedTableRowIndex={selectedTableRowIndex}
                  setSelectedTableRowIndex={setSelectedTableRowIndex}
                  searchOpen={searchOpen}
                />
              </div>
              <div>
                <Pagination
                  rowsPerPageOptions={[10, 50, 100, 300, 500, 800, 1000]}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  handleNextChange={handleNextChange}
                  handlePreviousChange={handlePreviousChange}
                  onPageNoChange={handlePageNoChange}
                  value={searchPageNo}
                  count={searchValue?.length}
                />
              </div>
            </Grid>
          </Grid>
        </Box>
        <div>
          {showUploadDialog && (
            <UploadInfoDialog
              setShowUploadDialog={setShowUploadDialog}
              setTableData={setTableData}
              tableData={tableData}
              tabIndex={tabIndex}
              setCombinedData={setCombinedData}
              combinedData={combinedData}
            />
          )}
        </div>
      </Fragment>
      {showManifestDialogue && (
        <MenifestInfoDialog
          setShowManifestDialogue={setShowManifestDialogue}
          actionName={"create"}
          tableData={tableData}
          setTableData={setTableData}
          previousTab={previousTab}
          setTabIndex={setTabIndex}
        />
      )}
      {showEditManifestWindow && (
        <MenifestEditInfoDialog
          actionName={"edit"}
          setShowEditManifestWindow={setShowEditManifestWindow}
          tableData={tableData}
          selectedTableRowIndex={selectedTableRowIndex}
          getTableData={getTableData}
          setTableData={setTableData}
          isDataLoaded={isDataLoaded}
          setIsDataLoaded={setIsDataLoaded}
        />
      )}
    </>
  );
};
export default MenifestContainer;
