import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import useTranslation from "../../localization/translations";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import InfoSubList from "../InfoSubList";
import CustomizedSteppers from "../Stepper";
import ChipButton from "../../elements/ChipButton";
import Tabs from "../../elements/Tabs";
import { SearchIconInfo } from "../../assets/buttonIcons";
import theme from "../../theme/theme";
import colorCodes from "../../theme/colors";
import Geofence from "components/Geofence";
import FloorMap from "components/Map/FloorMap";
import Map from "components/Map";
import useStyles from "./styles";
import InfoNotificationListItem from "components/InfoNotificationListItem";
import SearchBox from "elements/SearchBox";
import { CloseIconImg } from "../../assets/icons";
import SwitchBox from "elements/Switchbox";
import CheckBox from "elements/Checkbox";
import { AddIcon, AddAlertsBackButoon } from "../../assets/icons";
import Button from "elements/Button";
import AddAlertsConfigInfoDialog from "components/AddAlertsConfigInfoDialog";
import ConfigurationListItem from "components/ConfigurationList/ConfigurationList";
import AddTrackerConfig from "components/AddTrackerConfig/AddTrackerConfig";
// @ts-ignore
import { doc, setDoc, getDoc, onSnapShot } from "firebase/firestore/lite";
// @ts-ignore
import { db } from "services/firebase";
import NumberInput from "components/NumberInput/NumberInput";
import muiTheme from "theme/muiTheme";

const DialogWrapper = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiBackdrop-root": {
    marginTop: "84px !important",
  },
  "& .MuiPaper-root": {
    minHeight: "340px",
    minWidth: "42vw",
    maxWidth: "80vw",
    background: `${colorCodes.colorBlack} !important`,
    color: colorCodes.colorWhite,
    padding: 24,
    [muiTheme.breakpoints.down(1025)]: {
      minHeight: "340px",
    },
  },
  "& .MuiDialog-container": {
    marginTop: "60px !important",
    background: "rgb(0 0 0 / 0%)",
    backdropFilter: "blur(10px)",
    height: "calc(100vh - 65px) !important",
  },
}));

const PopUpConfigInfoDialog: React.FC<any> = (props) => {
  const {
    setShowInfoDialogue,
    alertType,
    isEquipmentCongig,
    onSave,
    configurationIndexArray,
    allAlerts,
    allConfigurations,
  } = props;

  const [firebaseCopyObj, setFirebaseCopyObj] = useState<any>([]);

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const [tabIndex, setTabIndex] = useState(0);
  const [alertName, setAlertName] = useState("");
  const [configurationType, setConfigurationType] = useState("");
  const [countValue, setCountValue] = useState({ minVal: 0, maxVal: 0 });
  const [minNumberInputError, setMinNumberInputError] = useState("");
  const [maxNumberInputError, setMaxNumberInputError] = useState("");

  const {
    headerStyle,
    customNotificationTabs,
    eventsContainer,

    alertsContainerStyle,
    footerStyle,
    saveButtonStyles,
    cancelButtonStyles,
    labelTextArea,
    inputTextArea,
    inputStyle,
    inputLabelStyle,
    inputNumericTextArea,
    inputEnterTextArea,
    numericContainerStyle,
  } = useStyles(appTheme);

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );

  const [alertsErrorMessage, setAlertsErrorMessage] = useState<any>("");

  const [minMaxValTouch, setMinMaxValTouch] = useState<any>({
    minValTouched: false,
    maxValTouched: false,
    saveBottonClicked: false,
  });

  const { minValTouched, maxValTouched, saveBottonClicked } = minMaxValTouch;

  const { minVal, maxVal } = countValue;

  const getUnit = (configName: string) => {
    const formattedName = configName.toLowerCase();
    if (formattedName.includes("pressure")) {
      return "Pa";
    } else if (
      formattedName.includes("dioxide") ||
      formattedName.includes("battery") ||
      formattedName.includes("humidity") ||
      formattedName.includes("usage") ||
      formattedName.includes("efficiency")
    ) {
      return "%";
    } else if (formattedName.includes("temperature")) {
      return "°F";
    } else if (
      formattedName.includes("disconnected") ||
      formattedName.includes("connected")
    ) {
      return "Minutes";
    } else if (formattedName.includes("power")) {
      return "kW";
    } else if (formattedName.includes("hour")) {
      return "hours";
    } else {
      return "";
    }
  };

  const onSaveClick = () => {
    const pattern = /^[a-zA-Z0-9\s]+$/;
    setMinMaxValTouch({ ...minMaxValTouch, saveBottonClicked: true });
    if (!configurationType.trim() && !alertName.trim()) {
      setAlertsErrorMessage("*Please a valid Enter alert type");
      return null;
    }

    if (isEquipmentCongig && !pattern.test(configurationType)) {
      setAlertsErrorMessage("*Please a valid Enter alert type");
      return null;
    } else if (!isEquipmentCongig && !pattern.test(alertName)) {
      setAlertsErrorMessage("*Please a valid Enter alert type");
      return null;
    }

    if (
      isEquipmentCongig &&
      ((!maxVal && !minVal))
    ) {
      setMinNumberInputError("Both Minimum and Maximum Values cannot be empty");
      return null;
    }

    if(maxVal && minVal && minVal > maxVal) {
      setMinNumberInputError("Minimum Value cannot exceed Maximum Value");
      return null;
    }

    if(getUnit(configurationType) === '%' && minVal > 100) {
      setMinNumberInputError("Minimum Value cannot exceed 100 for This Unit");
      return null;
    }

    if(getUnit(configurationType) === '%' && maxVal > 100) {
      setMaxNumberInputError("Maximum Value cannot exceed 100 for This Unit");
      return null;
    }

    if(isEquipmentCongig) {
      let isNotUnique = allConfigurations.some((tracker: any) => {
        let reformedString = tracker.title.toLowerCase().replaceAll(' threshold', '').replaceAll(' ', '')
        return reformedString === configurationType.toLowerCase().replaceAll(' threshold', '').replaceAll(' ', '')
      })
      if(isNotUnique) {
        setAlertsErrorMessage("Configuration Already Exists")
        return null;
      }
    } else {
      let isNotUnique = false;
      const combinedAlerts = [...allAlerts[0].eventItem, ...allAlerts[1].incidentsItem, ...allAlerts[2].opsAlertItem]
      isNotUnique = combinedAlerts.some((alertItem: any) => {
        return alertItem.label.toLowerCase().replaceAll(' ', '') === alertName.toLowerCase().replaceAll(' ', '')
      })
      if(isNotUnique) {
        setAlertsErrorMessage("Alert Already Exists")
        return null;
      }
    }

    if (isEquipmentCongig) {
      const object: any = {
        title: `${configurationType}`,
        isActive: true,
        values: [],
      };

      if(countValue.minVal) {
        object.values.push({
          label: `Low ${configurationType}`,
          prefix: "<",
          unit: ` ${getUnit(configurationType)}`,
          value: countValue.minVal,
        })
      }

      if(countValue.maxVal) {
        object.values.push({
          label: `High ${configurationType}`,
          prefix: ">",
          unit: ` ${getUnit(configurationType)}`,
          value: countValue.maxVal,
        })
      }

      onSave(object);
      return true;
    }
    setAlertsErrorMessage(false);
    onSave(alertName);
  };

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(!open);
    setShowInfoDialogue(false);
  };

  const tabsList = [{ name: "Add Alerts", val: 0 }];

  const tabsListEquipmentsConfiguration = [
    { name: "Add Equipments Configuration", val: 0 },
  ];

  const tabsListTrackersConfiguration = [
    { name: "Add Trackers Configuration", val: 0 },
  ];

  const handleTabs = (index: number) => {
    setTabIndex(index);
  };

  const handleChange = (value: any, type: string) => {
    if (type === "minVal") {
      setCountValue({ ...countValue, minVal: value });
      setMinMaxValTouch({ ...minMaxValTouch, minValTouched: true });
      setMinNumberInputError("")
    } else {
      setCountValue({ ...countValue, maxVal: value });
      setMinMaxValTouch({ ...minMaxValTouch, maxValTouched: true });
      setMaxNumberInputError("")
    }
  };

  return (
    <>
      <DialogWrapper open={open} sx={{ top: "22px" }}>
        <div className={headerStyle}>
          <Tabs
            initialIndex={0}
            tabsList={
              isEquipmentCongig
                ? configurationIndexArray === 4
                  ? tabsListEquipmentsConfiguration
                  : tabsListTrackersConfiguration
                : tabsList
            }
            handleTabs={handleTabs}
            dashboardNotificationClassName={customNotificationTabs}
          />
        </div>
        <div>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 15,
              top: 12,
              color: appTheme.palette.infoDialogueComponent.colorWhite,
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <Grid
          container
          className={alertsContainerStyle}
          direction="row"
          justifyContent="space-between"
          sx={{ height: isEquipmentCongig ? "unset" : "unset" }}
        >
          {isEquipmentCongig ? (
            <>
              <Grid
                item
                xs={12}
                style={{ marginBottom: alertsErrorMessage ? "18px" : "40px" }}
              >
                <label className={inputLabelStyle}>Configuration Type</label>
                <div className={inputEnterTextArea}>
                  <input
                    className={inputStyle}
                    value={configurationType}
                    type="text"
                    placeholder="Enter configuration type"
                    onChange={(event) => {
                      setConfigurationType(event.target.value);
                      setAlertsErrorMessage(false);
                    }}
                    style={{
                      border: alertsErrorMessage
                        ? "0.5px solid #F50303"
                        : "0.5px solid #DDDDDD",
                    }}
                  />
                </div>
                {alertsErrorMessage && (
                  <label style={{ color: "#F50303" }}>
                    {alertsErrorMessage}
                  </label>
                )}
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent="space-between"
                  className={numericContainerStyle}
                >
                  <Grid item xs={5.8}>
                    <label className={inputLabelStyle}>Minimum Value</label>
                    <div className={inputNumericTextArea}>
                      <NumberInput
                        isEditable={true}
                        isActive={true}
                        direction="row"
                        size="large"
                        value={minVal}
                        variant={
                          minNumberInputError
                            ? "alertRed"
                            : "black"
                        }
                        onChange={(value: any) => handleChange(value, "minVal")}
                        pageName={"popupConfig"}
                        
                      />
                      {minNumberInputError && (
                        <label style={{ color: "#F50303" }}>
                          {minNumberInputError}
                        </label>
                      )}
                    </div>
                  </Grid>

                  <Grid item xs={5.8}>
                    <label className={inputLabelStyle}>Maximum Value</label>
                    <div className={inputNumericTextArea}>
                      <NumberInput
                        isEditable={true}
                        isActive={true}
                        direction="row"
                        size="large"
                        value={maxVal}
                        variant={
                          maxNumberInputError
                            ? "alertRed"
                            : "black"
                        }
                        onChange={(value: any) => handleChange(value, "maxVal")}
                        pageName={"popupConfig"}
                      />
                      {maxNumberInputError && (
                        <label style={{ color: "#F50303" }}>
                          {maxNumberInputError}
                        </label>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={5.8}>
                <label className={inputLabelStyle}>Alert Type</label>
                <div className={labelTextArea}>
                  <span>{alertType}</span>
                </div>
              </Grid>

              <Grid item xs={5.8}>
                <label className={inputLabelStyle}>Alert Name</label>
                <div
                  className={inputTextArea}
                  style={{ height: alertsErrorMessage ? "54%" : "60%" }}
                >
                  <input
                    className={inputStyle}
                    // value={item?.alertName}
                    type="text"
                    placeholder="Enter alert name"
                    onChange={(event) => {
                      setAlertName(event.target.value);
                      setAlertsErrorMessage(false);
                    }}
                    style={{
                      border: alertsErrorMessage
                        ? "0.5px solid #F50303"
                        : "0.5px solid #DDDDDD",
                    }}
                  />
                </div>
                {alertsErrorMessage && (
                  <label style={{ color: "#F50303" }}>
                    {alertsErrorMessage}
                  </label>
                )}
              </Grid>
            </>
          )}
        </Grid>

        <div className={footerStyle}>
          <Button
            variant="outlined"
            // handleClick={() => handleCancelButtonClick()}
            buttonStyles={cancelButtonStyles}
            handleClick={() => setShowInfoDialogue(false)}
          >
            {"Cancel"}
          </Button>
          <Button
            variant="contained"
            handleClick={() => onSaveClick()}
            buttonStyles={saveButtonStyles}
          >
            {"Save"}
          </Button>
        </div>
      </DialogWrapper>
    </>
  );
};

export default PopUpConfigInfoDialog;
