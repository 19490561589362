import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: (props: any) => ({
    background: props.palette.profile.pageBackgroundColor,
  }),
  formSection: (props: any) => ({
    color: "white",
    background: "black",
    padding: "24px",
    display: "flex",
    justifyContent: "space-between",
    fontFamily: `'Nunito Sans', sans-serif !important`,
  }),
});
export default useStyles;
