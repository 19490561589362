import { styled, makeStyles } from "@mui/material/styles";

export const RootContainer = styled("div")`
  padding: 0;
  margin-bottom: 38px;
  flex-wrap: wrap;
`;

export const Header = styled("div")<{ variant?: string }>`
  background: ${({ variant }) => (variant === "dark" ? "#1E1E1E" : "#A092E6")};
  padding: 14px;
  display: flex;
  align-items: center;
`;

export const FlexSpace = styled("div")`
  flex: 1;
`;

export const Title = styled("div")`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin-left: 20px;
`;

export const Content = styled("div")<{ variant?: string }>`
  background: ${({ variant }) => (variant === "dark" ? "#272727" : "#F9F9F9")};
  border-radius: 2px;
  padding: 34px 20px 10px 20px;
  border-top: none;
  display: flex;
  align-items: center;
  border: ${({ variant }) =>
    variant === "dark" ? "unset;" : "1px solid #e0e0e0; border-top: 0;"};
  border-radius: 2px;
  flex-wrap: wrap;
  max-height: 77px;
  overflow: auto;
  
`;

export const CheckBoxWrapper = styled("div")`
  display: flex;
  align-items: center;
  margin-right: 50px;
  margin-bottom: 24px !important;
  cursor: pointer;
  min-width: 170px;
`;

export const CheckBoxContainer = styled("div")<{
  isEditable: boolean;
  isChecked: boolean;
}>`
  width: 18px;
  height: 18px;
  background-color: ${({ isEditable }) => (isEditable ? "#FFF" : "#E1E1E1")};
  border: ${({ isEditable, isChecked }) =>
    isEditable ? (!isChecked ? "1px solid #8A7BD6" : "none") : "none"};
  border-radius: 3px;
  box-sizing: border-box;
`;

export const CheckMark = styled("img")<{ selected: boolean }>`
  width: 20px;
  height: 20px;
  opacity: ${({ selected }) => Number(selected)};
  transition: all 0.3s ease;
`;

export const CheckBoxLabel = styled("div")`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #565656;
  margin-left: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 170px;
`;

export const AddButton = styled("img")`
  width: 30px;
  height: 30px;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;

export const InfoWindowBackdrop = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InfoWindowContainer = styled("div")`
  background: rgba(0, 0, 0, 0.87);
  border-radius: 10px;
  padding: 25px;
`;

export const InfoWindowHeader = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InfoWindowTitle = styled("div")`
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */
  padding: 5px 3px;
  color: #77b77c;
  border-bottom: 5px solid #77b77c;
`;

export const Close = styled("span")`
  font-size: 13px;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
`;

export const InfoDialogContent = styled("div")`
  margin: 40px 5px;
`;

export const InfoDialogFooter = styled("div")`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const SaveButton = styled("button")`
  border: none;
  outline: none;
  width: 99px;
  height: 36px;
  background: #7e6ecc;
  border-radius: 25px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
`;

export const CancelButton = styled(SaveButton)`
  border: 1.5px solid #cbcbcb;
  background: transparent;
  border-radius: 25px;
  color: #b5b5b5;
  margin-right: 15px;
`;
