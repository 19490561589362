import { styled } from "@mui/material/styles";

export const Chip = styled("div")<{
  focused: boolean;
  highlight: string;
  whiteShade: string;
  lightPurple: string;
}>`
  cursor: pointer;
  margin-right: 16px;
  padding: 6px;
  flex: 1;
  border: ${(props) =>
    props?.whiteShade ? `1.5px solid ${props?.whiteShade}` : null};
  border-radius: 40px;
  background-color: ${(props) =>
    props.focused ? props.highlight : props.lightPurple};
  display: flex;
  align-items: center;
  justify-content: space-between;
  :last-child {
    margin-right: 0;
  }
`;

export const ChipLabel = styled("div")<{
  focused: boolean;
  greyShade: string;
  colorWhite: string;
}>`
  margin-left: 8px;
  text-align: center;
  font-size: 16px;
  line-height: 18.26px;
  font-weight: 700;
  color: ${(props) => (props.focused ? props?.colorWhite : props?.greyShade)};
  @media (max-width: 1537px) {
    font-size: 11px;
    line-height: 17px;
  }
  @media (max-width: 1025px) {
    font-size: 8px;
    line-height: 12px;
  }
  @media (max-width: 600px) {
    font-size: 13px;
    line-height: 16.26px;
  }
`;

export const ChipBadgeContainer = styled("div")<{
  whiteShade: string;
  greyShade: string;
  violet: string;
  focused: boolean;
  highlight: string;
}>`
  height: 35px;
  width: 35px;
  font-size: 14px;
  line-height: 19px;
  font-weight: 700;
  border-radius: 39px;
  background-color: ${(props) =>
    props?.focused ? props?.whiteShade : props?.violet};
  color: ${(props) => (props?.focused ? props?.highlight : props?.whiteShade)};
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1537px) {
    height: 25px;
    width: 25px;
    font-size: 12px;
  }
  @media (max-width: 1025px) {
    height: 18px;
    width: 18px;
    font-size: 8px;
  }
  @media (max-width: 600px) {
    height: 25px;
    width: 25px;
  }
`;
