const equipmentAnalytics = {
  analytics: [
    {
      id: "overallEquipmentAnalytics",
      category: "overallEquipmentAnalytics",
      graphAnalytics: [
        {
          id: "",
          title: "Operating Hrs",
          category: "operatingHrs",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 83.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 541.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 344.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 541.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 543.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 542.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 345.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 541.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 542.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 344.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 349.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 544.0,
                },
              ],
            },
          },
        },
        {
          id: "",
          title: "Total Trips",
          category: "totalTrips",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
        {
          id: "",
          title: "Notifications",
          category: "notifications",
          analytics: {
            day: {
              events: 250,
              incidents: 170,
              opralerts: 80,
              totalNotifications: 500,
            },
            weekly: {
              events: 250,
              incidents: 170,
              opralerts: 80,
              totalNotifications: 500,
            },
            monthly: {
              events: 250,
              incidents: 170,
              opralerts: 80,
              totalNotifications: 500,
            },
            yearly: {
              events: 250,
              incidents: 170,
              opralerts: 80,
              totalNotifications: 500,
            },
          },
        },
        {
          id: "",
          title: "Energy Consumed",
          category: "energyConsumed",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
        {
          id: "",
          title: "Equipment Efficiency",
          category: "equipmentEfficiency",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
              ],
            },
          },
        },
        {
          id: "",
          title: "Idle Hrs",
          category: "IdleHrs",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "equipmentAnalyticsForklift1",
      category: "equipmentAnalyticsForklift1",
      graphAnalytics: [
        {
          id: "",
          title: "Operating Hrs",
          category: "operatingHrs",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 83.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 541.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 344.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 541.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 543.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 542.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 345.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 541.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 542.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 344.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 349.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 544.0,
                },
              ],
            },
          },
        },
        {
          id: "",
          title: "Total Trips",
          category: "totalTrips",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
        {
          id: "",
          title: "Temperature",
          category: "temperature",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
              ],
            },
          },
        },
        {
          id: "",
          title: "Energy Consumed",
          category: "energyConsumed",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 15.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
        {
          id: "",
          title: "Equipment Efficiency",
          category: "equipmentEfficiency",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
              ],
            },
          },
        },
        {
          id: "",
          title: "Idle Hrs",
          category: "IdleHrs",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "equipmentAnalyticsForklift2",
      category: "equipmentAnalyticsForklift2",
      graphAnalytics: [
        {
          id: "",
          title: "Operating Hrs",
          category: "operatingHrs",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 83.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 541.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 344.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 541.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 543.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 542.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 345.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 541.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 542.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 344.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 349.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 544.0,
                },
              ],
            },
          },
        },
        {
          id: "",
          title: "Total Trips",
          category: "totalTrips",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
        {
          id: "",
          title: "Temperature",
          category: "temperature",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
              ],
            },
          },
        },
        {
          id: "",
          title: "Energy Consumed",
          category: "energyConsumed",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
        {
          id: "",
          title: "Equipment Efficiency",
          category: "equipmentEfficiency",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 10.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
              ],
            },
          },
        },
        {
          id: "",
          title: "Idle Hrs",
          category: "IdleHrs",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 12.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "equipmentAnalyticsPalletJack1",
      category: "equipmentAnalyticsPalletJack1",
      graphAnalytics: [
        {
          id: "",
          title: "Operating Hrs",
          category: "operatingHrs",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 83.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 541.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 344.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 541.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 543.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 542.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 345.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 541.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 542.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 344.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 349.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 544.0,
                },
              ],
            },
          },
        },
        {
          id: "",
          title: "Total Trips",
          category: "totalTrips",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
        {
          id: "",
          title: "Temperature",
          category: "temperature",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
              ],
            },
          },
        },
        {
          id: "",
          title: "Energy Consumed",
          category: "energyConsumed",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 15.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 10.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
        {
          id: "",
          title: "Equipment Efficiency",
          category: "equipmentEfficiency",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
              ],
            },
          },
        },
        {
          id: "",
          title: "Idle Hrs",
          category: "IdleHrs",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 10.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "equipmentAnalyticsPalletCart1",
      category: "equipmentAnalyticsPalletCart1",
      graphAnalytics: [
        {
          id: "",
          title: "Operating Hrs",
          category: "operatingHrs",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 83.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 541.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 344.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 541.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 543.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 542.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 345.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 541.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 542.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 344.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 349.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 544.0,
                },
              ],
            },
          },
        },
        {
          id: "",
          title: "Total Trips",
          category: "totalTrips",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 16.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
        {
          id: "",
          title: "Temperature",
          category: "temperature",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 10.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
              ],
            },
          },
        },
        {
          id: "",
          title: "Energy Consumed",
          category: "energyConsumed",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
        {
          id: "",
          title: "Equipment Efficiency",
          category: "equipmentEfficiency",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 11.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 10.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
              ],
            },
          },
        },
        {
          id: "",
          title: "Idle Hrs",
          category: "IdleHrs",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "equipmentAnalyticsPalletTruck1",
      category: "equipmentAnalyticsPalletTruck1",
      graphAnalytics: [
        {
          id: "",
          title: "Operating Hrs",
          category: "operatingHrs",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 83.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 541.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 344.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 541.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 543.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 542.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 345.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 541.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 542.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 344.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 349.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 544.0,
                },
              ],
            },
          },
        },
        {
          id: "",
          title: "Total Trips",
          category: "totalTrips",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
        {
          id: "",
          title: "Temperature",
          category: "temperature",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
              ],
            },
          },
        },
        {
          id: "",
          title: "Energy Consumed",
          category: "energyConsumed",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
        {
          id: "",
          title: "Equipment Efficiency",
          category: "equipmentEfficiency",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
              ],
            },
          },
        },
        {
          id: "",
          title: "Idle Hrs",
          category: "IdleHrs",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
              ],
            },
          },
        },
      ],
    },
  ],
};

export default equipmentAnalytics;
