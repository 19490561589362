import { useState, useEffect, Fragment } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import DashboardFloorMap from "components/DashboardFloorMap";
import DashboardViewDetailList from "components/DashboardViewDetailList";
import theme from "../../theme/theme";
import moment from "moment";
import useTranslation from "../../localization/translations";
import RightArrow from "../../assets/rightArrow.svg";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import dashboardViewDetails from "../../mockdata/dashboardViewDetails";
import AccessPointIcon from "../../assets/floormap_markers/AccessPointIcons.svg";
import GoodsGreenBoxIcon from "../../assets/floormap_markers/GoodsGreenBox.svg";
import GoodsDisconnectedInactiveIcon from "../../assets/floormap_markers/GoodsDisconnectedInactive.svg";
import ForkliftGreenIcon from "../../assets/floormap_markers/ForkliftGreenIcon.svg";
import ForkliftOrangeIcon from "../../assets/floormap_markers/ForkliftOrangeIcon.svg";
import PalletCartGreenIcon from "../../assets/floormap_markers/PalletCartGreenIcon.svg";
import PalletCartRedIcon from "../../assets/floormap_markers/PalletCartRedIcon.svg";
import PalletJackGreenIcon from "../../assets/floormap_markers/PalletJackGreenIcon.svg";
import PalletTruckGreenIcon from "../../assets/floormap_markers/PalletTruckGreenIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./styles";

const DashboardViewPageContainer: React.FC<any> = (props) => {
  const {
    dashboardList,
    setViewDetailActive,
    selectedNotification,
    setSelectedNotification,
    notificationTimeStamp,
    setNotificationTimeStamp,
    selectedTitle,
    selectedArea,
    selectedId,
  } = props;

  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch({
  //     type: "GET_DASHBOARD_VIEW_DETAILS_GODDS_ACCESS_POINTS_DATA",
  //   },
  //   );
  //   dispatch({
  //     type: "GET_DASHBOARD_VIEW_DETAILS_EQUIPMENTS_DATA",
  //   },
  //   );

  //   dispatch({
  //     type: "GET_DASHBOARD_VIEW_DETAILS_NOTIFICATIONS_DATA",
  //   },
  //   );
  // }, []);

  // const apiGoodsAccessPointsData= useSelector(
  //   (state: any) => state?.dashboardViewDetailsGoodsAccessPointsList?.dashboardViewDetailsGoodsAccessPointsData
  // );

  // const apiEquipmentsData = useSelector(
  //   (state: any) => state?.dashboardViewDetailsEquipmentsList?.dashboardViewDetailsEquipmentsData
  // );

  // const apiNotificationsData = useSelector(
  //   (state: any) => state?.dashboardViewDetailsNotificationsList?.dashboardViewDetailsNotificationsData
  // );

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const {
    viewDetailHeader,
    viewDetailHeaderButtonStyle,
    viewDetailHeaderLeft,
    rightArrowStyle,
    toggleButtonStyle,
    arrowIconStyle,
    dashboardContentInner,
    rightArrowStyleInactive,
  } = useStyles(appTheme);
  const {} = useTranslation();

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  const [selectedMarker, setSelectedMarker] = useState<any>();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [selectedTabButton, setSelectedTabButton] = useState<any>(0);
  const [searchOpen, setSearchOpen] = useState<any>(false);
  const [isListExpanded, setIsListExpanded] = useState<any>(false);
  const [rightPanelActive, setRightPanelActive] = useState(true);
  const [dashboardArray, setDashboardArray] = useState([]);
  const [chipButtonIndex, setChipButtonIndex] = useState<number>(1);
  const [viewDetailData, setViewDetailData] = useState<any>();

  const [viewDashboardData, setViewDashboardData] = useState<any>();
  const [isMarkerClicked, setIsMarkerClicked] = useState<any>(false);

  const handleArrowIcon = () => {
    setViewDetailActive(false);
    setRightPanelActive(false);
    setSelectedNotification("");
    setIsMarkerClicked(false);
  };

  const [toggleButtonStatus, setToggleButtonStatus] = useState("hide");

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    status: string | null
  ) => {
    if (status !== null) {
      setToggleButtonStatus(status);
    }
    setSelectedNotification("");
  };

  useEffect(() => {
    const data = JSON.parse(
      localStorage.getItem("dashboardViewDetails") || "{}"
    );
    if (selectedTitle) {
      localStorage.setItem("warehouseId", JSON.stringify(selectedId));
    }

    if (data?.length > 0) {
      localStorage.setItem("dashboardViewDetails", JSON.stringify(data));
    } else {
      localStorage.setItem(
        "dashboardViewDetails",
        JSON.stringify(dashboardViewDetails)
      );
      setViewDashboardData(dashboardViewDetails);
    }
    if (data?.length > 0) {
      setViewDashboardData(data);
    }
  }, []);

  useEffect(() => {
    if (viewDashboardData !== undefined && selectedId !== undefined) {
      let selectedData;
      if (selectedId) {
        for (let i = 0; i <= viewDashboardData?.length; i++) {
          if (viewDashboardData[i]?.id === selectedId) {
            selectedData = viewDashboardData[i];
          }
        }
      }

      const { equipments, goods, accessPoints } = selectedData;
      const getMarkerType = (item: any) => {
        switch (item?.category) {
          case "forklift":
            return item?.notificationType === "events"
              ? ForkliftGreenIcon
              : ForkliftOrangeIcon;
          case "palletJack":
            return item?.notificationType === "events" && PalletJackGreenIcon;
          case "palletCart":
            return item?.notificationType === "events"
              ? PalletCartGreenIcon
              : PalletCartRedIcon;
          case "palletTruck":
            return item?.notificationType === "events" && PalletTruckGreenIcon;
        }
      };
      const getBoxMarkerType = (item: any) => {
        switch (item.connectedStatus) {
          case "Connected":
            return GoodsGreenBoxIcon;
          case "Disconnected":
            return GoodsDisconnectedInactiveIcon;
        }
      };
      const combinedDataList: any = [];
      goods.forEach((item: any, index: number) => {
        combinedDataList.push({
          ...item,
          type: "goods",
          icon: getBoxMarkerType(item),
        });
      });
      equipments.forEach((item: any, index: number) => {
        combinedDataList.push({
          ...item,
          type: "equipments",
          icon: getMarkerType(item),
        });
      });
      {
        toggleButtonStatus === "show" &&
          accessPoints.forEach((item: any, index: number) => {
            combinedDataList.push({
              ...item,
              type: "accessPoints",
              icon: AccessPointIcon,
            });
          });
      }
      let currentTimeStampValue;
      let timeArrayNew: any = [];
      for (let i = 0; i < combinedDataList?.length; i++) {
        currentTimeStampValue = moment()
          .subtract({
            hours: i === 0 ? i : i > 20 ? 20 : i + 1,
            minutes: i + 59,
            seconds: i + 49,
          })
          .format("MM-DD-YYYY | h:mm A");
        timeArrayNew.push({ currentTimeStamp: currentTimeStampValue });
      }
      let consolidatedData = timeArrayNew?.map((item: any, i: any) =>
        Object.assign({}, item, combinedDataList[i])
      );
      setViewDetailData(consolidatedData);
    }
  }, [viewDashboardData, toggleButtonStatus]);

  const [listCount, setListCount] = useState({
    eventCount: 0,
    incidentCount: 0,
    oprCount: 0,
  });

  useEffect(() => {
    let eventVal = [];
    let incidentVal = [];
    let oprVal = [];
    for (let i = 0; i <= viewDetailData?.length; i++) {
      if (viewDetailData[i]?.notificationType === "events") {
        eventVal.push(viewDetailData[i]);
      } else if (viewDetailData[i]?.notificationType === "incidents") {
        incidentVal.push(viewDetailData[i]);
      } else if (viewDetailData[i]?.notificationType === "oprAlerts") {
        oprVal.push(viewDetailData[i]);
      }
    }

    listCount.eventCount = eventVal.length;
    listCount.incidentCount = incidentVal.length;
    listCount.oprCount = oprVal.length;
  }, [viewDetailData, tabIndex, chipButtonIndex]);

  const handleClose = () => {
    const data = JSON.parse(
      localStorage.getItem("dashboardViewDetails") || "{}"
    );

    setViewDashboardData(data);
  };

  const handleTabChange = () => {
    const data = JSON.parse(
      localStorage.getItem("dashboardViewDetails") || "{}"
    );

    setViewDashboardData(data);
  };

  return (
    <Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container className={dashboardContentInner}>
          <Grid
            item
            xs={12}
            sm={12}
            md={!rightPanelActive ? 12 : 8}
            lg={!rightPanelActive ? 12 : 8}
            xl={!rightPanelActive ? 12 : 9}
          >
            <div style={{ position: "relative" }}>
              <div className={viewDetailHeader}>
                <div className={viewDetailHeaderButtonStyle}>
                  <img
                    src={RightArrow}
                    alt=""
                    onClick={handleArrowIcon}
                    className={rightArrowStyle}
                  />
                  {selectedTitle && selectedTitle},{" "}
                  {selectedArea && selectedArea}
                </div>
                <div className={viewDetailHeaderLeft}>
                  Access Points
                  <div className={toggleButtonStyle}>
                    {" "}
                    <ToggleButtonGroup
                      color="primary"
                      value={toggleButtonStatus}
                      exclusive
                      onChange={handleChange}
                      aria-label="Platform"
                    >
                      <ToggleButton value="show">Show</ToggleButton>
                      <ToggleButton value="hide">Hide</ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </div>
              </div>
              <DashboardFloorMap
                dashboardList={dashboardList}
                setTabIndex={setTabIndex}
                tabIndex={tabIndex}
                setSelectedNotification={setSelectedNotification}
                selectedNotification={selectedNotification}
                setSelectedTabButton={setSelectedTabButton}
                selectedTabButton={selectedTabButton}
                searchOpen={searchOpen}
                setSearchOpen={setSearchOpen}
                notificationTimeStamp={notificationTimeStamp}
                setRightPanelActive={setRightPanelActive}
                setViewDetailActive={setViewDetailActive}
                viewDetailData={viewDetailData}
                setChipButtonIndex={setChipButtonIndex}
                chipButtonIndex={chipButtonIndex}
                toggleButtonStatus={toggleButtonStatus}
                isListExpanded={isListExpanded}
                setIsListExpanded={setIsListExpanded}
                selectedId={selectedId}
                handleTabChange={handleTabChange}
                setIsMarkerClicked={setIsMarkerClicked}
              />
            </div>
          </Grid>
          {!rightPanelActive && (
            <img
              src={RightArrow}
              alt=""
              onClick={() => {
                setRightPanelActive(true);
              }}
              className={rightArrowStyleInactive}
            />
          )}
          {rightPanelActive && (
            <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
              {viewDetailData?.length > 0 && (
                <DashboardViewDetailList
                  dashboardList={dashboardList}
                  setTabIndex={setTabIndex}
                  tabIndex={tabIndex}
                  setSelectedNotification={setSelectedNotification}
                  selectedNotification={selectedNotification}
                  setSelectedTabButton={setSelectedTabButton}
                  selectedTabButton={selectedTabButton}
                  searchOpen={searchOpen}
                  setSearchOpen={setSearchOpen}
                  notificationTimeStamp={notificationTimeStamp}
                  setRightPanelActive={setRightPanelActive}
                  setViewDetailActive={setViewDetailActive}
                  viewDetailData={viewDetailData}
                  setChipButtonIndex={setChipButtonIndex}
                  chipButtonIndex={chipButtonIndex}
                  listCount={listCount}
                  handleClose={handleClose}
                  isListExpanded={isListExpanded}
                  setIsListExpanded={setIsListExpanded}
                  selectedId={selectedId}
                  handleTabChange={handleTabChange}
                  isMarkerClicked={isMarkerClicked}
                />
              )}
            </Grid>
          )}
        </Grid>
      </Box>
    </Fragment>
  );
};

export default DashboardViewPageContainer;
