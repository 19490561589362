import { makeStyles } from "@mui/styles";
import muiTheme from "../../theme/muiTheme";

const useStyles = makeStyles({
  searchClass: (props: any) => ({
    border: `1px solid ${props?.palette?.dashboardList?.lightGrey}`,
    background: props?.palette?.dashboardList?.white,
    color: props?.palette?.dashboardList?.lightGrey3,
    borderRadius: 25,
    height: "45px",
    boxShadow: "0px 0px 4px 1px #D5D0F1",
    [muiTheme.breakpoints.down(963)]: {
      width: "100%",
    },
    "& .MuiIconButton-root": {
      marginRight: "15px",
    },
    "& .MuiInputBase-root": {
      marginLeft: "12px",
    },
    "& .MuiInputBase-input": {
      padding: "5px 5px 5px",
    },
  }),
  searchBoxContainer: (props: any) => ({
    margin: "20px 20px 10px 20px",
  }),
  customNotificationTabs: (props: any) => ({
    "& .MuiButtonBase-root": {
      marginRight: "25px !important",
      minWidth: "64px !important",
      [muiTheme.breakpoints.down(1181)]: {
        marginRight: "15px !important",
        minWidth: "54px !important",
      },
      [muiTheme.breakpoints.down(1025)]: {
        marginRight: "10px !important",
        minWidth: "44px !important",
      },
    },
  }),
  tabSection: (props: any) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: " 8px 16px 0 16px",
    margin: "0px 20px",
    borderBottom: `1px solid #b1a6eaad`,
    "& .MuiButtonBase-root": {
      marginRight: "0 !important",
    },
    "& .MuiTabs-root": {
      marginTop: "6px !important",
    },
    "& .MuiTab-root": {
      fontSize: 18,
      padding: "0px 20px !important",
      paddingBottom: "8px !important",
      fontWeight: 600,
      color: "#B1A6EA",
      fontFamily: `'Nunito Sans', sans-serif !important`,
      [muiTheme.breakpoints.down(1181)]: {
        fontSize: 14,
      },
      [muiTheme.breakpoints.down(1025)]: {
        fontSize: 14,
      },
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#6858B7",
      // padding: "0px 20px !important",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#6858B7",
      borderRadius: "0px 0px 50px 50px !important",
      transform: "rotate(-180deg)",
      height: 4,
      left: "114.0938px",
    },
  }),
  loaderStyle: (props: any) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 420px)",
  }),
});
export default useStyles;
