import { makeStyles } from "@mui/styles";
import muiTheme from "theme/muiTheme";

const useStyles = makeStyles({
  rootContainer: (props: any) => ({
    fontFamily: `'Nunito Sans', sans-serif !important`,
  }),
  meniFestsSection: (props: any) => ({
    background: "#F3F0FC",
    height: "calc(100vh - 84px)",
    marginTop: "84px !important",
    [muiTheme.breakpoints.down(960)]: {
      height: "calc(100vh - 136px)",
    },
  }),
});
export default useStyles;
