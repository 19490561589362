import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  rootContainer: (props: any) => ({
    fontFamily: `'Nunito Sans', sans-serif !important`,
  }),
  graphSection: (props: any) => ({
    height: "33vh",
    margin: "10px 15px 10px 0px",
    padding: "10px 15px 10px 15px",
    background: "#F3F0FC",
    border: "1px solid #CFCAE8",
    boxShadow: "0px 0px 4px 2px rgba(144, 135, 200, 0.25)",
    borderRadius: "5px",
  }),
  graphAreaStyle: {
    display: "flex",
    justifyContent: "center",
    marginTop: "-46px",
  },
  graphHeaderSection: {
    display: "flex",
    justifyContent: "space-between",
  },
  graphTitle: {
    color: "#464646",
    fontSize: "15px",
    fontWeight: 700,
    fontFamily: `'Nunito Sans', sans-serif !important`,
  },
  graphSelect: {},
  graphFooterSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    height: "100%",
  },
  footerContentList: {
    margin: "10px 25px 0px 25px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  graphCount: {
    color: "#000",
    fontSize: "18px",
    fontFamily: `'Nunito Sans', sans-serif !important`,
    fontWeight: 700,
  },
  graphLineColor: {
    background: "#8BDEAE",
    width: "40px",
    height: "5px",
    borderRadius: "5px",
  },
  graphLineColorEvent: {
    background: "#8BDEAE",
    width: "40px",
    height: "5px",
    borderRadius: "5px",
  },
  graphLineColorIncident: {
    background: "#DE8282 ",
    width: "40px",
    height: "5px",
    borderRadius: "5px",
  },
  graphLineColorOprAlert: {
    background: "#FFAC5F",
    width: "40px",
    height: "5px",
    borderRadius: "5px",
  },
  graphFooterTitle: {
    color: "#000",
    fontSize: "15px",
    fontWeight: 700,
    fontFamily: `'Nunito Sans', sans-serif !important`,
  },
  graphCustomSelectDropDown: (props: any) => ({
    minWidth: "100px !important",
    minheight: "5px !important",
    "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
      {
        // paddingRight: "150px !important",
      },
    "& .MuiPaper-root": {
      border: "1px solid #F3F0FC !important",
    },
    "& .MuiSelect-select": {
      padding: "10px 11px",
    },
    "& .MuiInputBase-root": {
      borderRadius: "45px",
      fontSize: 14,
      fontFamily: `'Nunito Sans', sans-serif !important`,
      // lineHeight: 21,
      textAlign: "left",
      fontWeight: "bold",
      position: "relative",
      color: "#7E6ECC",
      width: "130px",
      paddingLeft: "15px",
      "& .MuiSvgIcon-root": {
        position: "absolute",
        right: "10px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        color: "#F3F0FC",
        border: `1px solid #7E6ECC !important`,
      },
    },
    "& .css-t0wjx6-MuiButtonBase-root-MuiMenuItem-root.Mui-selected.MuiMenuItem-root":
      {
        color: "#fff !important",
      },

    "& .MuiSelect-iconOutlined": {
      color: `#7E6ECC !important`,
    },
  }),
});
export default useStyles;
