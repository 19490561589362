import { useState, useEffect } from "react";
//@ts-ignore
import { TreeItem, TreeView, TreeItemProps } from "@material-ui/lab";
import theme from "theme/theme";
import ChevronRightIcon from "assets/chevronRight.svg";
import ExpandMoreIcon from "assets/chevronDownIcon.svg";
import useStyles from "./styles";

interface TreeViewListProps {
  data?: any;
  handleOnDrag?: any;
  draggingCategory?: any;
  draggingSubCategory?: any;
  handleConfiguredList?: any;
  searchExpanded?: any;
  handleToggleConfigured?: any;
  seletedTrackerListItemId?: any;
  seletedEquipmentListItemId?: any;
  searchSelected?: any;
}

const TreeViewListConfigured: React.FC<any> = (props: TreeViewListProps) => {
  const {
    data,
    handleOnDrag,
    draggingCategory,
    draggingSubCategory,
    handleConfiguredList,
    searchExpanded,
    seletedTrackerListItemId,
    seletedEquipmentListItemId,
    searchSelected,
  } = props;

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const {
    rootContainer,
    treeViewContainer,
    treeViewListContainer,
    treeViewListMainContainer,
    treeViewSubListMainContainer,
    treeViewSubListContainer,
    treeViewListContainerTracker,
    treeViewListContainerDragged,
    treeViewSubListContainerDragged,
    treeViewListMainContainerDragged,
    noRecords,
    treeViewListContainerSelected,
    treeViewSubListContainerSelected,
  } = useStyles(appTheme);

  const handleToggleConfigured = (e: any, nodeIds: any) => {
    props.handleToggleConfigured(nodeIds);
  };

  const handleAllLabelClick = (e: any) => {
    e.preventDefault();
  };

  return (
    <div className={rootContainer}>
      {searchExpanded?.length >= 1 ? (
        <TreeView
          multiSelect
          style={{ height: 240, flexGrow: 1, margin: 3 }}
          expanded={searchExpanded}
          onNodeToggle={handleToggleConfigured}
          selected={searchSelected}
        >
          <TreeItem
            nodeId="0"
            label="All"
            onLabelClick={handleAllLabelClick}
            className={treeViewContainer}
          >
            {data?.map((item: any, itemIndex: number) => {
              /* Warehouses */
              return (
                <TreeItem
                  nodeId={item?.searchId}
                  label={item?.title}
                  className={treeViewSubListMainContainer}
                  expandIcon={<img src={ChevronRightIcon} />}
                  collapseIcon={<img src={ExpandMoreIcon} />}
                >
                  {item?.subList?.map(
                    (subListItem: any, sublistItemIndex: number) => {
                      /* Tracker & Equipments */
                      if (
                        (subListItem.category === "tracker" &&
                          subListItem?.list?.some(
                            (i: any) => i.configured === true
                          )) ||
                        subListItem.category === "equipment"
                      ) {
                        return (
                          <TreeItem
                            nodeId={subListItem?.searchId}
                            label={subListItem?.title}
                            className={treeViewListMainContainer}
                            expandIcon={<img src={ChevronRightIcon} />}
                            collapseIcon={<img src={ExpandMoreIcon} />}
                          >
                            {subListItem?.list?.map(
                              (listItem: any, listItemIndex: number) => {
                                if (
                                  (listItem?.configured &&
                                    subListItem.category === "tracker") ||
                                  (!listItem?.configured &&
                                    listItem?.subListItem?.some(
                                      (i: any) => i.configured === true
                                    ))
                                ) {
                                  /* Tracker & Equipments lists */
                                  return (
                                    <TreeItem
                                      onClick={() =>
                                        handleConfiguredList(
                                          subListItem?.category,
                                          [
                                            itemIndex,
                                            sublistItemIndex,
                                            listItemIndex,
                                          ],
                                          listItem
                                        )
                                      }
                                      className={
                                        listItem?.title?.includes("Tracker") &&
                                        seletedTrackerListItemId ===
                                          listItem?.id
                                          ? treeViewListContainerSelected
                                          : listItem?.title?.includes("Tracker")
                                          ? treeViewListContainerTracker
                                          : treeViewListContainer
                                      }
                                      nodeId={listItem?.searchId}
                                      label={listItem?.title}
                                      expandIcon={
                                        <img src={ChevronRightIcon} />
                                      }
                                      collapseIcon={
                                        <img src={ExpandMoreIcon} />
                                      }
                                    >
                                      {listItem?.subListItem?.map(
                                        (
                                          listItemSub: any,
                                          listItemSubIndex: number
                                        ) => {
                                          if (listItemSub?.configured) {
                                            /* Equipments Sublists */
                                            return (
                                              <TreeItem
                                                className={
                                                  seletedEquipmentListItemId ===
                                                  listItemSub?.id
                                                    ? treeViewSubListContainerSelected
                                                    : treeViewSubListContainer
                                                }
                                                nodeId={listItemSub?.searchId}
                                                label={listItemSub?.title}
                                                onClick={() =>
                                                  handleConfiguredList(
                                                    subListItem?.category,
                                                    [
                                                      itemIndex,
                                                      sublistItemIndex,
                                                      listItemIndex,
                                                      listItemSubIndex,
                                                    ],
                                                    listItemSub
                                                  )
                                                }
                                              />
                                            );
                                          }
                                        }
                                      )}
                                    </TreeItem>
                                  );
                                }
                              }
                            )}
                          </TreeItem>
                        );
                      }
                    }
                  )}
                </TreeItem>
              );
            })}
          </TreeItem>
        </TreeView>
      ) : (
        <div className={noRecords}>{"No Devices Found"}</div>
      )}
    </div>
  );
};

export default TreeViewListConfigured;
