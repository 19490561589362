import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import useTranslation from "../../localization/translations";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InfoSubList from "../InfoSubList";
import CustomizedSteppers from "../Stepper";
import ChipButton from "../../elements/ChipButton";
import Tabs from "../../elements/Tabs";
import { SearchIconInfo } from "../../assets/buttonIcons";
import theme from "../../theme/theme";
import colorCodes from "../../theme/colors";
import Geofence from "components/Geofence";
import FloorMap from "components/Map/FloorMap";
import Map from "components/Map";
import useStyles from "./styles";
import InfoNotificationListItem from "components/InfoNotificationListItem";
import SearchBox from "elements/SearchBox";
import moment from "moment";
import { CloseIconImg } from "../../assets/icons";

const DialogWrapper = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiBackdrop-root": {
    marginTop: "84px !important",
  },
  "& .MuiPaper-root": {
    maxHeight: "calc(100% - 110px)",
    minHeight: "calc(100% - 110px)",
    width: "80vw",
    maxWidth: "1772px",
    background: `${colorCodes.colorBlack} !important`,
    color: colorCodes.colorWhite,
    padding: 24,
  },
  "& .MuiDialog-container": {
    marginTop: "60px !important",
    background: "rgb(0 0 0 / 58%)",
    backdropFilter: "blur(10px)",
    height: "calc(100vh - 65px) !important",
  },
}));

const InfoDialog: React.FC<any> = (props) => {
  const {
    selectedItem,
    setShowInfoDialogue,
    parentTabIndex,
    markerArray,
    showInfoDialogue,
    pageName,
    selectedId,
    selectedType,
  } = props;

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const {
    headerStyle,

    tripsSection1,
    tripsSection,
    tripsElementSection,
    violationElementSection,
    violationSection,
    tripsSection2,
    detailsSection,
    notificationList,
    subListRow,
    subListSection,
    chipButtonSection,
    labelSection,
    customNotificationTabs,
    infoDialogHeading,
    searchClass,
    notificationSearchIcon,
    searchBoxClass,
    searchContainer,
  } = useStyles(appTheme);

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );

  const {
    infoDialogueCategory,
    infoDialogueMake,
    infoDialogueDimensions,
    infoDialogueTemperature,
    infoDialogueBattery,
    infoDialogueLastUpdated,
    infoDialogueNotifications,
    infoSubListBoxId,
    infoSubListTrackerId,
    infoSubListStatus,
    infoSubListLocation,
    infoSubListTotalUnits,
    infoSubListSource,
    chipButtonEvents,
    chipButtonIncidents,
    chipButtonOprAlerts,
    infoTabsListDetails,
    infoTabsListGeofence,
    infoDialogueType,
    infoDialogueUtilization,
    infoTabsListEquipmentID,
    infoTabsListState,
    infoTabsListCurrentLocation,
    infoTabsListOperator,
  } = useTranslation();

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  const [selectedItemNew, setSelectedItemNew] = useState<any>();
  const [isBackToGeoFenceChecked, setIsBackToGeoFenceChecked] =
    useState<boolean>(false);
  const [isOutOfGeoFenceChecked, setIsOutOfGeofenceChecked] =
    useState<boolean>(false);
  const [isInsideGeofence, setIsInsideGeofence] = useState<boolean>(false);

  const [localStorageData, setLocalStorageData] = useState<any>();
  const [selectedWareHouseID, setSelectedWareHouseID] = useState<any>();
  const [isSelectedItem, setIsSelectedItem] = useState<any>();
  const [tabIndex, setTabIndex] = useState<number>(0);

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("dashboardViewDetails") || "{}");

    data?.map((item: any) => {
      if (item?.id === selectedId) {
        for (let i = 0; i < item[selectedItem?.type].length; i++) {
          if (item[selectedItem?.type][i]["id"] === selectedItem?.id) {
            setIsSelectedItem(item[selectedItem?.type][i]);
          }
        }
      }
    });
  }, [selectedItem]);

  useEffect(() => {
    const data = JSON.parse(
      localStorage?.getItem("dashboardViewDetails") || "{}"
    );
    setLocalStorageData(data);

    const warehouseId = JSON.parse(
      localStorage?.getItem("warehouseId") || "{}"
    );

    setSelectedWareHouseID(warehouseId);
  }, []);

  const [open, setOpen] = useState(!false);
  const [chipButtons, setChipButtons] = useState<any>([]);
  const [geofenceFloorMapEnable, setGeofenceFloorMapEnable] =
    useState<boolean>(false);
  const [geofenceFloorMapDetailsEnable, setGeofenceFloorMapDetailsEnable] =
    useState<boolean>(true);
  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const [isCircleDrawing, setIsCircleDrawing] = useState<boolean>(false);
  const [polygonData, setPolygonData] = useState<any>(null);
  const [circleData, setCircleData] = useState<any>(null);
  const [circleRadius, setCircleRadius] = useState<any>(null);
  const [circleCenter, setCircleCenter] = useState<any>(null);
  const [polygonPath, setPolygonPath] = useState<any>(null);
  const [geoFenceName, setGeofenceName] = useState<string>("");
  const [geoFenceType, setGeofenceType] = useState<string>("");
  const [newSearchValue, setNewSearchValue] = useState<any>();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isGeofenceLocation, setIsGeofenceLocation] = useState<boolean>(false);

  const [isNotification, setIsNotification] = useState<any>(1);
  const [infoNotificationSelectedTab, setinfoNotificationSelectedTab] =
    useState<any>();
  const [isUpdateEnable, setIsUpdateEnable] = useState<boolean>(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const getFormattedChipButtonsData = (list: any, chipBtnIndex: number) => {
    for (const [index] of list.entries()) {
      if (chipBtnIndex === index) list[index].focused = true;
      else list[index].focused = false;
    }

    return list;
  };

  useEffect(() => {
    if (isSelectedItem !== undefined) {
      if (isSelectedItem?.geoFence.type === "circle") {
        setCircleRadius(isSelectedItem?.geoFence.radius);
        setCircleCenter(isSelectedItem?.geoFence.coOrdinates[0]);
        setGeofenceName(isSelectedItem?.geoFence.name);
        setGeofenceType(isSelectedItem?.geoFence.type);
      } else {
        setPolygonPath(isSelectedItem?.geoFence.coOrdinates);
        setGeofenceType(isSelectedItem?.geoFence.type);
        setGeofenceName(isSelectedItem?.geoFence.name);
      }
      setNewSearchValue(isSelectedItem?.notifications?.incidents?.list);
      setinfoNotificationSelectedTab(
        isSelectedItem?.notifications?.incidents?.list
      );
      setChipButtons([
        {
          label: chipButtonEvents,
          count:
            isSelectedItem &&
            isSelectedItem?.notifications?.events?.list?.length,
          focused:
            isSelectedItem?.geoFence?.backToGeofence &&
            isSelectedItem?.geoFence?.isInsideGeofence &&
            isUpdateEnable,
          color: "green",
        },
        {
          label: chipButtonIncidents,
          count:
            isSelectedItem &&
            isSelectedItem?.notifications?.incidents?.list?.length,
          focused: true,
          color: "red",
        },
        {
          label: chipButtonOprAlerts,
          count:
            isSelectedItem &&
            isSelectedItem?.notifications?.oprAlerts?.list?.length,
          focused: false,
          color: "orange",
        },
      ]);

      setIsInsideGeofence(isSelectedItem?.geoFence?.isInsideGeofence);
      setIsBackToGeoFenceChecked(isSelectedItem?.geoFence?.backToGeofence);
      setIsOutOfGeofenceChecked(isSelectedItem?.geoFence?.outsideGeofence);
      setIsNotification(isSelectedItem?.geoFence?.isInsideGeofence ? 0 : 1);
    }
  }, [isSelectedItem]);

  useEffect(() => {
    setTabIndex(tabIndex);
    if (tabIndex === 0) {
      let data = JSON.parse(
        localStorage.getItem("dashboardViewDetails") || "{}"
      );

      data?.map((item: any) => {
        if (item?.id === selectedId) {
          for (let i = 0; i < item[selectedItem?.type].length; i++) {
            if (item[selectedItem?.type][i]["id"] === selectedItem?.id) {
              setIsSelectedItem(item[selectedItem?.type][i]);
            }
          }
        }
      });
    } else if (tabIndex === 1) {
      // setIsInsideGeofence(false);
      setIsBackToGeoFenceChecked(false);
      setIsOutOfGeofenceChecked(false);
      setIsNotification(false);
      setIsUpdateEnable(false);
    }
  }, [tabIndex]);

  const handleClose = () => {
    setOpen(!open);
    setShowInfoDialogue(false);
    props.handleClose();
    setIsUpdateEnable(false);
  };

  const handleChipButton = (data: any, index: number) => {
    setIsSearchOpen(false);
    setChipButtons([
      ...chipButtons,
      getFormattedChipButtonsData(chipButtons, index),
    ]);

    setIsUpdateEnable(false);
    setIsNotification(index);
    let localStorageData = JSON.parse(
      localStorage.getItem("dashboardViewDetails") || "{}"
    );

    localStorageData?.map((item: any) => {
      if (item?.id === selectedId) {
        for (let i = 0; i < item[selectedItem?.type].length; i++) {
          if (item[selectedItem?.type][i]["id"] === selectedItem?.id) {
            const data =
              index === 0
                ? item[selectedItem?.type][i]?.notifications?.events?.list
                : index === 1
                ? item[selectedItem?.type][i]?.notifications?.incidents?.list
                : item[selectedItem?.type][i]?.notifications?.oprAlerts?.list;
            setinfoNotificationSelectedTab(data);
            setNewSearchValue(data);
          }
        }
      }
    });
  };

  const tabsList = [
    { name: infoTabsListDetails, val: 0 },
    { name: infoTabsListGeofence, val: 1 },
  ];

  const handleTabChange = (index: number) => {
    setIsUpdateEnable(false);
    if (index === 1) {
      setGeofenceFloorMapEnable(true);
    }
    props.handleTabChange();
    setGeofenceFloorMapDetailsEnable(true);
    setTabIndex(index);
    setIsDisabled(false);
    setChipButtons([
      {
        label: chipButtonEvents,
        count: false,
        focused: false,
        color: "green",
      },
      {
        label: chipButtonIncidents,
        count:
          isSelectedItem && isSelectedItem?.notifications?.events?.list?.length,
        focused: true,
        color: "red",
      },
      {
        label: chipButtonOprAlerts,
        count:
          isSelectedItem &&
          isSelectedItem?.notifications?.oprAlerts?.list?.length,
        focused: false,
        color: "orange",
      },
    ]);
  };

  useEffect(() => {
    if (
      isBackToGeoFenceChecked &&
      isInsideGeofence &&
      isNotification === 0 &&
      isUpdateEnable
    ) {
      const obj = {
        area: "Staging Area ",
        timeStamp: moment().format("MM-DD-YYYY | h:mm A"),
        title: "Back to geofence",
      };
      setNewSearchValue([...isSelectedItem?.notifications?.events?.list, obj]);
      setChipButtons([
        {
          label: chipButtonEvents,
          count: [...isSelectedItem?.notifications?.events?.list, obj]?.length,
          focused: true,
          color: "green",
        },
        {
          label: chipButtonIncidents,
          count:
            isSelectedItem &&
            isSelectedItem?.notifications?.incidents?.list?.length,
          focused: false,
          color: "red",
        },
        {
          label: chipButtonOprAlerts,
          count:
            isSelectedItem &&
            isSelectedItem?.notifications?.oprAlerts?.list?.length,
          focused: false,
          color: "orange",
        },
      ]);
    } else if (
      !isInsideGeofence &&
      isOutOfGeoFenceChecked &&
      isNotification === 1 &&
      isUpdateEnable
    ) {
      const obj1 = {
        area: "Staging Area ",
        timeStamp: moment().format("MM-DD-YYYY | h:mm A"),
        title: "Out of geofence",
      };
      setNewSearchValue([
        ...isSelectedItem?.notifications?.incidents?.list,
        obj1,
      ]);
      setChipButtons([
        {
          label: chipButtonEvents,
          count:
            isSelectedItem &&
            isSelectedItem?.notifications?.events?.list?.length,
          focused: false,
          color: "green",
        },
        {
          label: chipButtonIncidents,
          count: [...isSelectedItem?.notifications?.incidents?.list, obj1]
            ?.length,
          focused: true,
          color: "red",
        },
        {
          label: chipButtonOprAlerts,
          count:
            isSelectedItem &&
            isSelectedItem?.notifications?.oprAlerts?.list?.length,
          focused: false,
          color: "orange",
        },
      ]);
    }
  }, [
    isBackToGeoFenceChecked,
    isInsideGeofence,
    isOutOfGeoFenceChecked,
    isNotification,
  ]);

  useEffect(() => {
    if (isUpdateEnable) {
      let data = JSON.parse(
        localStorage.getItem("dashboardViewDetails") || "{}"
      );

      data?.map((item: any) => {
        if (item?.id === selectedId) {
          for (let i = 0; i < item[selectedItem?.type].length; i++) {
            if (item[selectedItem?.type][i]["id"] === selectedItem?.id) {
              if (
                isNotification === 0 &&
                isBackToGeoFenceChecked &&
                isInsideGeofence
              ) {
                item[selectedItem?.type][i]["notifications"]["events"].list =
                  newSearchValue;
              } else if (
                isNotification === 1 &&
                !isInsideGeofence &&
                isOutOfGeoFenceChecked
              ) {
                item[selectedItem?.type][i]["notifications"]["incidents"].list =
                  newSearchValue;
              }
            }
          }
        }
      });

      localStorage.setItem("dashboardViewDetails", JSON.stringify(data));
    }
  }, [newSearchValue]);

  useEffect(() => {
    if (!isSearchOpen) {
      let data = JSON.parse(
        localStorage.getItem("dashboardViewDetails") || "{}"
      );

      data?.map((item: any) => {
        if (item?.id === selectedId) {
          for (let i = 0; i < item[selectedItem?.type].length; i++) {
            if (item[selectedItem?.type][i]["id"] === selectedItem?.id) {
              const data =
                isNotification === 0
                  ? item[selectedItem?.type][i]?.notifications?.events?.list
                  : isNotification === 1
                  ? item[selectedItem?.type][i]?.notifications?.incidents?.list
                  : item[selectedItem?.type][i]?.notifications?.oprAlerts?.list;
              setinfoNotificationSelectedTab(data);
              setNewSearchValue(data);
              setChipButtons([
                {
                  label: chipButtonEvents,
                  count:
                    isNotification === 0
                      ? data?.length
                      : item[selectedItem?.type][i]?.notifications?.events?.list
                          ?.length,
                  focused: isNotification === 0,
                  color: "green",
                },
                {
                  label: chipButtonIncidents,
                  count:
                    isNotification === 1
                      ? data?.length
                      : item[selectedItem?.type][i]?.notifications?.incidents
                          ?.list?.length,
                  focused: isNotification === 1,
                  color: "red",
                },
                {
                  label: chipButtonOprAlerts,
                  count:
                    isNotification === 2
                      ? data?.length
                      : item[selectedItem?.type][i]?.notifications?.oprAlerts
                          ?.list?.length,
                  focused: isNotification === 2,
                  color: "orange",
                },
              ]);
            }
          }
        }
      });
    }
  }, [isSearchOpen]);

  const infoSubList = [
    {
      title: parentTabIndex === 0 ? infoSubListBoxId : infoTabsListEquipmentID,
      value: selectedItem?.id,
    },
    {
      title: infoSubListTrackerId,
      value: selectedItem?.trackerId,
    },
    {
      title: parentTabIndex === 0 ? infoSubListStatus : infoTabsListState,
      value:
        parentTabIndex === 0
          ? selectedItem?.connectedStatus
          : selectedItem?.state,
    },
    {
      title: parentTabIndex === 0 ? infoSubListLocation : infoSubListStatus,
      value:
        parentTabIndex === 0
          ? selectedItem?.area
          : selectedItem?.connectedStatus,
    },
    {
      title:
        parentTabIndex === 0
          ? infoSubListTotalUnits
          : infoTabsListCurrentLocation,
      value:
        parentTabIndex === 0 ? selectedItem?.totalUnits : selectedItem?.area,
    },
    {
      title: parentTabIndex === 0 ? infoSubListSource : infoTabsListOperator,
      value:
        parentTabIndex === 0 ? selectedItem?.source : selectedItem?.operator,
    },
  ];

  const handleGeofencePolygonClick = () => {
    setIsCircleDrawing(false);
    setIsDrawing(true);
    setIsDisabled(true);
    circleData?.setMap(null);
    polygonData?.setMap(null);
  };

  const handleGeofenceCircleClick = () => {
    setIsCircleDrawing(true);
    setIsDrawing(true);
    setIsDisabled(true);
    circleData?.setMap(null);
    polygonData?.setMap(null);
  };

  const handleCancelClick = () => {
    polygonData?.setMap(null);
    circleData?.setMap(null);
    setIsDisabled(false);
    setIsCircleDrawing(false);
    setIsDrawing(false);
  };

  const handleSearchIsOpenNotificationList = () => {
    setIsSearchOpen(!isSearchOpen);
    setIsUpdateEnable(false);
    let data = JSON.parse(localStorage.getItem("dashboardViewDetails") || "{}");

    data?.map((item: any) => {
      if (item?.id === selectedId) {
        for (let i = 0; i < item[selectedItem?.type].length; i++) {
          if (item[selectedItem?.type][i]["id"] === selectedItem?.id) {
            const filteredData =
              isNotification === 0
                ? item[selectedItem?.type][i]?.notifications?.events?.list
                : isNotification === 1
                ? item[selectedItem?.type][i]?.notifications?.incidents?.list
                : item[selectedItem?.type][i]?.notifications?.oprAlerts?.list;
            setinfoNotificationSelectedTab(filteredData);
            setNewSearchValue(filteredData);
            setChipButtons([
              {
                label: chipButtonEvents,
                count:
                  isNotification === 0
                    ? filteredData?.length
                    : item[selectedItem?.type][i]?.notifications?.events?.list
                        ?.length,
                focused: isNotification === 0,
                color: "green",
              },
              {
                label: chipButtonIncidents,
                count:
                  isNotification === 1
                    ? filteredData?.length
                    : item[selectedItem?.type][i]?.notifications?.incidents
                        ?.list?.length,
                focused: isNotification === 1,
                color: "red",
              },
              {
                label: chipButtonOprAlerts,
                count:
                  isNotification === 2
                    ? filteredData?.length
                    : item[selectedItem?.type][i]?.notifications?.oprAlerts
                        ?.list?.length,
                focused: isNotification === 2,
                color: "orange",
              },
            ]);
          }
        }
      }
    });
  };

  const handleSearch = (searchValue: any) => {
    let searchResult = infoNotificationSelectedTab?.filter((value: any) => {
      return value?.title
        ?.toLowerCase()
        .includes(searchValue?.toString()?.toLowerCase());
      //   ||
      // value?.timeStamp
      //   ?.toLowerCase()
      //   .includes(searchValue?.toString()?.toLowerCase())
    });
    setNewSearchValue(searchResult);
    let data = JSON.parse(localStorage.getItem("dashboardViewDetails") || "{}");

    data?.map((item: any) => {
      if (item?.id === selectedId) {
        for (let i = 0; i < item[selectedItem?.type].length; i++) {
          if (item[selectedItem?.type][i]["id"] === selectedItem?.id) {
            setChipButtons([
              {
                label: chipButtonEvents,
                count:
                  isNotification === 0
                    ? searchResult?.length
                    : item[selectedItem?.type][i]?.notifications?.events?.list
                        ?.length,
                focused: isNotification === 0,
                color: "green",
              },
              {
                label: chipButtonIncidents,
                count:
                  isNotification === 1
                    ? searchResult?.length
                    : item[selectedItem?.type][i]?.notifications?.incidents
                        ?.list?.length,
                focused: isNotification === 1,
                color: "red",
              },
              {
                label: chipButtonOprAlerts,
                count:
                  isNotification === 2
                    ? searchResult?.length
                    : item[selectedItem?.type][i]?.notifications?.oprAlerts
                        ?.list?.length,
                focused: isNotification === 2,
                color: "orange",
              },
            ]);
          }
        }
      }
    });
  };

  const handleResetClick = () => {
    setIsDisabled(true);
    setIsDrawing(true);
    setIsCircleDrawing(
      isSelectedItem?.geoFence.type === "circle" ? true : false
    );
  };

  const handleUpdateClick = () => {
    setIsDisabled(false);
  };

  const handleCircleLatChange = () => {
    setIsDisabled(true);
    polygonData?.setMap(null);
    circleData?.setMap(null);
  };

  const handleCircleDrag = (centerCoOrdinates: any) => {
    setIsDisabled(true);
    polygonData?.setMap(null);
    circleData?.setMap(null);
    const startLatLng = new google.maps.LatLng(centerCoOrdinates);
    const circle = new window.google.maps.Circle({
      center: startLatLng,
      radius: circleRadius,
    })
      .getBounds()
      ?.contains(selectedItem?.location);

    if (circle) {
      setIsGeofenceLocation(true);
    } else {
      setIsGeofenceLocation(false);
    }
  };

  const onCircleComplete = (centerCoOrdinates: any, drawRadius: any) => {
    const startLatLng = new google.maps.LatLng(centerCoOrdinates);
    const circle = new window.google.maps.Circle({
      center: startLatLng,
      radius: drawRadius,
    })
      .getBounds()
      ?.contains(selectedItem?.location);

    if (circle) {
      setIsGeofenceLocation(true);
    } else {
      setIsGeofenceLocation(false);
    }
  };

  const onPolygonComplete = (path: any) => {
    const polygon = new window.google.maps.Polygon({
      paths: path,
    });

    const contains = window.google.maps.geometry.poly.containsLocation(
      new window.google.maps.LatLng(
        selectedItem?.location?.lat,
        selectedItem?.location?.lng
      ),
      polygon
    );

    if (contains) {
      setIsGeofenceLocation(true);
    } else {
      setIsGeofenceLocation(false);
    }
  };

  const handleCloseIcon = () => {
    let data = JSON.parse(localStorage.getItem("dashboardViewDetails") || "{}");

    data?.map((item: any) => {
      if (item?.id === selectedId) {
        for (let i = 0; i < item[selectedItem?.type].length; i++) {
          if (item[selectedItem?.type][i]["id"] === selectedItem?.id) {
            const data =
              isNotification === 0
                ? item[selectedItem?.type][i]?.notifications?.events?.list
                : isNotification === 1
                ? item[selectedItem?.type][i]?.notifications?.incidents?.list
                : item[selectedItem?.type][i]?.notifications?.oprAlerts?.list;
            setinfoNotificationSelectedTab(data);
            setNewSearchValue(data);
          }
        }
      }
    });
  };

  return (
    <>
      <DialogWrapper open={open} sx={{ top: "22px" }}>
        <div className={headerStyle}>
          <Tabs
            initialIndex={tabIndex}
            tabsList={tabsList}
            handleTabs={handleTabChange}
            dashboardNotificationClassName={customNotificationTabs}
          />
        </div>

        <div>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 15,
              top: 12,
              color: appTheme.palette.infoDialogueComponent.colorWhite,
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {tabIndex === 0 ? (
          <Grid container>
            <Grid item xs={12} className={subListRow}>
              <InfoSubList
                highlighted={"safetyScore"}
                infoSubList={infoSubList}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Grid container className={subListSection}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <div className={tripsSection1}>
                        <div className={tripsElementSection}>
                          <div className={detailsSection}>
                            <div className={labelSection}>
                              {parentTabIndex === 0
                                ? infoDialogueCategory
                                : infoDialogueType}
                            </div>
                            <div>{selectedItem?.category}</div>
                          </div>
                          <div className={detailsSection}>
                            <div className={labelSection}>
                              {infoDialogueMake}
                            </div>
                            <div>{selectedItem?.make}</div>
                          </div>
                          <div className={detailsSection}>
                            <div className={labelSection}>
                              {parentTabIndex === 0
                                ? infoDialogueDimensions
                                : infoDialogueUtilization}
                            </div>
                            <div>
                              {parentTabIndex === 0
                                ? selectedItem?.dimension
                                : selectedItem?.utilization}
                            </div>
                          </div>
                        </div>

                        <div className={tripsElementSection}>
                          <div className={detailsSection}>
                            <div className={labelSection}>
                              {infoDialogueTemperature}
                            </div>
                            <div>{selectedItem?.temperature}</div>
                          </div>
                          <div className={detailsSection}>
                            <div className={labelSection}>
                              {infoDialogueBattery}
                            </div>
                            <div>{selectedItem?.battery}</div>
                          </div>
                          <div className={detailsSection}>
                            <div className={labelSection}>
                              {infoDialogueLastUpdated}
                            </div>
                            <div>{selectedItem?.timeStamp}</div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div
                        // style={{
                        //   height: parentTabIndex === 0 ? "210px" : "350px",
                        // }}
                        className={tripsSection2}
                      >
                        <FloorMap
                          geofenceFloorMapDetailsEnable={
                            geofenceFloorMapDetailsEnable
                          }
                          circleCenter={circleCenter}
                          circleRadius={circleRadius}
                          polygonPath={polygonPath}
                          markerArray={[selectedItem]}
                          selectedListItem={selectedItem}
                          pageName={pageName}
                          selectedNotification={selectedItem?.id}
                          parentTabIndex={parentTabIndex}
                          isInsideGeofence={isInsideGeofence}
                        />
                      </div>
                    </Grid>
                    {parentTabIndex === 0 ? (
                      <Grid item xs={12}>
                        <div className={tripsSection}>
                          <CustomizedSteppers
                            packagaeData={selectedItem?.packagaeData}
                          />
                        </div>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Grid container direction="column" className={violationSection}>
                <Grid item>
                  <Grid container className={searchContainer}>
                    {!isSearchOpen && (
                      <Grid item xs={10} className={violationElementSection}>
                        <div>
                          <p className={infoDialogHeading}>
                            {infoDialogueNotifications}
                          </p>
                        </div>
                      </Grid>
                    )}

                    {isSearchOpen && (
                      <Grid item xs={10}>
                        <div className={searchBoxClass}>
                          <SearchBox
                            searchInput={searchClass}
                            placeHolder={"Search"}
                            handleSearch={handleSearch}
                            borderRadius={2}
                            borderColor={"1px solid #DCDADA"}
                            searchIsOpenOne={isSearchOpen}
                            fontColor={"#fff"}
                            handleCloseIcon={handleCloseIcon}
                          />
                        </div>
                      </Grid>
                    )}

                    <Grid item xs={2}>
                      <div className={notificationSearchIcon}>
                        <img
                          src={isSearchOpen ? CloseIconImg : SearchIconInfo}
                          onClick={handleSearchIsOpenNotificationList}
                          width={isSearchOpen ? 20 : 30}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item className={chipButtonSection}>
                  {chipButtons && chipButtons.length > 0
                    ? chipButtons.map((item: any, index: number) => {
                        if (index <= 2) {
                          return (
                            <ChipButton
                              key={index}
                              data={item}
                              index={index}
                              handleClick={handleChipButton}
                              pageName="infoDialog"
                            />
                          );
                        }
                      })
                    : null}
                </Grid>

                <Grid
                  item
                  // xs={12}
                  className={`${notificationList} notificationListHeight`}
                >
                  {newSearchValue && newSearchValue?.length > 0 ? (
                    newSearchValue.map((item: any, index: number) => {
                      return (
                        <InfoNotificationListItem
                          key={index}
                          data={item}
                          index={index}
                        />
                      );
                    })
                  ) : (
                    <div>
                      <span style={{ padding: 10, color: "gray" }}>
                        No Result Found
                      </span>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={12} className={subListRow}>
              <InfoSubList
                highlighted={"safetyScore"}
                infoSubList={infoSubList}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <FloorMap
                geofenceFloorMapEnable={geofenceFloorMapEnable}
                isDrawing={isDrawing}
                setIsDrawing={setIsDrawing}
                isCircleDrawing={isCircleDrawing}
                setPolygonData={setPolygonData}
                setCircleData={setCircleData}
                setCircleRadius={setCircleRadius}
                setCircleCenter={setCircleCenter}
                setPolygonPath={setPolygonPath}
                circleCenter={circleCenter}
                circleRadius={circleRadius}
                polygonPath={polygonPath}
                geoFenceType={geoFenceType}
                isDisabled={isDisabled}
                markerArray={[selectedItem]}
                selectedListItem={selectedItem}
                pageName={pageName}
                selectedNotification={selectedItem?.id}
                handleCircleDrag={handleCircleDrag}
                onCircleComplete={onCircleComplete}
                onPolygonComplete={onPolygonComplete}
                isInsideGeofence={isInsideGeofence}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Geofence
                handleGeofencePolygonClick={handleGeofencePolygonClick}
                handleGeofenceCircleClick={handleGeofenceCircleClick}
                circleCenter={circleCenter}
                circleRadius={circleRadius}
                setCircleRadius={setCircleRadius}
                setCircleCenter={setCircleCenter}
                geoFenceName={geoFenceName}
                setGeofenceName={setGeofenceName}
                geoFenceType={geoFenceType}
                setPolygonPath={setPolygonPath}
                polygonPath={polygonPath}
                selectedItem={selectedItem}
                handleCancelClick={handleCancelClick}
                handleResetClick={handleResetClick}
                handleUpdateClick={handleUpdateClick}
                handleCircleLatChange={handleCircleLatChange}
                isGeofenceLocation={isGeofenceLocation}
                selectedId={selectedId}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                setIsUpdateEnable={setIsUpdateEnable}
              />
            </Grid>
          </Grid>
        )}
      </DialogWrapper>
    </>
  );
};

export default InfoDialog;
