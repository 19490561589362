import { useState, useEffect } from "react";
import theme from "../../theme/theme";
import {
  RootContainer,
  ItemContainer,
  ItemValue,
  ItemLabel,
  VerticalSpace,
} from "./styles";

const InfoSubList: React.FC<any> = ({ highlighted,infoSubList, ...props }) => {
  // const [items, setItems] = useState<any>([]);

  // useEffect(() => {
  //   const propItems: any = [];
  //   Object.keys(props).forEach((key) => {
  //     propItems.push({ key, value: props[key] });
  //   });

  //   setItems(propItems);
  // }, []);

  // const toTitleCase = (testString: string) => {
  //   let titleCaseString = testString
  //     .replace(/(_|-)/g, " ")
  //     .trim()
  //     .replace(/\w\S*/g, function (str) {
  //       return str.charAt(0).toUpperCase() + str.substr(1);
  //     })
  //     .replace(/([a-z])([A-Z])/g, "$1 $2")
  //     .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");

  //   return titleCaseString;
  // };

  return (
    <RootContainer
      paletteColor={
        theme?.defaultTheme?.palette?.infoSubListElement?.lightShadeGray
      }
    >
      {infoSubList.map((item: any) => {
        return (
          <ItemContainer key={item.title + item.value}>
            <ItemValue
              paletteColor={
                theme?.defaultTheme?.palette?.infoSubListElement?.colorWhite
              }
            >
              {item.value}
            </ItemValue>
            <VerticalSpace count={3} />
            <ItemLabel
              paletteColor={
                theme?.defaultTheme?.palette?.infoSubListElement
                  ?.lightShadeGray1
              }
            >
              {item.title}
            </ItemLabel>
          </ItemContainer>
        );
      })}
    </RootContainer>
  );
};

export default InfoSubList;
