const maniFestTrailers = {
  trailers: [
    {
      value: "tr1",
      label: "TRAILER#1",
    },
    {
      value: "tr2",
      label: "TRAILER#2",
    },
    {
      value: "tr3",
      label: "TRAILER#3",
    },
    {
      value: "tr4",
      label: "TRAILER#4",
    },
    {
      value: "tr5",
      label: "TRAILER#5",
    },
  ],
};

export default maniFestTrailers;
