import { useState, useEffect } from "react";
import {
  RootContainer,
  StoreContainer,
  StoreHeader,
  StoreHeaderContent,
  StoreTitle,
  StoreLocation,
  PalletContainer,
  PalletTitle,
  PalletStatusIndicator,
  Icon,
} from "./styles";
import { Location, Down } from "assets/icons";

const Accordian = (props: any) => {
  const { selectedData, setSelectedPallet, selectedPallet } = props;

  const [selectedStore, setSelectedStore] = useState("");

  const onStoreSelection = (store: any) => {
    setSelectedStore(store?.name);
    setSelectedPallet(store.pallets[0]);
  };

  useEffect(() => {
    selectedData?.length && setSelectedStore(selectedData[0].name);
    selectedData?.length && setSelectedPallet(selectedData[0].pallets[0]);
  }, [selectedData]);

  return (
    <RootContainer>
      {selectedData && selectedData?.length > 0 ? (
        selectedData?.map((store: any) => (
          <StoreContainer>
            <StoreHeader
              onClick={() => onStoreSelection(store)}
              focused={selectedStore === store?.name}
            >
              <StoreHeaderContent>
                <StoreTitle>
                  {store?.name} ({store?.count})
                </StoreTitle>
                <StoreLocation>
                  {" "}
                  <Icon src={Location} /> <span>{store?.area}</span>
                </StoreLocation>
              </StoreHeaderContent>
              <Icon src={Down} />
            </StoreHeader>
            {selectedStore === store?.name &&
              store?.pallets?.map((pallet: any) => (
                <PalletContainer
                  onClick={() => setSelectedPallet(pallet)}
                  focused={selectedPallet?.name === pallet?.name}
                >
                  <PalletStatusIndicator
                    color={"#5AA700"}
                  ></PalletStatusIndicator>
                  <PalletTitle>Pallet ID: {pallet?.name}</PalletTitle>
                </PalletContainer>
              ))}
          </StoreContainer>
        ))
      ) : (
        <div>
          <span style={{ paddingLeft: "30%", color: "gray" }}>No Result Found</span>
        </div>
      )}
    </RootContainer>
  );
};

export default Accordian;
