import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(
  {
    datePickerStyle: (props: any) => ({
      width: "100%",
      position: "relative",

      "& .react-datepicker-wrapper": {
        border: "1px solid white",
        borderRadius: 5,
      },
      "& .MuiOutlinedInput-root": {
        width: "100%",
      },
      "& .react-datepicker": {
        fontFamily: `'Nunito Sans', sans-serif !important`,
        fontSize: 16,
      },
      "& .react-datepicker-popper": {
        position: "absolute",
        right: "0px !important",
        top: "58px !important",
        inset: "0 auto !important",
      },
      "& .react-datepicker__triangle": {
        position: "absolute",
        transform: "translate(188px, 0px) !important",
        inset: " 0 auto !important",
      },
      "& .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after":
        {
          top: "0px",
          left: "30px",
        },
      "& .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before":
        {
          top: "0px",
          left: "30px",
        },
    }),
  },
  { index: 1 }
);
export default useStyles;
