export default {
  title: "Hello Infinite English",
  profile: "Profile English",
  hello: "Hello",
  tabListName1: "Tracking",
  tabListName2: "Notification",
  trackingListButton1: "Warehouse",
  trackingListButton2: "Trailers",
  trackingListButton3: "Stores",
  helloInfinite: "Hello Infinite",
  changeTheme: "Change Theme",
  changeLang: "Change Language",
  dashboardButton: "Dashboard",
  manifestsButton: "Manifests",
  configurationsButton: "Configurations",
  supervisorDefalutDomain: "Supervisor",
  infoIconTitle1: "WAREHOUSE",
  infoIconTitle2: "IN TRANSIT",
  infoIconTitle3: "TRACKING DISCONNECTED",
  infoIconTitle4: "DELAYED DELIVERY",
  listItemName1: "Goods Tracked",
  listItemName2: "Equipment Tracked",
  listItemName3: "Access Point",
  listItemButtonName: "View Details",
  infoDialogueCategory: "Category",
  infoDialogueMake: "Make",
  infoDialogueDimensions: "Dimensions",
  infoDialogueTemperature: "Temperature",
  infoDialogueBattery: "Battery",
  infoDialogueLastUpdated: "Last Updated",
  infoDialogueNotifications: "Notifications",
  infoDialogueType: "Type",
  infoDialogueUtilization: "Utilization",
  infoSubListBoxId: "Box ID",
  infoSubListTrackerId: "Tracker ID",
  infoSubListStatus: "Status",
  infoSubListLocation: "Location",
  infoSubListTotalUnits: "Total Units",
  infoSubListSource: "Source",
  chipButtonEvents: "Events",
  chipButtonIncidents: "Incidents",
  chipButtonOprAlerts: "Opr.Alerts",
  infoTabsListDetails: "DETAILS",
  infoTabsListGeofence: "GEOFENCE",
  BoxId: "Box ID",
  EquipmentId: "Equipment ID",
  TrackerId: "Tracker ID",
  Status: "Status",
  Battery: "Battery",
  Temperature: "Temperature",
  Notification: "Notification",
  Goods: "Goods",
  Equipments: "Equipments",
  ViewDetail: "View Detail",
  Utilization: "Utilization",
  Connected: "Connected",
  Disconnected: "Disconnected",
  LocationText: "Location",
  infoTabsListEquipmentID: "Equipment ID",
  infoTabsListState: "State",
  infoTabsListCurrentLocation: "Current Location",
  infoTabsListOperator: "Operator",
  Pallets: "Pallets",
  Stores: "Stores",
  CurrentLocation: "Current Location",
  trailerRouteDetails: "Trailer Route Details",
  trailerInfoTrailerID: "Trailer ID",
  trailerInfoManifestID: "Manifest ID",
  trailerInfoStartLocation: "Start Location",
  trailerInfoEndLocation: "End Location",
  trailerInfoTotalPallets: "Total Pallets",
  trailerInfoDeliveryStores: "Delivery Stores",
  analyticsButton: "Analytics",
};
