import { makeStyles } from "@mui/styles";
import muiTheme from "theme/muiTheme";

const useStyles = makeStyles(() => ({
  stepperContainer: (props: any) => ({
    "& .MuiTimeline-root": {
      "& li:last-child": {
        flex: "0 !important",
      },
    },
    "& .MuiTimelineItem-root": {
      flex: 1,
      [muiTheme.breakpoints.down(1437)]: {
        minHeight: 56,
      },
    },
    "& ul": {
      height: "calc(100vh - 475px)",
      padding: "6px 0px",
      display: "flex",
      flexDirection: "column",
      [muiTheme.breakpoints.down(1437)]: {
        height: "auto !important",
      },
      [muiTheme.breakpoints.down(1025)]: {
        height: "calc(100vh - 400px) !important",
      },
    },
    "& li::before": {
      display: "none",
    },
    "& .MuiTimelineContent-root": {
      padding: "0px 0 0px 16px",
      marginTop: "-10px",
    },
    "& .MuiTimelineDot-defaultGrey": {
      backgroundColor: "unset !important",
      boxShadow: "unset !important",
    },
    "& .MuiTimelineDot-root": {
      padding: 0,
      marginTop: "-6px",
      marginBottom: "-6px",
      marginLeft: "-2px",
      backgroundColor: "unset",
    },
    "& .MuiTimelineSeparator-root": {
      width: "22px !important",
      // height: "135px",
    },
  }),
  routeDetailasContainer: (props: any) => ({
    padding: "10px 10px 10px 14px",
    border: "1px solid #dcdada",
    background: "#202020",
    borderRadius: "5px",
    cursor: "pointer",
    borderTopRightRadius: "0px !important",
    borderTopLeftRadius: "0px !important",
    borderBottomLeftRadius: "0px !important",
    height: "calc(100vh - 464px) !important",
    // height: "calc(100vh - 354px)",
    // overflow: "auto",
    boxSizing: "border-box",
    // [theme.breakpoints.down("lg")]: {
    //   height: "calc(100vh - 400px)",
    // },
    [muiTheme.breakpoints.down(1437)]: {
      height: "auto !important",
    },
    [muiTheme.breakpoints.down(1281)]: {
      height: "calc(100vh - 464px) !important",
    },
    [muiTheme.breakpoints.down(1025)]: {
      height: "calc(100vh - 348px) !important",
    },
    [muiTheme.breakpoints.down(963)]: {
      height: "calc(100vh - 282px) !important",
    },
    "@media (max-height: 801px)": {
      height: "calc(100vh - 382px) !important",
    },
  }),
  routesHeading: (props: any) => ({
    fontFamily: `'Nunito Sans', sans-serif !important`,
    fontWeight: 800,
    fontSize: 16,
    lineHeight: "22px",
    margin: "0 0 10px 0",
    // [theme.breakpoints.down("lg")]: {
    //   fontSize: 14,
    // },
  }),
  routesSubHeading: (props: any) => ({
    fontFamily: `'Nunito Sans', sans-serif !important`,
    fontSize: 13,
    lineHeight: "22px",
    margin: 0,
    // [theme.breakpoints.down("lg")]: {
    //   fontSize: 12,
    // },
  }),
  routeName: (props: any) => ({
    fontFamily: `'Nunito Sans', sans-serif !important`,
    fontSize: 14,
    lineHeight: "15px",
    marginBottom: 5,
    color: "#fff",
    [muiTheme.breakpoints.down(1437)]: {
      fontSize: 10,
      lineHeight: "12px",
    },
  }),
  routeArea: (props: any) => ({
    fontFamily: `'Nunito Sans', sans-serif !important`,
    fontSize: 12,
    lineHeight: "16px",
    marginBottom: 7,
    fontWeight: 100,
    [muiTheme.breakpoints.down(1437)]: {
      fontSize: 9,
      lineHeight: "12px",
    },
  }),
  routeTimestamp: (props: any) => ({
    fontFamily: `'Nunito Sans', sans-serif !important`,
    fontSize: 9,
    lineHeight: "12px",
    marginBottom: 1,
    fontWeight: 100,
  }),
  routeNameSpan: (props: any) => ({
    background: "#FB4A4A",
    borderRadius: 2,
    padding: 2,
    color: "#fff",
    marginLeft: 10,
    fontSize: 10,
  }),
  routeNameSpanNextStop: (props: any) => ({
    background: "#79BE6D",
    borderRadius: 2,
    padding: 2,
    color: "#fff",
    marginLeft: 10,
    fontSize: 10,
  }),
  timeLineConnector: (props: any) => ({
    top: "-3px !important",
    left: "-2px",
    width: "2px",
    position: "relative",
    backgroundColor: "#1D5472",
  }),
  timeLineConnectorDashed: (props: any) => ({
    left: "-2px",
    position: "relative",
    backgroundColor: "unset !important",
    width: 0,
    borderLeft: "3px dashed #456EFF",
  }),
}));
export default useStyles;
