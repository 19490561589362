import loginActions from "../actions/loginActions";
import dashboardListAction from "../actions/dashboardListAction";

const initialState = {
  dashboardListData: {},
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case dashboardListAction.SET_DASHBOARD_LIST:
      const { dashboardListData } = action;
      return { ...state, dashboardListData };

    default:
      return state;
  }
};
