import { useState, useEffect, useContext } from "react";
import {
  GoogleMap,
  LoadScript,
  MarkerF,
  useLoadScript,
  DrawingManager,
  Circle,
  GroundOverlay,
  // Marker,
  InfoWindow,
} from "@react-google-maps/api";
import Marker from "elements/Marker";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import theme from "../../theme/theme";
import InfoIcon from "../../elements/InfoIcon";
import moment from "moment";
import Map from "../Map/map";
import WareHouseIcon from "../../assets/infoIcon/warehouse.svg";
import AlertIcon from "../../assets/infoIcon/alert.svg";
import TransitIcon from "../../assets/infoIcon/transit.svg";
import DelayIcon from "../../assets/infoIcon/delay.svg";
import useTranslation from "../../localization/translations";
import useStyles from "./styles";

const DashboardMap: React.FC<any> = (props) => {
  const {
    dashboardList,
    markerArray,
    setSelectedNotification,
    selectedNotification,
    setSelectedTabButton,
    selectedTabButton,
    notificationTimeStamp,
    setIsMarkerClicked,
    isMarkerClicked,
    setViewDetailActive,
    selectedItem,
    tabIndex,
    setRightPanelActive,
    rightPanelActive,
    setChipButtonIndex,
    chipButtonIndex,
    searchOpen,
    setShowInfoDialogue,
    showInfoDialogue,
  } = props;

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const [currentFocusedMarker, setCurrentFocusedMarker] = useState("");
  const {} = useStyles(appTheme);
  const { infoIconContainer, dashboardMapContainer } = useStyles(appTheme);

  const { infoIconTitle1, infoIconTitle2, infoIconTitle3, infoIconTitle4 } =
    useTranslation();

  const InfoIconList = [
    {
      color: appTheme?.palette?.dashboardList?.green,
      title: dashboardList?.wareHouse,
      tagLine: infoIconTitle1?.toUpperCase(),
      icon: WareHouseIcon,
    },
    {
      color: appTheme?.palette?.dashboardList?.green,
      title: dashboardList?.inTransit,
      tagLine: infoIconTitle2?.toUpperCase(),
      icon: TransitIcon,
    },
    {
      color: appTheme?.palette?.dashboardList?.red,
      title: dashboardList?.trtackingDisconnected,
      tagLine: infoIconTitle3?.toUpperCase(),
      icon: AlertIcon,
    },
    {
      color: appTheme?.palette?.dashboardList?.orange,
      title: dashboardList?.delayedDelivery,
      tagLine: infoIconTitle4?.toUpperCase(),
      icon: DelayIcon,
    },
  ];

  return (
    <div className={dashboardMapContainer}>
      <div>
        <Map
          markerArray={markerArray}
          setSelectedNotification={setSelectedNotification}
          selectedNotification={selectedNotification}
          setSelectedTabButton={setSelectedTabButton}
          selectedTabButton={selectedTabButton}
          notificationTimeStamp={notificationTimeStamp}
          setIsMarkerClicked={setIsMarkerClicked}
          isMarkerClicked={isMarkerClicked}
          setViewDetailActive={setViewDetailActive}
          selectedItem={selectedItem}
          tabIndex={tabIndex}
          setRightPanelActive={setRightPanelActive}
          rightPanelActive={rightPanelActive}
          setChipButtonIndex={setChipButtonIndex}
          chipButtonIndex={chipButtonIndex}
          searchOpen={searchOpen}
       
        />
      </div>
      <div className={infoIconContainer}>
        {InfoIconList &&
          InfoIconList?.map((value, index) => {
            return (
              <InfoIcon
                color={value?.color}
                title={value?.title}
                tagLine={value?.tagLine}
                icon={value?.icon}
                fabWidth={65}
                fabHeight={65}
                imgWidth={30}
                imgHeight={30}
                key={index}
              />
            );
          })}
      </div>
    </div>
  );
};
export default DashboardMap;
