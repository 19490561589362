import React, { useEffect, useState } from "react";
// import {
//   Timeline,
//   TimelineItem,
//   TimelineSeparator,
//   TimelineConnector,
//   TimelineContent,
//   TimelineDot,
// } from "@material-ui/lab";
// import Icon from "@material-ui/core/Icon";
import Icon from "@mui/material/Icon";
import {
  currentLocationIcon,
  GreenHomeIcon,
  HomeIcon,
} from "../../assets/icons";
import theme from "../../theme/theme";
import useStyles from "./styles";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

const TimeLineStepper: React.FC<any> = (props) => {
  const { trailerRouteDetails, pageName, parentTabIndex } = props;

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const {
    stepperContainer,
    routeDetailasContainer,
    routesHeading,
    routesSubHeading,
    routeName,
    routeArea,
    routeTimestamp,
    routeNameSpan,
    timeLineConnector,
    timeLineConnectorDashed,
    routeNameSpanNextStop,
  } = useStyles(appTheme);

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  // const tripStatusData = "In Progress";
  // const routeDetailsData = {

  //     stoppages: [
  //       {
  //         name: "WAREHOUSE#1",
  //         observation: "15 Min Delay",
  //         area: "Idaho Falls, IDHAO, US ",
  //         coOrdinates: {
  //           lat: "",
  //           lng: "",
  //         },
  //         timeStamp: "02-13-2023 | 9:00 AM",
  //       },
  //       {
  //         name: "WYOMING",
  //         observation: "Over Speeding",
  //         area: "San Carlos, Costa Rica",
  //         coOrdinates: {
  //           lat: "",
  //           lng: "",
  //         },
  //         timeStamp: "03-13-2023 | 10:30 AM",
  //       },
  //       {
  //         name: "STORE#1",
  //         observation: "Next Stop",
  //         area: "Nebraska, 80831, US ",
  //         coOrdinates: {
  //           lat: "",
  //           lng: "",
  //         },
  //         timeStamp: "03-13-2023 | 11:00 AM",
  //       },
  //       {
  //         name: "STORE#2",
  //         observation: "",
  //         area: "Idaho Falls, IDHAO, US ",
  //         coOrdinates: {
  //           lat: "",
  //           lng: "",
  //         },
  //         timeStamp: "03-13-2023 | 1:30 PM",
  //       },

  //     ],

  // };

  const [routeDetails, setRouteDetails] = useState<any>(trailerRouteDetails);
  const [tripStatus, setTripStatus] = useState<string>("In Progress");

  useEffect(() => {
    // const routeDetails =
    //   infoDialogTripList && infoDialogTripList[0]?.routeDetails;
    // const details = [...trailerRouteDetails];
    if (tripStatus === "Completed") {
      // details.splice(1, 1);
      // const finalRoutes = {
      //   ...trailerRouteDetails,
      //      details,
      // };
      // setRouteDetails(finalRoutes);
    } else {
      setRouteDetails(trailerRouteDetails);
    }
  }, [trailerRouteDetails]);

  return (
    <div className={routeDetailasContainer}>
      <div className={stepperContainer}>
        <Timeline>
          {routeDetails?.map((route: any, index: any) => {
            return (
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot>
                    {
                    pageName === "manifest" ? 
                    index === 0 ? (
                      <img src={HomeIcon} />
                    ) : index === routeDetails?.length - 1 ? (
                      <img
                        width={"30px"}
                        height={"30px"}
                        style={{ marginLeft: "-5px" }}
                        src={GreenHomeIcon}
                      />
                    )
                    :
                    (
                      route?.status !== "Completed" &&
                      route?.name === "WYOMING" ? (
                        <img
                          width={"20px"}
                          height={"20px"}
                          src={currentLocationIcon}
                        />
                      ) : (
                        <img
                          width={"30px"}
                          height={"30px"}
                          style={{ marginLeft: "-5px" }}
                          src={GreenHomeIcon}
                        />
                      )
                    ) 
                    
                    : index === 0 ? (
                      <img src={HomeIcon} />
                    ) : index === routeDetails?.length - 1 ? (
                      <img
                        width={"30px"}
                        height={"30px"}
                        style={{ marginLeft: "-5px" }}
                        src={GreenHomeIcon}
                      />
                    ) : tripStatus !== "Completed" && index === 1 ? (
                      <img
                        width={"20px"}
                        height={"20px"}
                        src={currentLocationIcon}
                      />
                    ) : (
                      <img
                        width={"30px"}
                        height={"30px"}
                        style={{ marginLeft: "-5px" }}
                        src={GreenHomeIcon}
                      />
                    )}
                  </TimelineDot>
                  {index === routeDetails?.length - 1 ? null : (
                    <TimelineConnector
                      className={
                        pageName === "manifest"
                          ? parentTabIndex===0 ? timeLineConnectorDashed : route?.status !== "Completed" && index === 0
                            ? timeLineConnector
                            : route?.status === "Completed"
                            ? timeLineConnector
                            : timeLineConnectorDashed
                          : tripStatus !== "Completed" && index === 0
                          ? timeLineConnector
                          : tripStatus === "Completed"
                          ? timeLineConnector
                          : timeLineConnectorDashed
                      }
                    />
                  )}
                </TimelineSeparator>
                <TimelineContent>
                  <p className={routeName}>
                    {route?.name}
                    {/* {tripStatus !== "Completed" && route?.observation && (
                      <span
                        className={
                          index === 2 ? routeNameSpanNextStop : routeNameSpan
                        }
                      >
                        {tripStatus !== "Completed" && route?.observation}
                      </span>
                    )} */}
                  </p>
                  {route?.name !== "WYOMING" && (
                    <p className={routeArea}>{route?.area}</p>
                  )}

                  <p className={routeTimestamp}>{route?.timeStamp}</p>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      </div>
    </div>
  );
};

export default TimeLineStepper;
