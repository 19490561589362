import { useState, useEffect, Fragment } from "react";
import Grid from "@mui/material/Grid";
import theme from "../../theme/theme";
import CircularChart from "elements/CircularChart";
import Select from "elements/Select";
import useStyles from "./styles";
import moment from "moment";
import Chart from "elements/Chart";
import {
  AnalyticsStepUpArraowGreen,
  AnalyticsStepDownArraowRed,
} from "../../assets/icons";

const AnalyticsWarehouse: React.FC<any> = (props) => {
  const { selectedWarehouseGraphObjData } = props;
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const {
    graphSection,
    graphAreaStyle,
    graphHeaderSection,
    graphTitle,
    graphSelect,
    graphCustomSelectDropDown,
    graphFooterSection,
    footerContentList,
    graphCount,
    graphLineColor,
    graphFooterTitle,
    graphLineColorEvent,
    graphLineColorIncident,
    graphLineColorOprAlert,
    headerMiddleSection,
    boxLabelgroup,
    colorBoxes,
    colorBoxlabelStyle,
    colorRectangleBoxes,
    rectangleboxLabelgroup,
  } = useStyles(appTheme);

  const [selectedValue, setSelectedValue] = useState<string>("Week");
  const [circularGraphData, setCircularGraphData] = useState<any>([]);
  const [circularInnerValue, setCircularInnerValue] = useState<number>();

  const notificationGraphData =
    selectedWarehouseGraphObjData?.graphAnalytics?.filter(
      (notifications: any) => {
        return notifications?.category?.toLowerCase().includes("notifications");
      }
    );

  const getNotificationGraphData = (selectedValue: any) => {
    let graphData;
    switch (selectedValue) {
      case "Day":
        graphData = notificationGraphData[0]?.analytics?.day;
        break;
      case "Week":
        graphData = notificationGraphData[0]?.analytics?.weekly;
        break;
      case "Month":
        graphData = notificationGraphData[0]?.analytics?.monthly;
        break;
      case "Year":
        graphData = notificationGraphData[0]?.analytics?.yearly;
        break;
      default:
        graphData = notificationGraphData[0]?.analytics?.weekly;
        break;
    }
    setCircularGraphData([
      { title: "Event", value: graphData?.events, color: "#8BDEAE" },
      { title: "Incident", value: graphData?.incidents, color: "#DE8282" },
      { title: "Ops. Alert", value: graphData?.opralerts, color: "#FFAC5F" },
    ]);
    setCircularInnerValue(graphData?.totalNotifications);
  };

  useEffect(() => {
    getNotificationGraphData(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    getNotificationGraphData(selectedValue);
  }, []);

  //new changes for graph warehouse
  
  const [deliveredGraphData, setDeliveredGraphData] = useState<any>();
  
  const [hoursOfOperationGraphData, setHoursOfOperationGraphData] =
    useState<any>();
  const [equipmentUtilGraphData, setEquipmentUtilGraphData] = useState<any>();

  const [totalGoodsGraphData, setTotalGoodsGraphData] = useState<any>();
  

  const [
    deliveredSuccessGraphDataStateUpdates,
    setDeliveredSuccessGraphDataStateUpdates,
  ] = useState<any>();
  const [
    deliveredWrongGraphDataStateUpdates,
    setDeliveredWrongGraphDataStateUpdates,
  ] = useState<any>();
  const [
    deliveredDelayedGraphDataStateUpdates,
    setDeliveredDelayedGraphDataStateUpdates,
  ] = useState<any>();

  

  

  const [
    totalDeliveriesUpcomingGraphDataStateUpdates,
    setTotalDeliveriesUpcomingGraphDataStateUpdates,
  ] = useState<any>();
  const [
    totalDeliveriesInprogressGraphDataStateUpdates,
    setTotalDeliveriesInprogressGraphDataStateUpdates,
  ] = useState<any>();
  const [
    totalDeliveriesCompletedGraphDataStateUpdates,
    setTotalDeliveriesCompletedGraphDataStateUpdates,
  ] = useState<any>();

  const [
    equipmentUtilGraphDataStateUpdates,
    setEquipmentUtilGraphDataStateUpdates,
  ] = useState<any>();

  const [totalGoodsGraphDataStateUpdates, setTotalGoodsGraphDataStateUpdates] =
    useState<any>();
  

  const [graphOneTitle, setGraphOneTitle] = useState<string>();
  const [graphTwoTitle, setGraphTwoTitle] = useState<string>();
  const [graphThreeTitle, setGraphThreeTitle] = useState<string>();
  const [graphFourTitle, setGraphFourTitle] = useState<string>();
  const [graphFiveTitle, setGraphFiveTitle] = useState<string>();
  const [graphSixTitle, setGraphSixTitle] = useState<string>();
  

  

  // const { width, height } = useWindowDimensions();
  const [chartWidth, setChartWidth] = useState<number>(500);
  const [chartHeight, setChartHeight] = useState<number>(270);

  useEffect(() => {
    selectedWarehouseGraphObjData?.graphAnalytics?.map(
      (data: any, index: number) => {
     
        switch (data?.category) {
          case "delivered":
            setDeliveredSuccessGraphDataStateUpdates(
              data?.analytics[selectedFormatGraph1]?.successAnalytics
            );
            setDeliveredWrongGraphDataStateUpdates(
              data?.analytics[selectedFormatGraph1]?.wrongAnalytics
            );
            setDeliveredDelayedGraphDataStateUpdates(
              data?.analytics[selectedFormatGraph1]?.delayedAnalytics
            );
            setDeliveredGraphData(data?.analytics);
            setGraphOneTitle(data?.title);
            

            break;
          case "totalDeliveries":
            setTotalDeliveriesUpcomingGraphDataStateUpdates(
              data?.analytics[selectedFormatGraph3]?.analyticsDataUpcoming
            );
            setTotalDeliveriesInprogressGraphDataStateUpdates(
              data?.analytics[selectedFormatGraph3]?.analyticsDataInprogress
            );
            setTotalDeliveriesCompletedGraphDataStateUpdates(
              data?.analytics[selectedFormatGraph3]?.analyticsDataCompleted
            );
            setHoursOfOperationGraphData(data?.analytics);
            setGraphThreeTitle(data?.title);
            
            break;
          case "equipmentUtilization":
            setEquipmentUtilGraphDataStateUpdates(
              data?.analytics[selectedFormatGraph4]?.analyticsData
            );
            setEquipmentUtilGraphData(data?.analytics);
            setGraphFourTitle(data?.title);
           
            break;
          case "totalGoods":
            setTotalGoodsGraphDataStateUpdates(
              data?.analytics[selectedFormatGraph5]?.analyticsData
            );
            setTotalGoodsGraphData(data?.analytics);
            setGraphFiveTitle(data?.title);
            
            break;
          default:
          // setTempratureGraphDataStateUpdates(data?.data?.weekly?.analyticsData);
          // setTempratureGraphData(data?.data);
        }
      }
    );
  }, [selectedWarehouseGraphObjData]);

  useEffect(() => {
    getDeliveredGraphData();
    
    getTotalDeliveriesGraphData();
    getEquipmentUtilGraphData();
    getTotalGoodsGraphData();
    
  }, [
    deliveredSuccessGraphDataStateUpdates,
    
    totalDeliveriesUpcomingGraphDataStateUpdates,
    equipmentUtilGraphDataStateUpdates,

    totalGoodsGraphDataStateUpdates,
    
  ]);

  const [updatedDeliveredGraphData, setUpdatedDeliveredGraphData] =
    useState<any>();
  const [updatedHumidityGraphData, setUpdatedHumidityGraphData] =
    useState<any>();
  const [
    updatedTotalDeliveriesGraphData,
    setUpdatedTotalDeliveriesGraphData,
  ] = useState<any>();
  const [updatedEquipmentUtilGraphData, setUpdatedEquipmentUtilGraphData] =
    useState<any>();

  const [updatedTotalGoodsGraphData, setUpdatedTotalGoodsGraphData] =
    useState<any>();
 

  const getDeliveredGraphData = () => {
    let data = [
      {
        data: graphDataManipulation(deliveredSuccessGraphDataStateUpdates),
        color: "#B3A7F0",
        name: "Successful",
      },
      {
        data: graphDataManipulation(deliveredWrongGraphDataStateUpdates),
        color: "#DD3C63",
        name: "Wrong",
      },
      {
        data: graphDataManipulation(deliveredDelayedGraphDataStateUpdates),
        color: "#F1933B",
        name: "Delayed",
      },
    ];

    setUpdatedDeliveredGraphData(data);
  };

 

  const getTotalDeliveriesGraphData = () => {
    const data = [
      {
        data: graphDataManipulation(totalDeliveriesUpcomingGraphDataStateUpdates),

        color: "#949FFF",
      },
      {
        data: graphDataManipulation(
          totalDeliveriesInprogressGraphDataStateUpdates
        ),

        color: "#EEAD51",
      },
      {
        data: graphDataManipulation(
          totalDeliveriesCompletedGraphDataStateUpdates
        ),

        color: "#887CCA",
      },
    ];
    setUpdatedTotalDeliveriesGraphData(data);
  };

  const getEquipmentUtilGraphData = () => {
    let data = [
      {
        data: graphDataManipulation(equipmentUtilGraphDataStateUpdates),

        color:
          graphFourTitle === "Energy Consumed (kWh)"
            ? "#9486DA"
            : graphFourTitle === "Hours of Operation"
            ? "#949FFF"
            : "#949FFF",
      },
    ];

    setUpdatedEquipmentUtilGraphData(data);
  };

  const getTotalGoodsGraphData = () => {
    let data = [
      {
        data: graphDataManipulation(totalGoodsGraphDataStateUpdates),

        color: "#77B77C",
      },
    ];

    setUpdatedTotalGoodsGraphData(data);
  };

 

  

  const graphDataManipulation = (analyticsGraphData: any) => {
    let manipulatedGraphData = [];
    if (analyticsGraphData) {
      if (analyticsGraphData) {
        for (let i = 0; i < analyticsGraphData?.length; i++) {
          manipulatedGraphData.push([
            // new Date(analyticsGraphData[i]?.node).getTime(),
            analyticsGraphData[i]?.count,
          ]);
        }
      }
    }
    return manipulatedGraphData;
  };

  // Updated Time 12Hrs Format - Day

  let times: any = [],
    periods: any = ["AM", "PM"],
    hours: any = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    prop: any = null,
    hour: any = null,
    min: any = null;

  for (prop in periods) {
    for (hour in hours) {
      for (min = 0; min < 60; min += 60) {
        times.push(
          ("0" + hours[hour]).slice(-2) +
            ":" +
            ("0" + min).slice(-2) +
            " " +
            periods[prop]
        );
      }
    }
  }

  const currentTime = moment().format("h A");
  const xAxisArray = times.filter(
    (value: any) =>
      moment(value, ["h A"]).format("HH") <
      moment(currentTime, ["h A"]).format("HH")
  );
  const xAxisTimeArray = xAxisArray.slice(
    xAxisArray.length - 10,
    xAxisArray.length
  );
  const xAxisNewValueTime = Array.from(xAxisTimeArray, (ps) => ps);

  // Updated Time 24Hrs Format - Day

  var hoursPerDay: any = 24;
  var xAxisNewtime: any = [];

  function timeOneDay() {
    var formattedTime;
    var newTimeFormat;
    for (let i = 0; i < hoursPerDay + 1; i++) {
      formattedTime = moment().subtract(i, "hours").format("HH");
      newTimeFormat = formattedTime + ":00";
      xAxisNewtime.unshift(newTimeFormat);
    }
    const newTimePop = xAxisNewtime.pop();
  }
  timeOneDay();

  //Updated Day List - Week

  const today: any = moment();
  const xAxisWeek: any = Array(7)
    .fill(7)
    .map(() => today.subtract(1, "d").format("MM/DD"));

  const xAxisNewValueWeek = xAxisWeek.reverse();

  //Updated Day List - Month

  const todayMonth: any = moment();
  const xAxisMonth: any = Array(30)
    .fill(30)
    .map(() => todayMonth.subtract(1, "d").format("MM/DD"));

  const xAxisNewValueMonth = xAxisMonth.reverse();

  // Year

  let monthName: any = new Array(
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  );
  let currentDate: any = new Date();

  let result: any = [];
  currentDate.setDate(1);
  for (let i = 0; i <= 11; i++) {
    result.push(
      monthName[currentDate.getMonth()]
      // +
      //   " " +
      //   currentDate.getFullYear().toString().substr(-2)
    );
    currentDate.setMonth(currentDate.getMonth() - 1);
  }
  const xAxisNewValue: any = result.reverse();
  const xAxisValueYear: any = xAxisNewValue.slice(
    xAxisNewValue.length - 12,
    xAxisNewValue.length
  );

  const [xAxisChartDataGraph1, setXAxisChartDataGraph1] =
    useState<any>(xAxisNewValueWeek);
  const [xAxisChartDataGraph2, setXAxisChartDataGraph2] =
    useState<any>(xAxisNewValueWeek);
  const [xAxisChartDataGraph3, setXAxisChartDataGraph3] =
    useState<any>(xAxisNewValueWeek);
  const [xAxisChartDataGraph4, setXAxisChartDataGraph4] =
    useState<any>(xAxisNewValueWeek);
  const [xAxisChartDataGraph5, setXAxisChartDataGraph5] =
    useState<any>(xAxisNewValueWeek);
  const [xAxisChartDataGraph6, setXAxisChartDataGraph6] =
    useState<any>(xAxisNewValueWeek);
  

  const [xAxisIntervalGraph1, setXAxisIntervalGraph1] = useState(0);
  const [xAxisIntervalGraph2, setXAxisIntervalGraph2] = useState(0);
  const [xAxisIntervalGraph3, setXAxisIntervalGraph3] = useState(0);
  const [xAxisIntervalGraph4, setXAxisIntervalGraph4] = useState(0);
  const [xAxisIntervalGraph5, setXAxisIntervalGraph5] = useState(0);
  const [xAxisIntervalGraph6, setXAxisIntervalGraph6] = useState(0);
  

  const selectList = [
    { label: "Day", value: "Day" },
    { label: "Week", value: "Week" },
    { label: "Month", value: "Month" },
    { label: "Year", value: "Year" },
  ];

  const monthFomrat = "{value:%m/%e}";
  const dayFormat = "{value:%H:00}";
  const yearFormat = "{value:%b}";

  const [formatGraph1, setFormatGraph1] = useState(monthFomrat);
  const [formatGraph2, setFormatGraph2] = useState(monthFomrat);
  const [formatGraph3, setFormatGraph3] = useState(monthFomrat);
  const [formatGraph4, setFormatGraph4] = useState(monthFomrat);
  const [formatGraph5, setFormatGraph5] = useState(monthFomrat);
  const [formatGraph6, setFormatGraph6] = useState(monthFomrat);
 

  const [selectedFormatGraph1, setSelectedFormatGraph1] = useState("weekly");
  const [selectedFormatGraph2, setSelectedFormatGraph2] = useState("weekly");
  const [selectedFormatGraph3, setSelectedFormatGraph3] = useState("weekly");
  const [selectedFormatGraph4, setSelectedFormatGraph4] = useState("weekly");
  const [selectedFormatGraph5, setSelectedFormatGraph5] = useState("weekly");
  const [selectedFormatGraph6, setSelectedFormatGraph6] = useState("weekly");
 

  const [selectedRealTimeGraph, setSelectedRealTimeGraph] = useState("Real Time");

  

  const handleSelect = (val: string, graphName: string) => {
    
    if (graphName === "graph1") {
     
      switch (val) {
        case "Day":
          setFormatGraph1(dayFormat);
          setSelectedFormatGraph1("day");
          setDeliveredSuccessGraphDataStateUpdates(
            deliveredGraphData?.day?.successAnalytics
          );
          setDeliveredWrongGraphDataStateUpdates(
            deliveredGraphData?.day?.wrongAnalytics
          );
          setDeliveredDelayedGraphDataStateUpdates(
            deliveredGraphData?.day?.delayedAnalytics
          );

          setXAxisChartDataGraph1(xAxisNewtime);
          setXAxisIntervalGraph1(4);

          break;
        case "Week":
          setFormatGraph1(monthFomrat);
          setSelectedFormatGraph1("weekly");
          setDeliveredSuccessGraphDataStateUpdates(
            deliveredGraphData?.weekly?.successAnalytics
          );
          setDeliveredWrongGraphDataStateUpdates(
            deliveredGraphData?.weekly?.wrongAnalytics
          );
          setDeliveredDelayedGraphDataStateUpdates(
            deliveredGraphData?.weekly?.delayedAnalytics
          );
          setXAxisChartDataGraph1(xAxisNewValueWeek);
          setXAxisIntervalGraph1(0);
          break;
        case "Month":
          setFormatGraph1(monthFomrat);
          setSelectedFormatGraph1("monthly");
          setDeliveredSuccessGraphDataStateUpdates(
            deliveredGraphData?.monthly?.successAnalytics
          );
          setDeliveredWrongGraphDataStateUpdates(
            deliveredGraphData?.monthly?.wrongAnalytics
          );
          setDeliveredDelayedGraphDataStateUpdates(
            deliveredGraphData?.monthly?.delayedAnalytics
          );
          setXAxisChartDataGraph1(xAxisNewValueMonth);
          setXAxisIntervalGraph1(5);
          break;
        case "Year":
          setFormatGraph1(yearFormat);
          setSelectedFormatGraph1("yearly");
          setDeliveredSuccessGraphDataStateUpdates(
            deliveredGraphData?.yearly?.successAnalytics
          );
          setDeliveredWrongGraphDataStateUpdates(
            deliveredGraphData?.yearly?.wrongAnalytics
          );
          setDeliveredDelayedGraphDataStateUpdates(
            deliveredGraphData?.yearly?.delayedAnalytics
          );
          setXAxisChartDataGraph1(xAxisValueYear);
          setXAxisIntervalGraph1(1);
          break;
        default:
          setFormatGraph1(dayFormat);
          setDeliveredSuccessGraphDataStateUpdates(
            deliveredGraphData?.day?.successAnalytics
          );
          setDeliveredWrongGraphDataStateUpdates(
            deliveredGraphData?.day?.wrongAnalytics
          );
          setDeliveredDelayedGraphDataStateUpdates(
            deliveredGraphData?.day?.delayedAnalytics
          );
          setXAxisChartDataGraph1(xAxisNewtime);
          setXAxisIntervalGraph1(4);
          break;
      }
    }
    if (graphName === "graph2") {
      setSelectedValue(val);
      
    }
    if (graphName === "graph3") {
      switch (val) {
        case "Day":
          setFormatGraph3(dayFormat);
          setSelectedFormatGraph3("day");
          setTotalDeliveriesUpcomingGraphDataStateUpdates(
            hoursOfOperationGraphData?.day?.analyticsDataUpcoming
          );
          setTotalDeliveriesInprogressGraphDataStateUpdates(
            hoursOfOperationGraphData?.day?.analyticsDataInprogress
          );
          setTotalDeliveriesCompletedGraphDataStateUpdates(
            hoursOfOperationGraphData?.day?.analyticsDataCompleted
          );
          setXAxisChartDataGraph3(xAxisNewtime);
          setXAxisIntervalGraph3(4);
          break;
        case "Week":
          setFormatGraph3(monthFomrat);
          setSelectedFormatGraph3("weekly");

          setTotalDeliveriesUpcomingGraphDataStateUpdates(
            hoursOfOperationGraphData?.weekly?.analyticsDataUpcoming
          );
          setTotalDeliveriesInprogressGraphDataStateUpdates(
            hoursOfOperationGraphData?.weekly?.analyticsDataInprogress
          );
          setTotalDeliveriesCompletedGraphDataStateUpdates(
            hoursOfOperationGraphData?.weekly?.analyticsDataCompleted
          );
          setXAxisChartDataGraph3(xAxisNewValueWeek);
          setXAxisIntervalGraph3(0);
          break;
        case "Month":
          setFormatGraph3(monthFomrat);
          setSelectedFormatGraph3("monthly");

          setTotalDeliveriesUpcomingGraphDataStateUpdates(
            hoursOfOperationGraphData?.monthly?.analyticsDataUpcoming
          );
          setTotalDeliveriesInprogressGraphDataStateUpdates(
            hoursOfOperationGraphData?.monthly?.analyticsDataInprogress
          );
          setTotalDeliveriesCompletedGraphDataStateUpdates(
            hoursOfOperationGraphData?.monthly?.analyticsDataCompleted
          );
          setXAxisChartDataGraph3(xAxisNewValueMonth);
          setXAxisIntervalGraph3(5);
          break;
        case "Year":
          setFormatGraph3(yearFormat);
          setSelectedFormatGraph3("yearly");

          setTotalDeliveriesUpcomingGraphDataStateUpdates(
            hoursOfOperationGraphData?.yearly?.analyticsDataUpcoming
          );
          setTotalDeliveriesInprogressGraphDataStateUpdates(
            hoursOfOperationGraphData?.yearly?.analyticsDataInprogress
          );
          setTotalDeliveriesCompletedGraphDataStateUpdates(
            hoursOfOperationGraphData?.yearly?.analyticsDataCompleted
          );
          setXAxisChartDataGraph3(xAxisValueYear);
          setXAxisIntervalGraph3(1);
          break;
        default:
          setFormatGraph3(dayFormat);
          setSelectedFormatGraph3("day");
          setTotalDeliveriesUpcomingGraphDataStateUpdates(
            hoursOfOperationGraphData?.day?.analyticsDataUpcoming
          );
          setTotalDeliveriesInprogressGraphDataStateUpdates(
            hoursOfOperationGraphData?.day?.analyticsDataInprogress
          );
          setTotalDeliveriesCompletedGraphDataStateUpdates(
            hoursOfOperationGraphData?.day?.analyticsDataCompleted
          );
          setXAxisChartDataGraph3(xAxisNewtime);
          setXAxisIntervalGraph3(4);
          break;
      }
    }
    if (graphName === "graph4") {
      switch (val) {
        case "Real Time":
          setSelectedRealTimeGraph("Real Time");
          break;
        case "Day":
          setFormatGraph4(dayFormat);
          setSelectedFormatGraph4("day");
          setEquipmentUtilGraphDataStateUpdates(
            equipmentUtilGraphData?.day?.analyticsData
          );
          setXAxisChartDataGraph4(xAxisNewtime);
          setXAxisIntervalGraph4(4);
          setSelectedRealTimeGraph("");
          break;
        case "Week":
          setFormatGraph4(monthFomrat);
          setSelectedFormatGraph4("weekly");
          setEquipmentUtilGraphDataStateUpdates(
            equipmentUtilGraphData?.weekly?.analyticsData
          );
          setXAxisChartDataGraph4(xAxisNewValueWeek);
          setXAxisIntervalGraph4(0);
          setSelectedRealTimeGraph("");
          break;
        case "Month":
          setFormatGraph4(monthFomrat);
          setSelectedFormatGraph4("monthly");
          setEquipmentUtilGraphDataStateUpdates(
            equipmentUtilGraphData?.monthly?.analyticsData
          );
          setXAxisChartDataGraph4(xAxisNewValueMonth);
          setXAxisIntervalGraph4(5);
          setSelectedRealTimeGraph("");
          break;
        case "Year":
          setFormatGraph4(yearFormat);
          setSelectedFormatGraph4("yearly");
          setEquipmentUtilGraphDataStateUpdates(
            equipmentUtilGraphData?.yearly?.analyticsData
          );
          setXAxisChartDataGraph4(xAxisValueYear);
          setXAxisIntervalGraph4(1);
          setSelectedRealTimeGraph("");
          break;
        default:
          setFormatGraph4(dayFormat);
          setEquipmentUtilGraphDataStateUpdates(
            equipmentUtilGraphData?.day?.analyticsData
          );
          break;
      }
    }

    if (graphName === "graph5") {
      switch (val) {
        case "Day":
          setFormatGraph5(dayFormat);
          setSelectedFormatGraph5("day");
          setTotalGoodsGraphDataStateUpdates(
            totalGoodsGraphData?.day?.analyticsData
          );
          setXAxisChartDataGraph5(xAxisNewtime);
          setXAxisIntervalGraph5(4);

          break;
        case "Week":
          setFormatGraph5(monthFomrat);
          setSelectedFormatGraph5("weekly");
          setTotalGoodsGraphDataStateUpdates(
            totalGoodsGraphData?.weekly?.analyticsData
          );
          setXAxisChartDataGraph5(xAxisNewValueWeek);
          setXAxisIntervalGraph5(0);

          break;
        case "Month":
          setFormatGraph5(monthFomrat);
          setSelectedFormatGraph5("monthly");
          setTotalGoodsGraphDataStateUpdates(
            totalGoodsGraphData?.monthly?.analyticsData
          );
          setXAxisChartDataGraph5(xAxisNewValueMonth);
          setXAxisIntervalGraph5(5);

          break;
        case "Year":
          setFormatGraph5(yearFormat);
          setSelectedFormatGraph5("yearly");
          setTotalGoodsGraphDataStateUpdates(
            totalGoodsGraphData?.yearly?.analyticsData
          );
          setXAxisChartDataGraph5(xAxisValueYear);
          setXAxisIntervalGraph5(1);

          break;
        default:
          setFormatGraph5(dayFormat);
          setTotalGoodsGraphDataStateUpdates(
            totalGoodsGraphData?.day?.analyticsData
          );
          break;
      }
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <div className={graphSection}>
            <div className={graphHeaderSection}>
              <div className={graphTitle}>Delivered</div>

              <div className={headerMiddleSection}>
                <div className={boxLabelgroup}>
                  <div
                    className={colorBoxes}
                    style={{ backgroundColor: "#37CB94" }}
                  ></div>
                  <label className={colorBoxlabelStyle}>Successful</label>
                  <div>
                    <img src={AnalyticsStepUpArraowGreen} /> +1.0%
                  </div>
                </div>

                <div className={boxLabelgroup}>
                  <div
                    className={colorBoxes}
                    style={{ backgroundColor: "#DD3C63" }}
                  ></div>
                  <label className={colorBoxlabelStyle}>Wrong</label>
                  <div>
                    <img src={AnalyticsStepDownArraowRed} /> -0.5%
                  </div>
                </div>

                <div className={boxLabelgroup}>
                  <div
                    className={colorBoxes}
                    style={{ backgroundColor: "#EEA61F" }}
                  ></div>
                  <label className={colorBoxlabelStyle}>Delayed</label>
                  <div>
                    <img src={AnalyticsStepDownArraowRed} /> -0.4%
                  </div>
                </div>
              </div>
              <div className={graphSelect}>
                <Select
                  selectList={selectList}
                  handleSelect={handleSelect}
                  // customWidth={"40%"}
                  customHeight={"40px"}
                  customSelectCustom={graphCustomSelectDropDown}
                  pageName={"analyticsPage"}
                  drowpDownTextColor={"#837DAB"}
                  dropDownBgColor={"#EEE9FF"}
                  dropDownSelectedBgColor={"#9087C8"}
                  dropDownSelectedTextColor={"#FFF"}
                  // selectedDropDownValue={selectedValue}
                  graphName={"graph1"}
                  // placeholder={"Select Trailer"}
                />
              </div>
            </div>
            <Chart
              type={"spline"}
              minWidth={chartWidth}
              height={chartHeight}
              dataPoints={updatedDeliveredGraphData}
              format={formatGraph1}
              toolTipShared={true}
              splineWidth={2}
              graphSequence={"graph1"}
              tooltipShow={true}
              isCrosshair={true}
              dataLabels={false}
              tabIdentity={"drivers"}
              pageName={"analyticsPage"}
              xAxisArray={xAxisChartDataGraph1}
              xAxisInterval={xAxisIntervalGraph1}
              graphTitle={graphOneTitle}
              selectedAnalyticListItem={"Overall Analytics"}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <div className={graphSection}>
            <div className={graphHeaderSection}>
              <div className={graphTitle}>Notifications</div>
              <div className={graphSelect}>
                <Select
                  selectList={selectList}
                  handleSelect={handleSelect}
                  // customWidth={"40%"}
                  customHeight={"40px"}
                  customSelectCustom={graphCustomSelectDropDown}
                  pageName={"analyticsPage"}
                  drowpDownTextColor={"#837DAB"}
                  dropDownBgColor={"#EEE9FF"}
                  dropDownSelectedBgColor={"#9087C8"}
                  dropDownSelectedTextColor={"#FFF"}
                  graphName={"graph2"}
                  // selectedDropDownValue={selectedValue}
                  // placeholder={"Select Trailer"}
                />
              </div>
            </div>

            <div className={graphAreaStyle}>
              <CircularChart
                data={circularGraphData}
                circularInnerText={"Total Notifications"}
                circularInnerValue={circularInnerValue}
              />
            </div>
            <div className={graphFooterSection}>
              {circularGraphData &&
                circularGraphData?.length > 0 &&
                circularGraphData?.map((item: any) => {
                  return (
                    <div className={footerContentList}>
                      <div className={graphCount}>{item?.value}</div>
                      <div
                        className={
                          item?.title === "Event"
                            ? graphLineColorEvent
                            : item?.title === "Incident"
                            ? graphLineColorIncident
                            : graphLineColorOprAlert
                        }
                      ></div>
                      <div className={graphFooterTitle}>{item?.title}</div>
                    </div>
                  );
                })}
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <div className={graphSection}>
            <div className={graphHeaderSection}>
              <div className={graphTitle}>Total Deliveries</div>

              <div className={headerMiddleSection}>
                <div className={rectangleboxLabelgroup}>
                  <div
                    className={colorRectangleBoxes}
                    style={{ backgroundColor: "#949FFF" }}
                  ></div>
                  <label className={colorBoxlabelStyle}>Delayed</label>
                </div>

                <div className={rectangleboxLabelgroup}>
                  <div
                    className={colorRectangleBoxes}
                    style={{ backgroundColor: "#EEAD51" }}
                  ></div>
                  <label className={colorBoxlabelStyle}>Delayed</label>
                </div>

                <div className={rectangleboxLabelgroup}>
                  <div
                    className={colorRectangleBoxes}
                    style={{ backgroundColor: "#887CCA" }}
                  ></div>
                  <label className={colorBoxlabelStyle}>Delayed</label>
                </div>
              </div>
              <div className={graphSelect}>
                <Select
                  selectList={selectList}
                  handleSelect={handleSelect}
                  // customWidth={"40%"}
                  customHeight={"40px"}
                  customSelectCustom={graphCustomSelectDropDown}
                  pageName={"analyticsPage"}
                  drowpDownTextColor={"#837DAB"}
                  dropDownBgColor={"#EEE9FF"}
                  dropDownSelectedBgColor={"#9087C8"}
                  dropDownSelectedTextColor={"#FFF"}
                  graphName={"graph3"}
                  // selectedDropDownValue={selectedValue}
                  // placeholder={"Select Trailer"}
                />
              </div>
            </div>
            <Chart
              type={"column"}
              minWidth={chartWidth}
              height={chartHeight}
              dataPoints={updatedTotalDeliveriesGraphData}
              format={formatGraph3}
              toolTipShared={false}
              splineWidth={2}
              graphSequence={"graph3"}
              tooltipShow={true}
              isCrosshair={true}
              dataLabels={false}
              tabIdentity={"drivers"}
              pageName={"analyticsPage"}
              xAxisArray={xAxisChartDataGraph3}
              xAxisInterval={xAxisIntervalGraph3}
              graphTitle={graphThreeTitle}
              selectedAnalyticListItem={"Overall Analytics"}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <div className={graphSection}>
            {" "}
            <div className={graphHeaderSection}>
              <div className={graphTitle}>Equipment Utilization</div>
              <div className={graphSelect}>
                <Select
                  selectList={selectList}
                  handleSelect={handleSelect}
                  // customWidth={"40%"}
                  customHeight={"40px"}
                  customSelectCustom={graphCustomSelectDropDown}
                  pageName={"analyticsPage"}
                  drowpDownTextColor={"#837DAB"}
                  dropDownBgColor={"#EEE9FF"}
                  dropDownSelectedBgColor={"#9087C8"}
                  dropDownSelectedTextColor={"#FFF"}
                  // selectedDropDownValue={selectedValue}
                  graphName={"graph4"}
                  // placeholder={"Select Trailer"}
                />
              </div>
            </div>
            <Chart
              type={"areaspline"}
              minWidth={chartWidth}
              height={chartHeight}
              dataPoints={updatedEquipmentUtilGraphData}
              format={formatGraph4}
              toolTipShared={false}
              splineWidth={2}
              graphSequence={"graph4"}
              tooltipShow={true}
              isCrosshair={true}
              dataLabels={false}
              tabIdentity={"drivers"}
              pageName={"analyticsPage"}
              xAxisArray={xAxisChartDataGraph4}
              xAxisInterval={xAxisIntervalGraph4}
              graphTitle={graphFourTitle}
              selectedAnalyticListItem={"Overall Analytics"}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <div className={graphSection}>
            {" "}
            <div className={graphHeaderSection}>
              <div className={graphTitle}>Total Goods</div>
              <div className={graphSelect}>
                <Select
                  selectList={selectList}
                  handleSelect={handleSelect}
                  // customWidth={"40%"}
                  customHeight={"40px"}
                  customSelectCustom={graphCustomSelectDropDown}
                  pageName={"analyticsPage"}
                  drowpDownTextColor={"#837DAB"}
                  dropDownBgColor={"#EEE9FF"}
                  dropDownSelectedBgColor={"#9087C8"}
                  dropDownSelectedTextColor={"#FFF"}
                  // selectedDropDownValue={selectedValue}
                  graphName={"graph5"}
                  // placeholder={"Select Trailer"}
                />
              </div>
            </div>
            <Chart
              type={"spline"}
              minWidth={chartWidth}
              height={chartHeight}
              dataPoints={updatedTotalGoodsGraphData}
              format={formatGraph5}
              toolTipShared={false}
              splineWidth={2}
              graphSequence={"graph5"}
              tooltipShow={true}
              isCrosshair={true}
              dataLabels={false}
              tabIdentity={"drivers"}
              pageName={"analyticsPage"}
              xAxisArray={xAxisChartDataGraph5}
              xAxisInterval={xAxisIntervalGraph5}
              graphTitle={graphFiveTitle}
              selectedAnalyticListItem={"Overall Analytics"}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default AnalyticsWarehouse;
