import { makeStyles } from "@mui/styles";
import { padding } from "@mui/system";
import muiTheme from "theme/muiTheme";

const useStyles = makeStyles(() => ({
  customNotificationTabs: (props: any) => ({
    "& .MuiTabs-flexContainer": {
      // borderBottom: "2px solid #3e3e3e",
    },

    "& .MuiButtonBase-root": {
      marginRight: "0 !important",
      minWidth: "64px !important",
      fontSize: "18px",
      fontFamily: `'Nunito Sans', sans-serif !important`,
    },

    "& .MuiTab-root": {
      "&:first-child": {
        marginRight: "0 !important",
        minWidth: "64px !important",
        color: `${props?.palette?.infoDialogueComponent?.darkGrayShades}!important`,
      },
      "&:first-child.Mui-selected": {
        color: `${props?.palette?.infoDialogueComponent?.greenBlackShades}!important`,
      },
      "&:nth-child(2)": {
        marginRight: "0 !important",
        minWidth: "64px !important",
        color: `${props?.palette?.infoDialogueComponent?.darkGrayShades}!important`,
      },
      "&:nth-child(2).Mui-selected": {
        color: `${props?.palette?.infoDialogueComponent?.greenBlackShades}!important`,
      },
    },
    "& .MuiTabs-root .MuiTabs-indicator": {
      background: props?.palette?.infoDialogueComponent?.greenBlackShades,
      height: "3px !important",
      borderRadius: "1.5px",
    },
  }),

  alertsContainerStyle: (props: any) => ({
    // height: "calc(100vh - 660px)",
  }),

  headerStyle: (props: any) => ({
    display: "flex",
    fontWeight: 700,
    fontSize: 20,
    lineHeight: "27px",
    textTransform: "uppercase",
    marginBottom: 30,
  }),

  eventsContainer: (props: any) => ({
    // background: "#4E1DE0",
  }),

  footerStyle: (props: any) => ({
    // background: "#2B2A2A",
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0,
    display: "flex",
    justifyContent: "flex-end",
    padding: "25px",
  }),

  saveButtonStyles: (props: any) => ({
    backgroundColor: `#7E6ECC !important`,
    borderRadius: "15px !important",
    width: "90px !important",
    fontFamily: `'Nunito Sans', sans-serif !important`,
    fontSize: "15px !important",
    lineHeight: "20px !important",
    boxShadow: "none !important",
    fontWeight: "600 !important",
    marginLeft: "15px !important",
  }),

  cancelButtonStyles: (props: any) => ({
    color: "#CBCBCB !important",
    border: "1px solid #CBCBCB !important",
    backgroundColor: `none !important`,
    borderRadius: "15px !important",
    width: "90px !important",
    fontSize: "15px !important",
    fontFamily: `'Nunito Sans', sans-serif !important`,
    lineHeight: "20px !important",
    boxShadow: "none !important",
    fontWeight: "500 !important",
    marginLeft: "15px !important",
  }),

  labelTextArea: (props: any) => ({
    // marginRight: "30px",
    marginTop: "8px",
    background: "#2E2E2E",
    padding: "15px 0px 15px 15px",
    border: "0.5px solid #DDDDDD",
    borderRadius: "6px",
  }),

  inputTextArea: (props: any) => ({
    // marginRight: "30px",
    marginTop: "8px",
    background: "#2E2E2E",
    height: "54%",
  }),

  inputNumericTextArea: (props: any) => ({
    marginTop: "8px",

    height: "66%",
  }),

  inputEnterTextArea: (props: any) => ({
    marginTop: "8px",
   marginBottom: "6px",
  }),

  inputLabelStyle: (props: any) => ({
    marginLeft: "0px",
  }),

  inputStyle: (props: any) => ({
    background: "#000000",
    color: "#fff",
    width: "100%",
    margin: 0,
    fontSize: "16px",
    fontFamily: `'Nunito Sans', sans-serif !important`,
    boxSizing: "border-box",
    border: "0.5px solid #DDDDDD",
    borderRadius: "6px",
    padding: 16,
  }),

  
  numericContainerStyle: (props: any) => ({
    marginBottom: "44px",
  }),
}));

export default useStyles;
