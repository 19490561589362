import { styled } from "@mui/material/styles";

export const RootContainer = styled("div")`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 410px);
  overflow-y: scroll;
`;

export const ItemContainer = styled("div")`
  display: flex;
  align-items: center;
  @media (max-width: 963px) {
    flex-direction: column;
    align-items: inherit;
  }
`;

export const ElementContainer = styled("div")<{ noMargin?: boolean }>`
  flex: 5;
  padding: ${({ noMargin }) => (noMargin ? "0px 10px" : "10px")};
  box-sizing: content-box;
  display: flex;
  /* flex-direction: column; */
`;

export const ElementLabel = styled("div")`
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
`;

export const ElementInput = styled("input")<{ errored?: true }>`
  background: #050505;
  border: 1px solid ${({ errored }) => (errored ? "#F50303" : "#dddddd")};
  border-radius: 6px;
  outline: none;
  padding: 20px;
  width: 100%;
  color: #fff;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const ButtonWrapper = styled("div")`
  flex: 1;
  padding: 10px;
`;

export const ButtonContainer = styled("div")<{ isActive: boolean }>`
  width: 47px;
  height: 47px;
  border: 1px solid #ffffff;
  border-radius: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ isActive }) => (isActive ? "pointer" : "auto")};
  font-size: 40px;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
`;

export const FlexSpace = styled("div")`
  flex: 1;
`;

export const Footer = styled("div")`
  width: 100%;
  height: 78px;
  background: #2b2a2a;
  display: flex;
  justify-content: flex-end;
  padding: 21px 32px;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const SaveButton = styled("div")`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  background: #7e6ecc;
  border-radius: 25px;
  padding: 7px 30px;
  cursor: pointer;
`;

export const CancelButton = styled("div")`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #b5b5b5;
  border: 1.5px solid #cbcbcb;
  border-radius: 25px;
  padding: 7px 30px;
  margin-right: 15px;
  cursor: pointer;
`;
