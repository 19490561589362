import { useState } from "react";
import {
  RootContainer,
  Header,
  FlexSpace,
  Title,
  Content,
  CheckBoxWrapper,
  CheckBoxContainer,
  CheckMark,
  CheckBoxLabel,
  AddButton,
  InfoWindowBackdrop,
  InfoWindowContainer,
  InfoWindowHeader,
  InfoWindowTitle,
  Close,
  InfoDialogContent,
  InfoDialogFooter,
  SaveButton,
  CancelButton,
} from "./styles";
import Button from "../../elements/Button";
import Switch from "react-switch";
import {
  AddButton as Addbutton,
  CheckMark as Checkmark,
  CheckMarkEditable,
} from "../../assets/icons";
import NumberInput from "components/NumberInput/NumberInput";


const ConfigurationListItem = (props: any) => {
  const {
    id,
    title,
    tab,
    list,
    onChange,
    onAdd,
    variant,
    onToggleSwitchChange,
    isEditable,
    hideHeaderRightButton,
    onHeaderRightButtonClick,
    isDisabled,
    pageName,
  } = props;

const isActive = !isDisabled

  if(tab === 1) console.log("TRacker Config", isActive, isDisabled)
  
  const handleChange = (index: number, value: any) => {
    if (tab === 0) {
      const newList = [...list];

      newList[index].isChecked = value;
      onChange(newList);
    } else {
      const newList = [...list];
      newList[index].value = value;
      onChange(newList);
    }
  };

  const onSwitch = () => {
    onToggleSwitchChange(id, tab === 0 ? "alert" : "tracker", isDisabled);
  };

  const dummyFunAddBtn = ()=>{
  }

  return (
    <RootContainer>
      <Header variant={variant}>
        <Switch
          checked={!isDisabled}
          onChange={onSwitch}
          uncheckedIcon={false}
          checkedIcon={false}
        />
        <Title>{title}</Title>
        <FlexSpace />
        {!hideHeaderRightButton && variant !== "dark" && (
          <AddButton
            src={Addbutton}
            onClick={isActive && !isEditable ?  onHeaderRightButtonClick : dummyFunAddBtn}
          />
        )}
      </Header>
      <Content variant={variant}>
        {tab === 0 ? (
          <>
            {list.map((item: any, index: number) => (
              <CheckBoxWrapper
                onClick={() =>
                  isEditable && isActive ? handleChange(index, !item.isChecked) : null
                }
              >
                <CheckBoxContainer
                  isChecked={item.isChecked}
                  isEditable={isEditable}
                >
                  <CheckMark
                    src={isEditable ? CheckMarkEditable : Checkmark}
                    selected={item.isChecked}
                  />
                </CheckBoxContainer>
                <CheckBoxLabel>{item.name}</CheckBoxLabel>
              </CheckBoxWrapper>
            ))}
          </>
        ) : (
          <>
            {list.map((item: any, index: number) => (
              <NumberInput
                isEditable={isEditable}
                isActive={!isDisabled}
                direction="row"
                size="small"
                label={item.label}
                value={item.value}
                valuePrefix={item.prefix}
                valueUnit={item.unit}
                variant={variant}
                onChange={(value: any) => handleChange(index, value)}
                pageName={pageName}
              />
            ))}
            {/* <NumberInput direction="row" size="small" label="Max Temperature" value={250} valuePrefix="<" valueUnit="F" variant={variant} /> */}
          </>
        )}
      </Content>
      {false ? (
        <InfoWindowBackdrop>
          <InfoWindowContainer>
            <InfoWindowHeader>
              <InfoWindowTitle>Add Alert</InfoWindowTitle>
              <Close>X</Close>
            </InfoWindowHeader>
            <InfoDialogContent>Name</InfoDialogContent>
            <InfoDialogFooter>
              <CancelButton>Cancel</CancelButton>
              <SaveButton>Save</SaveButton>
            </InfoDialogFooter>
          </InfoWindowContainer>
        </InfoWindowBackdrop>
      ) : null}
    </RootContainer>
  );
};

// const ConfigurationList = (props: any) => {
//     const {list, tab} = props;

//     return (
//         {
//             list.map((item, index) => (
//                 <ConfigurationListItem title={item.title} tab={tab} id={index}  />
//             ))
//         }
//     )
// }

// <ConfigurationListElement variant="dark" />

export default ConfigurationListItem;
