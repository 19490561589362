import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import theme from "../../theme/theme";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useStyles from "./styles";
import Select from "elements/Select";

interface tabProps {
  initialIndex: number;
  tabsList: any;
  handleTabs: any;
  dashboardNotificationClassName: any;
  pageName?: any;
  isEquipmentDropdown?: any;
  handleSelectEquipmentWareHouse?: any;
  selectListEquipmentWareHouse?: any;
}
const INF_Tabs: React.FC<tabProps> = (props: tabProps) => {
  const {
    initialIndex,
    tabsList,
    handleTabs,
    dashboardNotificationClassName,
    pageName,
    isEquipmentDropdown,
    handleSelectEquipmentWareHouse,
    selectListEquipmentWareHouse,
  } = props;

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const { graphCustomSelectDropDown } = useStyles(appTheme);

  const [value, setValue] = useState<any>();

  const handleChange = (event: any, val: number) => {
    setValue(val);
    handleTabs(val);
  };

  useEffect(() => {
    setValue(initialIndex);
  }, [initialIndex]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={dashboardNotificationClassName}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
          >
            {tabsList.map((item: any, index: number) => (
              <Tab
                key={index}
                value={pageName === "sendConfig" ? item?.val : index}
                label={
                  item?.name === "Equipment" &&
                  isEquipmentDropdown &&
                  pageName === "analytics" ? (
                    <div>
                      <Select
                        variant={"standard"}
                        selectList={selectListEquipmentWareHouse}
                        handleSelect={handleSelectEquipmentWareHouse}
                        // customWidth={"40%"}
                        customHeight={"40px"}
                        customSelectCustom={graphCustomSelectDropDown}
                        pageName={"analyticsTabEquipmentDropdown"}
                        drowpDownTextColor={"#837DAB"}
                        dropDownBgColor={"#EEE9FF"}
                        dropDownSelectedBgColor={"#9087C8"}
                        dropDownSelectedTextColor={"#FFF"}
                        graphName={"graph1"}
                        // selectedDropDownValue={selectedValue}
                        // placeholder={"Select Trailer"}
                      />
                    </div>
                  ) : (
                    <div>{item?.name}</div>
                  )
                }
              />
            ))}
          </Tabs>
        </div>
      </Grid>
    </Grid>
  );
};
export default INF_Tabs;
