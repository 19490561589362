const dashboardViewDetails = [
  {
    id: "wh-01",
    category: "",
    goods: [
      {
        id: "123456",
        title: "High Temperature",
        trackerId: "TID#123",
        area: "Rack - A017, Staging Area",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F",
        totalUnits: "20",
        source: "Sony Corporation of America",
        category: "Electronics",
        make: "Sony",
        dimension: "380mm x 380mm x 380mm",
        timeStamp: "02-06-2023 | 4:30 PM",
        notificationType: "events",
        location: {
          lat: 12.949600757573837,
          lng: 80.1237549523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "circle",
          name: "GeoTID#123",
          coOrdinates: [
            {
              lat: 12.948441308184758,
              lng: 80.12244664550813,
            },
          ],
          radius: 97.80039691791197,
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "123446",
        title: "Tracking disconnected",
        trackerId: "TID#123",
        area: "Rack - A017, Staging Area",
        connectedStatus: "Disconnected",
        battery: "70",
        temperature: "68°F",
        totalUnits: "20",
        source: "Sony Corporation of America",
        category: "Electronics",
        make: "Sony",
        dimension: "380mm x 380mm x 380mm",
        timeStamp: "02-06-2023 | 4:30 PM",
        notificationType: "incidents",
        location: {
          lat: 12.949608757573837,
          lng: 80.1227999523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "circle",
          name: "GeoTID#123",
          coOrdinates: [
            {
              lat: 12.948441308184758,
              lng: 80.12244664550813,
            },
          ],
          radius: 97.80039691791197,
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
    ],
    equipments: [
      {
        id: "1",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "oprAlerts",
        utilization: "70%",
        category: "forklift",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.938374757573837,
          lng: 80.1150049523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#123",
          coOrdinates: [
            { lat: 12.939861154970764, lng: 80.11438235441291 },
            { lat: 12.93989073324904, lng: 80.12460995821989 },
            { lat: 12.938234344263364, lng: 80.12388158287457 },
            { lat: 12.938027294866624, lng: 80.11328979139498 },
          ],
          radius: null,
          outsideGeofence: true,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "2",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Disconnected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "forklift",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.945858757573837,
          lng: 80.1132349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            { lat: 12.947817585338637, lng: 80.11262211399513 },
            { lat: 12.948083781220523, lng: 80.12394228082005 },
            { lat: 12.945421809607097, lng: 80.12312285855657 },
            { lat: 12.945096455571248, lng: 80.1120454835134 },
          ],
          radius: null,
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "3",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletJack",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.948098757573837,
          lng: 80.1213349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            { lat: 12.949651373423789, lng: 80.11911679415739 },
            { lat: 12.949651373423789, lng: 80.1325917380455 },
            { lat: 12.948438708620703, lng: 80.13186336270023 },
            { lat: 12.948468285881251, lng: 80.11699236606691 },
          ],
          radius: null,
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "4",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletCart",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.939558757573837,
          lng: 80.1260349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "5",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "15",
        temperature: "68°F ",
        notificationType: "incidents",
        utilization: "70%",
        category: "palletCart",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.944288757573837,
          lng: 80.1247549523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            { lat: 12.945599275265849, lng: 80.12033075306624 },
            { lat: 12.946249981600227, lng: 80.13368430106348 },
            { lat: 12.944268279745524, lng: 80.13268278496366 },
            { lat: 12.943735879547482, lng: 80.1188133044302 },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "6",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletJack",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940578757573837,
          lng: 80.1129349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            { lat: 12.942641497791941, lng: 80.10998175336839 },
            { lat: 12.942523185963049, lng: 80.12491344794712 },
            { lat: 12.940304828776396, lng: 80.12372983801099 },
            { lat: 12.940393563442816, lng: 80.10989070645023 },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "7",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletTruck",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.946228757573837,
          lng: 80.1297049523929,
        },
        packagaeData: [
          {
            packageStage: "",
            timeStamp: "",
            status: "",
            packagaeData: [
              {
                packageStage: "Package Picked Up",
                timeStamp: "1-11-2023 9.00AM",
                status: "",
              },
              {
                packageStage: "Initial Scan",
                timeStamp: "1-11-2023 10.30AM",
                status: "",
              },
              {
                packageStage: "Staging & Tagging",
                timeStamp: "1-11-2023 12.30PM",
                status: "",
              },
              {
                packageStage: "Putaway",
                timeStamp: "1-11-2023 1.30PM",
                status: "",
              },
              {
                packageStage: "Connected",
                timeStamp: "1-11-2023 1.35PM",
                status: "",
              },
            ],
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            { lat: 12.948320399543475, lng: 80.12260692602031 },
            { lat: 12.948438708620703, lng: 80.13632466169021 },
            { lat: 12.946427446669317, lng: 80.13589977607212 },
            { lat: 12.946279559120512, lng: 80.12190889964774 },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
    ],
    accessPoints: [
      {
        id: "ap-1",
        title: "AP#12 #1",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.949958757573837,
          lng: 80.1145349523929,
        },
      },
      {
        id: "ap-2",
        title: "AP#12 #2",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.949958757573837,
          lng: 80.1167349523929,
        },
      },
      {
        id: "ap-3",
        title: "AP#12 #3",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.948298757573837,
          lng: 80.1126649523929,
        },
      },
      {
        id: "ap-4",
        title: "AP#12 #4",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.939970757573837,
          lng: 80.1145349523929,
        },
      },
      {
        id: "ap-5",
        title: "AP#12 #5",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.948088757573837,
          lng: 80.1230349523929,
        },
      },
      {
        id: "ap-6",
        title: "AP#12 #6",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.943880000003837,
          lng: 80.1245349523929,
        },
      },
      {
        id: "ap-7",
        title: "AP#12 #7",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.943880000003837,
          lng: 80.1295349523929,
        },
      },

      {
        id: "ap-8",
        title: "AP#12 #8",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.943880000003837,
          lng: 80.1362099523929,
        },
      },
      {
        id: "ap-9",
        title: "AP#12 #9",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940588757573837,
          lng: 80.1269999523929,
        },
      },
      {
        id: "ap-10",
        title: "AP#12 #10",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940588757573837,
          lng: 80.1312999523929,
        },
      },
      {
        id: "ap-11",
        title: "AP#12 #11",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940588757573837,
          lng: 80.1360999523929,
        },
      },
    ],
  },
  {
    id: "wh-02",
    category: "",
    goods: [
      {
        id: "123456",
        title: "High Temperature",
        trackerId: "TID#123",
        area: "Rack - A017, Staging Area",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F",
        totalUnits: "20",
        source: "Sony Corporation of America",
        category: "Electronics",
        make: "Sony",
        dimension: "380mm x 380mm x 380mm",
        timeStamp: "02-06-2023 | 4:30 PM",
        notificationType: "events",
        location: {
          lat: 12.949600757573837,
          lng: 80.1237549523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "circle",
          name: "GeoTID#123",
          coOrdinates: [
            {
              lat: 12.948441308184758,
              lng: 80.12244664550813,
            },
          ],
          radius: 97.80039691791197,
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "123446",
        title: "Tracking disconnected",
        trackerId: "TID#123",
        area: "Rack - A017, Staging Area",
        connectedStatus: "Disconnected",
        battery: "70",
        temperature: "68°F",
        totalUnits: "20",
        source: "Sony Corporation of America",
        category: "Electronics",
        make: "Sony",
        dimension: "380mm x 380mm x 380mm",
        timeStamp: "02-06-2023 | 4:30 PM",
        notificationType: "incidents",
        location: {
          lat: 12.949608757573837,
          lng: 80.1227999523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "circle",
          name: "GeoTID#123",
          coOrdinates: [
            {
              lat: 12.948441308184758,
              lng: 80.12244664550813,
            },
          ],
          radius: 97.80039691791197,
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
    ],
    equipments: [
      {
        id: "1",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "oprAlerts",
        utilization: "70%",
        category: "forklift",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.938374757573837,
          lng: 80.1150049523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "circle",
          name: "GeoTID#123",
          coOrdinates: [
            {
              lat: 12.948441308184758,
              lng: 80.12244664550813,
            },
          ],
          radius: 97.80039691791197,
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "2",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Disconnected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "forklift",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.945858757573837,
          lng: 80.1132349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "3",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletJack",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.948098757573837,
          lng: 80.1213349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
    ],
    accessPoints: [
      {
        id: "ap-1",
        title: "AP#12 #1",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.949958757573837,
          lng: 80.1145349523929,
        },
      },
      {
        id: "ap-2",
        title: "AP#12 #2",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.949958757573837,
          lng: 80.1167349523929,
        },
      },
      {
        id: "ap-3",
        title: "AP#12 #3",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.948298757573837,
          lng: 80.1126649523929,
        },
      },
      {
        id: "ap-4",
        title: "AP#12 #4",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.939970757573837,
          lng: 80.1145349523929,
        },
      },
      {
        id: "ap-5",
        title: "AP#12 #5",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.948088757573837,
          lng: 80.1230349523929,
        },
      },
      {
        id: "ap-6",
        title: "AP#12 #6",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.943880000003837,
          lng: 80.1245349523929,
        },
      },
      {
        id: "ap-7",
        title: "AP#12 #7",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.943880000003837,
          lng: 80.1295349523929,
        },
      },

      // {
      //   id: "ap-8",
      //   title: "AP#12 #8",
      //   area: "Picked Order Staging Area, Warehouse#1",
      //   connectedStatus: "Connected",
      //   category: "Forklift",
      //   noOfConnectedLabels: "100",
      //   timeStamp: "02-06-2023 | 4:30 PM",
      //   location: {
      //     lat: 12.943880000003837,
      //     lng: 80.1362099523929,
      //   },
      // },
      // {
      //   id: "ap-9",
      //   title: "AP#12 #9",
      //   area: "Picked Order Staging Area, Warehouse#1",
      //   connectedStatus: "Connected",
      //   category: "Forklift",
      //   noOfConnectedLabels: "100",
      //   timeStamp: "02-06-2023 | 4:30 PM",
      //   location: {
      //     lat: 12.940588757573837,
      //     lng: 80.1269999523929,
      //   },
      // },
      // {
      //   id: "ap-10",
      //   title: "AP#12 #10",
      //   area: "Picked Order Staging Area, Warehouse#1",
      //   connectedStatus: "Connected",
      //   category: "Forklift",
      //   noOfConnectedLabels: "100",
      //   timeStamp: "02-06-2023 | 4:30 PM",
      //   location: {
      //     lat: 12.940588757573837,
      //     lng: 80.1312999523929,
      //   },
      // },
      // {
      //   id: "ap-11",
      //   title: "AP#12 #11",
      //   area: "Picked Order Staging Area, Warehouse#1",
      //   connectedStatus: "Connected",
      //   category: "Forklift",
      //   noOfConnectedLabels: "100",
      //   timeStamp: "02-06-2023 | 4:30 PM",
      //   location: {
      //     lat: 12.940588757573837,
      //     lng: 80.1360999523929,
      //   },
      // },
    ],
  },
  {
    id: "wh-03",
    category: "",
    goods: [
      {
        id: "123456",
        title: "High Temperature",
        trackerId: "TID#123",
        area: "Rack - A017, Staging Area",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F",
        totalUnits: "20",
        source: "Sony Corporation of America",
        category: "Electronics",
        make: "Sony",
        dimension: "380mm x 380mm x 380mm",
        timeStamp: "02-06-2023 | 4:30 PM",
        notificationType: "events",
        location: {
          lat: 12.949600757573837,
          lng: 80.1237549523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "circle",
          name: "GeoTID#123",
          coOrdinates: [
            {
              lat: 12.948441308184758,
              lng: 80.12244664550813,
            },
          ],
          radius: 97.80039691791197,
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "123446",
        title: "Tracking disconnected",
        trackerId: "TID#123",
        area: "Rack - A017, Staging Area",
        connectedStatus: "Disconnected",
        battery: "70",
        temperature: "68°F",
        totalUnits: "20",
        source: "Sony Corporation of America",
        category: "Electronics",
        make: "Sony",
        dimension: "380mm x 380mm x 380mm",
        timeStamp: "02-06-2023 | 4:30 PM",
        notificationType: "incidents",
        location: {
          lat: 12.949608757573837,
          lng: 80.1227999523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "circle",
          name: "GeoTID#123",
          coOrdinates: [
            {
              lat: 12.948441308184758,
              lng: 80.12244664550813,
            },
          ],
          radius: 97.80039691791197,
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
    ],
    equipments: [
      {
        id: "1",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "oprAlerts",
        utilization: "70%",
        category: "forklift",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.938374757573837,
          lng: 80.1150049523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "circle",
          name: "GeoTID#123",
          coOrdinates: [
            {
              lat: 12.948441308184758,
              lng: 80.12244664550813,
            },
          ],
          radius: 97.80039691791197,
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "2",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Disconnected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "forklift",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.945858757573837,
          lng: 80.1132349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "3",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletJack",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.948098757573837,
          lng: 80.1213349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "4",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletCart",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.939558757573837,
          lng: 80.1260349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "5",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Disconnected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "incidents",
        utilization: "70%",
        category: "palletCart",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.944288757573837,
          lng: 80.1247549523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "6",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletJack",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940578757573837,
          lng: 80.1129349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "7",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletTruck",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.946228757573837,
          lng: 80.1297049523929,
        },
        packagaeData: [
          {
            packageStage: "",
            timeStamp: "",
            status: "",
            packagaeData: [
              {
                packageStage: "Package Picked Up",
                timeStamp: "1-11-2023 9.00AM",
                status: "",
              },
              {
                packageStage: "Initial Scan",
                timeStamp: "1-11-2023 10.30AM",
                status: "",
              },
              {
                packageStage: "Staging & Tagging",
                timeStamp: "1-11-2023 12.30PM",
                status: "",
              },
              {
                packageStage: "Putaway",
                timeStamp: "1-11-2023 1.30PM",
                status: "",
              },
              {
                packageStage: "Connected",
                timeStamp: "1-11-2023 1.35PM",
                status: "",
              },
            ],
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
    ],
    accessPoints: [
      {
        id: "ap-1",
        title: "AP#12 #1",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.949958757573837,
          lng: 80.1145349523929,
        },
      },
      {
        id: "ap-2",
        title: "AP#12 #2",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.949958757573837,
          lng: 80.1167349523929,
        },
      },
      {
        id: "ap-3",
        title: "AP#12 #3",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.948298757573837,
          lng: 80.1126649523929,
        },
      },
      {
        id: "ap-4",
        title: "AP#12 #4",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.939970757573837,
          lng: 80.1145349523929,
        },
      },
      {
        id: "ap-5",
        title: "AP#12 #5",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.948088757573837,
          lng: 80.1230349523929,
        },
      },
      {
        id: "ap-6",
        title: "AP#12 #6",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.943880000003837,
          lng: 80.1245349523929,
        },
      },
      {
        id: "ap-7",
        title: "AP#12 #7",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.943880000003837,
          lng: 80.1295349523929,
        },
      },

      {
        id: "ap-8",
        title: "AP#12 #8",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.943880000003837,
          lng: 80.1362099523929,
        },
      },
      {
        id: "ap-9",
        title: "AP#12 #9",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940588757573837,
          lng: 80.1269999523929,
        },
      },
      {
        id: "ap-10",
        title: "AP#12 #10",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940588757573837,
          lng: 80.1312999523929,
        },
      },
      {
        id: "ap-11",
        title: "AP#12 #11",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940588757573837,
          lng: 80.1360999523929,
        },
      },
    ],
  },
  {
    id: "wh-04",
    category: "",
    goods: [
      {
        id: "123456",
        title: "High Temperature",
        trackerId: "TID#123",
        area: "Rack - A017, Staging Area",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F",
        totalUnits: "20",
        source: "Sony Corporation of America",
        category: "Electronics",
        make: "Sony",
        dimension: "380mm x 380mm x 380mm",
        timeStamp: "02-06-2023 | 4:30 PM",
        notificationType: "events",
        location: {
          lat: 12.949600757573837,
          lng: 80.1237549523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "circle",
          name: "GeoTID#123",
          coOrdinates: [
            {
              lat: 12.948441308184758,
              lng: 80.12244664550813,
            },
          ],
          radius: 97.80039691791197,
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "123446",
        title: "Tracking disconnected",
        trackerId: "TID#123",
        area: "Rack - A017, Staging Area",
        connectedStatus: "Disconnected",
        battery: "70",
        temperature: "68°F",
        totalUnits: "20",
        source: "Sony Corporation of America",
        category: "Electronics",
        make: "Sony",
        dimension: "380mm x 380mm x 380mm",
        timeStamp: "02-06-2023 | 4:30 PM",
        notificationType: "incidents",
        location: {
          lat: 12.949608757573837,
          lng: 80.1227999523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "circle",
          name: "GeoTID#123",
          coOrdinates: [
            {
              lat: 12.948441308184758,
              lng: 80.12244664550813,
            },
          ],
          radius: 97.80039691791197,
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
    ],
    equipments: [
      {
        id: "1",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "oprAlerts",
        utilization: "70%",
        category: "forklift",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.938374757573837,
          lng: 80.1150049523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "circle",
          name: "GeoTID#123",
          coOrdinates: [
            {
              lat: 12.948441308184758,
              lng: 80.12244664550813,
            },
          ],
          radius: 97.80039691791197,
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "2",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Disconnected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "forklift",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.945858757573837,
          lng: 80.1132349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "3",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletJack",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.948098757573837,
          lng: 80.1213349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "4",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletCart",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.939558757573837,
          lng: 80.1260349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "5",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Disconnected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "incidents",
        utilization: "70%",
        category: "palletCart",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.944288757573837,
          lng: 80.1247549523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "6",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletJack",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940578757573837,
          lng: 80.1129349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "7",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletTruck",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.946228757573837,
          lng: 80.1297049523929,
        },
        packagaeData: [
          {
            packageStage: "",
            timeStamp: "",
            status: "",
            packagaeData: [
              {
                packageStage: "Package Picked Up",
                timeStamp: "1-11-2023 9.00AM",
                status: "",
              },
              {
                packageStage: "Initial Scan",
                timeStamp: "1-11-2023 10.30AM",
                status: "",
              },
              {
                packageStage: "Staging & Tagging",
                timeStamp: "1-11-2023 12.30PM",
                status: "",
              },
              {
                packageStage: "Putaway",
                timeStamp: "1-11-2023 1.30PM",
                status: "",
              },
              {
                packageStage: "Connected",
                timeStamp: "1-11-2023 1.35PM",
                status: "",
              },
            ],
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
    ],
    accessPoints: [
      {
        id: "ap-1",
        title: "AP#12 #1",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.949958757573837,
          lng: 80.1145349523929,
        },
      },
      {
        id: "ap-2",
        title: "AP#12 #2",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.949958757573837,
          lng: 80.1167349523929,
        },
      },
      {
        id: "ap-3",
        title: "AP#12 #3",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.948298757573837,
          lng: 80.1126649523929,
        },
      },
      {
        id: "ap-4",
        title: "AP#12 #4",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.939970757573837,
          lng: 80.1145349523929,
        },
      },
      {
        id: "ap-5",
        title: "AP#12 #5",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.948088757573837,
          lng: 80.1230349523929,
        },
      },
      {
        id: "ap-6",
        title: "AP#12 #6",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.943880000003837,
          lng: 80.1245349523929,
        },
      },
      {
        id: "ap-7",
        title: "AP#12 #7",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.943880000003837,
          lng: 80.1295349523929,
        },
      },

      {
        id: "ap-8",
        title: "AP#12 #8",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.943880000003837,
          lng: 80.1362099523929,
        },
      },
      {
        id: "ap-9",
        title: "AP#12 #9",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940588757573837,
          lng: 80.1269999523929,
        },
      },
      {
        id: "ap-10",
        title: "AP#12 #10",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940588757573837,
          lng: 80.1312999523929,
        },
      },
      {
        id: "ap-11",
        title: "AP#12 #11",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940588757573837,
          lng: 80.1360999523929,
        },
      },
    ],
  },
  {
    id: "wh-05",
    category: "",
    goods: [
      {
        id: "123456",
        title: "High Temperature",
        trackerId: "TID#123",
        area: "Rack - A017, Staging Area",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F",
        totalUnits: "20",
        source: "Sony Corporation of America",
        category: "Electronics",
        make: "Sony",
        dimension: "380mm x 380mm x 380mm",
        timeStamp: "02-06-2023 | 4:30 PM",
        notificationType: "events",
        location: {
          lat: 12.949600757573837,
          lng: 80.1237549523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "circle",
          name: "GeoTID#123",
          coOrdinates: [
            {
              lat: 12.948441308184758,
              lng: 80.12244664550813,
            },
          ],
          radius: 97.80039691791197,
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "123446",
        title: "Tracking disconnected",
        trackerId: "TID#123",
        area: "Rack - A017, Staging Area",
        connectedStatus: "Disconnected",
        battery: "70",
        temperature: "68°F",
        totalUnits: "20",
        source: "Sony Corporation of America",
        category: "Electronics",
        make: "Sony",
        dimension: "380mm x 380mm x 380mm",
        timeStamp: "02-06-2023 | 4:30 PM",
        notificationType: "incidents",
        location: {
          lat: 12.949608757573837,
          lng: 80.1227999523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "circle",
          name: "GeoTID#123",
          coOrdinates: [
            {
              lat: 12.948441308184758,
              lng: 80.12244664550813,
            },
          ],
          radius: 97.80039691791197,
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
    ],
    equipments: [
      {
        id: "1",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "oprAlerts",
        utilization: "70%",
        category: "forklift",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.938374757573837,
          lng: 80.1150049523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "circle",
          name: "GeoTID#123",
          coOrdinates: [
            {
              lat: 12.948441308184758,
              lng: 80.12244664550813,
            },
          ],
          radius: 97.80039691791197,
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "2",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Disconnected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "forklift",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.945858757573837,
          lng: 80.1132349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "3",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletJack",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.948098757573837,
          lng: 80.1213349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "4",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletCart",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.939558757573837,
          lng: 80.1260349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "5",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Disconnected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "incidents",
        utilization: "70%",
        category: "palletCart",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.944288757573837,
          lng: 80.1247549523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "6",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletJack",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940578757573837,
          lng: 80.1129349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "7",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletTruck",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.946228757573837,
          lng: 80.1297049523929,
        },
        packagaeData: [
          {
            packageStage: "",
            timeStamp: "",
            status: "",
            packagaeData: [
              {
                packageStage: "Package Picked Up",
                timeStamp: "1-11-2023 9.00AM",
                status: "",
              },
              {
                packageStage: "Initial Scan",
                timeStamp: "1-11-2023 10.30AM",
                status: "",
              },
              {
                packageStage: "Staging & Tagging",
                timeStamp: "1-11-2023 12.30PM",
                status: "",
              },
              {
                packageStage: "Putaway",
                timeStamp: "1-11-2023 1.30PM",
                status: "",
              },
              {
                packageStage: "Connected",
                timeStamp: "1-11-2023 1.35PM",
                status: "",
              },
            ],
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
    ],
    accessPoints: [
      {
        id: "ap-1",
        title: "AP#12 #1",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.949958757573837,
          lng: 80.1145349523929,
        },
      },
      {
        id: "ap-2",
        title: "AP#12 #2",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.949958757573837,
          lng: 80.1167349523929,
        },
      },
      {
        id: "ap-3",
        title: "AP#12 #3",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.948298757573837,
          lng: 80.1126649523929,
        },
      },
      {
        id: "ap-4",
        title: "AP#12 #4",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.939970757573837,
          lng: 80.1145349523929,
        },
      },
      {
        id: "ap-5",
        title: "AP#12 #5",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.948088757573837,
          lng: 80.1230349523929,
        },
      },
      {
        id: "ap-6",
        title: "AP#12 #6",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.943880000003837,
          lng: 80.1245349523929,
        },
      },
      {
        id: "ap-7",
        title: "AP#12 #7",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.943880000003837,
          lng: 80.1295349523929,
        },
      },

      {
        id: "ap-8",
        title: "AP#12 #8",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.943880000003837,
          lng: 80.1362099523929,
        },
      },
      {
        id: "ap-9",
        title: "AP#12 #9",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940588757573837,
          lng: 80.1269999523929,
        },
      },
      {
        id: "ap-10",
        title: "AP#12 #10",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940588757573837,
          lng: 80.1312999523929,
        },
      },
      {
        id: "ap-11",
        title: "AP#12 #11",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940588757573837,
          lng: 80.1360999523929,
        },
      },
    ],
  },
  {
    id: "wh-06",
    category: "",
    goods: [
      {
        id: "123456",
        title: "High Temperature",
        trackerId: "TID#123",
        area: "Rack - A017, Staging Area",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F",
        totalUnits: "20",
        source: "Sony Corporation of America",
        category: "Electronics",
        make: "Sony",
        dimension: "380mm x 380mm x 380mm",
        timeStamp: "02-06-2023 | 4:30 PM",
        notificationType: "events",
        location: {
          lat: 12.949600757573837,
          lng: 80.1237549523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "circle",
          name: "GeoTID#123",
          coOrdinates: [
            {
              lat: 12.948441308184758,
              lng: 80.12244664550813,
            },
          ],
          radius: 97.80039691791197,
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "123446",
        title: "Tracking disconnected",
        trackerId: "TID#123",
        area: "Rack - A017, Staging Area",
        connectedStatus: "Disconnected",
        battery: "70",
        temperature: "68°F",
        totalUnits: "20",
        source: "Sony Corporation of America",
        category: "Electronics",
        make: "Sony",
        dimension: "380mm x 380mm x 380mm",
        timeStamp: "02-06-2023 | 4:30 PM",
        notificationType: "incidents",
        location: {
          lat: 12.949608757573837,
          lng: 80.1227999523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "circle",
          name: "GeoTID#123",
          coOrdinates: [
            {
              lat: 12.948441308184758,
              lng: 80.12244664550813,
            },
          ],
          radius: 97.80039691791197,
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
    ],
    equipments: [
      {
        id: "1",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "oprAlerts",
        utilization: "70%",
        category: "forklift",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.938374757573837,
          lng: 80.1150049523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "circle",
          name: "GeoTID#123",
          coOrdinates: [
            {
              lat: 12.948441308184758,
              lng: 80.12244664550813,
            },
          ],
          radius: 97.80039691791197,
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "2",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Disconnected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "forklift",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.945858757573837,
          lng: 80.1132349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "3",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletJack",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.948098757573837,
          lng: 80.1213349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "4",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletCart",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.939558757573837,
          lng: 80.1260349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "5",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Disconnected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "incidents",
        utilization: "70%",
        category: "palletCart",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.944288757573837,
          lng: 80.1247549523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "6",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletJack",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940578757573837,
          lng: 80.1129349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "7",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletTruck",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.946228757573837,
          lng: 80.1297049523929,
        },
        packagaeData: [
          {
            packageStage: "",
            timeStamp: "",
            status: "",
            packagaeData: [
              {
                packageStage: "Package Picked Up",
                timeStamp: "1-11-2023 9.00AM",
                status: "",
              },
              {
                packageStage: "Initial Scan",
                timeStamp: "1-11-2023 10.30AM",
                status: "",
              },
              {
                packageStage: "Staging & Tagging",
                timeStamp: "1-11-2023 12.30PM",
                status: "",
              },
              {
                packageStage: "Putaway",
                timeStamp: "1-11-2023 1.30PM",
                status: "",
              },
              {
                packageStage: "Connected",
                timeStamp: "1-11-2023 1.35PM",
                status: "",
              },
            ],
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
    ],
    accessPoints: [
      {
        id: "ap-1",
        title: "AP#12 #1",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.949958757573837,
          lng: 80.1145349523929,
        },
      },
      {
        id: "ap-2",
        title: "AP#12 #2",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.949958757573837,
          lng: 80.1167349523929,
        },
      },
      {
        id: "ap-3",
        title: "AP#12 #3",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.948298757573837,
          lng: 80.1126649523929,
        },
      },
      {
        id: "ap-4",
        title: "AP#12 #4",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.939970757573837,
          lng: 80.1145349523929,
        },
      },
      {
        id: "ap-5",
        title: "AP#12 #5",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.948088757573837,
          lng: 80.1230349523929,
        },
      },
      {
        id: "ap-6",
        title: "AP#12 #6",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.943880000003837,
          lng: 80.1245349523929,
        },
      },
      {
        id: "ap-7",
        title: "AP#12 #7",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.943880000003837,
          lng: 80.1295349523929,
        },
      },

      {
        id: "ap-8",
        title: "AP#12 #8",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.943880000003837,
          lng: 80.1362099523929,
        },
      },
      {
        id: "ap-9",
        title: "AP#12 #9",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940588757573837,
          lng: 80.1269999523929,
        },
      },
      {
        id: "ap-10",
        title: "AP#12 #10",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940588757573837,
          lng: 80.1312999523929,
        },
      },
      {
        id: "ap-11",
        title: "AP#12 #11",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940588757573837,
          lng: 80.1360999523929,
        },
      },
    ],
  },
  {
    id: "wh-07",
    category: "",
    goods: [
      {
        id: "123456",
        title: "High Temperature",
        trackerId: "TID#123",
        area: "Rack - A017, Staging Area",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F",
        totalUnits: "20",
        source: "Sony Corporation of America",
        category: "Electronics",
        make: "Sony",
        dimension: "380mm x 380mm x 380mm",
        timeStamp: "02-06-2023 | 4:30 PM",
        notificationType: "events",
        location: {
          lat: 12.949600757573837,
          lng: 80.1237549523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "circle",
          name: "GeoTID#123",
          coOrdinates: [
            {
              lat: 12.948441308184758,
              lng: 80.12244664550813,
            },
          ],
          radius: 97.80039691791197,
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "123446",
        title: "Tracking disconnected",
        trackerId: "TID#123",
        area: "Rack - A017, Staging Area",
        connectedStatus: "Disconnected",
        battery: "70",
        temperature: "68°F",
        totalUnits: "20",
        source: "Sony Corporation of America",
        category: "Electronics",
        make: "Sony",
        dimension: "380mm x 380mm x 380mm",
        timeStamp: "02-06-2023 | 4:30 PM",
        notificationType: "incidents",
        location: {
          lat: 12.949608757573837,
          lng: 80.1227999523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "circle",
          name: "GeoTID#123",
          coOrdinates: [
            {
              lat: 12.948441308184758,
              lng: 80.12244664550813,
            },
          ],
          radius: 97.80039691791197,
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
    ],
    equipments: [
      {
        id: "1",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "oprAlerts",
        utilization: "70%",
        category: "forklift",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.938374757573837,
          lng: 80.1150049523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "circle",
          name: "GeoTID#123",
          coOrdinates: [
            {
              lat: 12.948441308184758,
              lng: 80.12244664550813,
            },
          ],
          radius: 97.80039691791197,
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "2",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Disconnected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "forklift",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.945858757573837,
          lng: 80.1132349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "3",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletJack",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.948098757573837,
          lng: 80.1213349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "4",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletCart",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.939558757573837,
          lng: 80.1260349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "5",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Disconnected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "incidents",
        utilization: "70%",
        category: "palletCart",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.944288757573837,
          lng: 80.1247549523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "6",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletJack",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940578757573837,
          lng: 80.1129349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "7",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletTruck",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.946228757573837,
          lng: 80.1297049523929,
        },
        packagaeData: [
          {
            packageStage: "",
            timeStamp: "",
            status: "",
            packagaeData: [
              {
                packageStage: "Package Picked Up",
                timeStamp: "1-11-2023 9.00AM",
                status: "",
              },
              {
                packageStage: "Initial Scan",
                timeStamp: "1-11-2023 10.30AM",
                status: "",
              },
              {
                packageStage: "Staging & Tagging",
                timeStamp: "1-11-2023 12.30PM",
                status: "",
              },
              {
                packageStage: "Putaway",
                timeStamp: "1-11-2023 1.30PM",
                status: "",
              },
              {
                packageStage: "Connected",
                timeStamp: "1-11-2023 1.35PM",
                status: "",
              },
            ],
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
    ],
    accessPoints: [
      {
        id: "ap-1",
        title: "AP#12 #1",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.949958757573837,
          lng: 80.1145349523929,
        },
      },
      {
        id: "ap-2",
        title: "AP#12 #2",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.949958757573837,
          lng: 80.1167349523929,
        },
      },
      {
        id: "ap-3",
        title: "AP#12 #3",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.948298757573837,
          lng: 80.1126649523929,
        },
      },
      {
        id: "ap-4",
        title: "AP#12 #4",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.939970757573837,
          lng: 80.1145349523929,
        },
      },
      {
        id: "ap-5",
        title: "AP#12 #5",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.948088757573837,
          lng: 80.1230349523929,
        },
      },
      {
        id: "ap-6",
        title: "AP#12 #6",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.943880000003837,
          lng: 80.1245349523929,
        },
      },
      {
        id: "ap-7",
        title: "AP#12 #7",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.943880000003837,
          lng: 80.1295349523929,
        },
      },

      {
        id: "ap-8",
        title: "AP#12 #8",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.943880000003837,
          lng: 80.1362099523929,
        },
      },
      {
        id: "ap-9",
        title: "AP#12 #9",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940588757573837,
          lng: 80.1269999523929,
        },
      },
      {
        id: "ap-10",
        title: "AP#12 #10",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940588757573837,
          lng: 80.1312999523929,
        },
      },
      {
        id: "ap-11",
        title: "AP#12 #11",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940588757573837,
          lng: 80.1360999523929,
        },
      },
    ],
  },
  {
    id: "wh-08",
    category: "",
    goods: [
      {
        id: "123456",
        title: "High Temperature",
        trackerId: "TID#123",
        area: "Rack - A017, Staging Area",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F",
        totalUnits: "20",
        source: "Sony Corporation of America",
        category: "Electronics",
        make: "Sony",
        dimension: "380mm x 380mm x 380mm",
        timeStamp: "02-06-2023 | 4:30 PM",
        notificationType: "events",
        location: {
          lat: 12.949600757573837,
          lng: 80.1237549523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "circle",
          name: "GeoTID#123",
          coOrdinates: [
            {
              lat: 12.948441308184758,
              lng: 80.12244664550813,
            },
          ],
          radius: 97.80039691791197,
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "123446",
        title: "Tracking disconnected",
        trackerId: "TID#123",
        area: "Rack - A017, Staging Area",
        connectedStatus: "Disconnected",
        battery: "70",
        temperature: "68°F",
        totalUnits: "20",
        source: "Sony Corporation of America",
        category: "Electronics",
        make: "Sony",
        dimension: "380mm x 380mm x 380mm",
        timeStamp: "02-06-2023 | 4:30 PM",
        notificationType: "incidents",
        location: {
          lat: 12.949608757573837,
          lng: 80.1227999523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "circle",
          name: "GeoTID#123",
          coOrdinates: [
            {
              lat: 12.948441308184758,
              lng: 80.12244664550813,
            },
          ],
          radius: 97.80039691791197,
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
    ],
    equipments: [
      {
        id: "1",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "oprAlerts",
        utilization: "70%",
        category: "forklift",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.938374757573837,
          lng: 80.1150049523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "circle",
          name: "GeoTID#123",
          coOrdinates: [
            {
              lat: 12.948441308184758,
              lng: 80.12244664550813,
            },
          ],
          radius: 97.80039691791197,
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "2",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Disconnected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "forklift",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.945858757573837,
          lng: 80.1132349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "3",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletJack",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.948098757573837,
          lng: 80.1213349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "4",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletCart",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.939558757573837,
          lng: 80.1260349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "5",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Disconnected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "incidents",
        utilization: "70%",
        category: "palletCart",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.944288757573837,
          lng: 80.1247549523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "6",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletJack",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940578757573837,
          lng: 80.1129349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "7",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletTruck",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.946228757573837,
          lng: 80.1297049523929,
        },
        packagaeData: [
          {
            packageStage: "",
            timeStamp: "",
            status: "",
            packagaeData: [
              {
                packageStage: "Package Picked Up",
                timeStamp: "1-11-2023 9.00AM",
                status: "",
              },
              {
                packageStage: "Initial Scan",
                timeStamp: "1-11-2023 10.30AM",
                status: "",
              },
              {
                packageStage: "Staging & Tagging",
                timeStamp: "1-11-2023 12.30PM",
                status: "",
              },
              {
                packageStage: "Putaway",
                timeStamp: "1-11-2023 1.30PM",
                status: "",
              },
              {
                packageStage: "Connected",
                timeStamp: "1-11-2023 1.35PM",
                status: "",
              },
            ],
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
    ],
    accessPoints: [
      {
        id: "ap-1",
        title: "AP#12 #1",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.949958757573837,
          lng: 80.1145349523929,
        },
      },
      {
        id: "ap-2",
        title: "AP#12 #2",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.949958757573837,
          lng: 80.1167349523929,
        },
      },
      {
        id: "ap-3",
        title: "AP#12 #3",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.948298757573837,
          lng: 80.1126649523929,
        },
      },
      {
        id: "ap-4",
        title: "AP#12 #4",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.939970757573837,
          lng: 80.1145349523929,
        },
      },
      {
        id: "ap-5",
        title: "AP#12 #5",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.948088757573837,
          lng: 80.1230349523929,
        },
      },
      {
        id: "ap-6",
        title: "AP#12 #6",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.943880000003837,
          lng: 80.1245349523929,
        },
      },
      {
        id: "ap-7",
        title: "AP#12 #7",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.943880000003837,
          lng: 80.1295349523929,
        },
      },

      {
        id: "ap-8",
        title: "AP#12 #8",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.943880000003837,
          lng: 80.1362099523929,
        },
      },
      {
        id: "ap-9",
        title: "AP#12 #9",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940588757573837,
          lng: 80.1269999523929,
        },
      },
      {
        id: "ap-10",
        title: "AP#12 #10",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940588757573837,
          lng: 80.1312999523929,
        },
      },
      {
        id: "ap-11",
        title: "AP#12 #11",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940588757573837,
          lng: 80.1360999523929,
        },
      },
    ],
  },
  {
    id: "wh-09",
    category: "",
    goods: [
      {
        id: "123456",
        title: "High Temperature",
        trackerId: "TID#123",
        area: "Rack - A017, Staging Area",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F",
        totalUnits: "20",
        source: "Sony Corporation of America",
        category: "Electronics",
        make: "Sony",
        dimension: "380mm x 380mm x 380mm",
        timeStamp: "02-06-2023 | 4:30 PM",
        notificationType: "events",
        location: {
          lat: 12.949600757573837,
          lng: 80.1237549523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "circle",
          name: "GeoTID#123",
          coOrdinates: [
            {
              lat: 12.948441308184758,
              lng: 80.12244664550813,
            },
          ],
          radius: 97.80039691791197,
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "123446",
        title: "Tracking disconnected",
        trackerId: "TID#123",
        area: "Rack - A017, Staging Area",
        connectedStatus: "Disconnected",
        battery: "70",
        temperature: "68°F",
        totalUnits: "20",
        source: "Sony Corporation of America",
        category: "Electronics",
        make: "Sony",
        dimension: "380mm x 380mm x 380mm",
        timeStamp: "02-06-2023 | 4:30 PM",
        notificationType: "incidents",
        location: {
          lat: 12.949608757573837,
          lng: 80.1227999523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "circle",
          name: "GeoTID#123",
          coOrdinates: [
            {
              lat: 12.948441308184758,
              lng: 80.12244664550813,
            },
          ],
          radius: 97.80039691791197,
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
    ],
    equipments: [
      {
        id: "1",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "oprAlerts",
        utilization: "70%",
        category: "forklift",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.938374757573837,
          lng: 80.1150049523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "circle",
          name: "GeoTID#123",
          coOrdinates: [
            {
              lat: 12.948441308184758,
              lng: 80.12244664550813,
            },
          ],
          radius: 97.80039691791197,
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "2",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Disconnected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "forklift",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.945858757573837,
          lng: 80.1132349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "3",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletJack",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.948098757573837,
          lng: 80.1213349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "4",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletCart",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.939558757573837,
          lng: 80.1260349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "5",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Disconnected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "incidents",
        utilization: "70%",
        category: "palletCart",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.944288757573837,
          lng: 80.1247549523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "6",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletJack",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940578757573837,
          lng: 80.1129349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "7",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletTruck",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.946228757573837,
          lng: 80.1297049523929,
        },
        packagaeData: [
          {
            packageStage: "",
            timeStamp: "",
            status: "",
            packagaeData: [
              {
                packageStage: "Package Picked Up",
                timeStamp: "1-11-2023 9.00AM",
                status: "",
              },
              {
                packageStage: "Initial Scan",
                timeStamp: "1-11-2023 10.30AM",
                status: "",
              },
              {
                packageStage: "Staging & Tagging",
                timeStamp: "1-11-2023 12.30PM",
                status: "",
              },
              {
                packageStage: "Putaway",
                timeStamp: "1-11-2023 1.30PM",
                status: "",
              },
              {
                packageStage: "Connected",
                timeStamp: "1-11-2023 1.35PM",
                status: "",
              },
            ],
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
    ],
    accessPoints: [
      {
        id: "ap-1",
        title: "AP#12 #1",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.949958757573837,
          lng: 80.1145349523929,
        },
      },
      {
        id: "ap-2",
        title: "AP#12 #2",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.949958757573837,
          lng: 80.1167349523929,
        },
      },
      {
        id: "ap-3",
        title: "AP#12 #3",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.948298757573837,
          lng: 80.1126649523929,
        },
      },
      {
        id: "ap-4",
        title: "AP#12 #4",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.939970757573837,
          lng: 80.1145349523929,
        },
      },
      {
        id: "ap-5",
        title: "AP#12 #5",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.948088757573837,
          lng: 80.1230349523929,
        },
      },
      {
        id: "ap-6",
        title: "AP#12 #6",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.943880000003837,
          lng: 80.1245349523929,
        },
      },
      {
        id: "ap-7",
        title: "AP#12 #7",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.943880000003837,
          lng: 80.1295349523929,
        },
      },

      {
        id: "ap-8",
        title: "AP#12 #8",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.943880000003837,
          lng: 80.1362099523929,
        },
      },
      {
        id: "ap-9",
        title: "AP#12 #9",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940588757573837,
          lng: 80.1269999523929,
        },
      },
      {
        id: "ap-10",
        title: "AP#12 #10",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940588757573837,
          lng: 80.1312999523929,
        },
      },
      {
        id: "ap-11",
        title: "AP#12 #11",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940588757573837,
          lng: 80.1360999523929,
        },
      },
    ],
  },
  {
    id: "wh-10",
    category: "",
    goods: [
      {
        id: "123456",
        title: "High Temperature",
        trackerId: "TID#123",
        area: "Rack - A017, Staging Area",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F",
        totalUnits: "20",
        source: "Sony Corporation of America",
        category: "Electronics",
        make: "Sony",
        dimension: "380mm x 380mm x 380mm",
        timeStamp: "02-06-2023 | 4:30 PM",
        notificationType: "events",
        location: {
          lat: 12.949600757573837,
          lng: 80.1237549523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "circle",
          name: "GeoTID#123",
          coOrdinates: [
            {
              lat: 12.948441308184758,
              lng: 80.12244664550813,
            },
          ],
          radius: 97.80039691791197,
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "123446",
        title: "Tracking disconnected",
        trackerId: "TID#123",
        area: "Rack - A017, Staging Area",
        connectedStatus: "Disconnected",
        battery: "70",
        temperature: "68°F",
        totalUnits: "20",
        source: "Sony Corporation of America",
        category: "Electronics",
        make: "Sony",
        dimension: "380mm x 380mm x 380mm",
        timeStamp: "02-06-2023 | 4:30 PM",
        notificationType: "incidents",
        location: {
          lat: 12.949608757573837,
          lng: 80.1227999523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "1-11-2023 | 12.30PM",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "circle",
          name: "GeoTID#123",
          coOrdinates: [
            {
              lat: 12.948441308184758,
              lng: 80.12244664550813,
            },
          ],
          radius: 97.80039691791197,
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
    ],
    equipments: [
      {
        id: "1",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "oprAlerts",
        utilization: "70%",
        category: "forklift",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.938374757573837,
          lng: 80.1150049523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "circle",
          name: "GeoTID#123",
          coOrdinates: [
            {
              lat: 12.948441308184758,
              lng: 80.12244664550813,
            },
          ],
          radius: 97.80039691791197,
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "2",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Disconnected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "forklift",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.945858757573837,
          lng: 80.1132349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "3",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletJack",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.948098757573837,
          lng: 80.1213349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "4",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletCart",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.939558757573837,
          lng: 80.1260349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "5",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Disconnected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "incidents",
        utilization: "70%",
        category: "palletCart",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.944288757573837,
          lng: 80.1247549523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "6",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletJack",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940578757573837,
          lng: 80.1129349523929,
        },
        packagaeData: [
          {
            packageStage: "Package Picked Up",
            timeStamp: "1-11-2023 9.00AM",
            status: "",
          },
          {
            packageStage: "Initial Scan",
            timeStamp: "1-11-2023 10.30AM",
            status: "",
          },
          {
            packageStage: "Staging & Tagging",
            timeStamp: "1-11-2023 12.30PM",
            status: "",
          },
          {
            packageStage: "Putaway",
            timeStamp: "1-11-2023 1.30PM",
            status: "",
          },
          {
            packageStage: "Connected",
            timeStamp: "1-11-2023 1.35PM",
            status: "",
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
      {
        id: "7",
        trackerId: "TID#128",
        title: "Low Battery",
        state: "In - Use ",
        area: "Inbound Staging Area  ",
        connectedStatus: "Connected",
        battery: "70",
        temperature: "68°F ",
        notificationType: "events",
        utilization: "70%",
        category: "palletTruck",
        operator: "Brayden Tim",
        make: "Toyota 3-Wheel Electric",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.946228757573837,
          lng: 80.1297049523929,
        },
        packagaeData: [
          {
            packageStage: "",
            timeStamp: "",
            status: "",
            packagaeData: [
              {
                packageStage: "Package Picked Up",
                timeStamp: "1-11-2023 9.00AM",
                status: "",
              },
              {
                packageStage: "Initial Scan",
                timeStamp: "1-11-2023 10.30AM",
                status: "",
              },
              {
                packageStage: "Staging & Tagging",
                timeStamp: "1-11-2023 12.30PM",
                status: "",
              },
              {
                packageStage: "Putaway",
                timeStamp: "1-11-2023 1.30PM",
                status: "",
              },
              {
                packageStage: "Connected",
                timeStamp: "1-11-2023 1.35PM",
                status: "",
              },
            ],
          },
        ],
        notifications: {
          events: {
            totalCount: "1",
            list: [
              {
                title: "Moved out of geofence",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          incidents: {
            totalCount: "1",
            list: [
              {
                title: "Tracking disconnected",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
          oprAlerts: {
            totalCount: "1",
            list: [
              {
                title: "High Temperature ",
                area: "Staging Area ",
                timeStamp: "",
              },
            ],
          },
        },
        geoFence: {
          id: "",
          type: "polygon",
          name: "GeoTID#1234",
          coOrdinates: [
            {
              lat: 12.946705602114173,
              lng: 80.1173611772159,
            },
            {
              lat: 12.948650428582098,
              lng: 80.11839114547762,
            },
            {
              lat: 12.948566780444228,
              lng: 80.12070857406648,
            },
            {
              lat: 12.94511627029118,
              lng: 80.11989318252596,
            },
          ],
          radius: "",
          outsideGeofence: false,
          backToGeofence: false,
          isInsideGeofence: false,
        },
      },
    ],
    accessPoints: [
      {
        id: "ap-1",
        title: "AP#12 #1",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.949958757573837,
          lng: 80.1145349523929,
        },
      },
      {
        id: "ap-2",
        title: "AP#12 #2",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.949958757573837,
          lng: 80.1167349523929,
        },
      },
      {
        id: "ap-3",
        title: "AP#12 #3",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.948298757573837,
          lng: 80.1126649523929,
        },
      },
      {
        id: "ap-4",
        title: "AP#12 #4",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.939970757573837,
          lng: 80.1145349523929,
        },
      },
      {
        id: "ap-5",
        title: "AP#12 #5",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.948088757573837,
          lng: 80.1230349523929,
        },
      },
      {
        id: "ap-6",
        title: "AP#12 #6",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.943880000003837,
          lng: 80.1245349523929,
        },
      },
      {
        id: "ap-7",
        title: "AP#12 #7",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.943880000003837,
          lng: 80.1295349523929,
        },
      },

      {
        id: "ap-8",
        title: "AP#12 #8",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.943880000003837,
          lng: 80.1362099523929,
        },
      },
      {
        id: "ap-9",
        title: "AP#12 #9",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940588757573837,
          lng: 80.1269999523929,
        },
      },
      {
        id: "ap-10",
        title: "AP#12 #10",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940588757573837,
          lng: 80.1312999523929,
        },
      },
      {
        id: "ap-11",
        title: "AP#12 #11",
        area: "Picked Order Staging Area, Warehouse#1",
        connectedStatus: "Connected",
        category: "Forklift",
        noOfConnectedLabels: "100",
        timeStamp: "02-06-2023 | 4:30 PM",
        location: {
          lat: 12.940588757573837,
          lng: 80.1360999523929,
        },
      },
    ],
  },
];
export default dashboardViewDetails;
