import { styled } from "@mui/material/styles";

export const RootContainer = styled("div")<{
  direction: string;
  isActive: boolean;
  pageName: string;
}>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ direction }) =>
    direction === "row" ? "center" : "flex-start"};
  margin-right: ${({ pageName }) =>
    pageName === "popupConfig" ? "0px" : "50px"};
  margin-bottom: ${({ pageName }) =>
    pageName === "popupConfig" ? "6px" : "24px !important"};
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
`;

export const Label = styled("p")<{ variant: string }>`
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: ${({ variant }) => (variant === "dark" ? "#FFFFFF" : "#464646")};
  margin: 0;
  padding: 0;
  margin-right: 10px;
`;

export const InputContainer = styled("div")<{ variant: string; size: string }>`
  box-sizing: border-box;
  display: flex;
  width: ${({ size }) =>
    size === "small" ? "121px" : size === "large" ? "100%" : "306px"};
  height: 45px;
  border: 1px solid
    ${({ variant }) =>
      variant === "dark"
        ? "#D1D1D1"
        : variant === "alertRed"
        ? "#F50303"
        : "#DDDDDD"};
  border-radius: 6px;
  background-color: ${({ variant }) =>
    variant === "dark"
      ? "#3E3E3E"
      : variant === "black" || variant === "alertRed"
      ? "#000"
      : "#FFFFFF"};
  align-items: center;
  padding: 0px 11px;
`;

export const InputPrefixSuffix = styled(Label)<{ variant: string }>`
  color: ${({ variant }) =>
    variant === "dark" ? "#FFFFFF" : "#707070"} !important;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
`;

export const Input = styled("input")<{ length: number; variant: string }>`
  border: none;
  outline: none;
  width: ${({ length }) => length}ch;
  color: ${({ variant }) =>
    variant === "dark" || variant === "black" || variant === "alertRed" ? "#FFFFFF" : "#000"} !important;
  font-size: 15px;
  background-color: transparent;
  margin: 0px 0px 0px 0px;
`;

export const FlexSpace = styled("div")`
  flex: 1;
`;

export const ControlButtonsContainer = styled("div")`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;
