import { makeStyles } from "@mui/styles";
import { padding } from "@mui/system";
import muiTheme from "theme/muiTheme";

const useStyles = makeStyles(() => ({
  customNotificationTabs: (props: any) => ({
    "& .MuiTabs-flexContainer": {
      // borderBottom: "2px solid #3e3e3e",
    },

    "& .MuiButtonBase-root": {
      marginRight: "0 !important",
      minWidth: "64px !important",
    },

    "& .MuiTab-root": {
      "&:first-child": {
        marginRight: "0 !important",
        minWidth: "64px !important",
        color: `${props?.palette?.infoDialogueComponent?.darkGrayShades}!important`,
      },
      "&:first-child.Mui-selected": {
        color: `${props?.palette?.infoDialogueComponent?.greenBlackShades}!important`,
      },
      "&:nth-child(2)": {
        marginRight: "0 !important",
        minWidth: "64px !important",
        color: `${props?.palette?.infoDialogueComponent?.darkGrayShades}!important`,
      },
      "&:nth-child(2).Mui-selected": {
        color: `${props?.palette?.infoDialogueComponent?.greenBlackShades}!important`,
      },
    },
    "& .MuiTabs-root .MuiTabs-indicator": {
      background: props?.palette?.infoDialogueComponent?.greenBlackShades,
    },
  }),
  chipButtonSection: (props: any) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "14px",
    [muiTheme.breakpoints.down(1537)]: {
      padding: 10,
    },
    [muiTheme.breakpoints.down(1025)]: {
      padding: 6,
    },
  }),
  headerStyle: (props: any) => ({
    fontWeight: 700,
    fontSize: 20,
    lineHeight: "27px",
    textTransform: "uppercase",
    marginBottom: 30,
    fontFamily: `'Nunito Sans', sans-serif !important`,
  }),
  tripsElementSection: (props: any) => ({
    display: "flex",
    flexDirection: "column",
    width: "50%",

    padding: "26px 40px 26px 40px",
    borderRight: `1px solid ${props?.palette?.infoDialogueComponent?.colorWhite}`,
    "& :last-child": {
      marginBottom: "0 !important",
    },
    [muiTheme.breakpoints.down(1437)]: {
      padding: 8,
    },
  }),
  detailsSection: (props: any) => ({
    display: "flex",
    marginBottom: "20px",
    fontFamily: `'Nunito Sans', sans-serif !important`,
    [muiTheme.breakpoints.down(1437)]: {
      fontSize: 10,
      lineHeight: "15px",
      marginBottom: 8,
      fontFamily: `'Nunito Sans', sans-serif !important`,
    },
  }),
  labelSection: (props: any) => ({
    width: "40%",
  }),

  infoListItem: (props: any) => ({
    borderBottomLeftRadius: "6px",
    borderTopLeftRadius: "6px",
    padding: "8px 0px 2px 8px",
    flex: 1,
  }),

  tripsSection2: (props: any) => ({
    border: `1px solid ${props?.palette?.infoDialogueComponent?.lightShadeGray}`,
    borderBottomLeftRadius: "6px",
    borderTopLeftRadius: "6px",
    // height: "210px",
    // overflow: "auto",
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 16,
  }),
  tripsSection1: (props: any) => ({
    border: `1px solid ${props?.palette?.infoDialogueComponent?.lightShadeGray}`,
    borderBottomLeftRadius: "6px",
    borderTopLeftRadius: "6px",

    overflow: "auto",
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    "& :last-child": {
      borderRight: "none !important",
    },
  }),
  tripsSection: (props: any) => ({
    border: `1px solid ${props?.palette?.infoDialogueComponent?.lightShadeGray}`,
    borderBottomLeftRadius: "6px",
    borderTopLeftRadius: "6px",

    overflow: "auto",
    position: "relative",
    marginTop: 16,
  }),

  violationElementSection: (props: any) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px",
    fontFamily: `'Nunito Sans', sans-serif !important`,
  }),

  violationSection: (props: any) => ({
    background: props?.palette?.infoDialogueComponent?.blackShades,
    height: "calc(100vh - 362px) !important",
    overflow: "auto",
    borderRadius: "5px",
    marginLeft: "16px",
  }),

  notificationList: (props: any) => ({
    color: props?.palette?.infoDialogueComponent?.colorWhite,
    height: "calc(100vh - 570px)",
    overflow: "auto",
  }),

  subListRow: (props: any) => ({
    marginBottom: "12px !important",
  }),
  subListSection: (props: any) => ({
    padding: "16px",
    height: "calc(100vh - 362px) !important",
    background: "#333333",
    border: "1px solid #828282",
    borderRadius: "0px 6px 6px 0px",
  }),
  infoVideoTitle: (props: any) => ({
    marginLeft: 8,
  }),
  tripsElementListSection: (props: any) => ({
    height: "calc(100vh - 415px)",
    overflow: "auto",
  }),
  videoMapSection: (props: any) => ({
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 355px)",
  }),
  infoMainListClassName: (props: any) => ({
    height: "100%",

    borderBottomLeftRadius: "6px",
    borderTopLeftRadius: "6px",
    margin: "8px 0px 2px 8px",
    flex: 1,
  }),
  infoMainListClassNameOne: (props: any) => ({
    borderBottomLeftRadius: "6px",
    borderTopLeftRadius: "6px",
    margin: "8px 0px 2px 8px",
    flex: 1,
    overflow: "hidden",
  }),
  infoDialogClassName: (props: any) => ({
    display: "flex",

    margin: 6,
    borderRadius: 4,
  }),
  pastViolationsClass: (props: any) => ({
    display: "flex",

    margin: 16,
    borderRadius: 4,
  }),

  searchClass: (props: any) => ({
    border: `1px solid #fff`,
    // background: props?.palette?.dashboardList?.white,
    color: "#fff !important",
    borderRadius: 6,
    height: "40px",
    "& .MuiIconButton-root": {
      marginRight: 7,
    },
    "& .MuiInputBase-root": {
      // fontWeight: 500,
    },
  }),

  infoDialogHeading: (props: any) => ({
    fontFamily: "Nunito Sans",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "25px",
  }),

  notificationSearchIcon: (props: any) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "12px",
    marginTop: "10px",
  }),

  searchBoxClass: (props: any) => ({
    padding: "12px",
  }),

  searchContainer: (props: any) => ({
    // height: "calc(100vh - 872px)",
  }),
}));

export default useStyles;
