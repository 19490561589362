import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  dashboardContentInner: {
    marginTop: "84px !important",
  },
  viewDetailHeader: (props: any) => ({
    display: "flex",
    justifyContent: "space-between",
    // margin: 10,
    position: "absolute",
    zIndex: 1,
    width: "100%",
  }),
  viewDetailHeaderButtonStyle: (props: any) => ({
    display: "flex",
    alignItems: "center",
    margin: "17px 0px 0px 68px",
    background: props?.palette?.dashboardList?.viewDetailHeaderButton, //viewDetailHeaderButton
    padding: 10,
    borderRadius: 10,
  }),
  viewDetailHeaderLeft: (props: any) => ({
    display: "flex",
    alignItems: "center",
    margin: "17px 76px 0px 0px",
    background: props?.palette?.dashboardList?.floormapHeaderBg, //floormapHeaderBg
    color: props?.palette?.dashboardList?.white,
    fontWeight: 700,
    letterSpacing: 0.5,
    fontSize: 17,
    padding: 10,
    borderRadius: 10,
    fontFamily: `'Nunito Sans', sans-serif !important`,
  }),
  rightArrowStyle: (props: any) => ({
    transform: "rotate(180deg)",
    cursor: "pointer",
    marginRight: 10,
  }),
  toggleButtonStyle: (props: any) => ({
    marginLeft: 10,
    "& .MuiToggleButtonGroup-root": {
      height: 35,
      // background: `${props?.palette?.dashboardList?.accessButtonInactive} !important`,
    },
    "&. MuiButtonBase-root-MuiToggleButton-root": {
      border: 0,
    },
    "& .MuiToggleButton-root.Mui-selected": {
      background: `${props?.palette?.header?.darkGreenHeaderButton} !important`,
    },
    "& .MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped": {
      color: `${props?.palette?.dashboardList?.white} !important`,
      textTransform: "none",
      letterSpacing: 0.5,
      background: "#7E7E97",
      borderRadius: "9.81818px",
    },
  }),
  arrowIconStyle: (props: any) => ({
    position: "absolute",
    right: 20,
    top: 21,
    transform: "rotate(180deg)",
    cursor: "pointer",
  }),
  rightArrowStyleInactive: (props: any) => ({
    position: "absolute",
    top: "104px",
    right: "16px",
    transform: "rotate(180deg)",
    cursor: "pointer",
    zIndex: "1",
  }),
});
export default useStyles;
