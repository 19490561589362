import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import theme from "../../theme/theme";
import useTranslation from "../../localization/translations";
import Button from "../../elements/Button";
import NotificationInfoWindowContent from "../../elements/NotificationInfoWindowContent";
import WifiIcon from "../../assets/wifiIcon.svg";
import WifiDisconnected from "../../assets/wifiDisconnected.svg";
import BatteryIcon from "../../assets/BatteryIcon.svg";
import BatteryRedIcon from "../../assets/BatteryRedIcon.svg";
import TemperatureIcon from "../../assets/TemperatureIcon.svg";
import UtilizationIcon from "../../assets/UtilizationIcon.svg";
import SourceIcon from "../../assets/SourceIcon.svg";
import DestinationIcon from "../../assets/DestinationIcon.svg";
import ArrowIcon from "../../assets/ArrowIcon.svg";
import PalletsIcon from "../../assets/PalletsIcon.svg";
import CurrentLocationIcon from "../../assets/CurrentLocationIcon.svg";
import StoresIcon from "../../assets/StoresIcon.svg";
import CalendarIcon from "../../assets/CalendarIcon.svg";
import useStyles from "./styles";

const InfoWindow: React.FC<any> = (props) => {
  const {
    handleMarkerClose,
    notificationTimeStamp,
    marker,
    marker: {
      title,
      itemList,
      id,
      connectedStatus,
      utilization,
      type,
      area,
      trackerId,
      battery,
      temperature,
      category,
      currentTimeStamp,
      noOfConnectedLabels,
      source,
      destination,
      manifestId,
    },
    setViewDetailActive,
    pageName,
    handleInfoDialogue,
    selectedNotification,
    tabIndex,
    selectedTabButton,
    setSelectedTabButton,
    refObject,
    // buttonIndex,    
    handleButtonClick,
  } = props;
  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );

  const {
    listItemName1,
    listItemName2,
    listItemName3,
    listItemButtonName,
    BoxId,
    TrackerId,
    EquipmentId,
    Status,
    Battery,
    Temperature,
    Utilization,
    LocationText,
    Pallets,
    Stores,
    CurrentLocation,
  } = useTranslation();

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const {
    rootContainer,
    itemTitleStyle,
    itemAreaStyle,
    expandedListClass,
    collapsedListClass,
    displayFlex,
    itemListCount,
    itemListName,
    selectedButtonStyles,
    timeStampStyle,
    markerCloseIcon,
    itemListIconStyle,
    connectedStatusButtonStyle,
    connectedButtonPosition,
    disConnectedStatusButtonStyle,
    expandedListClassViewDetails,
    markerCloseIconDashboard,
    itemListStyle,
    trailerAreaStyle,
    trailerLocationStyle,
    trailerLocationTextStyle,
    trailerLocationIconStyle,
    trailerArrowStyle,
    NotificationInfoWindowContentSection,
  } = useStyles(appTheme);

  const navigate = useNavigate();

  // const handleButtonClick = () => {
  //   setShowInfoDialogue(true);
  // };

  if (tabIndex === 1 && pageName !== "dashboardViewDetails") {
    return (
      <div className={NotificationInfoWindowContentSection}>
        <div className="closeIcon" onClick={handleMarkerClose}>
          x
        </div>
        <NotificationInfoWindowContent refObject={refObject} data={marker} />
      </div>
    );
  }

  if (pageName === "dashboardViewDetails" && type !== "accessPoints") {
    return (
      <div ref={refObject} className={rootContainer}>
        <div>
          {selectedNotification === id ? (
            <div className={expandedListClassViewDetails}>
              <div className={displayFlex}>
                <div className={itemTitleStyle}>
                  {type === "goods" ? (
                    <>
                      {BoxId} : {id}
                    </>
                  ) : (
                    <>
                      {EquipmentId} : {id}
                    </>
                  )}{" "}
                  | {TrackerId} : {trackerId}
                </div>
                <div className={markerCloseIcon} onClick={handleMarkerClose}>
                  x
                </div>
              </div>

              <div className={itemAreaStyle}>
                {type === "notifications" && category && (
                  <span>Type - {category} | </span>
                )}
                {area}
              </div>

              <div className={displayFlex}>
                <div>
                  <div className={itemListCount}>
                    <img
                      className={itemListIconStyle}
                      src={
                        connectedStatus.toLowerCase() === "connected"
                          ? WifiIcon
                          : WifiDisconnected
                      }
                      alt=""
                    />
                    {connectedStatus}
                  </div>
                  <div className={itemListName}>{Status}</div>
                </div>
                {utilization && (
                  <div>
                    <div className={itemListCount}>
                      <img
                        className={itemListIconStyle}
                        src={UtilizationIcon}
                        alt="UtilizationIcon"
                      />
                      {utilization}
                    </div>
                    <div className={itemListName}>{Utilization}</div>
                  </div>
                )}
                <div>
                  <div className={itemListCount}>
                    <img
                      className={itemListIconStyle}
                      src={battery <= 20 ? BatteryRedIcon : BatteryIcon}
                      alt=""
                    />
                    {battery}%
                  </div>
                  <div className={itemListName}>{Battery}</div>
                </div>
                <div>
                  <div className={itemListCount}>
                    <img
                      className={itemListIconStyle}
                      src={TemperatureIcon}
                      alt=""
                    />
                    {temperature}
                  </div>
                  <div className={itemListName}>{Temperature}</div>
                </div>
              </div>
              <>
                {tabIndex !== 2 ? (
                  <div className={displayFlex}>
                    <Button
                      variant="contained"
                      handleClick={() => handleInfoDialogue(marker)}

                      buttonStyles={selectedButtonStyles}
                    >
                      {listItemButtonName}
                    </Button>
                    <div className={timeStampStyle}>{currentTimeStamp}</div>
                  </div>
                ) : (
                  <div className={timeStampStyle}>{currentTimeStamp}</div>
                )}
              </>
            </div>
          ) : (
            <div className={collapsedListClass}>
              <div className={itemTitleStyle}>
                {type !== "notifications" && (
                  <div className={connectedButtonPosition}>
                    <div
                      className={
                        connectedStatus.toLowerCase() === "connected"
                          ? connectedStatusButtonStyle
                          : disConnectedStatusButtonStyle
                      }
                    >
                      {connectedStatus}
                    </div>
                  </div>
                )}
                {type === "goods" ? (
                  <>
                    {BoxId} : {id}
                  </>
                ) : type === "notifications" ? (
                  <>{marker?.title}</>
                ) : (
                  <>
                    {EquipmentId} : {id}
                  </>
                )}
              </div>
              <div className={itemAreaStyle}>
                {type === "notifications" ? (
                  <>
                    {BoxId} : {id} | {LocationText} : {area}
                  </>
                ) : (
                  <>
                    {TrackerId} - {trackerId}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (pageName === "dashboardViewDetails" && type === "accessPoints") {
    return (
      <div
        ref={refObject}
        className={rootContainer}
        // onClick={() => handleExpandListItem(id)}
        // ref={refs[id]}
      >
        <div>
          {selectedNotification === id ? (
            <div className={expandedListClassViewDetails}>
              <div className={displayFlex}>
                <div className={itemTitleStyle}>{title}</div>
                <div className={markerCloseIcon} onClick={handleMarkerClose}>
                  x
                </div>
              </div>

              <div className={itemAreaStyle}>{area}</div>

              <div className={displayFlex}>
                <div>
                  <div className={itemListCount}>{connectedStatus}</div>
                  <div className={itemListName}>{Status}</div>
                </div>

                <div>
                  <div className={itemListCount}>{noOfConnectedLabels}</div>
                  <div className={itemListName}>Number of Labels Connected</div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  if (category === "trailers") {
    return (
      <div className={rootContainer}>
        <div className={expandedListClass}>
          <div className={displayFlex}>
            <div className={itemTitleStyle}>{title}</div>

            <div className={markerCloseIcon} onClick={handleMarkerClose}>
              x
            </div>
          </div>
          <div className={itemAreaStyle}>Manifest ID: {manifestId}</div>
          <div className={itemListStyle}>
            <div>
              <div className={itemListCount}>
                <img
                  className={itemListIconStyle}
                  src={
                    itemList?.connectedStatus?.toLowerCase() === "connected"
                      ? WifiIcon
                      : WifiDisconnected
                  }
                  alt=""
                />
                {itemList?.connectedStatus}
              </div>
              <div className={itemListName}>{Status}</div>
            </div>
            <div>
              <div className={itemListCount}>
                <img className={itemListIconStyle} src={PalletsIcon} alt="" />
                {itemList?.pallets}
              </div>
              <div className={itemListName}>{Pallets}</div>
            </div>
            <div>
              <div className={itemListCount}>
                {" "}
                <img className={itemListIconStyle} src={StoresIcon} alt="" />
                {itemList?.stores}
              </div>
              <div className={itemListName}>{Stores}</div>
            </div>
            <div>
              <div className={itemListCount}>
                <img
                  className={itemListIconStyle}
                  src={CurrentLocationIcon}
                  alt=""
                />
                {itemList?.currentLocation}
              </div>
              <div className={itemListName}>{CurrentLocation}</div>
            </div>
          </div>
          <div className={trailerAreaStyle}>
            <div className={trailerLocationStyle}>
              <img
                src={SourceIcon}
                alt="SourceIcon"
                className={trailerLocationIconStyle}
              />
              <h3 className={trailerLocationTextStyle}>{source}</h3>
            </div>
            <div className={trailerArrowStyle}>
              <img src={ArrowIcon} alt="ArrowIcon" />
            </div>
            <div className={trailerLocationStyle}>
              <img
                src={DestinationIcon}
                alt="DestinationIcon"
                className={trailerLocationIconStyle}
              />
              <h3 className={trailerLocationTextStyle}>{destination}</h3>
            </div>
          </div>
          <>
            <div className={displayFlex}>
              <Button
                variant="contained"
                // handleClick={() => handleInfoDialogue(marker)}
                handleClick={()=>handleButtonClick(marker?.itemList?.trailerId, category)}
                buttonStyles={selectedButtonStyles}
              >
                {listItemButtonName}
              </Button>
              <div className={timeStampStyle}>{notificationTimeStamp}</div>
            </div>
          </>
        </div>
      </div>
    );
  }

  if (category === "stores") {
    return (
      <div className={rootContainer}>
        <div className={expandedListClass}>
          <div className={displayFlex}>
            <div className={itemTitleStyle}>{title}</div>

            <div className={markerCloseIcon} onClick={handleMarkerClose}>
              x
            </div>
          </div>
          <div className={itemAreaStyle}>Manifest ID : {id}</div>
          <div className={itemListStyle}>
            <div>
              <div className={itemListCount}>
                <img className={itemListIconStyle} src={CalendarIcon} alt="" />
                {itemList?.deliveryDate}
              </div>
              <div className={itemListName}>Delivery Date</div>
            </div>
            <div>
              <div className={itemListCount}>
                <img className={itemListIconStyle} src={PalletsIcon} alt="" />
                {itemList?.pallets}
              </div>
              <div className={itemListName}>{Pallets}</div>
            </div>
            {/* <div>
                    <div className={itemListCount}>
                      {" "}
                      <img
                        className={itemListIconStyle}
                        src={StoresIcon}
                        alt=""
                      />
                      {itemList?.stores}
                    </div>
                    <div className={itemListName}>{Stores}</div>
                  </div> */}
            <div>
              <div className={itemListCount}>
                <img
                  className={itemListIconStyle}
                  src={CurrentLocationIcon}
                  alt=""
                />
                {area}
              </div>
              <div className={itemListName}>{CurrentLocation}</div>
            </div>
          </div>
          <>
            {/* <div className={displayFlex}>
                    <Button
                      variant="contained"
                      handleClick={() => handleButtonClick(index)}
                      buttonStyles={selectedButtonStyles}
                    >
                      {listItemButtonName}
                    </Button>
                    <div className={timeStampStyle}>
                      {notificationTimeStamp}
                    </div>
                  </div> */}
          </>
        </div>
      </div>
    );
  }

  return (
    <div ref={refObject} className={rootContainer}>
      {" "}
      <div className={expandedListClass}>
        <div className={displayFlex}>
          <div className={itemTitleStyle}>{title}</div>
          {pageName === "dashboard" ? (
            <div
              className={markerCloseIconDashboard}
              onClick={handleMarkerClose}
            >
              x
            </div>
          ) : (
            <div className={markerCloseIcon} onClick={handleMarkerClose}>
              x
            </div>
          )}
        </div>
        <div className={itemAreaStyle}>{itemList?.area}</div>
        <div className={displayFlex}>
          <div>
            <div className={itemListCount}>{itemList?.goodsTracked}</div>
            <div className={itemListName}>{listItemName1}</div>
          </div>
          <div>
            <div className={itemListCount}>{itemList?.equippmentTracked}</div>
            <div className={itemListName}>{listItemName2}</div>
          </div>
          <div>
            <div className={itemListCount}>{itemList?.accesspoint}</div>
            <div className={itemListName}>{listItemName3}</div>
          </div>
        </div>
        <>
          <div className={displayFlex}>
            <Button
              variant="contained"
              handleClick={() => handleButtonClick()}
              buttonStyles={selectedButtonStyles}
            >
              {listItemButtonName}
            </Button>
            <div className={timeStampStyle}>{notificationTimeStamp}</div>
          </div>
        </>
      </div>
    </div>
  );
};

export default InfoWindow;
