import { useState } from "react";
import { TextField, Tooltip, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import theme from "theme/theme";
import useStyles from "./styles";

interface TextFieldProps {
  type?: any;
  value?: any;
  onChange?: any;
  disabled?: any;
  placeholder?: any;
  fullWidth?: any;
  tooltip?: any;
  tooltipText?: any;
  handleClose?: any;
  searchType?: any;
}
const INF_TextField: React.FC<any> = (props: any) => {
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);

  const {
    type,
    value,
    onChange,
    disabled,
    placeholder,
    fullWidth,
    tooltip,
    tooltipText,
    handleClose,
    searchType,
  } = props;
  const { inputText } = useStyles(appTheme);

  return (
    <Tooltip
      title={tooltipText ? tooltipText : ""}
      arrow
      open={tooltip ? tooltip : false}
      placement={"top"}
    >
      <TextField
        className={inputText ? inputText : ""}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        value={value}
        type={type}
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
        fullWidth={fullWidth}
        InputProps={{
          endAdornment: value && searchType === "search" && (
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          ),
        }}
      />
    </Tooltip>
  );
};

export default INF_TextField;
