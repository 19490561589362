import { useState, useEffect, Fragment, useRef } from "react";
import {
  GoogleMap,
  LoadScript,
  useLoadScript,
  DrawingManager,
  Circle,
  GroundOverlay,
  Marker,
  InfoWindow,
  Polygon,
} from "@react-google-maps/api";
import MarkerInfoWindow from "../../../elements/InfoWindow";
import customMapStyles from "./customMapStyles";
import theme from "../../../theme/theme";
import appData from "../../../data/appData";
import FloorMapPlan from "../../../assets/FloorMap.svg";
import GoodsPurpleBoxIcon from "../../../assets/floormap_markers/GoodsPurpleBox.svg";
import GoodsDisconnectedActiveIcon from "../../../assets/floormap_markers/GoodsDisconnectedActive.svg";
import AccessPointIcon from "../../../assets/floormap_markers/AccessPointIcons.svg";
import useStyles from "./styles";
import { url } from "inspector";
import InfoDialog from "components/InfoDialog";
import GeofenceInfoWindow from "elements/GeofenceInfoWindow";
import GoodsGreenBoxIcon from "../../../assets/floormap_markers/GoodsGreenBox.svg";
import GoodsDisconnectedInactiveIcon from "../../../assets/floormap_markers/GoodsDisconnectedInactive.svg";

const containerStyle = {
  width: "100%",
  height: "calc(100vh - 84px)",
};

const geofenceContainerStyle = {
  width: "100%",
  height: "calc(100vh - 362px)",
};

const manifestDialogueContainerStyle = {
  width: "100%",
  height: "calc(100vh - 662px)",
};

const geofenceViewDetailsContainerStyleGoods = {
  width: "100%",
  height: "calc(100vh - 710px)",
};

const geofenceViewDetailsContainerStyleEquipment = {
  width: "100%",
  height: "calc(100vh - 570px)",
};

const defaultCenter = {
  lat: 12.944164757573837,
  lng: 80.1233049523929,
};

const FloorMap: React.FC<any> = (props) => {
  const {
    markerArray,
    setSelectedNotification,
    selectedNotification,
    setSelectedTabButton,
    notificationTimeStamp,
    setIsMarkerClicked,
    isMarkerClicked,
    setViewDetailActive,
    geofenceFloorMapEnable,
    isCircleDrawing,
    isDrawing,
    setIsDrawing,
    setPolygonData,
    setCircleData,
    setPolygonPath,
    setCircleRadius,
    setCircleCenter,
    circleCenter,
    circleRadius,
    polygonPath,
    geoFenceType,
    viewDetailData,
    toggleButtonStatus,
    setTabIndex,
    tabIndex,
    selectedTabButton,
    isDisabled,
    selectedListItem,
    geofenceFloorMapDetailsEnable,
    geofenceFloorMapEnableManifest,
    pageName,
    isListExpanded,
    selectedId,
    isInsideGeofence,
    handleTabChange,
    setRightPanelActive,
    parentTabIndex,
    setChipButtonIndex,
  } = props;
  const infoWindowContainer = useRef<any>(null);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: appData?.googleApiKey, //"AIzaSyCmwqbYb48dfmPqYiWWU0A2kRr54I2L3wE",
    libraries: ["places", "drawing"],
  });

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const { googleMapStyle, googleMapStyleViewDetails } = useStyles(appTheme);

  const [map, setMap] = useState<any>(null);
  const [zoomValue, setZoomValue] = useState<number>(16);
  const [isDrawingEnable, setIsDrawingEnable] = useState<boolean>();

  useEffect(() => {
    if (isDrawing) {
      setIsDrawingEnable(true);
    } else {
      setIsDrawingEnable(false);
    }
    setZoomValue(15.5);
  }, [isDrawing]);

  const getMapTypeControls = () => {
    const defaultMapOptions = {
      styles: customMapStyles,
    };
    return {
      ...defaultMapOptions,
      mapTypeControl: false,
      rotateControl: false,
      fullscreenControl: false,
      scrollwheel: false,
      streetViewControl: false,
      keyboardShortcuts: false,
    };
  };

  const getFloorMapTypeControls = () => {
    const defaultMapOptions = {
      styles: customMapStyles,
    };
    return {
      ...defaultMapOptions,
      mapTypeControl: false,
      rotateControl: false,
      fullscreenControl: false,
      scrollwheel: false,
      streetViewControl: false,
      zoomControl: false,
      scaleControl: false,
      gestureHandling: "none",
    };
  };

  useEffect(() => {
    if (selectedNotification) {
      const id = markerArray?.findIndex(
        (marker: any) => marker.id === selectedNotification
      );
      if (
        markerArray &&
        markerArray[id]?.location?.lat &&
        markerArray[id]?.location?.lng
      ) {
        // map?.panTo(markerArray[id]?.location);
      }
    }
  }, [selectedNotification, notificationTimeStamp]);

  const switchTabs = (type: any, marker: any) => {
    if (!setTabIndex) return null;
    if (tabIndex !== 2) {
      switch (type) {
        case "goods": {
          setTabIndex(0);
          break;
        }
        case "equipments": {
          setTabIndex(1);
          break;
        }
        default: {
          break;
        }
      }
    } else if (tabIndex === 2) {
      setTabIndex(2);
      marker?.notificationType === "events" && setChipButtonIndex(0);
      marker?.notificationType === "incidents" && setChipButtonIndex(1);
      marker?.notificationType === "oprAlerts" && setChipButtonIndex(2);
    }
  };

  const handleActiveMarker = (marker: any, index: number, event: any) => {
    // setSelectedNotification((prev: any) => {
    //   if (prev && prev === marker?.id) {
    //     map?.panTo(marker?.location);
    //     return "";
    //   } else {
    //     map?.panTo(marker?.location);
    //     return marker?.id;
    //   }
    // });
    if (pageName !== "viewdetailInfoWindow") {
      marker?.type !== "accessPoints" && setRightPanelActive(true);
      switchTabs(marker?.type, marker);
      setSelectedNotification && setSelectedNotification(marker?.id);
      setIsMarkerClicked(true);
    }
  };

  const handleMarkerClose = () => {
    setSelectedNotification && setSelectedNotification("");
    map?.panTo(defaultCenter);
    setIsMarkerClicked(false);
  };

  useEffect(() => {
    if (isListExpanded === false) {
      map?.panTo(defaultCenter);
    }
  }, [isListExpanded]);

  const options: any = {
    drawingControl: false,
    drawingControlOptions: {
      drawingModes: [
        window &&
          window.google &&
          window.google.maps &&
          window.google.maps.drawing &&
          window.google.maps.drawing.OverlayType.POLYGON,
        window &&
          window.google &&
          window.google.maps &&
          window.google.maps.drawing &&
          window.google.maps.drawing.OverlayType.CIRCLE,
      ],
    },
    polygonOptions: {
      fillColor: "#523BC0",
      fillOpacity: 0.5,
      strokeWeight: 2,
      strokeColor: "#523BC0",
      clickable: false,
      editable: false,
      geodesic: false,
      visible: true,
      zIndex: 1,
    },
    circleOptions: {
      fillColor: `#523BC0`,
      fillOpacity: 0.5,
      strokeWeight: 2,
      strokeColor: "#523BC0",
      clickable: false,
      editable: false,
      zIndex: 1,
    },
  };

  const drawModeOptions: any = [
    window &&
      window.google &&
      window.google.maps &&
      window.google.maps.drawing &&
      window.google.maps.drawing.OverlayType.POLYGON,
    window &&
      window.google &&
      window.google.maps &&
      window.google.maps.drawing &&
      window.google.maps.drawing.OverlayType.CIRCLE,
  ];

  const onPolygonComplete = (data: any) => {
    let array: [] = data.getPath().getArray();
    let points: any = [];
    array.forEach((item: any) => {
      points.push({ lat: item.lat(), lng: item.lng() });
    });
    setPolygonData(data);
    setPolygonPath(points);
    props.onPolygonComplete(points);
  };

  const onCircleComplete = (data: any) => {
    if (isCircleDrawing) {
      setCircleRadius(data.getRadius());
      setCircleCenter({
        lat: data.getCenter().lat(),
        lng: data.getCenter().lng(),
      });
      setCircleData(data);
    }
    const centerCoOrdinates = {
      lat: data.getCenter().lat(),
      lng: data.getCenter().lng(),
    };
    props.onCircleComplete(centerCoOrdinates, data.getRadius());
  };

  const handleOverlayComplete = () => {
    setIsDrawingEnable(false);
    setIsDrawing(false);
  };

  const moveInfowindowArrow = (position: any, center: any) => {
    let verticalOffset = 0,
      horizontalOffset = 0;

    if (position?.lat > center?.lat) {
      // if(position?.lng > center.lng) {
      //   verticalOffset =
      // }
      setTimeout(() => {
        const arrow = document.querySelector<HTMLElement>(".gm-style-iw-tc");
        if (arrow) {
          const infoWindowHeight =
            infoWindowContainer?.current?.getBoundingClientRect();
          arrow.style.transform = `translateY(-${
            infoWindowHeight.height + 12
          }px) rotateX(180deg)`;
          arrow.style.position = "relative";
          arrow.style.left = "-50px !important";
        }
      }, 500);
      verticalOffset = 220;
      horizontalOffset = -10;
    }
    const newSize = new google.maps.Size(horizontalOffset, verticalOffset);
    return newSize;
  };

  useEffect(() => {
    setZoomValue(
      geofenceFloorMapEnable ? 15.5 : geofenceFloorMapEnableManifest ? 15 : 16
    );
  }, [geofenceFloorMapEnable, geofenceFloorMapEnableManifest]);

  const [showInfoDialogue, setShowInfoDialogue] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>();

  const handleInfoDialogue = (data: string) => {
    setShowInfoDialogue(true);
    setSelectedItem(data);
  };

  const handleClose = () => {
    // props.handleClose();
  };

  const handleCircleDrag = (event: any) => {
    setCircleCenter({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
    const center = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    props.handleCircleDrag(center);
  };

  return (
    <>
      {isLoaded && (
        <GoogleMap
          id="drawing-manager-example"
          mapContainerStyle={
            geofenceFloorMapEnable
              ? geofenceContainerStyle
              : geofenceFloorMapEnableManifest
              ? manifestDialogueContainerStyle
              : geofenceFloorMapDetailsEnable && parentTabIndex === 0
              ? geofenceViewDetailsContainerStyleGoods
              : geofenceFloorMapDetailsEnable && parentTabIndex !== 0
              ? geofenceViewDetailsContainerStyleEquipment
              : containerStyle
          }
          // center={map?.panTo(defaultCenter)}
          center={defaultCenter}
          zoom={zoomValue}
          onLoad={setMap}
          options={
            geofenceFloorMapEnable
              ? getFloorMapTypeControls()
              : getMapTypeControls()
          }
          mapContainerClassName={
            pageName === "dashboardViewDetails"
              ? googleMapStyleViewDetails
              : googleMapStyle
          }
          onClick={(mapEvent) => console.log("MAP EVENT", zoomValue, mapEvent)}
        >
          <GroundOverlay
            key={FloorMapPlan}
            url={FloorMapPlan}
            bounds={{
              north: 12.955859606863363,
              south: 12.932004989612363,
              east: 80.13735411996152,
              west: 80.1092097617352,
            }}
          />
          <DrawingManager
            drawingMode={
              isDrawingEnable
                ? isCircleDrawing
                  ? drawModeOptions[1]
                  : drawModeOptions[0]
                : null
            }
            onPolygonComplete={onPolygonComplete}
            onCircleComplete={onCircleComplete}
            onOverlayComplete={handleOverlayComplete}
            options={options}
          />
          {circleRadius !== null && (
            <Circle
              radius={circleRadius}
              center={circleCenter}
              visible={true}
              onDragEnd={handleCircleDrag}
              options={
                isDisabled
                  ? {
                      fillColor: `#523BC0`,
                      fillOpacity: 0.5,
                      strokeWeight: 2,
                      strokeColor: "#523BC0",
                      clickable: true,
                      editable: false,
                      draggable: true,
                      zIndex: 1,
                    }
                  : {
                      fillColor: "#F4BA69",
                      fillOpacity: 0.5,
                      strokeWeight: 2,
                      strokeColor: "#985D09",
                      clickable: false,
                      editable: false,
                      draggable: true,
                      zIndex: 1,
                    }
              }
            />
          )}
          {polygonPath?.length > 0 && (
            <Polygon
              path={polygonPath}
              // onDragEnd={handlePolygonDrag}
              options={
                isDisabled
                  ? {
                      fillColor: "#523BC0",
                      fillOpacity: 0.5,
                      strokeWeight: 2,
                      strokeColor: "#523BC0",
                      clickable: false,
                      editable: false,
                      geodesic: false,
                      visible: true,
                      draggable: false,
                      zIndex: 1,
                    }
                  : {
                      fillColor: "#F4BA69",
                      fillOpacity: 0.5,
                      strokeWeight: 2,
                      strokeColor: "#985D09",
                      clickable: false,
                      editable: false,
                      draggable: false,
                      zIndex: 1,
                    }
              }
            />
          )}
          {markerArray &&
            markerArray.length > 0 &&
            markerArray?.map((marker: any, index: number) => {
              return (
                <Marker
                  position={marker?.location}
                  icon={{
                    url:
                      marker?.type === "goods"
                        ? selectedNotification === marker?.id
                          ? pageName === "viewdetailInfoWindow"
                            ? (marker?.connectedStatus === "Connected" ||
                                marker?.connectedStatus === "Disconnected") &&
                              isInsideGeofence
                              ? GoodsPurpleBoxIcon
                              : GoodsDisconnectedActiveIcon
                            : marker?.connectedStatus === "Connected"
                            ? GoodsPurpleBoxIcon
                            : GoodsDisconnectedActiveIcon
                          : pageName === "viewdetailInfoWindow"
                          ? (marker?.connectedStatus === "Connected" ||
                              marker?.connectedStatus === "Disconnected") &&
                            isInsideGeofence
                            ? GoodsGreenBoxIcon
                            : GoodsDisconnectedInactiveIcon
                          : marker?.icon
                        : marker?.type === "accessPoints" &&
                          toggleButtonStatus === "show"
                        ? AccessPointIcon
                        : marker?.type === "equipments"
                        ? marker?.icon
                        : "",
                    scaledSize:
                      marker?.type === "goods"
                        ? geofenceFloorMapEnable
                          ? new google.maps.Size(23, 23)
                          : geofenceFloorMapEnableManifest
                          ? new google.maps.Size(15, 15)
                          : new google.maps.Size(30, 30)
                        : marker?.type === "accessPoints" &&
                          toggleButtonStatus === "show"
                        ? new google.maps.Size(33, 33)
                        : new google.maps.Size(50, 50),
                  }}
                  key={index}
                  onClick={(event) => handleActiveMarker(marker, index, event)}
                >
                  {pageName !== "manifestDialogue" &&
                  pageName !== "viewdetailInfoWindow" &&
                  selectedNotification === marker?.id ? (
                    <InfoWindow
                      options={{
                        pixelOffset: moveInfowindowArrow(
                          marker?.location,
                          defaultCenter
                        ),
                      }}
                    >
                      <MarkerInfoWindow
                        refObject={infoWindowContainer}
                        data={marker?.id}
                        marker={marker}
                        notificationTimeStamp={notificationTimeStamp}
                        setSelectedNotification={setSelectedNotification}
                        handleMarkerClose={handleMarkerClose}
                        setViewDetailActive={setViewDetailActive}
                        pageName={"dashboardViewDetails"}
                        selectedNotification={selectedNotification}
                        tabIndex={tabIndex}
                        selectedTabButton={selectedTabButton}
                        handleInfoDialogue={handleInfoDialogue}
                      />
                    </InfoWindow>
                  ) : null}
                  {geofenceFloorMapDetailsEnable &&
                  selectedListItem?.id === marker?.id ? (
                    <InfoWindow>
                      <GeofenceInfoWindow
                        boxId={`${
                          selectedListItem?.type === "goods"
                            ? "Box ID"
                            : "Equip.ID"
                        }: ${selectedListItem?.id}`}
                        handleMarkerClose={handleMarkerClose}
                      />
                    </InfoWindow>
                  ) : null}
                </Marker>
              );
            })}
        </GoogleMap>
      )}
      <div>
        {showInfoDialogue && (
          <InfoDialog
            selectedItem={selectedItem}
            setShowInfoDialogue={setShowInfoDialogue}
            parentTabIndex={tabIndex}
            handleClose={handleClose}
            markerArray={markerArray}
            showInfoDialogue={showInfoDialogue}
            pageName={"viewdetailInfoWindow"}
            // selectedId={selectedItem?.id}
            selectedType={selectedItem?.type}
            selectedId={selectedId}
            handleTabChange={handleTabChange}
          />
        )}
      </div>
    </>
  );
};

export default FloorMap;
