import React, {useState, useEffect} from "react";
import Fab from "@mui/material/Fab";
import Box from "@mui/material/Box";
import theme from "../../theme/theme";
import useStyles from "./styles";

interface infoIconProps {
    color : any,
    title:string,
    tagLine:string,
    icon:any,
    fabWidth:number,
    fabHeight:number,
    imgWidth:number,
    imgHeight:number,
}

const InfoIcon: React.FC<infoIconProps> = (props : infoIconProps) => {
  const {
    color,
    title,
    tagLine,
    icon,
    fabWidth,
    fabHeight,
    imgWidth,
    imgHeight,
  } = props;
  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
        case "default":
          setAppTheme(theme?.defaultTheme);
          break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);
  const {
    infoIconMainClass,
    infoBoxClass,
    infoTextH1,
    infoTextH4,
    infoTextContent,
    imageWidth,
  } = useStyles(appTheme);

  return (
    <div className={infoIconMainClass}>
      <div className={infoBoxClass}>
        <Box sx={{ flexGrow: 1 }}>
          <Fab
            disableRipple={true}
            aria-label="add"
            sx={{
              background: color,
              width: fabWidth,
              height: fabHeight,
              m: 1,
              "&:hover": { backgroundColor: color },
            }}
          >
            <img
              src={icon}
              alt="Icons"
              width={imgWidth}
              height={imgHeight}
              className={imageWidth}
            />
          </Fab>
        </Box>
        <div className={infoTextContent}>
          <h1 className={infoTextH1}>{title}</h1>
          <h4 className={infoTextH4}>{tagLine}</h4>
        </div>
      </div>
    </div>
  );
};

export default InfoIcon;
