import { useState } from "react";
import TextField from "elements/TextField";
import theme from "theme/theme";
import useStyles from "./styles";

const InputSearch: React.FC<any> = (props) => {
  const { searchValue, handleSearch, handleClose, type } = props;
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const { customTextField } = useStyles(appTheme);

  return (
    <div className={customTextField}>
      <TextField
        value={searchValue}
        type={"text"}
        disabled={false}
        placeholder={"Search & Select Pallets"}
        fullWidth={true}
        onChange={handleSearch}
        handleClose={handleClose}
        searchType={type}
      />
    </div>
  );
};

export default InputSearch;
