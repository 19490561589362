import { makeStyles } from "@mui/styles";
import muiTheme from "theme/muiTheme";

const useStyles = makeStyles(() => ({
  rootContainer: (props: any) => ({
    margin: "30px 0",
    height: "calc(100vh - 284px)",
    overflow: "auto",
    "& .MuiTypography-body1": {
      fontWeight: 600,
      [muiTheme.breakpoints.down(1025)]: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        fontSize: "10px",
        lineHeight: "24px",
      },
    },
    [muiTheme.breakpoints.down(960)]: {
      height: "auto !important",
    },
  }),
  noRecords: (props: any) => ({
    display: "flex",
    justifyContent: "center",
  }),
  treeViewContainer: (props: any) => ({
    "& .MuiTreeItem-group": {
      marginLeft: 32,
      paddingLeft: 26,
      borderLeft: `2px solid #817CA6`,
      position: "relative",
      "&::before": {
        pointerEvents: "none",
        content: '""',
        position: "absolute",
        width: 2,
        height: 43,
        left: -2,
        bottom: 0,
        background: "#e9e6ff",
      },
    },
    "& .MuiTreeItem-label:hover": {
      background: " none",
    },
    "&.Mui-selected .MuiTreeItem-content .MuiTreeItem-label:hover": {
      background: " none",
    },
    "&.Mui-selected .MuiTreeItem-content .MuiTreeItem-label": {
      background: " none",
    },
  }),
  treeViewListContainer: (props: any) => ({
    position: "relative",
    padding: "15px 0 15px 10px !important",
    color: "#5D5D5D !important",
    fontWeight: "700 !important",
    // margin: "15px 15px 15px 0px",
    "&:before": {
      pointerEvents: "none",
      content: '""',
      position: "absolute",
      width: 38,
      left: -26,
      top: 36,
      borderBottom: `2px solid #817CA6`,
    },
    "& .MuiTreeItem-group": {
      marginLeft: 16,
      paddingLeft: 26,
      borderLeft: `2px dashed #817CA6 !important`,
      position: "relative",
      "&::before": {
        pointerEvents: "none",
        content: '""',
        position: "absolute",
        width: 2,
        height: "22px !important !important",
        left: -2,
        bottom: 0,
        background: "#e9e6ff",
      },
    },
    // "& .MuiTreeItem-label": {
    //   color: "#5A46BD !important",
    // },
  }),
  treeViewListContainerTracker: (props: any) => ({
    position: "relative",
    padding: "15px 0 15px 10px !important",
    color: "#5A46BD !important",
    fontWeight: "700 !important",
    // margin: "15px 15px 15px 0px",
    "&:before": {
      pointerEvents: "none",
      content: '""',
      position: "absolute",
      width: 38,
      left: -26,
      top: 36,
      borderBottom: `2px solid #817CA6`,
    },
    "& .MuiTreeItem-group": {
      marginLeft: 16,
      paddingLeft: 26,
      borderLeft: `2px dashed #817CA6 !important`,
      position: "relative",
      "&::before": {
        pointerEvents: "none",
        content: '""',
        position: "absolute",
        width: 2,
        height: "22px !important !important",
        left: -2,
        bottom: 0,
        background: "#e9e6ff",
      },
    },
    "& .MuiTreeItem-label": {
      color: "#5A46BD !important",
    },
    "& .Mui-selected .MuiTreeItem-content .MuiTreeItem-label": {
      background: "blue !important",
    },
  }),

  treeViewListContainerSelected: (props: any) => ({
    position: "relative",
    // padding: "15px 50px 15px 10px !important",
    color: "#FFF !important",
    fontWeight: "700 !important",

    marginRight: "60px",
    // margin: "15px 15px 15px 0px",
    "&:before": {
      pointerEvents: "none",
      content: '""',
      position: "absolute",
      width: 38,
      left: -26,
      top: 36,
      borderBottom: `2px solid #817CA6`,
    },
    "& .MuiTreeItem-group": {
      marginLeft: 16,
      paddingLeft: 26,
      borderLeft: `2px dashed #817CA6 !important`,
      position: "relative",

      "&::before": {
        pointerEvents: "none",
        content: '""',
        position: "absolute",
        width: 2,
        height: "22px !important !important",
        left: -2,
        bottom: 0,
        background: "#e9e6ff",
      },
    },
    "& .MuiTreeItem-label": {
      // background: "#5A46BD !important",
      color: "#FFF !important",
      background: "#5A46BD !important",
      padding: "14px 11px 16px 10px !important",
    },
    // "& .MuiTreeItem-label:hover": {
    //   background: "#5A46BD !important",
    // },
    "& .MuiTreeItem-content": {
      // padding: "10px 50px 10px 10px !important",
    },
  }),

  treeViewListContainerDragged: (props: any) => ({
    position: "relative",
    // padding: "15px 0 15px 10px !important",
    // background: "#BEB6E7",
    // margin: "15px 90px 15px 0px",
    color: "white !important",
    // fontSize: 17,
    // fontWeight: "700 !important",

    "&:before": {
      pointerEvents: "none",
      content: '""',
      position: "absolute",
      width: 38,
      left: -26,
      top: 35,
      borderBottom: `2px solid #817CA6`,
    },
    "& .MuiTreeItem-root.Mui-selected": {
      background: "transparent !important",
    },
    "& .MuiTreeItem-root.Mui-selected:focus": {
      background: "transparent !important",
    },
    "& .MuiTreeItem-label": {
      background: "#BEB6E7 !important",
      padding: "15px 0 15px 10px !important",
    },
  }),
  treeViewListMainContainer: (props: any) => ({
    position: "relative",
    padding: "15px 0 15px 10px !important",
    "&:before": {
      pointerEvents: "none",
      content: '""',
      position: "absolute",
      width: 38,
      left: -26,
      top: 36,
      borderBottom: `2px solid #817CA6`,
    },
  }),
  treeViewListMainContainerDragged: (props: any) => ({
    position: "relative",
    // padding: "15px 0 15px 10px !important",
    "&:before": {
      pointerEvents: "none",
      content: '""',
      position: "absolute",
      width: 38,
      left: -26,
      top: 36,
      borderBottom: `2px solid #817CA6`,
    },
    "& .MuiTreeItem-label": {
      background: "#BEB6E7 !important",
      padding: "15px 0 15px 10px !important",
    },
  }),
  treeViewSubListMainContainer: (props: any) => ({
    position: "relative",
    padding: "15px 0 20px 10px !important",
    color: "#000 !important",
    "&:before": {
      pointerEvents: "none",
      content: '""',
      position: "absolute",
      width: 38,
      left: -26,
      top: 36,
      borderBottom: `2px solid #817CA6`,
    },
    "& .MuiTreeItem-group": {
      marginLeft: 16,
      paddingLeft: 26,
      borderLeft: `2px solid #817CA6`,
      position: "relative",
      "&::before": {
        pointerEvents: "none",
        content: '""',
        position: "absolute",
        width: 2,
        height: 36,
        left: -2,
        bottom: 0,
        background: "#e9e6ff",
      },
    },
    "& .MuiTreeItem-content": {
      border: "1px solid #817CA6",
      width: "200px",
      padding: "10px 10px",
      borderRadius: "3px",
      fontFamily: "Nunito Sans",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "22px",
      color: "#5D5D5D",
    },
    [muiTheme.breakpoints.down(1025)]: {
      "& .MuiTreeItem-content": { width: "100px !important" },
    },
    "& :first-child .MuiTreeItem-content": {
      border: "1px solid #817CA6",

      borderRadius: "3px",
    },

    "& :nth-child(1) .MuiTreeItem-content": {
      border: "none !important",
    },
    "&.Mui-expanded :nth-child(1) .MuiTreeItem-content": {
      background: " none",
      border: "none !important",
      borderRadius: "unset",
    },
    // "&.Mui-expanded:focus .MuiTreeItem-content .MuiTreeItem-label": {
    //   background: "red !imporant",
    //   "&:hover": {
    //     background: "none",
    //   },
    // },

    "&.Mui-expanded .MuiTreeItem-content": {
      background: " #E1DBFC",
      border: "1px solid #A396E3 !important",
      borderRadius: "3px",
    },
    // "& .MuiTreeItem-label:hover": {
    //   background: "none !important",
    // },
    // "& .MuiTreeItem-label": {
    //   color: "#000 !important",
    // },

    "& .MuiTreeItem-iconContainer": {
      zIndex: 1,
    },
  }),
  treeViewSubListContainer: (props: any) => ({
    position: "relative",
    // padding: "15px 0 15px 10px !important",
    // margin: "15px 90px 15px 0px",
    margin: "15px 90px 15px 0px",

    "&:before": {
      pointerEvents: "none",
      content: '""',
      position: "absolute",
      width: 38,
      left: -26,
      top: 22,
      borderBottom: `2px dashed #817CA6`,
    },
    "& .MuiTreeItem-group": {
      marginLeft: 16,
      paddingLeft: 26,
      borderLeft: `2px dashed #817CA6 !important`,
      position: "relative",
      color: "#5A46BD !important",

      "&::before": {
        pointerEvents: "none",
        content: '""',
        position: "absolute",
        width: 2,
        height: 36,
        left: -2,
        bottom: 0,
        background: "#e9e6ff",
      },
    },
    "& .MuiTreeItem-content": {
      color: "#5A46BD !important",
    },
  }),

  treeViewSubListContainerDragged: (props: any) => ({
    position: "relative",
    // padding: "15px 0 15px 10px !important",
    // background: "#BEB6E7",
    // margin: "15px 90px 15px 0px",
    color: "white !important",
    "&:before": {
      pointerEvents: "none",
      content: '""',
      position: "absolute",
      width: 38,
      left: -26,
      top: 36,
      borderBottom: `2px dashed #817CA6`,
    },
    "& .MuiTreeItem-label": {
      background: "#BEB6E7 !important",
      padding: "15px 0 15px 10px !important",
    },
  }),

  treeViewSubListContainerSelected: (props: any) => ({
    position: "relative",
    // padding: "15px 0 15px 10px !important",
    // margin: "15px 90px 15px 0px",
    // margin: "15px 90px 15px 0px",

    "&:before": {
      pointerEvents: "none",
      content: '""',
      position: "absolute",
      width: 38,
      left: -26,
      top: 22,
      borderBottom: `2px dashed #817CA6`,
    },
    "& .MuiTreeItem-group": {
      marginLeft: 16,
      paddingLeft: 26,
      borderLeft: `2px dashed #817CA6 !important`,
      position: "relative",
      color: "#5A46BD !important",

      "&::before": {
        pointerEvents: "none",
        content: '""',
        position: "absolute",
        width: 2,
        height: 36,
        left: -2,
        bottom: 0,
        background: "#e9e6ff",
      },
    },
    // "& .MuiTreeItem-content": {
    //   padding: "14px 11px 10px 10px !important",
    // },
    "& .MuiTreeItem-label": {
      // background: "#5A46BD !important",
      color: "#FFF !important",
      background: "#5A46BD !important",
      padding: "5px 11px 5px 10px !important",
    },
  }),
}));
export default useStyles;
