const dashBoardLogisticsData = {
  wareHouse: "400",
  inTransit: "400",
  trtackingDisconnected: "50",
  delayedDelivery: "10",
  tracking: [
    {
      id: "wareHouses",
      title: "Ware Houses",
      list: [
        {
          category: "wareHouses",
          type: "events",
          id: "wh-01",
          title: "WAREHOUSE#1",
          area: "Blaine County,Idaho,83333,US",
          timeStamp: "12 - 15 - 2022 | 09: 00 AM ",
          goodsTracked: "500",
          equippmentTracked: "50",
          accesspoint: "100",
          name: "Tracking Disconnected",
          warehouseCategory: {
            id: "78956",
            type: "equipment",
            locationType: "Forklift",
          },
          connectedStatus: "Disconnected",
          battery: "70%",
          temperature: "68°F",
          utilization: "70%",
          location: {
            lat: 43.79907035311681,
            lng: -114.28039120057936,
          },
        },
        {
          category: "wareHouses",

          type: "events",
          id: "wh-02",
          title: "WAREHOUSE#2",
          area: "Sanpete, Utah ,84004, US",
          timeStamp: "12 - 15 - 2022 | 09: 00 AM ",
          goodsTracked: "300",
          equippmentTracked: "20",
          accesspoint: "30",
          name: "Tracking Disconnected",
          warehouseCategory: {
            id: "78956",
            type: "equipment",
            locationType: "Forklift",
          },
          connectedStatus: "Disconnected",
          battery: "70%",
          temperature: "68°F",
          utilization: "70%",
          location: {
            lat: 39.086033079908006,
            lng: -111.7449989917454,
          },
        },
        {
          category: "wareHouses",
          type: "events",
          id: "wh-03",
          title: "WAREHOUSE#3",
          area: "Park County, Colorado ,80448, US",
          timeStamp: "12 - 15 - 2022 | 09: 00 AM ",
          goodsTracked: "250",
          equippmentTracked: "80",
          accesspoint: "90",
          name: "Tracking Disconnected",
          warehouseCategory: {
            id: "78956",
            type: "box",
            locationType: "Sanpete",
          },
          connectedStatus: "Disconnected",
          battery: "70%",
          temperature: "68°F",
          utilization: "70%",
          location: {
            lat: 39.29014748327991,
            lng: -105.51359377688414,
          },
        },
        {
          category: "wareHouses",
          type: "incidents",
          id: "wh-04",
          title: "WAREHOUSE#4",
          area: "New Mexico ,87070, US",
          timeStamp: "12 - 15 - 2022 | 09: 00 AM ",
          goodsTracked: "450",
          equippmentTracked: "56",
          accesspoint: "87",
          name: "Tracking Disconnected",
          warehouseCategory: {
            id: "78956",
            type: "box",
            locationType: "Sanpete",
          },
          connectedStatus: "Disconnected",
          battery: "70%",
          temperature: "68°F",
          utilization: "70%",
          location: {
            lat: 34.409092400018714,
            lng: -105.78635362479812,
          },
        },
        {
          category: "wareHouses",
          type: "incidents",
          id: "wh-05",
          title: "WAREHOUSE#5",
          area: "Arkansas ,72764, US",
          timeStamp: "12 - 15 - 2022 | 09: 00 AM ",
          goodsTracked: "270",
          equippmentTracked: "110",
          accesspoint: "150",
          name: "Tracking Disconnected",
          warehouseCategory: {
            id: "78956",
            type: "box",
            locationType: "Sanpete",
          },
          connectedStatus: "Disconnected",
          battery: "70%",
          temperature: "68°F",
          utilization: "70%",
          location: {
            lat: 34.59770786090786,
            lng: -92.33511244264716,
          },
        },
        {
          category: "wareHouses",
          type: "incidents",
          id: "wh-06",
          title: "WAREHOUSE#6",
          area: "Tennessee,37042, US",
          timeStamp: "12 - 15 - 2022 | 09: 00 AM ",
          goodsTracked: "186",
          equippmentTracked: "29",
          accesspoint: "48",
          name: "Tracking Disconnected",
          warehouseCategory: {
            id: "78956",
            type: "tracking",
            locationType: "Bedford County",
          },
          connectedStatus: "Disconnected",
          battery: "70%",
          temperature: "68°F",
          utilization: "70%",
          location: {
            lat: 35.452502871604196,
            lng: -86.59573881345014,
          },
        },
        {
          category: "wareHouses",
          type: "oprAlerts",
          id: "wh-07",
          title: "WAREHOUSE#7",
          area: "Kentucky ,42101, US",
          timeStamp: "12 - 15 - 2022 | 09: 00 AM ",
          goodsTracked: "230",
          equippmentTracked: "150",
          accesspoint: "130",
          name: "Tracking Disconnected",
          warehouseCategory: {
            id: "78956",
            type: "tracking",
            locationType: "Taylor County",
          },
          connectedStatus: "Disconnected",
          battery: "70%",
          temperature: "68°F",
          utilization: "70%",
          location: {
            lat: 37.29250034146601,
            lng: -85.26834029382246,
          },
        },
        {
          category: "wareHouses",
          type: "incidents",
          id: "wh-08",
          title: "WAREHOUSE#8",
          area: "Indiana,46307, US",
          timeStamp: "12 - 15 - 2022 | 09: 00 AM ",
          goodsTracked: "380",
          equippmentTracked: "120",
          accesspoint: "180",
          name: "Low Battery",
          warehouseCategory: {
            id: "78956",
            type: "box",
            locationType: "Madison County",
          },
          connectedStatus: "Connected",
          battery: "70%",
          temperature: "68°F",
          utilization: "70%",
          location: {
            lat: 40.11564979275498,
            lng: -85.79479322513693,
          },
        },
        {
          category: "wareHouses",
          type: "incidents",
          id: "wh-09",
          title: "WAREHOUSE#9",
          area: "Iowa,52001, US",
          timeStamp: "12 - 15 - 2022 | 09: 00 AM ",
          goodsTracked: "376",
          equippmentTracked: "123",
          accesspoint: "140",
          name: "High Temperature",
          warehouseCategory: {
            id: "78956",
            type: "box",
            locationType: "Story County",
          },
          connectedStatus: "Connected",
          battery: "70%",
          temperature: "68°F",
          utilization: "70%",
          location: {
            lat: 41.924691352436845,
            lng: -93.44515263221498,
          },
        },
        {
          category: "wareHouses",
          type: "incidents",
          id: "wh-10",
          title: "WAREHOUSE#10",
          area: "Wisconsin,54601, US",
          timeStamp: "12 - 15 - 2022 | 09: 00 AM ",
          goodsTracked: "400",
          equippmentTracked: "80",
          accesspoint: "200",
          name: "Moved out of Geofence",
          warehouseCategory: {
            id: "78956",
            type: "box",
            locationType: "Sanpete",
          },
          connectedStatus: "Disconnected",
          battery: "70%",
          temperature: "68°F",
          utilization: "70%",
          location: {
            lat: 44.76193082774029,
            lng: -90.47180628152171,
          },
        },
      ],
    },
    {
      id: "trailers",
      title: "Trailers",
      list: [
        {
          trailerId: "23123",
          category: "trailers",
          manifestId: "MID#123",
          type: "events",
          name: "Low Battery",
          id: "MID#213",
          title: "TRAILERS#1",
          source: "Blaine County,Idaho,83333,US",
          destination: "Missouri 82718, US",
          currentLocation: "Wyoming",
          connectedStatus: "Connected",
          timeStamp: "12 - 15 - 2022 | 09: 00 AM ",
          pallets: "210",
          stores: "120",
          direction: "SE",
          progress: 43,
          palletId: "PID#12345",
          battery: "70%",
          temperature: "68°F",
          utilization: "70%",
          location: {
            lat: 40.8802451532992,
            lng: -117.36097547059481,
          },
          packagaeData: [
            {
              packageStage: "Package Picked Up",
              timeStamp: "1-11-2023 9.00AM",
              status: "",
            },
            {
              packageStage: "Initial Scan",
              timeStamp: "1-11-2023 10.30AM",
              status: "",
            },
            {
              packageStage: "Staging & Tagging",
              timeStamp: "1-11-2023 12.30PM",
              status: "",
            },
            {
              packageStage: "Putaway",
              timeStamp: "1-11-2023 1.30PM",
              status: "",
            },
            {
              packageStage: "Connected",
              timeStamp: "1-11-2023 1.35PM",
              status: "",
            },
          ],
          sourceCoOrdinates: {
            lat: 40.8802451532992,
            lng: -117.36097547059481,
          },

          destinationCoOrdinates: {
            lat: 43.79907035311681,

            lng: -114.28039120057936,
          },
        },
        {
          trailerId: "18875",
          category: "trailers",
          manifestId: "MID#123",
          type: "events",
          name: "Tracking Disconnected",
          id: "MID#188",
          title: "TRAILERS#2",
          source: "Sanpete, Utah ,84004, US",
          destination: "St Landry Parish, Louisiana, 70072, US",
          currentLocation: "Texas",
          connectedStatus: "Disconnected",
          timeStamp: "12 - 15 - 2022 | 09: 00 AM ",
          pallets: "190",
          stores: "200",
          direction: "NW",
          progress: 32,
          palletId: "PID#12345",
          battery: "70%",
          temperature: "68°F",
          utilization: "70%",
          location: {
            lat: 28.582550349664588,
            lng: -99.95068507561465,
          },
          packagaeData: [
            {
              packageStage: "Package Picked Up",
              timeStamp: "1-11-2023 9.00AM",
              status: "",
            },
            {
              packageStage: "Initial Scan",
              timeStamp: "1-11-2023 10.30AM",
              status: "",
            },
            {
              packageStage: "Staging & Tagging",
              timeStamp: "1-11-2023 12.30PM",
              status: "",
            },
            {
              packageStage: "Putaway",
              timeStamp: "1-11-2023 1.30PM",
              status: "",
            },
            {
              packageStage: "Connected",
              timeStamp: "1-11-2023 1.35PM",
              status: "",
            },
          ],
          sourceCoOrdinates: {
            lat: 39.086033079908006,

            lng: -111.7449989917454,
          },

          destinationCoOrdinates: {
            lat: 30.582548349664588,
            lng: -91.95048507561465,
          },
        },
        {
          trailerId: "46454",
          category: "trailers",
          manifestId: "MID#123",
          type: "incidents",
          name: "High Temperature",
          id: "MID#464",
          title: "TRAILERS#3",
          source: "Park County, Colorado ,80448, US",
          destination: "Missouri 82718, US",
          currentLocation: "Wyoming",
          connectedStatus: "Disconnected",
          timeStamp: "12 - 15 - 2022 | 09: 00 AM ",
          pallets: "230",
          stores: "310",
          direction: "NE",
          progress: 40,
          palletId: "PID#12345",
          battery: "70%",
          temperature: "68°F",
          utilization: "70%",
          location: {
            lat: 43.76898438605152,
            lng: -107.7465857819973,
          },
          packagaeData: [
            {
              packageStage: "Package Picked Up",
              timeStamp: "1-11-2023 9.00AM",
              status: "",
            },
            {
              packageStage: "Initial Scan",
              timeStamp: "1-11-2023 10.30AM",
              status: "",
            },
            {
              packageStage: "Staging & Tagging",
              timeStamp: "1-11-2023 12.30PM",
              status: "",
            },
            {
              packageStage: "Putaway",
              timeStamp: "1-11-2023 1.30PM",
              status: "",
            },
            {
              packageStage: "Connected",
              timeStamp: "1-11-2023 1.35PM",
              status: "",
            },
          ],
          sourceCoOrdinates: {
            lat: 46.90571876690006,

            lng: -94.95721140748053,
          },

          destinationCoOrdinates: {
            lat: 43.79907035311681,

            lng: -114.28039120057936,
          },
        },
        {
          trailerId: "32132",
          category: "trailers",
          manifestId: "MID#123",
          type: "incidents",
          name: "Wrong Delivery",
          id: "tr-321",
          title: "TRAILERS#4",
          source: "ew Mexico,87070, US",
          destination: "West Dallas, Dallas,75001, US",
          currentLocation: "West Arlington",
          connectedStatus: "Connected",
          timeStamp: "12 - 15 - 2022 | 09: 00 AM ",
          pallets: "120",
          stores: "210",
          direction: "NE",
          progress: 50,
          palletId: "PID#12345",
          battery: "70%",
          temperature: "68°F",
          utilization: "70%",
          location: {
            lat: 35.77974081674368,
            lng: -102.78889171165856,
          },
          packagaeData: [
            {
              packageStage: "Package Picked Up",
              timeStamp: "1-11-2023 9.00AM",
              status: "",
            },
            {
              packageStage: "Initial Scan",
              timeStamp: "1-11-2023 10.30AM",
              status: "",
            },
            {
              packageStage: "Staging & Tagging",
              timeStamp: "1-11-2023 12.30PM",
              status: "",
            },
            {
              packageStage: "Putaway",
              timeStamp: "1-11-2023 1.30PM",
              status: "",
            },
            {
              packageStage: "Connected",
              timeStamp: "1-11-2023 1.35PM",
              status: "",
            },
          ],
          sourceCoOrdinates: {
            lat: 46.90571876690006,

            lng: -94.95721140748053,
          },

          destinationCoOrdinates: {
            lat: 43.79907035311681,

            lng: -114.28039120057936,
          },
        },
        {
          trailerId: "321265",
          category: "trailers",
          manifestId: "MID#123",
          type: "oprAlerts",
          name: "Low Battery",
          id: "tr-921",
          title: "TRAILERS#5",
          source: "Arkansas ,72764, US",
          destination: "Moore County,North Carolina,27610 US",
          currentLocation: "South Carolina",
          connectedStatus: "Connected",
          timeStamp: "12 - 15 - 2022 | 09: 00 AM ",
          pallets: "200",
          stores: "90",
          direction: "SE",
          progress: 30,
          palletId: "PID#12345",
          battery: "70%",
          temperature: "68°F",
          utilization: "70%",
          location: {
            lat: 43.43678962764772,
            lng: -99.40150534932609,
          },
          packagaeData: [
            {
              packageStage: "Package Picked Up",
              timeStamp: "1-11-2023 9.00AM",
              status: "",
            },
            {
              packageStage: "Initial Scan",
              timeStamp: "1-11-2023 10.30AM",
              status: "",
            },
            {
              packageStage: "Staging & Tagging",
              timeStamp: "1-11-2023 12.30PM",
              status: "",
            },
            {
              packageStage: "Putaway",
              timeStamp: "1-11-2023 1.30PM",
              status: "",
            },
            {
              packageStage: "Connected",
              timeStamp: "1-11-2023 1.35PM",
              status: "",
            },
          ],
          sourceCoOrdinates: {
            lat: 46.90571876690006,

            lng: -94.95721140748053,
          },

          destinationCoOrdinates: {
            lat: 43.79907035311681,

            lng: -114.28039120057936,
          },
        },
        // {
        //   trailerId: "645453",
        //   category: "trailers",
        //   type: "oprAlerts",
        //   name: "High Temperature",
        //   id: "tr-645",
        //   title: "TRAILERS#6",
        //   source: "ennessee,37042, US",
        //   destination: "Hardee County,Florida,32013, US",
        //   currentLocation: "South Carolina",
        //   connectedStatus: "Connected",
        //   timeStamp: "12 - 15 - 2022 | 09: 00 AM ",
        //   pallets: "100",
        //   stores: "230",
        //   direction: "SE",
        //   progress: 40,
        //   palletId: "PID#12345",
        //   battery: "70%",
        //   temperature: "68°F",
        //   utilization: "70%",
        //   location: {
        //     lat: 43.96240386055434,
        //     lng: -99.90178880677154,
        //   },
        //   packagaeData: [
        //     {
        //       packageStage: "Package Picked Up",
        //       timeStamp: "1-11-2023 9.00AM",
        //       status: "",
        //     },
        //     {
        //       packageStage: "Initial Scan",
        //       timeStamp: "1-11-2023 10.30AM",
        //       status: "",
        //     },
        //     {
        //       packageStage: "Staging & Tagging",
        //       timeStamp: "1-11-2023 12.30PM",
        //       status: "",
        //     },
        //     {
        //       packageStage: "Putaway",
        //       timeStamp: "1-11-2023 1.30PM",
        //       status: "",
        //     },
        //     {
        //       packageStage: "Connected",
        //       timeStamp: "1-11-2023 1.35PM",
        //       status: "",
        //     },
        //   ],
        //   sourceCoOrdinates: {
        //     lat: 46.90571876690006,

        //     lng: -94.95721140748053,
        //   },

        //   destinationCoOrdinates: {
        //     lat: 43.79907035311681,

        //     lng: -114.28039120057936,
        //   },
        // },
        // {
        //   trailerId: "543451",
        //   category: "trailers",
        //   type: "incidents",
        //   name: "Wrong Delivery",
        //   id: "tr-541",
        //   title: "TRAILERS#7",
        //   source: "entucky,42101, US",
        //   destination: "Brooklyn,New York,00501 , US",
        //   currentLocation: "New Jersey",
        //   connectedStatus: "Connected",
        //   timeStamp: "12 - 15 - 2022 | 09: 00 AM ",
        //   pallets: "180",
        //   stores: "120",
        //   direction: "SE",
        //   progress: 55,
        //   palletId: "PID#12345",
        //   battery: "70%",
        //   temperature: "68°F",
        //   utilization: "70%",
        //   location: {
        //     lat: 40.6718896695605,
        //     lng: -73.93711604130529,
        //   },
        //   packagaeData: [
        //     {
        //       packageStage: "Package Picked Up",
        //       timeStamp: "1-11-2023 9.00AM",
        //       status: "",
        //     },
        //     {
        //       packageStage: "Initial Scan",
        //       timeStamp: "1-11-2023 10.30AM",
        //       status: "",
        //     },
        //     {
        //       packageStage: "Staging & Tagging",
        //       timeStamp: "1-11-2023 12.30PM",
        //       status: "",
        //     },
        //     {
        //       packageStage: "Putaway",
        //       timeStamp: "1-11-2023 1.30PM",
        //       status: "",
        //     },
        //     {
        //       packageStage: "Connected",
        //       timeStamp: "1-11-2023 1.35PM",
        //       status: "",
        //     },
        //   ],
        //   sourceCoOrdinates: {
        //     lat: 46.90571876690006,

        //     lng: -94.95721140748053,
        //   },

        //   destinationCoOrdinates: {
        //     lat: 43.79907035311681,

        //     lng: -114.28039120057936,
        //   },
        // },
        // {
        //   trailerId: "434231",
        //   category: "trailers",
        //   type: "incidents",
        //   name: "Tracking Disconnected",
        //   id: "tr-434",
        //   title: "TRAILERS#8",
        //   source: "Indiana,46307, US",
        //   destination: "Brooklyn,New York,00501 , US",
        //   currentLocation: "New Jersey",
        //   connectedStatus: "Disconnected",
        //   timeStamp: "12 - 15 - 2022 | 09: 00 AM ",
        //   pallets: "210",
        //   stores: "130",
        //   direction: "SE",
        //   progress: 25,
        //   palletId: "PID#12345",
        //   battery: "70%",
        //   temperature: "68°F",
        //   utilization: "70%",
        //   location: {
        //     lat: 44.10191467572244,
        //     lng: -84.85475136333928,
        //   },
        //   packagaeData: [
        //     {
        //       packageStage: "Package Picked Up",
        //       timeStamp: "1-11-2023 9.00AM",
        //       status: "",
        //     },
        //     {
        //       packageStage: "Initial Scan",
        //       timeStamp: "1-11-2023 10.30AM",
        //       status: "",
        //     },
        //     {
        //       packageStage: "Staging & Tagging",
        //       timeStamp: "1-11-2023 12.30PM",
        //       status: "",
        //     },
        //     {
        //       packageStage: "Putaway",
        //       timeStamp: "1-11-2023 1.30PM",
        //       status: "",
        //     },
        //     {
        //       packageStage: "Connected",
        //       timeStamp: "1-11-2023 1.35PM",
        //       status: "",
        //     },
        //   ],
        //   sourceCoOrdinates: {
        //     lat: 46.90571876690006,

        //     lng: -94.95721140748053,
        //   },

        //   destinationCoOrdinates: {
        //     lat: 43.79907035311681,

        //     lng: -114.28039120057936,
        //   },
        // },
        // {
        //   trailerId: "535231",
        //   category: "trailers",
        //   type: "incidents",
        //   name: "High Temperature",
        //   id: "tr-535",
        //   title: "TRAILERS#9",
        //   source: "Iowa,52001, US",
        //   destination: "Missouri 82718, US",
        //   currentLocation: "Wyoming",
        //   connectedStatus: "Connected",
        //   timeStamp: "12 - 15 - 2022 | 09: 00 AM ",
        //   pallets: "260",
        //   stores: "150",
        //   direction: "SE",
        //   progress: 65,
        //   palletId: "PID#12345",
        //   battery: "70%",
        //   temperature: "68°F",
        //   utilization: "70%",
        //   location: {
        //     lat: 40.764370717801995,
        //     lng: -73.6332412532966,
        //   },
        //   packagaeData: [
        //     {
        //       packageStage: "Package Picked Up",
        //       timeStamp: "1-11-2023 9.00AM",
        //       status: "",
        //     },
        //     {
        //       packageStage: "Initial Scan",
        //       timeStamp: "1-11-2023 10.30AM",
        //       status: "",
        //     },
        //     {
        //       packageStage: "Staging & Tagging",
        //       timeStamp: "1-11-2023 12.30PM",
        //       status: "",
        //     },
        //     {
        //       packageStage: "Putaway",
        //       timeStamp: "1-11-2023 1.30PM",
        //       status: "",
        //     },
        //     {
        //       packageStage: "Connected",
        //       timeStamp: "1-11-2023 1.35PM",
        //       status: "",
        //     },
        //   ],
        //   sourceCoOrdinates: {
        //     lat: 46.90571876690006,

        //     lng: -94.95721140748053,
        //   },

        //   destinationCoOrdinates: {
        //     lat: 43.79907035311681,

        //     lng: -114.28039120057936,
        //   },
        // },
        // {
        //   trailerId: "975842",
        //   category: "trailers",
        //   type: "incidents",
        //   name: "Wrong Delivery",
        //   id: "tr-975",
        //   title: "TRAILERS#10",
        //   source: "Wisconsin,54601, US",
        //   destination: "Maine,04240, US",
        //   currentLocation: "Vermont",
        //   connectedStatus: "Connected",
        //   timeStamp: "12 - 15 - 2022 | 09: 00 AM ",
        //   pallets: "290",
        //   stores: "270",
        //   direction: "SE",
        //   progress: 73,
        //   palletId: "PID#12345",
        //   battery: "70%",
        //   temperature: "68°F",
        //   utilization: "70%",
        //   location: {
        //     lat: 45.6677904344555,
        //     lng: -69.05224613349577,
        //   },
        //   packagaeData: [
        //     {
        //       packageStage: "Package Picked Up",
        //       timeStamp: "1-11-2023 9.00AM",
        //       status: "",
        //     },
        //     {
        //       packageStage: "Initial Scan",
        //       timeStamp: "1-11-2023 10.30AM",
        //       status: "",
        //     },
        //     {
        //       packageStage: "Staging & Tagging",
        //       timeStamp: "1-11-2023 12.30PM",
        //       status: "",
        //     },
        //     {
        //       packageStage: "Putaway",
        //       timeStamp: "1-11-2023 1.30PM",
        //       status: "",
        //     },
        //     {
        //       packageStage: "Connected",
        //       timeStamp: "1-11-2023 1.35PM",
        //       status: "",
        //     },
        //   ],
        //   sourceCoOrdinates: {
        //     lat: 46.90571876690006,

        //     lng: -94.95721140748053,
        //   },

        //   destinationCoOrdinates: {
        //     lat: 43.79907035311681,

        //     lng: -114.28039120057936,
        //   },
        // },
      ],
    },
    {
      id: "stores",
      title: "Stores",
      list: [
        {
          category: "stores",
          connectedStatus: "Disconnected",
          type: "incidents",
          name: "Wrong Delivery",
          id: "MID#212",
          title: "Stores#1",
          area: "Missouri, 80831, US",
          timeStamp: "12 - 15 - 2022 | 09: 00 AM ",
          pallets: "230",
          deliveryDate: "13-03-2023",
          palletId: "PID#12345",
          battery: "70%",
          temperature: "68°F",
          utilization: "70%",
          location: {
            lat: 38.14519438097562,
            lng: -93.98795505339388,
          },
        },
        {
          category: "stores",
          connectedStatus: "Disconnected",
          type: "incidents",
          name: "Wrong Delivery",
          id: "MID#542",
          title: "Stores#2",
          area: "St Landry Parish, Louisiana, 70072, US",
          timeStamp: "12 - 15 - 2022 | 09: 00 AM ",
          pallets: "320",
          deliveryDate: "13-03-2023",
          palletId: "PID#12345",
          battery: "70%",
          temperature: "68°F",
          utilization: "70%",
          location: {
            lat: 30.186066559868127,
            lng: -91.40111595998022,
          },
        },
        {
          category: "stores",
          connectedStatus: "Disconnected",
          type: "incidents",
          name: "Wrong Delivery",
          id: "MID#548",
          title: "Stores#3",
          area: "Missouri 82718, US",
          timeStamp: "12 - 15 - 2022 | 09: 00 AM ",
          pallets: "320",
          deliveryDate: "13-03-2023",
          palletId: "PID#12345",
          battery: "70%",
          temperature: "68°F",
          utilization: "70%",
          location: {
            lat: 44.90706408332429,
            lng: -100.07608985357214,
          },
        },
        {
          category: "stores",
          connectedStatus: "Disconnected",
          type: "incidents",
          name: "Wrong Delivery",
          id: "MID#321",
          title: "Stores#4",
          area: "West Dallas, Dallas,75001, US",
          timeStamp: "12 - 15 - 2022 | 09: 00 AM ",
          pallets: "290",
          deliveryDate: "13-03-2023",
          palletId: "PID#12345",
          battery: "70%",
          temperature: "68°F",
          utilization: "70%",
          location: {
            lat: 32.7721021868417,
            lng: -96.88124899881679,
          },
        },
        {
          category: "stores",
          connectedStatus: "Disconnected",
          type: "incidents",
          name: "Wrong Delivery",
          id: "MID#432",
          title: "Stores#5",
          area: "Moore County,North Carolina,27610 US",
          timeStamp: "12 - 15 - 2022 | 09: 00 AM ",
          pallets: "430",
          deliveryDate: "13-03-2023",
          palletId: "PID#12345",
          battery: "70%",
          temperature: "68°F",
          utilization: "70%",
          location: {
            lat: 34.02457421896558,
            lng: -77.90347859395338,
          },
        },
        {
          category: "stores",
          connectedStatus: "Connected",

          name: "Wrong Delivery",
          type: "incidents",
          id: "MID#269",
          title: "Stores#6",
          area: "Hardee County,Florida,32013, US",
          timeStamp: "12 - 15 - 2022 | 09: 00 AM ",
          pallets: "310",
          deliveryDate: "13-03-2023",
          palletId: "PID#12345",
          battery: "70%",
          temperature: "68°F",
          utilization: "70%",
          location: {
            lat: 30.46167025143046,
            lng: -85.18707482656161,
          },
        },
        // {
        //   category: "stores",
        //   connectedStatus: "Connected",

        //   name: "Wrong Delivery",
        //   type: "incidents",
        //   id: "MID#421",
        //   title: "Stores#7",
        //   area: "Brooklyn,New York,00501 , US",
        //   timeStamp: "12 - 15 - 2022 | 09: 00 AM ",
        //   pallets: "510",
        //   deliveryDate: "13-03-2023",
        //   palletId: "PID#12345",
        //   battery: "70%",
        //   temperature: "68°F",
        //   utilization: "70%",
        //   location: {
        //     lat: 40.627086762534624,
        //     lng: -73.9357427503417,
        //   },
        // },
        // {
        //   category: "stores",
        //   connectedStatus: "Connected",

        //   name: "Wrong Delivery",
        //   type: "oprAlerts",
        //   id: "MID#763",
        //   title: "Stores#8",
        //   area: "Brooklyn,New York,00501 , US",
        //   timeStamp: "12 - 15 - 2022 | 09: 00 AM ",
        //   pallets: "710",
        //   deliveryDate: "13-03-2023",
        //   palletId: "PID#12345",
        //   battery: "70%",
        //   temperature: "68°F",
        //   utilization: "70%",
        //   location: {
        //     lat: 40.772326203874975,
        //     lng: -73.80665339976453,
        //   },
        // },
        {
          category: "stores",
          connectedStatus: "Connected",
          name: "Delivered",
          type: "events",
          id: "MID#590",
          title: "Stores#7",
          area: "Missouri 82718, US",
          timeStamp: "12 - 15 - 2022 | 09: 00 AM ",
          pallets: "653",
          deliveryDate: "13-03-2023",
          palletId: "PID#12345",
          battery: "70%",
          temperature: "68°F",
          utilization: "70%",
          location: {
            lat: 39.18314338466818,
            lng: -91.24137312622004,
          },
        },
        // {
        //   category: "stores",
        //   connectedStatus: "Connected",

        //   name: "Wrong Delivery",
        //   type: "incidents",
        //   id: "MID#541",
        //   title: "Stores#10",
        //   area: "Maine,04240, US",
        //   timeStamp: "12 - 15 - 2022 | 09: 00 AM ",
        //   pallets: "765",
        //   deliveryDate: "13-03-2023",
        //   palletId: "PID#12345",
        //   battery: "70%",
        //   temperature: "68°F",
        //   utilization: "70%",
        //   location: {
        //     lat: 46.46811835898781,
        //     lng: -68.67871099140014,
        //   },
        // },
      ],
    },
  ],
};

export default dashBoardLogisticsData;
