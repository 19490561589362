import { makeStyles } from "@mui/styles";
import muiTheme from "theme/muiTheme";

const useStyles = makeStyles(() => ({
  customTextFieldItem: (props: any) => ({
    // marginTop: "5px",
    paddingRight: "16px",
    "& .MuiFormControl-root": {
      background: "none !important",
    },
    "& .MuiInputLabel-root": {
      color: "white !important",
      lineHeight: "22px",
      fontFamily: "Nunito Sans",
    },
    "& .MuiTextField-root": {
      width: "100%",
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
      width: "100%",
      border: "1px solid #DDDDDD",
      borderRadius: "6px",
      background: "#000000",
      color: "fff !important",
      "& .MuiInputBase-input": {
        color: "#FFFFFF",
        "-webkit-text-fill-color": "#FFFFFF",
        height: "38px",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px !important",
    },
  }),
  inputSearchItem: (props: any) => ({
    fontSize: "16px !importat",
    fontFamily: `'Nunito Sans', sans-serif !important`,
  }),
  dateTimePickerStyle: (props: any) => ({
    display: "flex",
    "& .MuiOutlinedInput-input": {
      color: "white !important",
      opacity: 1,
      fontFamily: "Nunito Sans",
      "& ::placeholder": {
        color: "white !important",
        opacity: 1,
        fontFamily: "Nunito Sans",
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
      outline: "none !important",
    },

    "& .MuiSvgIcon-root": {
      fill: "white !important",
    },
    "& .MuiInputLabel-root": {
      color: "white !important",
      lineHeight: "22px",
    },
    "::-webkit-input-placeholder": { color: "white !important" },
  }),
  rootContainer: (props: any) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }),
  addIcon: (props: any) => ({
    cursor: "pointer",
  }),
  palletListContainer: (props: any) => ({
    border: "1px solid rgba(255, 255, 255, 0.7)",
    borderRadius: "5px",
    height: "332px",
    overflow: " auto",
    [muiTheme.breakpoints.down(1345)]: {
      height: 120,
    },
    [muiTheme.breakpoints.down(1281)]: {
      height: 280,
    },
    [muiTheme.breakpoints.down(1025)]: {
      height: 260,
    },
    [muiTheme.breakpoints.down(963)]: {
      height: 78,
    },
  }),
  footer: (props: any) => ({
    display: "flex",
    justifyContent: "end",
    background: " #2B2A2A",
    borderRadius: "0px 0px 10px 10px",
    margin: "-24px",
    position: "absolute",
    width: "calc(100% - 48px)",
    padding: "24px",
    bottom: "24px",
  }),
  cancelButton: (props: any) => ({
    paddingRight: "20px",
    "& .MuiButton-outlined": {
      fontFamily: "Nunito Sans",
      fontWeight: "700 !important",
      fontSize: "14px !important",
      lineHeight: "19px",
      color: "#989898 !important",
      backgroundColor: "transparent !important",
      border: "1px solid #989898",
    },
  }),
  saveButton: (props: any) => ({
    // paddingRight: "20px",

    "& .MuiButton-contained": {
      fontFamily: "Nunito Sans",
      fontWeight: "700 !important",
      fontSize: "14px !important",
      lineHeight: "19px",
      color: "#fff !important",
      background: "#8F7DE9 !important",
      border: "1px solid #3A325F !important",
    },
  }),
  dashedLine: (props: any) => ({
    margin: "0px 20px 0px 20px",
    border: "1.5px dashed #CEC8EF",
  }),
  palletListItemContainer: (props: any) => ({
    display: "flex",
    padding: " 30px 20px 20px 20px",
  }),
  palletList: (props: any) => ({
    display: "flex",
    justifyContent: "center",
    border: "1px solid #8F8F8F",
    height: " 100px",
    alignItems: "center",
    background: "#535252",
  }),
  searchContainer: (props: any) => ({
    padding: "0px 20px 20px 20px",
  }),
  palletListItems: (props: any) => ({
    display: "flex",
    border: "1px solid #8F8F8F",
    height: " 100px",
    background: "#535252",
    padding: "15px 20px",
    "& .MuiChip-root": {
      backgroundColor: "#998AE2 !important",
      borderRadius: "15px",
      color: "#FFFFFF",
    },
  }),
  palletListData: (props: any) => ({
    background: "#000000",
    opacity: 0.9,
    border: "1px solid #FFFFFF",
    borderRadius: "8px",
    marginTop: "20px",
    "& .Mui-selected": {
      backgroundColor: "#9486DA !important",
      color: "#FFFFFF",
    },
    "& .MuiListItem-gutters:hover": {
      backgroundColor: "#9486DA",
      color: "#FFFFFF",
    },
  }),
  listSpace: () => ({
    paddingRight: "16px !important",
    [muiTheme.breakpoints.down(963)]: {
      marginBottom: "16px !important",
    },
  }),
}));

export default useStyles;
