import { makeStyles } from "@mui/styles";
import muiTheme from "../../theme/muiTheme";

const useStyles = makeStyles({
  innerSection: (props: any) => ({
    width: "auto !important",
    margin: 26,
    background: "white",
    padding: 48,
    height: "calc(100vh - 232px)",
    [muiTheme.breakpoints.down(811)]: {
      paddingTop: "90px !important",
      height: "calc(100vh - 304px)",
      padding: 26,
    },
  }),
  customTable: () => ({
    "& .MuiTableCell-root": {
      height: "66px !important",
      padding: "0 16px !important",
      [muiTheme.breakpoints.down(1437)]: {
        minWidth: 200,
      },
    },
    "& .MuiTableCell-head": {
      background: "#8A7BD6",
      fontFamily: "Nunito Sans",
      fontWeight: 700,
      fontSize: "15px",
      lineHeight: "20px",
      color: "#FFFFFF",
    },
    "& .MuiTable-root": {
      borderCollapse: "separate",
      borderSpacing: " 0 12px",
    },
    // "& :first-child": {
    //   borderLeft: "1px solid #d6d6d6 !important",
    // },
    "& .MuiTableCell-body": {
      fontFamily: "Nunito Sans",
      fontWeight: 700,
      fontSize: "15px",
      lineHeight: "20px",
      color: "#5D5D5D",
      borderBottom: "1px solid #d6d6d6 !important",
      borderTop: "1px solid #d6d6d6 !important",
      borderCollapse: "separate",
      borderSpacing: "15px",
      borderRadius: "2px",
    },

    "& .MuiTableBody-root": {
      "& .MuiTableRow-root": {
        "& td:first-child": {
          borderLeft: "1px solid #d6d6d6 !important",
        },
        "& td:last-child": {
          borderRight: "1px solid #d6d6d6 !important",
        },
      },
    },
  }),
  searchClass: (props: any) => ({
    border: `1px solid ${props?.palette?.dashboardList?.lightGrey}`,
    background: props?.palette?.dashboardList?.white,
    color: props?.palette?.dashboardList?.lightGrey3,
    borderRadius: 25,
    padding: "0px 18px 0 8px",
    boxShadow: "0px 0px 4px 1px #D5D0F1",
    "& .MuiButtonBase-root:hover": {
      background: "none !important",
    },
    "& .MuiButtonBase-root.Mui-focused .MuiTouchRipple-root": {
      background: "none !important",
    },
    "& .MuiButtonBase-root": {
      transition: "none !important",
    },
    [muiTheme.breakpoints.down(963)]: {
      width: 160,
    },
    "& .MuiIconButton-root": {
      marginRight: "-8px",
    },
    "& .MuiInputBase-root": {
      // fontWeight: 500,
    },
  }),
  searchBoxSection: () => ({
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: "-10px",
    right: "0",
    "& img": {
      cursor: "pointer",
      marginRight: 20,
    },
    // "& :first-child": {
    //   marginRight: " 20px !important",
    //   paddingLeft: "5px",
    // },
    [muiTheme.breakpoints.down(810)]: {
      top: "-70px",
    },
  }),
  tableTabsSection: () => ({
    position: "relative",
    height: "fit-content",
  }),

  customNotificationTabs: (props: any) => ({
    "& .MuiTabs-flexContainer": {
      // borderBottom: "2px solid #3e3e3e",
    },

    "& .MuiButtonBase-root": {
      marginRight: "5px !important",
      marginLeft: "20px !important",
      minWidth: "64px !important",
      padding: "0px 0px 12px 0px !important",
      borderRadius: "10px 10px 0px 0px",
    },

    "& .MuiTab-root": {
      fontSize: "16px",
      fontFamily: "Nunito Sans",
      fontWeight: 600,
      lineHeight: "20px",
      color: "#FFFFFF",

      "&:first-child": {
        "& div": {
          background: "#F3F0FC",
          padding: "10px 20px 10px 20px",
          borderRadius: "10px",
          color: "#A89BEC !important",
        },
      },
      "&:first-child.Mui-selected": {
        background: "#50AC58",

        "& div": {
          background: "#50AC58",
          padding: "10px 20px 10px 20px",
          borderRadius: "10px",
          color: "#fff !important",
        },
      },
      "&:nth-child(2)": {
        "& div": {
          background: "#F3F0FC",
          padding: "10px 20px 10px 20px",
          borderRadius: "10px",
          color: "#A89BEC !important",
        },
      },
      "&:nth-child(2).Mui-selected": {
        background: "#50AC58",
        "& div": {
          background: "#50AC58",
          padding: "10px 20px 10px 20px",
          borderRadius: "10px",
          color: "#fff !important",
        },
      },
      "&:nth-child(3)": {
        "& div": {
          background: "#F3F0FC",
          padding: "10px 20px 10px 20px",
          borderRadius: "10px",
          color: "#A89BEC !important",
        },
      },
      "&:nth-child(3).Mui-selected": {
        background: "#50AC58",
        "& div": {
          background: "#50AC58",
          padding: "10px 20px 10px 20px",
          borderRadius: "10px",
          color: "#fff !important",
        },
      },
    },
    "& .MuiTabs-root .MuiTabs-indicator": {
      background: "#50AC58",
      height: "3px !important",
      borderRadius: "1.5px",
      width: "100% !important",
      left: "0 !important",
    },
  }),
  loaderStyle: (props: any) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 250px)",
  }),
});
export default useStyles;
