import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import useTranslation from "../../localization/translations";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InfoSubList from "../InfoSubList";
import CustomizedSteppers from "../Stepper";
import ChipButton from "../../elements/ChipButton";
import Tabs from "../../elements/Tabs";
import { SearchIconInfo } from "../../assets/buttonIcons";
import theme from "../../theme/theme";
import colorCodes from "../../theme/colors";
import Geofence from "components/Geofence";
import FloorMap from "components/Map/FloorMap";
import Map from "components/Map";
import InfoNotificationListItem from "components/InfoNotificationListItem";
import TimeLineStepper from "elements/TimeLineStepper";
import trailersInfoWindowData from "mockdata/trailersInfoWindowData";
import Accordian from "components/InfoWindowAccordian";
import SearchBox from "elements/SearchBox";
import Select from "elements/Select";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import useStyles from "./styles";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import Calendar from "elements/Calendar";
import PalletList from "components/PalletList";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// @ts-ignore
import { doc, setDoc, updateDoc } from "firebase/firestore/lite";
// @ts-ignore
import { db } from "services/firebase";
import { useFormik } from "formik";
import * as Yup from "yup";

const DialogWrapper = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiBackdrop-root": {
    marginTop: "84px !important",
  },
  "& .MuiPaper-root": {
    maxHeight: "calc(100% - 160px)",
    minHeight: "calc(100% - 160px)",
    width: "80vw",
    maxWidth: "1772px",
    background: `#000 !important`,
    opacity: 0.9,
    color: colorCodes.colorWhite,
    padding: 24,
    borderRadius: "12px",
  },
  "& .MuiDialog-container": {
    marginTop: "60px !important",
    background: "rgb(1 1 1 / 0%)",
    backdropFilter: "blur(4px)",
    height: "calc(100vh - 65px) !important",
  },
}));

const MenifestInfoDialog: React.FC<any> = (props) => {
  const {
    selectedItemID,
    setShowInfoDialogue,
    actionName,
    setShowManifestDialogue,
    tableData,
    setTabIndex,
    previousTab,
    tabIndex,
    setTableData,
  } = props;

  const [selectedItem, setSelectedItem] = useState<any>();

  useEffect(() => {
    if (selectedItemID) {
      trailersInfoWindowData?.trailersInfolist?.map((item) => {
        if (item?.trailerId === selectedItemID) {
          setSelectedItem(item);
        }
      });
    }
  }, [selectedItemID]);

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const {
    headerStyle,

    subListRow,

    customNotificationTabs,
    dateTimePickerStyle,
    displayFlex,
    timePickerStyle,
    timeInputStyle,
    wareHouseClass,
    trailerClass,
    alertMsgStyle,
  } = useStyles(appTheme);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "GET_TRAILER_LIST",
    });
  }, []);

  const apiDate = useSelector(
    (state: any) => state?.trailerList.trailerDataValue
  );

  const [trailerData, setTrailerData] = useState(apiDate.trailers);

  useEffect(() => {
    setTrailerData(apiDate.trailers);
  }, [apiDate]);

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );

  const {} = useTranslation();

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  const [open, setOpen] = useState(!false);
  const [tabIndexInfo, setTabIndexInfo] = useState<number>(0);
  const [wareHouse, setWareHouse] = useState<string>();
  const [trailer, setTrailer] = useState<string>();
  const [startDate, setStartDate] = useState<any>();
  const [startTime, setStartTime] = useState<any>();

  const handleClose = () => {
    setTabIndex(previousTab);
    setOpen(!open);
    setShowManifestDialogue(false);
  };

  const tabsList = [
    {
      name: actionName === "create" ? "Create Manifest" : "Edit Manifest",
      val: 0,
    },
  ];

  const handleTabs = (index: number) => {
    setTabIndexInfo(index);
  };

  const selectListWareHouse = [
    { label: "WAREHOUSE#1", value: "WAREHOUSE#1" },
    { label: "WAREHOUSE#2", value: "WAREHOUSE#2" },
    { label: "WAREHOUSE#3", value: "WAREHOUSE#3" },
    { label: "WAREHOUSE#4", value: "WAREHOUSE#4" },
    { label: "WAREHOUSE#5", value: "WAREHOUSE#5" },
  ];

  const handleSelect = (val: string) => {
    setWareHouse(val);
  };

  const handleTrailerSelect = (val: string) => {
    setTrailer(val);
  };

  const handleSelectedDate = (value: any) => {
    setStartDate(value);
  };

  const handleSelectedTime = (e: any) => {
    setStartTime(e.target.innerText);
  };

  let times = [],
    periods = ["AM", "PM"],
    hours = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    prop = null,
    hour = null,
    min = null;

  for (prop in periods) {
    for (hour in hours) {
      for (min = 0; min < 60; min += 60) {
        times.push(
          ("0" + hours[hour]).slice(-2) +
            ":" +
            ("0" + min).slice(-2) +
            " " +
            periods[prop]
        );
      }
    }
  }

  const [alertMsg, setAlertMsg] = useState<any>(false);
  const [warehoueseAlert, setWareHouseAlert] = useState<any>(false);
  const [trailerAlert, setTrailerAlert] = useState<any>(false);
  const [startDateAlert, setStartDateAlert] = useState<any>(false);
  const [startTimeAlert, setStartTimeAlert] = useState<any>(false);
  const [storeAlert, setStoreAlert] = useState<any>(false);
  const [deliveryDateAlert, setDeliveryDateAlert] = useState<any>(false);
  const [deliveryTimeAlert, setDeliveryTimeAlert] = useState<any>(false);
  const [palletAlert, setPalletAlert] = useState<any>(false);

  useEffect(() => {
    setTimeout(() => {
      setAlertMsg(false);
      setDeliveryDateAlert(false);
      setDeliveryTimeAlert(false);
      setStoreAlert(false);
      setWareHouseAlert(false);
      setTrailerAlert(false);
      setStartDateAlert(false);
      setStartTimeAlert(false);
      setPalletAlert(false);
    }, 5000);
  }, [alertMsg]);

  const getAlertMessage = () => {
    return (
      <div className={alertMsgStyle}>
        Please Select "{warehoueseAlert && "WareHouse,"}{" "}
        {trailerAlert && "Trailer,"} {startDateAlert && "Start Date, "}{" "}
        {startTimeAlert && "Start Time,"} {storeAlert && "Store, "}{" "}
        {deliveryDateAlert && "Delivery Date,"}{" "}
        {deliveryTimeAlert && "Delivery Time,"} {palletAlert && "Pallet"}"
      </div>
    );
  };

  const handleSave = (stores: any) => {
    const storesMap = stores?.map((item: any) => {
      return (item?.deliveryDate !== "" || undefined) &&
        (item?.deliveryTime !== "" || undefined) &&
        item?.name !== "" &&
        item?.pallets?.length > 0
        ? true
        : false;
    });

    const storesMapMessage = stores?.map((item: any) => {
      item?.deliveryDate === "" && setDeliveryDateAlert(true);
      (item?.deliveryTime === "" || undefined) && setDeliveryTimeAlert(true);
      item?.name === "" && setStoreAlert(true);
      wareHouse === undefined && setWareHouseAlert(true);
      trailer === undefined && setTrailerAlert(true);
      startDate === undefined && setStartDateAlert(true);
      startTime === undefined && setStartTimeAlert(true);
      item?.pallets?.length === 0 && setPalletAlert(true);
    });

    if (
      wareHouse !== undefined &&
      trailer !== undefined &&
      startDate !== undefined &&
      startTime !== undefined &&
      !storesMap?.includes(false)
    ) {
      setShowManifestDialogue(false);
      const payload = {
        wareHouse: wareHouse,
        trailer: trailer,
        startDate: moment(startDate).format("DD-MM-YYYY"),
        startTime: startTime,
        stores: stores,
      };

      const autoMID =
        "MID#" + Math.floor(Math.random() * (999 - 100 + 1) + 100);

      const getLatestDate = (data: any) => {
        const sorted_ms = data
          .map((item: any) => {
            return new Date(item.deliveryDate).getTime();
          })
          .sort();

        const latest_ms = sorted_ms[sorted_ms.length - 1];

        return new Date(latest_ms);
      };

      const latestDate = getLatestDate(stores);

      const endLocation = stores?.find(
        (date: any) =>
          moment(date.deliveryDate).format("DD-MM-YYYY") ===
          moment(latestDate).format("DD-MM-YYYY")
      );

      const numberOfPallets: any[] = [];
      stores?.map((item: any) => {
        item?.pallets?.map((pallet: any) => {
          numberOfPallets.push(pallet);
        });
      });

      const createManifestData = {
        manifestId: autoMID,
        startDateTime:
          moment(startDate).format("DD-MM-YYYY") + " | " + startTime,
        trailerId: trailer,
        sourceWarehouse: wareHouse,
        deliveryStores: stores.length,
        noOfPallets: numberOfPallets?.length,
        endLocation: endLocation?.name,
        endDateTime:
          moment(latestDate).format("DD-MM-YYYY") +
          " | " +
          stores[0]?.deliveryTime,
        manifestData: payload,
      };

      tableData?.unshift(createManifestData);

      // tableData.length > 0
      //   && tableData.unshift(createManifestData)

      const ref = doc(db, "manifestUpcoming", "upcomingData");
      {
        if (tableData.length > 0) {
          updateDoc(ref, { data: tableData, merge: true })
            .then((success: any) => {})
            .catch((error: any) => console.log(error));
        } else {
          updateDoc(ref, { data: createManifestData, merge: true })
            .then((success: any) => {})
            .catch((error: any) => console.log(error));
        }
      }
    } else {
      setAlertMsg(true);
    }
  };

  return (
    <>
      <DialogWrapper open={open} sx={{ top: "22px" }}>
        <div className={headerStyle}>
          <Tabs
            initialIndex={0}
            tabsList={tabsList}
            handleTabs={handleTabs}
            dashboardNotificationClassName={customNotificationTabs}
          />
        </div>

        <div>
          {alertMsg && getAlertMessage()}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 15,
              top: 12,
              color: appTheme.palette.infoDialogueComponent.colorWhite,
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <Grid container>
          <Grid item xs={12} className={subListRow}>
            {/* <InfoSubList
              highlighted={"safetyScore"}
              infoSubList={infoSubList}
            /> */}
            <Grid container>
              <Grid item xs={3} className={wareHouseClass}>
                <Select
                  selectList={selectListWareHouse}
                  handleSelect={handleSelect}
                  customWidth={"100%"}
                  customHeight={"60px"}
                  graphName={"graph9"}
                  // customSelectCustom={customSelect}
                  pageName={"analyticsPage"}
                  drowpDownTextColor={"#fff"}
                  dropDownBgColor={"#000000"}
                  dropDownSelectedBgColor={"#9486DA"}
                  selectedValue={wareHouse}
                  placeholder={"Select WareHouse"}
                />
              </Grid>
              <Grid item xs={3} className={trailerClass}>
                <Select
                  selectList={trailerData}
                  handleSelect={handleTrailerSelect}
                  customWidth={"100%"}
                  customHeight={"60px"}
                  graphName={"graph9"}
                  // customSelectCustom={customSelect}
                  pageName={"analyticsPage"}
                  drowpDownTextColor={"#fff"}
                  dropDownBgColor={"#000000"}
                  dropDownSelectedBgColor={"#9486DA"}
                  selectedValue={trailer}
                  placeholder={"Select Trailer"}
                />
              </Grid>
              <Grid item xs={3} className={dateTimePickerStyle}>
                <Calendar
                  customWidth={"100%"}
                  selectedDate={startDate}
                  handleSelectedDate={handleSelectedDate}
                />
              </Grid>
              <Grid item xs={3} className={dateTimePickerStyle}>
                <Autocomplete
                  id="disabled-options-demo"
                  disableClearable
                  options={times}
                  value={startTime}
                  onChange={handleSelectedTime}
                  sx={{
                    width: "100%",
                    color: "white !important",
                    border: "1px solid white",
                    borderRadius: 1,
                    marginLeft: 1,
                  }}
                  popupIcon={<AccessTimeOutlinedIcon />}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Start Time"
                      sx={{ "::placeholder": { color: "white !important" } }}
                      InputLabelProps={{
                        style: { color: "#fff" },
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>

            {/* <div className={dateTimePickerStyle}></div> */}
          </Grid>
          <Grid item xs={12}>
            <PalletList
              handleCancel={handleClose}
              handleSave={handleSave}
              selectedStartDate={startDate}
            />
          </Grid>
        </Grid>
      </DialogWrapper>
    </>
  );
};

export default MenifestInfoDialog;
