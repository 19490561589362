import { makeStyles } from "@mui/styles";
import loginDot from "../../assets/top-right-dots.svg";
import logisticLogo from "../../assets/logistic-login-bg.jpg";
import loginPolygon from "../../assets/polygon.svg";
import muiTheme from "../../theme/muiTheme";

const useStyles = makeStyles(() => ({
  root: (props: any) => ({
    background: props?.palette?.login?.pageBackgroundColor,
  }),
  loginBannerSection: (props: any) => ({
    backgroundImage: `url("${logisticLogo}")`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "calc(100vh - 0px)",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  }),
  loginFormSection: (props: any) => ({
    background: props?.palette?.login?.loginFormBg,
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      right: "0",
      top: "0",
      background: `url("${loginDot}")`,
      width: "124px",
      height: "100px",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      [muiTheme.breakpoints.down(1181)]: {
        width: "100px",
        height: "70px",
      },
      [muiTheme.breakpoints.down(1025)]: {
        width: "100px",
        height: "70px",
      },
    },
    "&::after": {
      content: '""',
      position: "absolute",
      left: "-25px",
      bottom: "0px",
      background: `url("${loginPolygon}")`,
      width: "177px",
      height: "89px",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      transform: "rotate(360deg)",
      [muiTheme.breakpoints.down(1181)]: {
        width: "124px",
        height: "60px",
      },
      [muiTheme.breakpoints.down(1025)]: {
        width: "124px",
        height: "60px",
      },
    },
  }),
  innerForm: (props: any) => ({
    maxWidth: "440px !important",
  }),
  loginBannerContent: (props: any) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& img": {
      [muiTheme.breakpoints.down(1181)]: {
        width: " 75%",
      },
      [muiTheme.breakpoints.down(1025)]: {
        width: " 60%",
      },
    },
  }),
  logoSection: (props: any) => ({
    textAlign: "center",
    marginBottom: 54,
    "& img": {
      [muiTheme.breakpoints.down(1181)]: {
        width: " 70%",
      },
      [muiTheme.breakpoints.down(1025)]: {
        width: " 60%",
      },
    },
  }),
  welcomeSection: (props: any) => ({
    marginBottom: 23,
  }),
  welcomeContent: (props: any) => ({
    fontFamily: `'Nunito Sans', sans-serif !important`,
    fontWeight: " 600",
    fontSize: "28px",
    lineHeight: "42px",
    color: props?.palette?.login?.welcomeContent,
  }),
  inputTitle: (props: any) => ({
    fontFamily: `'Nunito Sans', sans-serif !important`,
    fontWeight: " 500",
    fontSize: "16px",
    lineHeight: "24px",
    color: props?.palette?.login?.inputTitleColor,
    marginBottom: 14,
  }),
  inputField: (props: any) => ({
    marginBottom: 30,
    "& .MuiInputBase-input": {
      fontFamily: `'Nunito Sans', sans-serif !important`,
      fontWeight: " 700",
      fontSize: "16px",
      lineHeight: "24px",
      color: "#2D3748",
      "&::placeholder": {
        color: "#848484",
        opacity: 1,
      },
    },
  }),
  loginButton: (props: any) => ({
    "& .MuiButtonBase-root": {
      background: props?.palette?.login?.loginButton,
      borderRadius: "30px",
      fontFamily: `'Nunito Sans', sans-serif !important`,
      fontWeight: " 500",
      fontSize: "16px",
      lineHeight: "27px",
      color: props?.palette?.login?.loginBannerTitle,
      textTransform: "Capitalize",
      padding: 12,
      "&:hover": {
        background: props?.palette?.login?.loginButton,
      },
    },
  }),
  formikErrorClass: (props: any) => ({
    fontFamily: `'Nunito Sans', sans-serif !important`,
    color: "red",
    padding: 0,
    margin: 0,
    paddingTop: 5,
    fontSize: 13,
    position: "absolute",
    fontWeight: " 500",
    bottom: "4px",
    left: 0,
  }),
  outlineInputField: (props: any) => ({
    position: "relative",
  }),
}));
export default useStyles;
