import { styled } from "@mui/material/styles";

export const RootContainer = styled("div")<{ paletteColor: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border: ${(props) =>
    props?.paletteColor ? `1px solid ${props?.paletteColor}` : null};
  border-radius: 6px;
  @media (max-width: 1437px) {
    padding: 10px;
  }
  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
`;

export const ItemContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 767px) {
    flex: 1 1 50%;
  }
  @media (max-width: 430px) {
    flex: 1 1 100%;
  }
`;

export const ItemValue = styled("div")<{ paletteColor: string }>`
  color: ${(props) => (props?.paletteColor ? props?.paletteColor : null)};
  font-size: 18px;
  line-height: 24px;
  @media (max-width: 1437px) {
    font-size: 13px;
    line-height: 19px;
  }
`;

export const ItemLabel = styled("div")<{ paletteColor: string }>`
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => (props?.paletteColor ? props?.paletteColor : null)};
  @media (max-width: 1437px) {
    font-size: 10px;
    line-height: 14px;
  }
`;

export const VerticalSpace = styled("div")<{ count: number }>`
  height: ${(props) => (props.count ? props.count * 4 : null)}px;
  @media (max-width: 1437px) {
    height: ${(props) => (props.count ? props.count * 1 : null)}px;
  }
`;
