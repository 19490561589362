import { styled } from "@mui/material/styles";

export const RootContainer = styled("div")<{ movingMarker?: boolean }>`
  position: relative;
  margin: 0;
  padding: 0;
  transform: translate(-50%, -50%);
  cursor: pointer;
  .RCP {
    width: 70px !important;
  }

  svg {
    width: 70px;
    height: 70px;
  }
`;

export const MarkerImage = styled("img")<{ angle: string }>`
  width: 35px;
  height: 35px;
  position: absolute;
  z-index: 1000;
  transform: translate(50%, -160%) rotate(${(props) => props.angle});
`;

export const InfoWindowTitle = styled("div")`
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
`;

export const MarkerBackground = styled("div")`
  position: absolute;
  top: 0px;
  left: 0px;
  transform: translate(35%, 35%);
  border-radius: 50%;
  background-color: #FFF;
  width: 40px;
  height: 40px;
`;