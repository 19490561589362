import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import theme from "../../theme/theme";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import loginBorder from "../../assets/login-border.svg";
import logisticLogo from "../../assets/logistic-logo.svg";
import { OutlinedInput } from "@mui/material";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import loginIllustration from "../../assets/Illustration-Login.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getUserLogin } from "../../redux/actions/loginActions";
import useStyles from "./styles";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state: any) => state.login.loginData);

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  const {
    loginBannerSection,
    loginFormSection,
    loginBannerContent,
    logoSection,
    welcomeSection,
    welcomeContent,
    inputTitle,
    inputField,
    loginButton,
    innerForm,
    formikErrorClass,
    outlineInputField,
  } = useStyles(appTheme);

  useEffect(() => {
    if (user && user?.email) {
      localStorage.setItem("user", JSON.stringify({ role: "ADMIN" }));
      navigate("/dashboard");
    }
  }, [user]);

  const formik = useFormik({
    initialValues: {
      userid: "",
      password: "",
    },
    validationSchema: Yup.object({
      userid: Yup.string()
        .min(2, "Mininum 2 characters")
        .required(" Please enter the user email address"),
      password: Yup.string()
        .min(8, "Mininum 2 characters")
        .max(12, "Maximum 15 characters")
        .required("Please enter the password"),
    }),
    onSubmit: (values) => {
      let payload = {
        deviceToken: "aaa",
        email: values.userid,
        passWord: values.password,
      };
      dispatch(getUserLogin(payload));
    },
  });

  return (
    <>
      <div>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className={loginBannerSection}
          >
            <div className={loginBannerContent}>
              <img src={loginIllustration} />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className={loginFormSection}>
              <Grid item xs={12} className={innerForm}>
                <Box>
                  <form onSubmit={formik.handleSubmit}>
                    <div className={logoSection}>
                      <img src={logisticLogo} />
                    </div>
                    <div className={welcomeSection}>
                      <p className={welcomeContent}>Welcome! 👋</p>
                    </div>
                    <div className={outlineInputField}>
                      <p className={inputTitle}>Your Email</p>
                      <OutlinedInput
                        className={inputField}
                        fullWidth
                        placeholder="John.snow@gmail.com"
                        type="text"
                        name="userid"
                        value={formik.values.userid}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.userid && formik.touched.userid && (
                        <p className={formikErrorClass}>
                          {formik.errors.userid}
                        </p>
                      )}
                    </div>
                    <div className={outlineInputField}>
                      <p className={inputTitle}>Password</p>
                      <OutlinedInput
                        className={inputField}
                        fullWidth
                        placeholder="&#1645;&nbsp;&#1645;&nbsp;&#1645;&nbsp;&#1645;&nbsp;&#1645;&nbsp;&#1645;&nbsp;&#1645;&nbsp;&#1645;&nbsp;&#1645;"
                        type="password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.password && formik.touched.password && (
                        <p className={formikErrorClass}>
                          {formik.errors.password}
                        </p>
                      )}
                    </div>

                    <div className={loginButton}>
                      <Button variant="contained" fullWidth type="submit">
                        Login
                      </Button>
                    </div>
                  </form>
                </Box>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Login;
