import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  containerStyle: (props: any) => ({
    width: "100%",
    height: "calc(100vh - 84px)",
  }),


  googleMapStyle: (props: any) => ({
    "& .gm-style .gm-style-cc a, .gm-style .gm-style-cc button, .gm-style .gm-style-cc span":
      {
        display: "none !important",
      },
    "& .gm-style-iw-tc::after": {
      backgroundColor: "#7E6ECC !important",
    },
    "& .gm-style .gm-style-iw-tc": {
      left: "38px !important",
    },
    "& .gm-style .gm-style-iw-t":{
      right: "38px !important"
    }
  }),

  googleMapStyleViewDetails: (props: any) => ({
    "& .gm-style .gm-style-cc a, .gm-style .gm-style-cc button, .gm-style .gm-style-cc span":
      {
        display: "none !important",
      },
    "& .gm-style-iw-tc::after": {
      backgroundColor: "#F6F4FC !important",
    },

    // .gm-style .gm-style-iw-tc 
    "& .gm-style .gm-style-iw-tc": {
      left: "0px",
      position: "relative"
    },
  }),
});
export default useStyles;
