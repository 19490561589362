import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import theme from "../../theme/theme";
import useTranslation from "../../localization/translations";
import DashboardContainer from "../../components/DashboardContainer";
import DashboardViewPageContainer from "components/DashboardViewPageContainer";
import Sample from "../Sample";
import Grid from "@mui/material/Grid";
import useStyles from "./styles";
import Header from "../../components/Header";

const DashBoard = () => {
  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );
  const dispatch = useDispatch();
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const [selectedNotification, setSelectedNotification] = useState<any>("");
  const [notificationTimeStamp, setNotificationTimeStamp] = useState<any>();
  const [selectedTitle, setSelectedTitle] = useState<any>("");
  const [selectedArea, setSelectedArea] = useState<any>("");
  const [viewDetailActive, setViewDetailActive] = useState(false);
  const [selectedId, setSelectedID] = useState<any>("");

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  useEffect(() => {
    dispatch({
      type: "GET_DASHBOARD_LIST",
      payload: {},
    });
  }, []);

  useEffect(() => {
    setSelectedNotification("");
  }, [viewDetailActive]);

  const dashboardList = useSelector(
    (state: RootState) => state.dashboardList.dashboardListData
  );

  const { rootContainer } = useStyles(appTheme);

  const navigate = useNavigate();

  const handleProfile = () => {
    navigate("/profile");
  };

  return (
    <>
      <Grid container className={rootContainer}>
        <Grid item xs={12}>
          {viewDetailActive ? (
            <DashboardViewPageContainer
              dashboardList={dashboardList}
              setViewDetailActive={setViewDetailActive}
              selectedNotification={selectedNotification}
              setSelectedNotification={setSelectedNotification}
              notificationTimeStamp={notificationTimeStamp}
              setNotificationTimeStamp={setNotificationTimeStamp}
              selectedTitle={selectedTitle}
              selectedArea={selectedArea}
              selectedId={selectedId}
            />
          ) : (
            <DashboardContainer
              dashboardList={dashboardList}
              setViewDetailActive={setViewDetailActive}
              selectedNotification={selectedNotification}
              setSelectedNotification={setSelectedNotification}
              notificationTimeStamp={notificationTimeStamp}
              setNotificationTimeStamp={setNotificationTimeStamp}
              setSelectedTitle={setSelectedTitle}
              setSelectedArea={setSelectedArea}
              setSelectedID={setSelectedID}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default DashBoard;
