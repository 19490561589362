import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ProtectedRoutes from "./protectedRoutes";
import Login from "../pages/Login";
import DashBoard from "../pages/DashBoard";
import Profile from "../pages/Profile";
import Manifests from "../pages/Manifests";
import Header from "components/Header";
import Configurations from "pages/Configurations";
import Analytics from "pages/Analytics";

const VIOT_Routes = () => {
  const user = useSelector((state: any) => state.login.loginData);

  return (
    <>
      {user?.email && <Header />}
      <Routes>
        {/** Protected Routes */}
        <Route path="/" element={<ProtectedRoutes />}>
          <Route path="/" element={<Navigate replace to="login" />} />
          <Route path="/dashboard" element={<DashBoard />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/menifests" element={<Manifests />} />
          <Route path="/configurations" element={<Configurations />} />
          <Route path="/analytics" element={<Analytics />} />
        </Route>

        {/** Public Routes */}
        <Route path="/login" element={<Login />} />

        {/** Permission denied route */}
        <Route path="/denied" element={<div>No permission</div>} />
      </Routes>
    </>
  );
};

export default VIOT_Routes;
