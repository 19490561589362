import { useState, Fragment, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Chip } from "@mui/material";
import ConfigurationLeftPanel from "components/ConfigurationLeftPanel";
import ConfigurationRightPanel from "components/ConfigurationRightPanel";
import notConfiguresWareHouses from "mockdata/notConfiguresWareHouses";
// @ts-ignore
import { doc, setDoc, getDoc } from "firebase/firestore/lite";
// @ts-ignore
import { db } from "services/firebase";
import theme from "../../theme/theme";
import useStyles from "./styles";
import SendConfigInfoDialog from "../../components/SendConfigInfoDialog";

const Configurations: React.FC<any> = (props) => {
  const { buttonStyles } = props;
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const {
    configrationContent,
    configurationsLeft,
    configurationsRight,
    configurationBg,
  } = useStyles(appTheme);

  const [notConfiguredData, setNotConfiguredData] = useState<any>([]);
  const [dbResponseData, setDbResponseData] = useState<any>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [draggableList, setDraggableList] = useState<any>([]);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [draggingSubCategory, setDraggingSubCategory] = useState<any>("");
  const [isResetClicked, setIsResetClicked] = useState<boolean>(false);
  const [rightPanelTabIndex, setRightPanelTabIndex] = useState<number>(0);
  const [isConfiguredItemSelected, setIsConfiguredItemSelected] =
    useState<boolean>(false);
  const [configuredSelectedCategory, setConfiguredSelectedCategory] =
    useState<any>("");
  const [searchValue, setSearchValue] = useState<any>(notConfiguredData);
  const [configurationIndexArray, setConfigurationIndexArray] = useState([]);
  const [draggedListId, setDraggedListId] = useState<any>("");
  const [droppedListId, setDroppedListId] = useState<any>("");
  const [warehouseId, setWarehouseId] = useState<any>("");

  // useEffect(() => {
    // // if (dbResponseData?.length > 0) {
    // console.log("running");
    // const ref = doc(db, "configuration", "notConfiguredList");
    // setDoc(ref, notConfiguresWareHouses)
    //   .then((success: any) => console.log("Success"))
    //   .catch((error) => console.log(error));
    // // }
  // }, []);

  useEffect(() => {
    getNotConfiguredData();
  }, []);

  useEffect(() => {
    setSearchValue(notConfiguredData);
  }, [notConfiguredData]);

  useEffect(() => {
    getNotConfiguredData();
  }, [isLoaded]);
  const [dbResponseDataToConfig, setDbResponseDataToConfig] = useState({});
  const getNotConfiguredData = () => {
    const notConfiguredListRef = doc(db, "configuration", "notConfiguredList");
    getDoc(notConfiguredListRef)
      .then((response: any) => {
        const dbResponse = response?.data();
        setDbResponseDataToConfig(dbResponse);
        setDbResponseData(dbResponse.wareHouses);
        setIsLoaded(true);
      })
      .catch((error) => console.log(error.message));

    let notConfiguredWareHouseData: any = [...dbResponseData];

    notConfiguredWareHouseData.forEach((warehouse: any) => {
      warehouse.subList.forEach((item: any) => {
        item.selected = false;
        item.list.forEach((listItem: any) => {
          listItem.selected = false;
          if (listItem?.subListItem) {
            listItem?.subListItem?.forEach((subListItem: any) => {
              subListItem.selected = false;
            });
          }
        });
      });
    });

    setNotConfiguredData(notConfiguredWareHouseData);
    setSearchValue(notConfiguredWareHouseData);
  };

  useEffect(() => {
    getNotConfiguredData();
  }, []);
  const [draggingCategory, setDraggingCategory] = useState<any>("");
  const [nestedChildrenElement, setNestedChildrenElement] = useState<any>("");
  const [firstDraggingSubCategory, setFirstDraggingSubCategory] =
    useState<any>("");
  const [lastDraggedItemCategory, setLastDraggedItemCategory] =
    useState<any>("");

  const handleOnDrag = (
    e: any,
    warehouseId: any,
    dragListItem: any,
    id: number,
    category: any,
    subcategory: any,
    nestedChildren: any
  ) => {
    nestedChildren && setNestedChildrenElement(nestedChildren);
    setDraggedListId(id);
    e.stopPropagation();
    e.dataTransfer.setData("dragListItem", dragListItem);
    e.dataTransfer.setData("dragListId", id);
    setDraggingCategory(category);
    if (subcategory) {
      setDraggingSubCategory(subcategory);
    }
    setWarehouseId(warehouseId);
  };

  const [selectedDragItem, setSelectedDragItem] = useState<any>("");
  const [previousDraggedCategory, setPreviousDraggedCategory] =
    useState<any>("");
  const handleOnDrop = (e: any) => {
    const dragListItem = e.dataTransfer.getData("dragListItem");
    const dragListId = e.dataTransfer.getData("dragListId");
    setDroppedListId(dragListId);

    // DRAG

    if (dragListId === draggedListId) {
      if (draggingCategory === "equipment") {
        let newData: any = [...notConfiguredData];
        newData.forEach((warehouse: any) => {
          warehouse.subList.forEach((item: any) => {
            if (item?.id === dragListId) item.selected = true;
            item.list.forEach((listItem: any) => {
              if (
                listItem?.id === dragListId &&
                warehouseId === warehouse?.id
              ) {
                listItem.selected = true;
              } else {
                listItem.selected = false;
              }
              const dragCategory = listItem?.subListItem?.find(
                (item: any) => item?.id === dragListId
              );
              if (listItem?.subListItem) {
                listItem?.subListItem?.forEach((sublistitem: any) => {
                  if (
                    sublistitem?.id === dragListId &&
                    warehouseId === item?.id
                  ) {
                    sublistitem.selected = true;
                  } else if (dragCategory?.category !== sublistitem?.category) {
                    sublistitem.selected = false;
                  }
                });
              }
            });
          });
        });
        setNotConfiguredData(newData);
      } else if (draggingCategory === "tracker") {
        let newData: any = [...notConfiguredData];
        newData.forEach((warehouse: any) => {
          if (warehouseId === warehouse?.id) {
            warehouse.subList.forEach((item: any) => {
              if (item?.id === dragListId) item.selected = true;
              item.list.forEach((listItem: any) => {
                if (listItem?.id === dragListId) {
                  listItem.selected = true;
                }
              });
            });
          } else {
            warehouse.subList.forEach((item: any) => {
              item.selected = false;
              item.list.forEach((listItem: any) => {
                listItem.selected = false;
              });
            });
          }

          warehouse.subList.forEach((item: any) => {
            if (item?.id === dragListId) item.selected = true;
            item.list.forEach((listItem: any) => {
              if (listItem?.id === dragListId) {
                listItem.selected = true;
              }
            });
          });
        });
        setNotConfiguredData(newData);
      }

      // DROP

      if (dragListItem === "Tracker") {
        let newArrayValue = [...notConfiguredData];
        let updatedArray: any = [];
        newArrayValue.forEach((warehouse: any) => {
          warehouse.subList.forEach((item: any) => {
            if (item?.id === dragListId) {
              updatedArray = item?.list;

              item.list.forEach((listItem: any) => {
                listItem.selected = true;
              });
            }
            const newUpdatedArray = updatedArray.filter(
              (item: any) => item?.configured === false
            );
            setDraggableList(newUpdatedArray);
          });
        });
      } else if (dragListItem === "Equipment") {
        let newArrayValue = [...notConfiguredData];
        let updatedArray: any = [];
        newArrayValue.forEach((warehouse: any) => {
          warehouse.subList.forEach((item: any) => {
            if (item?.id === dragListId) {
              updatedArray = item?.list;

              item.list.forEach((listItem: any) => {
                listItem.selected = true;
              });
            }
            const newUpdatedArray = updatedArray.filter(
              (item: any) => item?.configured === false
            );
            setDraggableList(newUpdatedArray);
          });
        });
      } else if (draggingCategory === "tracker") {
        let newArrayValue = [...notConfiguredData];
        let updatedArray: any = [];
        newArrayValue.forEach((warehouse: any) => {
          warehouse.subList.forEach((item: any) => {
            item.list.forEach((listItem: any) => {
              updatedArray.push(listItem);
              if (listItem?.id === dragListId) {
                listItem.selected = true;
              }
            });
          });
        });
        const newUpdatedArray = updatedArray?.filter(
          (item: any) => item?.configured === false && item?.selected === true
        );
        setDraggableList(newUpdatedArray);
      } else if (
        draggingSubCategory &&
        nestedChildrenElement === "nestedChildren"
      ) {
        let newArrayValue = [...notConfiguredData];
        let updatedArray: any = [];
        newArrayValue.forEach((warehouse: any) => {
          warehouse.subList.forEach((item: any) => {
            item.list.forEach((listItem: any) => {
              if (listItem?.id === dragListId) {
                updatedArray = listItem?.subListItem;
                listItem?.subListItem?.forEach((sublistitem: any) => {
                  sublistitem.selected = true;
                });
              }
            });
          });
        });
        const newUpdatedArray = updatedArray?.filter(
          (item: any) => item?.configured === false
        );
        setDraggableList(newUpdatedArray);
      } else if (nestedChildrenElement === "subNestedChildren") {
        let newArrayValue = [...notConfiguredData];
        let updatedArray: any = [];
        newArrayValue.forEach((warehouse: any) => {
          warehouse.subList.forEach((item: any) => {
            {
              item?.list.forEach((listItem: any) => {
                listItem?.subListItem?.forEach((sublistitem: any) => {
                  if (sublistitem?.id === dragListId) {
                    if (draggingSubCategory === sublistitem?.category) {
                      sublistitem.selected = true;
                      updatedArray?.push(sublistitem);
                    }
                  }
                });
              });
            }
          });
        });
        const newUpdatedArray = updatedArray.filter(
          (item: any) => item?.configured === false && item?.selected === true
        );
        let dragListCopy = [...draggableList, ...newUpdatedArray];
        let new123 = dragListCopy?.filter(
          (item: any) => item?.configured === false && item?.selected === true
        );
        setDraggableList(new123);
      } else {
        let newArray = [...draggableList];
        newArray.push({ id: dragListId, title: dragListItem, disabled: true });

        let newArrayValue = [...notConfiguredData];
        let updatedArray: any = [];
        newArrayValue.forEach((warehouse: any) => {
          warehouse.subList.forEach((item: any) => {
            {
              item?.list.forEach((listItem: any) => {
                const newDraggingCategory = listItem?.category;
                listItem?.subListItem?.forEach((sublistitem: any) => {
                  if (sublistitem?.id === dragListId) {
                    sublistitem.selected = true;
                    updatedArray?.push(sublistitem);
                  }
                });
              });
            }
            const newUpdatedArray = updatedArray.filter(
              (item: any) => item?.configured === false
            );
            let newUpdatedArray2 = [];
            newUpdatedArray2.push(newUpdatedArray);
            setDraggableList([...draggableList, ...updatedArray]);
          });
        });
      }
    }
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  const handleChipDelete = (item: any, id: any) => {
    if (draggingCategory === "equipment" && draggingSubCategory) {
      let newData: any = [...notConfiguredData];
      newData.forEach((warehouse: any) => {
        warehouse.subList.forEach((item: any) => {
          item.list.forEach((listItem: any) => {
            listItem.selected = false;
            if (listItem?.subListItem) {
              listItem?.subListItem?.forEach((sublistitem: any) => {
                if (sublistitem?.id === id) {
                  sublistitem.selected = false;
                }
              });
            }
          });
        });
      });
      setNotConfiguredData(newData);
      const updatedDraggableList = draggableList?.filter(
        (value: any) => value !== item
      );
      setDraggableList(updatedDraggableList);
    } else {
      let newData: any = [...notConfiguredData];

      newData.forEach((warehouse: any) => {
        warehouse.subList.forEach((item: any) => {
          item.selected = false;
          item.list.forEach((listItem: any) => {
            if (listItem?.id === id) listItem.selected = false;
            if (listItem?.subListItem) {
              listItem?.subListItem?.forEach((sublistitem: any) => {
                if (sublistitem?.id === id) sublistitem.selected = false;
              });
            }
          });
        });
      });
      setNotConfiguredData(newData);
      const updatedDraggableList = draggableList?.filter(
        (value: any) => value !== item
      );
      setDraggableList(updatedDraggableList);
    }
  };

  const [showInfoDialogue, setShowInfoDialogue] = useState<boolean>(false);
  const [selectedDragableList, setSelectedDragableList] = useState<any>();

  const handleSendConfiguration = (draggableList: any) => {
    setSelectedDragableList(draggableList);
    setShowInfoDialogue(!showInfoDialogue);
  };

  useEffect(() => {
    if (draggableList?.length === 0) {
      setDraggingCategory("");
      setDraggingSubCategory("");
      setFirstDraggingSubCategory("");
      setLastDraggedItemCategory("");

      let notConfiguredWareHouseData: any = [...dbResponseData];

      notConfiguredWareHouseData.forEach((warehouse: any) => {
        warehouse.subList.forEach((item: any) => {
          item.selected = false;
          item.list.forEach((listItem: any) => {
            listItem.selected = false;
            if (listItem?.subListItem) {
              listItem?.subListItem?.forEach((subListItem: any) => {
                subListItem.selected = false;
              });
            }
          });
        });
      });

      setNotConfiguredData(notConfiguredWareHouseData);
    }
  }, [draggableList]);

  const [treeLableExpandedTitles, settTeeLableExpandedTitles] = useState({
    expandedWareHouseLabel: "",
    expandedWareHouseSubListLabel: "",
    expandedWareHouseInnerSubListLabel: "",
  });

  const handleWareHouseLabelClick = (selectedLableTitle: string) => {
    settTeeLableExpandedTitles({
      ...treeLableExpandedTitles,
      expandedWareHouseLabel: selectedLableTitle,
    });
  };

  const handleWareHouseSubListLabelClick = (selectedLableTitle: string) => {
    settTeeLableExpandedTitles({
      ...treeLableExpandedTitles,
      expandedWareHouseSubListLabel: selectedLableTitle,
    });
  };

  const handleWareHouseInnerSubListLabelClick = (
    selectedLableTitle: string
  ) => {
    settTeeLableExpandedTitles({
      ...treeLableExpandedTitles,
      expandedWareHouseInnerSubListLabel: selectedLableTitle,
    });
  };

  const [seletedTrackerListItemId, setSelectedTrackerListItemId] =
    useState<any>("");
  const [seletedEquipmentListItemId, setSelectedEquipmentListItemId] =
    useState<any>("");

  const handleConfiguredList = (
    category: any,
    indexArray: any,
    subListItem: any
  ) => {
    setConfiguredSelectedCategory(category);
    setConfigurationIndexArray(indexArray);
    if (category === "tracker") {
      setSelectedTrackerListItemId(subListItem?.id);
      setSelectedEquipmentListItemId("");
    } else if (category === "equipment") {
      setSelectedEquipmentListItemId(subListItem?.id);
      setSelectedTrackerListItemId("");
    }
    category === "tracker" && setIsConfiguredItemSelected(true);
    category === "equipment" && setIsConfiguredItemSelected(false);

    category === "equipment" && subListItem?.subListItem?.length > 0
      ? setIsConfiguredItemSelected(false)
      : subListItem?.alertConfiguredList?.length > 0
      ? setIsConfiguredItemSelected(true)
      : setIsConfiguredItemSelected(true);
  };

  const handleTabs = (index: number) => {
    setTabIndex(index);
    setIsConfiguredItemSelected(false);
    setDraggableList([]);
    setRightPanelTabIndex(0);
  };

  useEffect(() => {
    if (draggableList && draggableList?.length > 0) {
      draggableList &&
        draggableList[0] &&
        setFirstDraggingSubCategory(draggableList[0]?.category);
      draggableList?.map((item: any) =>
        setLastDraggedItemCategory(item?.category)
      );
    }
  }, [draggableList, notConfiguredData]);

  return (
    <>
      <Fragment>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container className={configrationContent}>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={3}
              className={configurationsLeft}
            >
              <ConfigurationLeftPanel
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                handleOnDrag={handleOnDrag}
                notConfiguredData={notConfiguredData}
                draggingCategory={draggingCategory}
                isLoaded={isLoaded}
                draggingSubCategory={draggingSubCategory}
                handleWareHouseLabelClick={handleWareHouseLabelClick}
                handleWareHouseSubListLabelClick={
                  handleWareHouseSubListLabelClick
                }
                handleWareHouseInnerSubListLabelClick={
                  handleWareHouseInnerSubListLabelClick
                }
                handleConfiguredList={handleConfiguredList}
                setIsConfiguredItemSelected={setIsConfiguredItemSelected}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                handleTabs={handleTabs}
                seletedTrackerListItemId={seletedTrackerListItemId}
                seletedEquipmentListItemId={seletedEquipmentListItemId}
                newDraggingSubCategory={firstDraggingSubCategory}
              />
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={8}
              lg={8}
              xl={9}
              className={tabIndex === 0 ? configurationsRight : configurationBg}
            >
              <ConfigurationRightPanel
                draggableList={draggableList}
                handleOnDrop={handleOnDrop}
                handleDragOver={handleDragOver}
                handleChipDelete={handleChipDelete}
                tabIndex={tabIndex}
                handleSendConfiguration={handleSendConfiguration}
                isResetClicked={isResetClicked}
                setIsResetClicked={setIsResetClicked}
                rightPanelTabIndex={rightPanelTabIndex}
                setRightPanelTabIndex={setRightPanelTabIndex}
                isConfiguredItemSelected={isConfiguredItemSelected}
                configuredSelectedCategory={configuredSelectedCategory}
                configurationIndexArray={configurationIndexArray}
                dbResponseDataToConfig={dbResponseDataToConfig}
              />
            </Grid>
          </Grid>
        </Box>
        <div>
          {showInfoDialogue && (
            <SendConfigInfoDialog
              setShowInfoDialogue={setShowInfoDialogue}
              selectedDragableList={selectedDragableList}
              treeLableExpandedTitles={treeLableExpandedTitles}
              firebaseResponseData={dbResponseDataToConfig}
              setDraggableList={setDraggableList}
              getNotConfiguredData={getNotConfiguredData}
              category={
                draggingCategory === "equipment" ? "Equipment" : "Tracker"
              }
              treeViewTabIndex={tabIndex}
            />
          )}
        </div>
      </Fragment>
    </>
  );
};

export default Configurations;
