import transitEvent from './transit-event.png';
import transitIncident from './transit-incident.png';
import transitOpAlert from './transit-opAlert.png';
import warehouseEvent from './blueWarehouse.png';
import warehouseOpAlert from './orangeWarehouse.png';
import warehouseIncident from './redWarehouse.png';
import storeEvent from './store-event.png';
import storeIncident from './store-incident.png';
import storeOpAlert from './store-opAlert.png';

const markerIcons = {
    transitEvent,
    transitIncident,
    transitOpAlert,
    warehouseEvent,
    warehouseOpAlert,
    warehouseIncident,
    storeEvent,
    storeIncident,
    storeOpAlert,
}

export default markerIcons;