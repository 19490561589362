import { useState, useEffect, Fragment } from "react";
import {
  GoogleMap,
  LoadScript,
  useLoadScript,
  DrawingManager,
  Circle,
  GroundOverlay,
  MarkerF,
  PolylineF,
  InfoWindowF,
  GroundOverlayF,
  DirectionsRenderer,
} from "@react-google-maps/api";
import MarkerInfoWindow from "../../elements/InfoWindow";
import Marker from "elements/Marker";
import customMapStyles from "./customMapStyles";
import theme from "../../theme/theme";
import appData from "../../data/appData";
import FloorMap from "../../assets/FloorMap.svg";
import operationsFloorMap from "../../assets/operationsFloorMap.svg";
import movingMarker from "assets/marker-icons/transit-event.png";
import useStyles from "./styles";
import TrailersInfoDialog from "components/TrailersInfoDialog";

const containerStyle = {
  width: "100%",
  height: "calc(100vh - 84px)",
};

const defaultCenter = {
  lat: 37.0902,
  lng: -100.55,
};

const Map: React.FC<any> = (props) => {
  const {
    markerArray,
    setSelectedNotification,
    selectedNotification,
    selectedTabButton,
    setSelectedTabButton,
    notificationTimeStamp,
    setIsMarkerClicked,
    isMarkerClicked,
    setViewDetailActive,
    selectedItem,
    tabIndex,
    setRightPanelActive,
    rightPanelActive,
    setChipButtonIndex,
    chipButtonIndex,
    searchOpen,
    
  } = props;

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const { googleMapStyle } = useStyles(appTheme);

  const [map, setMap] = useState<any>(null);
  const [zoomValue, setZoomValue] = useState<number>(5.3);
  const [progress, setProgress] = useState<any>([]);
  let [points, setPoints] = useState<any>([]);
  let [data, setData] = useState<any>(points);
  const [selectedListItemSource, setSelectedListItemSource] = useState<any>();
  const [selectedListItemDestination, setSelectedListItemDestination] =
    useState<any>();
  const [selectedListItemDirection, setSelectedListItemDirection] =
    useState<any>();

  useEffect(() => {
    setZoomValue(5);
  }, [searchOpen, selectedTabButton]);

  useEffect(() => {
    if (
      selectedItem?.itemList?.sourceCoOrdinates &&
      selectedItem?.itemList?.destinationCoOrdinates
    ) {
      setSelectedListItemSource(selectedItem?.itemList?.sourceCoOrdinates);
      setSelectedListItemDestination(
        selectedItem?.itemList?.destinationCoOrdinates
      );
      setSelectedListItemDirection(selectedItem?.itemList?.movingDirection);
    } else {
      setProgress([]);
      setPoints([]);
      setData([]);
      setSelectedListItemDirection("");
      setSelectedListItemSource("");
      setSelectedListItemDestination("");
    }
  }, [selectedItem]);

  useEffect(() => {
    setProgress([]);
    setPoints([]);
    setData([]);
    setSelectedListItemDirection("");
    setSelectedListItemSource("");
    setSelectedListItemDestination("");
  }, [tabIndex, selectedTabButton]);
  const velocity: any = 10000;
  const initialDate: any = new Date();

  // const [buttonIndex, setButtonIndex] = useState<any>(selectedTabButton);

  // useEffect(() => {
  //   setButtonIndex(selectedTabButton);
  // }, [selectedTabButton]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: appData?.googleApiKey, //"AIzaSyCmwqbYb48dfmPqYiWWU0A2kRr54I2L3wE",
    libraries: ["places", "drawing"],
  });

  const getMapTypeControls = () => {
    const defaultMapOptions = {
      styles: customMapStyles,
    };
    return {
      ...defaultMapOptions,
      mapTypeControl: true,
      rotateControl: true,
      fullscreenControl: false,
    };
  };

  useEffect(() => {
    if (selectedNotification) {
      const id = markerArray?.findIndex(
        (marker: any) => marker.id === selectedNotification
      );
      if (
        markerArray &&
        markerArray[id]?.location?.lat &&
        markerArray[id]?.location?.lng
      ) {
        map?.panTo(markerArray[id]?.location);
      }
    }
  }, [selectedNotification, notificationTimeStamp]);

  const switchTabs = (category: any, marker: any) => {
    switch (category) {
      case "wareHouses": {
        setSelectedTabButton && setSelectedTabButton(0);
        if (tabIndex === 1) {
          marker?.type === "events" && setChipButtonIndex(0);
          marker?.type === "incidents" && setChipButtonIndex(1);
          marker?.type === "oprAlerts" && setChipButtonIndex(2);
        }
        break;
      }
      case "trailers": {
        setSelectedTabButton && setSelectedTabButton(1);
        if (tabIndex === 1) {
          marker?.type === "events" && setChipButtonIndex(0);
          marker?.type === "incidents" && setChipButtonIndex(1);
          marker?.type === "oprAlerts" && setChipButtonIndex(2);
        }
        break;
      }
      case "stores": {
        setSelectedTabButton && setSelectedTabButton(2);
        if (tabIndex === 1) {
          marker?.type === "events" && setChipButtonIndex(0);
          marker?.type === "incidents" && setChipButtonIndex(1);
          marker?.type === "oprAlerts" && setChipButtonIndex(2);
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleActiveMarker = (marker: any, index: number) => {
    setRightPanelActive(true);
    setTimeout(() => {
      setSelectedNotification((prev: any) => {
        if (prev && prev === marker?.id) {
          map?.panTo(marker?.location);
          return "";
        } else {
          map?.panTo(marker?.location);
          return marker?.id;
        }
      });
      switchTabs(marker?.category, marker);
      setIsMarkerClicked(true);
    }, 100);
  };

  const handleMarkerClose = () => {
    setSelectedNotification("");
    map?.panTo(defaultCenter);
    setIsMarkerClicked(false);
    setProgress([]);
    setPoints([]);
    setData([]);
    setSelectedListItemDirection("");
    setSelectedListItemSource("");
    setSelectedListItemDestination("");
  };

  const calculatePath = () => {
    data = points.map((coordinates: any, i: any, array: any) => {
      if (i === 0) {
        return { ...coordinates, distance: 0 }; // it begins here!
      }
      const { lat: lat1, lng: lng1 } = coordinates;
      const latLong1 = new window.google.maps.LatLng(lat1, lng1);

      const { lat: lat2, lng: lng2 } = array[0];
      const latLong2 = new window.google.maps.LatLng(lat2, lng2);

      // in meters:
      const distance =
        window.google.maps.geometry.spherical.computeDistanceBetween(
          latLong1,
          latLong2
        );
      return { ...coordinates, distance };
    });
    setData(data);
  };

  const getDistance = () => {
    const date: any = new Date();
    const differentInTime = (date - initialDate) / 1000; // pass to seconds
    return differentInTime * velocity; // d = v*t -- thanks Newton!
  };

  const moveObject = () => {
    const distance = getDistance();
    if (!distance) {
      return;
    }
    let progress = data?.filter(
      (coordinates: any) => coordinates.distance < distance
    );

    const nextLine = data?.find(
      (coordinates: any) => coordinates.distance > distance
    );

    if (!nextLine) {
      setProgress(progress);
      // window.clearInterval(interval);
      return; // it's the end!
    }
    const lastLine = progress[progress?.length - 1];

    const lastLineLatLng = new window.google.maps.LatLng(
      lastLine.lat,
      lastLine.lng
    );

    const nextLineLatLng = new window.google.maps.LatLng(
      nextLine.lat,
      nextLine.lng
    );

    // distance of this line
    const totalDistance = nextLine.distance - lastLine.distance;
    const percentage = (distance - lastLine.distance) / totalDistance;

    const position = window.google.maps.geometry.spherical.interpolate(
      lastLineLatLng,
      nextLineLatLng,
      percentage
    );

    const angle = window.google.maps.geometry.spherical.computeHeading(
      lastLineLatLng,
      nextLineLatLng
    );
    const actualAngle = angle - 90;

    const marker = document.querySelector(`[src="${movingMarker}"]`);

    // if (marker) {
    //   // when it hasn't loaded, it's null
    //   marker.style.transform = `rotate(${actualAngle}deg)`;
    // }
    progress = progress?.concat(position);

    setProgress(progress);
  };

  const fetchDirection = async () => {
    const directionService = new window.google.maps.DirectionsService();

    const results = await directionService.route({
      origin: selectedItem?.itemList?.sourceCoOrdinates,
      destination: selectedItem?.itemList?.destinationCoOrdinates,
      travelMode: window.google.maps.TravelMode.DRIVING,
    });

    setPoints(JSON.parse(JSON.stringify(results?.routes[0]?.overview_path)));
  };

  useEffect(() => {
    if (points?.length > 0) {
      calculatePath();
    }
  }, [points]);

  useEffect(() => {
    if (points?.length > 0) {
      const timer = setInterval(() => {
        moveObject();
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [data]);

  useEffect(() => {
    if (selectedListItemSource && selectedListItemDestination) {
      fetchDirection();
    }
  }, [selectedListItemSource, selectedListItemDestination]);

  useEffect(() => {
    fetchDirection();
  }, []);

  const [showInfoDialogue, setShowInfoDialogue] = useState<boolean>(false);
  const [selectedItemTrailerID, setSelectedItemTrailerID] = useState<any>();

  const handleButtonClick = (dataID?: any, category?: any)=>{
    if (category==="trailers") {
      setShowInfoDialogue(true);
      setSelectedItemTrailerID(dataID);
    } else {
      setViewDetailActive(true);
    }
  }
  

  

  return (
    <>
      {isLoaded && (
        <GoogleMap
          id="drawing-manager-example"
          mapContainerStyle={containerStyle}
          center={map?.panTo(defaultCenter)}
          zoom={zoomValue}
          onLoad={setMap}
          options={getMapTypeControls()}
          mapContainerClassName={googleMapStyle}
        >
          {markerArray &&
            markerArray.length > 0 &&
            markerArray?.map((marker: any, index: number) => {
              return (
                <Marker
                  id={marker?.id}
                  location={marker.location}
                  direction={marker?.itemList?.direction}
                  progress={marker?.itemList?.progress}
                  label={marker?.itemList?.type}
                  category={marker.category}
                  currentFocusedMarker={selectedNotification}
                  onClick={() => handleActiveMarker(marker, index)}
                  marker={marker}
                  notificationTimeStamp={notificationTimeStamp}
                  setSelectedNotification={setSelectedNotification}
                  handleMarkerClose={handleMarkerClose}
                  setViewDetailActive={setViewDetailActive}
                  selectedNotification={selectedNotification}
                  selectedTabButton={selectedTabButton}
                  setSelectedTabButton={setSelectedTabButton}
                  tabIndex={tabIndex}
                  selectedItem={selectedItem}                  
                  handleButtonClick={handleButtonClick}
                  // buttonIndex={buttonIndex}
                />
              );
            })}

          {points && points?.length > 0 && (
            <PolylineF
              path={points}
              options={{
                strokeColor: "#1673CB",
                strokeOpacity: 1.0,
                strokeWeight: 3,
              }}
            />
          )}

          {points && points?.length > 0 && progress && progress?.length > 0 && (
            <>
              <PolylineF
                path={progress}
                options={{
                  strokeColor: "#59B974",
                  strokeOpacity: 1.0,
                  strokeWeight: 3,
                }}
              />
              <Marker
                id={"0"}
                location={progress[progress?.length - 1]}
                direction={selectedListItemDirection}
                progress={40}
                label={"events"}
                category={"trailers"}
                currentFocusedMarker={selectedNotification}
                onClick={() => {}}
                marker={selectedItem}
                notificationTimeStamp={""}
                setSelectedNotification={() => {}}
                handleMarkerClose={() => {}}
                setViewDetailActive={() => {}}
                selectedNotification={""}
                selectedTabButton={""}
                setSelectedTabButton={() => {}}
                movingMarker={true}
                handleButtonClick={handleButtonClick}
              />
            </>
          )}
        </GoogleMap>
      )}
      <div>
        {showInfoDialogue && (
          <TrailersInfoDialog
            selectedItemID={selectedItemTrailerID}
            setShowInfoDialogue={setShowInfoDialogue}
            pageName={"dashboard"}
          />
        )}
      </div>
    </>
  );
};

export default Map;
