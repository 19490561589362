let colorCodes = {
  darkBlueThemeColor: "#300254",
  greenThemeColor: "#68F73A",
  yellowThemeColor: "#D1F557",
  redThemeColor: "#C10132",
  blackColor: "#4B0000",
  blueDarkColor: "#34BACF",
  colorWhite: "#ffffff",
  colorBlack: "#000000",
  loginFormBg: "#EFF4FA",
  welcomeContent: "#3A345E",
  loginFormTitle: "#1A202C",
  inputTitleColor: "#4A5568",
  inputPlaceholder: "#2D3748",
  colorDark: "#39335D",
  lightGrey: "#d8d8d8",
  darkGrey: "#2D2946",
  darkBlue: "#2D2946",
  violet: "#9486DA",
  darkGray1: "#716F6F",
  lightGray: "#d7d7d7",
  darkGreen: "#4FB157",
  darkGreen2: "#66996A",
  lightPurple: "#E7DFFF",
  lightPurple2: "#EEE9FF",
  darkPurple: "#9087C8",
  disableGray: "#A4A4AD",
  darkBlue1: "#2A859B",
  deepDarkBlue: "#123D64",
  paleGray: "#A0B1C1",
  colorGreen: "#26C96B",
  colorRed: "#C24646",
  colorOrange: "#F1933B",
  darkGrey2: "#837DAB",
  darkGrey3: "#565656",
  darkGrey4: "#6B6B6B",
  darkPurple2: "#7E6FCC",
  lightWhitePurple: "#F6F4FC",
  lightShadeGray: "#dddddd",
  lightShadeGray1: "#c6c2c2",
  purpleShades: "#7E6ECC",
  darkGrayShades: "#646464",
  greenBlackShades: "#77B77C",
  blackShades: "#111111",
  greyShade: "#727272",
  whiteShade: "#ededed",
  greenShade: "#5AA700",
  paleRed: "#EF7D7D",
  paleYellow: "#FEBC7F",
  paleGreen: "#B9DD90",
  accessButtonInactive: "#7E7E97",
  floormapHeaderBg: "#A3A7D8",
  viewDetailHeaderButton: "#CED0E4",
  lightGreenShade: "#D0EED3",
  darkGreenShade: "#187C21",
  lightOrangeRedShade: "#FFB6B6",
  darkOrangeRedShade: "#FB6161",
  lightBlackGreyShade: "#5E5E5E",
  halfBlack: "rgba(0, 0, 0, 0.71)",
  greyBorder: "#DDDDDD",
  raisinBlack: "#202127",
  spaceCadet: "#232b47",
  jetGrey: "#333333",
  silverGrey: "#A6A6A6"
};
export default colorCodes;
