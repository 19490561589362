import * as React from 'react';
import { styled } from '@mui/material/styles';
import { useState, Fragment } from "react";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useStyles from "./styles";






const SwitchBox = (props: any) => {
  const { titleName, checked, handleSwitch } = props;
  const { customCheckbox } = useStyles();

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 42,
    height: 20,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(20px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1FDE00',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 16,
      height: 16,
      borderRadius: 8,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 20 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));

  const handleClick=()=>{
    handleSwitch()
  }

  return (
    <Fragment>     
      <Stack direction="row" spacing={1} alignItems="center">
        {/* <Typography>Off</Typography> */}
        <AntSwitch checked={checked} onClick={handleClick} inputProps={{ 'aria-label': 'ant design' }} />
        <Typography>{titleName}</Typography>
      </Stack>
    </Fragment>
  );
}

export default SwitchBox;
