import { makeStyles } from "@mui/styles";
import muiTheme from "theme/muiTheme";

const useStyles = makeStyles(() => ({
  customCheckbox: (props: any) => ({
    "& .Mui-checked.Mui-disabled": {
      color: "#1A1A1A !important",
    },
    "& .MuiFormControlLabel-label.Mui-disabled": {
      color: "#ffffff !important",
    },
    "& .Mui-disabled": {
      "& .MuiSvgIcon-root": {
        color: "#555555",
        borderRadius: "5px",
      },
    },
    "& .MuiCheckbox-colorPrimary": {
      color: "#7E6ECC !important",
    },
  }),
  customTextField: (props: any) => ({
    marginTop: "5px",
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
      background: "#232323",
      "& .MuiInputBase-input": {
        color: "#8F8F8F",
        "-webkit-text-fill-color": "#8F8F8F",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px !important",
    },
    "& .MuiInputBase-root": {
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
  }),
  rootContainer: (props: any) => ({
    display: "flex",
    justifyContent: "space-between",
    padding: "15px",
    background: "#151515",
    alignItems: "center",
    marginLeft: "16px !important",
  }),
  mainContainer: (props: any) => ({
    background: "#111111",
    marginLeft: "16px !important",
    height: "calc(100vh - 432px)",
    position: "relative",
    [muiTheme.breakpoints.down(1437)]: {
      height: "calc(100vh - 357px)",
    },
  }),
  geofenceContainer: (props: any) => ({
    display: "flex",
    justifyContent: "space-between",
    padding: "15px",
  }),
  geofenceTypes: (props: any) => ({
    padding: "15px 15px 0 15px",
  }),
  geofenceMainTypes: (props: any) => ({
    display: "flex",
  }),
  geofenceCircleType: (props: any) => ({
    paddingTop: "10px",
    paddingRight: "50px",
    cursor: "pointer",
  }),
  circularText: (props: any) => ({
    fontSize: "12px",
    fontFamily: `'Nunito Sans', sans-serif !important`,
  }),
  polygonContainer: (props: any) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }),
  polygonImage: (props: any) => ({
    paddingTop: "10px",
    cursor: "pointer",
  }),
  geofenceTextContainer: (props: any) => ({
    paddingTop: "15px",
  }),
  circleContainer: (props: any) => ({
    padding: "12px",
    display: "flex",
    justifyContent: "space-between",
  }),
  circularRadius: (props: any) => ({
    padding: "12px",
  }),
  notifyContainer: (props: any) => ({
    padding: "12px",
  }),
  polygonTextContainer: (props: any) => ({
    paddingLeft: "15px",
    display: "flex",
    alignItems: "center",
  }),
  polygonText: (props: any) => ({
    paddingLeft: "10px",
  }),
  buttonContainer: (props: any) => ({
    padding: "0 0 15px 15px",
    display: "flex",
    justifyContent: "end",
    position: "absolute",
    bottom: "0",
    right: "10px",
  }),
  cancelButtonContainer: (props: any) => ({
    paddingRight: "16px",
    "& .MuiButtonBase-root": {
      color: "#676767",
      border: "1px solid #363636",
      borderRadius: "50px",
      fontSize: "14px",
      fontFamily: `'Nunito Sans', sans-serif !important`,
      lineHeight: "19px",
      "&:hover": {
        border: "1px solid #363636",
      },
    },
  }),
  updateButtonContainer: (props: any) => ({
    "& .MuiButtonBase-root": {
      color: "#fff",
      background: "#7E6ECC",
      borderRadius: "50px",
      fontSize: "14px",
      fontFamily: `'Nunito Sans', sans-serif !important`,
      lineHeight: "19px",
      "&:hover": {
        background: "#7E6ECC",
      },
    },
    "& .Mui-disabled": {
      backgroundColor: "#7E7E97 !important",
      color: "#E0E0E0 !important",
    },
  }),
  resetButtonContainer: (props: any) => ({
    "& .MuiButtonBase-root": {
      color: "#fff",
      borderRadius: "55px",
      fontSize: "14px",
      fontFamily: `'Nunito Sans', sans-serif !important`,
      lineHeight: "19px",
      border: "1px solid #7E6ECC",
    },
  }),
}));

export default useStyles;
