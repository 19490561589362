import { all, takeLatest } from "redux-saga/effects";
import { handleLogin, handleLogout } from "./handlers/login";
import { handleDashboardList } from "./handlers/dashboardList";
import { handleTrailerList } from "./handlers/trailer";

import login from "../actions/loginActions";
import dashboardList from "../actions/dashboardListAction";
import trailerData from "../actions/trailerAction";


export default function* rootSaga() {
  yield all([
    watchLogin(),
    watchLanguage(),
    watchDashboardList(),
    watchLogout(),
    watchTrailer(),
    
  ]);
}

export function* watchLogin() {
  yield takeLatest(login.GET_USER_LOGIN, handleLogin);
}

export function* watchLanguage() {
  yield takeLatest(login.GET_LANGUAGE, handleLogin);
}

export function* watchDashboardList() {
  yield takeLatest(dashboardList.GET_DAHSBOARD_LIST, handleDashboardList);
}

export function* watchLogout() {
  yield takeLatest(login.GET_USER_LOGOUT, handleLogout);
}
export function* watchTrailer() {
  yield takeLatest(trailerData.GET_TRAILER_LIST, handleTrailerList);
}



