export default {
  title: "Hello Infinite Italian",
  profile: "Profile Italian",
  hello: "Hola",
  tabListName1: "Monitoraggio",
  tabListName2: "Notifica",
  trackingListButton1: "Magazzino",
  trackingListButton2: "Rimorchi",
  trackingListButton3: "Negozio",
  helloInfinite: "Hola Infinite",
  changeTheme: "Cambia Tema",
  changeLang: "Change Lingua",
  dashboardButton: "Pannello di controllo",
  manifestsButton: "Manifesta",
  configurationsButton: "Configurazioni",
  supervisorDefalutDomain: "Supervisore",
  infoIconTitle1: "MAGAZZINO",
  infoIconTitle2: "IN TRANSITO",
  infoIconTitle3: "TRACCIAMENTO DISCONNESSO",
  infoIconTitle4: "CONSEGNA IN RITARDO",
  listItemName1: "Merce Tracciata",
  listItemName2: "Attrezzatura Tracciata",
  listItemName3: "Punto Di Accesso",
  listItemButtonName: "Visualizza Dettagli",
  infoDialogueCategory: "Categoria",
  infoDialogueMake: "Fare",
  infoDialogueDimensions: "Dimensioni",
  infoDialogueTemperature: "Temperatura",
  infoDialogueBattery: "Batteria",
  infoDialogueLastUpdated: "Ultimo aggiornamento",
  infoDialogueNotifications: "Notifiche",
  infoDialogueType: "Tipo",
  infoDialogueUtilization: "Utilizzo",
  infoSubListBoxId: "ID scatola",
  infoSubListTrackerId: "Identificativo del localizzatore",
  infoSubListStatus: "Stato",
  infoSubListLocation: "Posizione",
  infoSubListTotalUnits: "Unità totali",
  infoSubListSource: "Fonte",
  chipButtonEvents: "Eventi",
  chipButtonIncidents: "Incidenti",
  chipButtonOprAlerts: "Opr.Avvisi",
  infoTabsListDetails: "DETTAGLI",
  infoTabsListGeofence: "GEOFENCE",
  BoxId: "Scatola ID",
  EquipmentId: "Attrezzatura ID",
  TrackerId: "Localizzatore ID",
  Status: "Stato",
  Battery: "Batteria",
  Temperature: "Temperatura",
  Notification: "Notifica",
  Goods: "Merce",
  Equipments: "Attrezzature",
  ViewDetail: "Visualizza Dettagli",
  Utilization: "Utilizzo",
  Connected: "Collegato",
  Disconnected: "Scollegato",
  LocationText: "Posizione",
  infoTabsListEquipmentID: "ID attrezzatura",
  infoTabsListState: "Stato",
  infoTabsListCurrentLocation: "Posizione Attuale",
  infoTabsListOperator: "Operatore",
  Pallets: "Pallet",
  Stores: "Negozio",
  CurrentLocation: "Posizione Attuale",
  trailerRouteDetails: "Trailer Route Details",
  trailerInfoTrailerID: "Trailer ID",
  trailerInfoManifestID: "Manifest ID",
  trailerInfoStartLocation: "Start Location",
  trailerInfoEndLocation: "End Location",
  trailerInfoTotalPallets: "Total Pallets",
  trailerInfoDeliveryStores: "Delivery Stores",
  analyticsButton: "Analitica",
};
