import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  rootContainer: (props: any) => ({
    fontFamily: `'Nunito Sans', sans-serif !important`,
  }),
  analyticsContent: (props: any) => ({
    minHeight: "calc(100vh - 106px) !important",
    marginTop: "84px !important",
    padding: "18px 0px 2px 20px",

    "& .google-map-code": {
      height: "100%",
    },
  }),
  analyticsInnerContainer: {
    marginTop: 10,
  },
  selectSection: (props: any) => ({}),
  tabSection: (props: any) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: " 8px 16px 0 0px",
    borderBottom: `1px solid #b1a6eaad`,
    "& .MuiButtonBase-root": {
      marginRight: "0 !important",
    },
    "& .MuiTabs-root": {
      marginTop: "6px !important",
    },
    "& .MuiTab-root": {
      fontSize: 18,
      padding: "0px 20px !important",
      paddingBottom: "8px !important",
      fontWeight: 600,
      color: "#B1A6EA",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#6858B7",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#6858B7",
      borderRadius: "0px 0px 50px 50px !important",
      transform: "rotate(-180deg)",
      height: 4,
      left: "114.0938px",
    },
  }),
  customNotificationTabs: (props: any) => ({
    "& .MuiButtonBase-root": {
      marginRight: "25px !important",
      minWidth: "64px !important",
    },
  }),
  customSelectDropDown: (props: any) => ({
    minWidth: "100px !important",
    minheight: "5px !important",
    "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
      {
        paddingRight: "150px !important",
      },
    "& .MuiPaper-root": {
      border: "1px solid #F3F0FC !important",
    },
    "& .MuiSelect-select": {
      padding: "10px 11px",
    },
    "& .MuiInputBase-root": {
      borderRadius: "5px",
      fontSize: 14,
      // lineHeight: 21,
      textAlign: "left",
      fontWeight: "bold",
      position: "relative",
      color: "#7E6ECC",
      "& .MuiSvgIcon-root": {
        position: "absolute",
        right: "10px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        color: "#F3F0FC",
        border: `1px solid #7E6ECC !important`,
      },
    },
    "& .css-t0wjx6-MuiButtonBase-root-MuiMenuItem-root.Mui-selected.MuiMenuItem-root":
      {
        color: "#fff !important",
      },

    "& .MuiSelect-iconOutlined": {
      color: `#7E6ECC !important`,
    },
  }),
});
export default useStyles;
