import { makeStyles } from "@mui/styles";
import muiTheme from "theme/muiTheme";

const useStyles = makeStyles({
  actionIcons: (props: any) => ({
    display: "flex",
  }),
  editIcon: (props: any) => ({
    paddingLeft: "20px",
    cursor: "pointer",
  }),
  deleteIcon: (props: any) => ({
    paddingLeft: "20px",
    cursor: "pointer",
  }),
  tableHeight: (props: any) => ({
    height: "calc(100vh - 282px)",
    overflow: "auto",
    [muiTheme.breakpoints.down(811)]: {
      height: "calc(100vh - 346px)",
    },
  }),

  loaderStyle: (props: any) => ({
    width: "1010%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 500px)",
  }),
});
export default useStyles;
