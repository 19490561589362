const manifestStorePallets = {
	stores: [
	  {
		id: "",
		name: "Store#1",
		area: "",
		location: {
		  lat: 12.949600757573837,
		  lng: 80.1237549523929,
		}
	  },
	  {
		id: "",
		name: "Store#2",
		area: "",
		location: {
		  lat: 10.949600757573837,
		  lng: 78.1237549523929,
		}
	  },
	],
  };
  
  export default manifestStorePallets;