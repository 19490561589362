import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import theme from "../../theme/theme";
import HC_rounded from "highcharts-rounded-corners";

HC_rounded(Highcharts);

// interface ExtendedXAxisTitleOptions extends Highcharts.XAxisTitleOptions {
//   enabled: boolean;
// }
// interface ExtendedChart extends Highcharts.Chart {
//   crosshair: Highcharts.SVGElement;
// }
// interface ExtendedSeriesLineOptions extends Highcharts.SeriesLineOptions {
//   enabledCrosshairs: boolean;
// }

const INF_Chart: React.FC<any> = (props) => {
  const {
    type,
    splineWidth,
    width,
    height,
    dataPoints,
    format,
    graphSequence,
    tooltipShow,
    isCrosshair,
    dataLabels,
    tabIdentity,
    pageName,
    xAxisArray,
    xAxisInterval,
    graphTitle,
    selectedAnalyticListItem,
    toolTipShared,
  } = props;

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);

  let collectedArrayValues: number[] = [];
  let minValue: number = 0;
  let maxValue: number = 0;

  dataPoints &&
    dataPoints[0].data.map((data: any) => {
      collectedArrayValues.push(data[0]);
    });

  minValue = Math.min(...collectedArrayValues);
  maxValue = Math.max(...collectedArrayValues);

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  const [toolTipBg, setToolTipBg] = useState<string>();
  const [tBorder, setTBorder] = useState<string>();

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        chart: {
          type: type,
          plotBackgroundColor: "transparent",
          backgroundColor: "transparent",
          height: height,
          width: width,
        },
        scrollbar: {
          barBackgroundColor: "gray",
          barBorderRadius: 7,
          barBorderWidth: 0,
          buttonBackgroundColor: "gray",
          buttonBorderWidth: 0,
          buttonBorderRadius: 7,
          trackBackgroundColor: "none",
          trackBorderWidth: 1,
          trackBorderRadius: 8,
          trackBorderColor: "#C38C8C",
        },
        title: false,
        legend: false,

        xAxis: {
          lineColor: "#C4C4C4",
          min: type === "spline" || type === "areaspline" ? 0.49 : 0,
          categories: xAxisArray,
          tickWidth: 0,
          tickLength: 0,
          tickInterval: xAxisInterval,
          showFirstLabel: true,
          showLastLabel: true,
          startOnTick: false,
          endOnTick: false,
          gridLineDashStyle: "LongDash",
          useHTML: true,
          lineWidth: pageName === "infoDialogue" ? 1 : 0,
          crosshair:
            isCrosshair && (type === "spline" || type === "areaspline")
              ? {
                  enabled: true,
                  width: 1,
                  color: "gray",
                  dashStyle: "ShortDash",
                  snap: true,
                }
              : false,
          type: "datetime",
          labels: {
            useHTML: true,
            overflow: "justify",
            format: format,
          },

          tickPositioner: function (this: any) {
            const ticks: any = this.tickPositions;
            if (!ticks.includes(this.dataMax)) ticks.push(this.dataMax);
            ticks.sort((a: any, b: any) => a - b);
            while (ticks[ticks.length - 1] > this.dataMax) {
              ticks.pop();
            }
            return ticks;
          },
        },
        yAxis: {
          title: false,
          visible: true,
          min:
            selectedAnalyticListItem === "Overall Analytics"
              ? graphTitle === "Equipment Efficiency (%)"
                ? 50
                : 0
              : 10,
          max: maxValue,
          tickInterval:
            selectedAnalyticListItem === "Overall Analytics"
              ? graphTitle === "Equipment Efficiency (%)"
                ? 25
                : 20
              : 20,
          startOnTick: false,
          lineColor: "#C4C4C4",
          lineWidth: 0.5,
          gridLineWidth: pageName === "infoDialogue" ? 0 : 1,
          minorGridLineWidth: 1,
          gridLineDashStyle: "LongDash",
          plotLines:
            pageName !== "infoDialogue"
              ? [
                  {
                    value: maxValue,
                    zIndex: 2,
                    width: 0,
                    dashStyle: "Dash",
                    color: "#F00C0C",
                  },
                  {
                    value: minValue,
                    zIndex: 2,
                    width: 0,
                    dashStyle: "Dash",
                    color: "#F00C0C",
                  },
                ]
              : [
                  {
                    value: 0,
                    zIndex: 2,
                    width: 0,
                    dashStyle: "Dash",
                    color: "#C4C4C4",
                  },
                ],
        },

        tooltip: {
          shared: toolTipShared,
          useHTML: true,
          backgroundColor: toolTipBg,
          borderColor: tBorder,
          padding: 4,
          className: "tooltipStyle",
          style: {
            color: "#fff",
            fontWeight: "bold",
          },
          formatter: function (
            this: Highcharts.TooltipFormatterContextObject
          ): string | boolean {
            if (tooltipShow && !toolTipShared) {
              const value: any = this.y;

              const thisColorColumnChart3: any = this?.color;
              switch (graphSequence) {
                case "graph1":
                  setToolTipBg(
                    pageName === "analyticsPage"
                      ? tabIdentity === "warehouse"
                        ? graphTitle === "Energy Consumed (kWh)"
                          ? "#9486DA"
                          : graphTitle === "Hours of Operation (Hrs)"
                          ? "#949FFF"
                          : "#0C8ACC"
                        : graphTitle === "Operating Hrs"
                        ? "#9BAEF4"
                        : "#C38C8C"
                      : graphTitle === "Operating Hrs"
                      ? "#9BAEF4"
                      : "#C38C8C"
                  );
                  setTBorder(
                    pageName === "analyticsPage"
                      ? graphTitle === "Energy Consumed (kWh)"
                        ? "#9486DA"
                        : graphTitle === "Hours of Operation (Hrs)"
                        ? "#949FFF"
                        : "#0C8ACC"
                      : graphTitle === "Operating Hours"
                      ? "#7979D7"
                      : "#C38C8C"
                  );
                  break;
                case "graph2":
                  setToolTipBg(
                    pageName === "analyticsPage"
                      ? graphTitle === "Total Trips"
                        ? "#2CAD39"
                        : graphTitle === "Hours of Operation (Hrs)"
                        ? "#949FFF"
                        : "#ED6A6A"
                      : "#C38C8C"
                  );
                  setTBorder(
                    pageName === "analyticsPage"
                      ? graphTitle === "Total Trips"
                        ? "#2CAD39"
                        : graphTitle === "Hours of Operation (Hrs)"
                        ? "#949FFF"
                        : "#ED6A6A"
                      : "#C38C8C"
                  );
                  break;
                case "graph3":
                  setToolTipBg(
                    pageName === "analyticsPage"
                      ? thisColorColumnChart3
                      : "#7979D7"
                  );
                  setTBorder(
                    pageName === "analyticsPage"
                      ? thisColorColumnChart3
                      : "#7979D7"
                  );
                  break;
                case "graph4":
                  setToolTipBg(
                    pageName === "analyticsPage"
                      ? graphTitle === "Energy Consumed"
                        ? "#219DDB"
                        : graphTitle === "Hours of Operation (Hrs)"
                        ? "#949FFF"
                        : "#949FFF"
                      : "#65D9F2"
                  );
                  setTBorder(
                    pageName === "analyticsPage"
                      ? graphTitle === "Energy Consumed"
                      ? "#219DDB"
                        : graphTitle === "Hours of Operation (Hrs)"
                        ? "#949FFF"
                        : "#949FFF"
                      : "#65D9F2"
                  );
                  break;
                case "graph5":
                  setToolTipBg(
                    graphTitle === "Total Goods"
                      ? "#77B77C"
                      : graphTitle === "Equipment Efficiency"
                      ? "#4334CD"
                      : "#949FFF"
                  );
                  setTBorder(
                    graphTitle === "Energy Consumed (kWh)"
                      ? "#9486DA"
                      : graphTitle === "Equipment Efficiency"
                      ? "#4334CD"
                      : "#949FFF"
                  );
                  break;
                case "graph6":
                  setToolTipBg(
                    graphTitle === "Idle Hrs"
                      ? "#F4BA69"
                      : graphTitle === "Hours of Operation (Hrs)"
                      ? "#949FFF"
                      : "#FF718B"
                  );
                  setTBorder(
                    graphTitle === "Idle Hrs"
                      ? "#F4BA69"
                      : graphTitle === "Hours of Operation (Hrs)"
                      ? "#949FFF"
                      : "#FF718B"
                  );
                  break;
                case "graph7":
                  setToolTipBg(
                    graphTitle === "Energy Consumed (kWh)"
                      ? "#9486DA"
                      : graphTitle === "Hours of Operation (Hrs)"
                      ? "#949FFF"
                      : "#FABB70"
                  );
                  setTBorder(
                    graphTitle === "Energy Consumed (kWh)"
                      ? "#9486DA"
                      : graphTitle === "Hours of Operation (Hrs)"
                      ? "#949FFF"
                      : "#FABB70"
                  );
                  break;
                case "graph8":
                  setToolTipBg(
                    graphTitle === "Energy Consumed (kWh)"
                      ? "#9486DA"
                      : graphTitle === "Hours of Operation (Hrs)"
                      ? "#949FFF"
                      : "#00A3FF"
                  );
                  setTBorder(
                    graphTitle === "Energy Consumed (kWh)"
                      ? "#9486DA"
                      : graphTitle === "Hours of Operation (Hrs)"
                      ? "#949FFF"
                      : "#00A3FF"
                  );
                  break;
                case "graph9":
                  setToolTipBg(
                    graphTitle === "Energy Consumed (kWh)"
                      ? "#9486DA"
                      : graphTitle === "Hours of Operation (Hrs)"
                      ? "#949FFF"
                      : "#D365D6"
                  );
                  setTBorder(
                    graphTitle === "Energy Consumed (kWh)"
                      ? "#9486DA"
                      : graphTitle === "Hours of Operation (Hrs)"
                      ? "#949FFF"
                      : "#D365D6"
                  );
                  break;
              }

              return ` <table>
          <tr>
            <td style="text-align: center;">
                ${
                  graphSequence === "graph1"
                    ? pageName === "analyticsPage"
                      ? graphTitle === "Energy Consumed (kWh)"
                        ? `${value}kWh`
                        : graphTitle === "Hours of Operation (Hrs)"
                        ? `${value}Hrs`
                        : graphTitle === "Predictive Maintenance Hours (Hrs)"
                        ? `${value}Hrs`
                        : graphTitle === "Equipment Efficiency (%)"
                        ? `${value}%`
                        : graphTitle === "Raw Material Level (T)"
                        ? `${value}T`
                        : graphTitle === "Temperature (°C)"
                        ? `${value}°C`
                        : graphTitle === "Humidity (%)"
                        ? `${value}%`
                        : `${value}`
                      : graphTitle === "Operating Hours" ||
                        graphTitle === "Hours of Operation"
                      ? `${value}Hrs`
                      : `${value}°C`
                    : graphSequence === "graph2"
                    ? pageName === "analyticsPage"
                      ? graphTitle === "Energy Consumed (kWh)"
                        ? `${value}kWh`
                        : graphTitle === "Hours of Operation (Hrs)"
                        ? `${value}Hrs`
                        : graphTitle === "Predictive Maintenance Hours (Hrs)"
                        ? `${value}Hrs`
                        : graphTitle === "Equipment Efficiency (%)"
                        ? `${value}%`
                        : graphTitle === "Raw Material Level (T)"
                        ? `${value}T`
                        : graphTitle === "Temperature (°C)"
                        ? `${value}°C`
                        : graphTitle === "Humidity (%)"
                        ? `${value}%`
                        : `${value}`
                      : graphTitle === "Operating Hours" ||
                        graphTitle === "Hours of Operation (Hrs)"
                      ? `${value}Hrs`
                      : `${value}%`
                    : graphSequence === "graph3"
                    ? pageName === "analyticsPage"
                      ? graphTitle === "Energy Consumed (kWh)"
                        ? `${value}kWh`
                        : graphTitle === "Hours of Operation (Hrs)"
                        ? `${value}Hrs`
                        : graphTitle === "Predictive Maintenance Hours (Hrs)"
                        ? `${value}Hrs`
                        : graphTitle === "Equipment Efficiency (%)"
                        ? `${value}%`
                        : graphTitle === "Raw Material Level (T)"
                        ? `${value}T`
                        : graphTitle === "Temperature (°C)"
                        ? `${value}°C`
                        : graphTitle === "Humidity (%)"
                        ? `${value}%`
                        : `${value}`
                      : graphTitle === "Operating Hours" ||
                        graphTitle === "Hours of Operation (Hrs)"
                      ? `${value}Hrs`
                      : graphTitle === "Safety Infractions" ||
                        graphTitle === "Alerts"
                      ? `${value}`
                      : `${value}Hrs`
                    : graphSequence === "graph4"
                    ? pageName === "analyticsPage"
                      ? graphTitle === "Energy Consumed (kWh)"
                        ? `${value}kWh`
                        : graphTitle === "Hours of Operation (Hrs)"
                        ? `${value}Hrs`
                        : graphTitle === "Predictive Maintenance Hours (Hrs)"
                        ? `${value}Hrs`
                        : graphTitle === "Equipment Efficiency (%)"
                        ? `${value}%`
                        : graphTitle === "Raw Material Level (T)"
                        ? `${value}T`
                        : graphTitle === "Temperature (°C)"
                        ? `${value}°C`
                        : graphTitle === "Humidity (%)"
                        ? `${value}%`
                        : `${value}`
                      : graphTitle === "Operating Hours" ||
                        graphTitle === "Hours of Operation (Hrs)"
                      ? `${value}Hrs`
                      : `${value}`
                    : graphSequence === "graph5"
                    ? graphTitle === "Energy Consumed (kWh)"
                      ? `${value}kWh`
                      : graphTitle === "Hours of Operation (Hrs)"
                      ? `${value}Hrs`
                      : graphTitle === "Predictive Maintenance Hours (Hrs)"
                      ? `${value}Hrs`
                      : graphTitle === "Equipment Efficiency (%)"
                      ? `${value}%`
                      : graphTitle === "Raw Material Level (T)"
                      ? `${value}T`
                      : graphTitle === "Temperature (°C)"
                      ? `${value}°C`
                      : graphTitle === "Humidity (%)"
                      ? `${value}%`
                      : `${value}`
                    : graphSequence === "graph6"
                    ? graphTitle === "Energy Consumed (kWh)"
                      ? `${value}kWh`
                      : graphTitle === "Hours of Operation (Hrs)"
                      ? `${value}Hrs`
                      : graphTitle === "Predictive Maintenance Hours (Hrs)"
                      ? `${value}Hrs`
                      : graphTitle === "Equipment Efficiency (%)"
                      ? `${value}%`
                      : graphTitle === "Raw Material Level (T)"
                      ? `${value}T`
                      : graphTitle === "Temperature (°C)"
                      ? `${value}°C`
                      : graphTitle === "Humidity (%)"
                      ? `${value}%`
                      : `${value}`
                    : graphSequence === "graph7"
                    ? graphTitle === "Energy Consumed (kWh)"
                      ? `${value}kWh`
                      : graphTitle === "Hours of Operation (Hrs)"
                      ? `${value}Hrs`
                      : graphTitle === "Predictive Maintenance Hours (Hrs)"
                      ? `${value}Hrs`
                      : graphTitle === "Equipment Efficiency (%)"
                      ? `${value}%`
                      : graphTitle === "Raw Material Level (T)"
                      ? `${value}T`
                      : graphTitle === "Temperature (°C)"
                      ? `${value}°C`
                      : graphTitle === "Humidity (%)"
                      ? `${value}%`
                      : `${value}`
                    : graphSequence === "graph8"
                    ? graphTitle === "Energy Consumed (kWh)"
                      ? `${value}kWh`
                      : graphTitle === "Hours of Operation (Hrs)"
                      ? `${value}Hrs`
                      : graphTitle === "Predictive Maintenance Hours (Hrs)"
                      ? `${value}Hrs`
                      : graphTitle === "Equipment Efficiency (%)"
                      ? `${value}%`
                      : graphTitle === "Raw Material Level (T)"
                      ? `${value}T`
                      : graphTitle === "Temperature (°C)"
                      ? `${value}°C`
                      : graphTitle === "Humidity (%)"
                      ? `${value}%`
                      : `${value}`
                    : graphSequence === "graph9"
                    ? graphTitle === "Energy Consumed (kWh)"
                      ? `${value}kWh`
                      : graphTitle === "Hours of Operation (Hrs)"
                      ? `${value}Hrs`
                      : graphTitle === "Predictive Maintenance Hours (Hrs)"
                      ? `${value}Hrs`
                      : graphTitle === "Equipment Efficiency (%)"
                      ? `${value}%`
                      : graphTitle === "Raw Material Level (T)"
                      ? `${value}T`
                      : graphTitle === "Temperature (°C)"
                      ? `${value}°C`
                      : graphTitle === "Humidity (%)"
                      ? `${value}%`
                      : `${value}`
                    : null
                }
            </td>
          </tr>
        </table>`;
            } else if (toolTipShared) {
              const thisXCopy: any = this?.x;
              return `<table>
                            <tr>
                            <td 
                            style="font-weight: 100;
                            line-height:10px;
                            font-size:14px;
                            color:#464646;
                            background-color:#fff;
                            padding:10px 20px 5px 10px;
                            vertical-align:middle;
                            border-radius:5px;
                            box-shadow:0 0 5px #464646;">
                              ${this?.points?.reduce(
                                (s: any, point: any): any => {
                                  return (
                                    s +
                                    `<div 
                                  style="background-color:${
                                    point.series.color === "#B3A7F0"
                                      ? "#37CB94"
                                      : point.series.color
                                  };
                                  padding:7px;
                                  display:inline-block;
                                  margin-right:5px;
                                  border-radius:3px;
                                  "></div>` +
                                    point?.series?.name +
                                    " - " +
                                    point?.y +
                                    "<br/><br/>"
                                  );
                                },
                                ""
                              )}
                            </td>
                      </tr>
                    </table>`;

              // return `<table>
              //         <tr>
              //         <td style="font-weight:bold;color:black">
              //           ${this?.points?.reduce((s:any, point:any):any =>{
              //             return (
              //               point?.series?.name + ": " + point?.y + "<br/>" + s
              //             );
              //           },
              //           `<b>
              //             ${new Date(thisXCopy).toLocaleDateString("nl-Nl")}
              //            </b>`
              //           )}
              //         </td>
              //   </tr>
              // </table>`;
            } else {
              return false;
            }
          },
        },

        plotOptions: {
          // column: {
          //   dataLabels: {
          //     enabled: dataLabels,
          //     format:
          //       '<div style="font-size: 11px; line-height: 20px">${y}K</div>',
          //     backgroundColor: "#000",
          //     color: "#fff",
          //     y: -4,
          //     shadow: true,
          //     align: "center",
          //     shape: "circle",
          //   },
          //   grouping: false,
          // },

          
          series: {
            
           borderRadius: graphTitle === "Total Deliveries" && 1,
            
            
            fillColor:
              graphTitle === "Equipment Utilization"
                ? {
                    linearGradient: [0, 0, 0, 300],
                    stops: [
                      [0, "#949FFF"],
                      [1, Highcharts.color("#fff").setOpacity(0).get("rgba")],
                    ],
                  }
                : graphTitle === "Energy Consumed"
                ? {
                    linearGradient: [0, 0, 0, 300],

                    stops: [
                      [0, "#62CBFF"],
                      [
                        1,
                        Highcharts.color("#fff").setOpacity(0).get("rgba"),
                      ],
                    ],
                  }
                : "",
            borderColor: "transparent",
            marker: {
              enabled: false,
            },

            lineWidth: splineWidth,
            
                
          },
          line: {
            dashStyle: "Dash",
          },
        },
        credits: {
          enabled: false,
        },
        series: dataPoints,
      }}
    />
  );
};

export default INF_Chart;
