import React, { useEffect, useState } from "react";
import theme from "../../theme/theme";
import Button from "../../elements/Button";
import useTranslation from "../../localization/translations";
import { useNavigate } from "react-router-dom";
import SourceIcon from "../../assets/SourceIcon.svg";
import DestinationIcon from "../../assets/DestinationIcon.svg";
import ArrowIcon from "../../assets/ArrowIcon.svg";
import WifiIcon from "../../assets/wifiIcon.svg";
import WifiDisconnected from "../../assets/wifiDisconnected.svg";
import PalletsIcon from "../../assets/PalletsIcon.svg";
import CurrentLocationIcon from "../../assets/CurrentLocationIcon.svg";
import StoresIcon from "../../assets/StoresIcon.svg";
import CalendarIcon from "../../assets/CalendarIcon.svg";
import BatteryIcon from "../../assets/BatteryIcon.svg";
import TemperatureIcon from "../../assets/TemperatureIcon.svg";
import UtilizationIcon from "../../assets/UtilizationIcon.svg";
import useStyles from "./styles";

interface dashboardListProps {
  selectedTabButton: number;
  data: any;
  handleExpandListItem: any;
  index: number;
  selectedNotification: number;
}

const DashboardListItems: React.FC<any> = (props: any) => {
  const {
    refs,
    data,
    data: {
      title,
      id,
      currentTimeStamp,
      itemList,
      connectedStatus,
      source,
      destination,
      currentLocation,
      pallets,
      stores,
      deliveryDate,
      area,
      name,
      utilization,
      battery,
      temperature,
      category,
    },
    handleExpandListItem,
    index,
    selectedNotification,
    notificationTimeStamp,
    tabIndex,
    selectedTabButton,
    handleViewDetail,
  } = props;

  const navigate = useNavigate();

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);

  const {
    listItemName1,
    listItemName2,
    listItemName3,
    listItemButtonName,
    Status,
    Pallets,
    Stores,
    CurrentLocation,
    BoxId,
    TrackerId,
    EquipmentId,
    Battery,
    Temperature,
    Utilization,
    LocationText,
  } = useTranslation();

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  const {
    rootContainer,
    itemTitleStyle,
    itemAreaStyle,
    expandedListClass,
    collapsedListClass,
    displayFlex,
    itemListCount,
    itemListName,
    selectedButtonStyles,
    timeStampStyle,
    trailerAreaStyle,
    trailerLocationStyle,
    trailerArrowStyle,
    trailerLocationIconStyle,
    trailerLocationTextStyle,
    itemListIconStyle,
    itemListStyle,
    itemTitleStyleNotification,
  } = useStyles(appTheme);

  if (tabIndex === 0 && selectedTabButton === 1) {
    return (
      <>
        <div
          className={rootContainer}
          onClick={() =>
            handleExpandListItem(index, id, currentTimeStamp, data)
          }
          ref={refs[id]}
        >
          <div>
            {selectedNotification === id ? (
              <div className={expandedListClass}>
                <div className={itemTitleStyle}>{title}</div>
                <div className={itemAreaStyle}>Manifest ID : {id}</div>
                <div className={itemListStyle}>
                  <div>
                    <div className={itemListCount}>
                      <img
                        className={itemListIconStyle}
                        src={
                          itemList?.connectedStatus.toLowerCase() ===
                          "connected"
                            ? WifiIcon
                            : WifiDisconnected
                        }
                        alt=""
                      />
                      {itemList?.connectedStatus}
                    </div>
                    <div className={itemListName}>{Status}</div>
                  </div>
                  <div>
                    <div className={itemListCount}>
                      <img
                        className={itemListIconStyle}
                        src={PalletsIcon}
                        alt=""
                      />
                      {itemList?.pallets}
                    </div>
                    <div className={itemListName}>{Pallets}</div>
                  </div>
                  <div>
                    <div className={itemListCount}>
                      {" "}
                      <img
                        className={itemListIconStyle}
                        src={StoresIcon}
                        alt=""
                      />
                      {itemList?.stores}
                    </div>
                    <div className={itemListName}>{Stores}</div>
                  </div>
                  <div>
                    <div className={itemListCount}>
                      <img
                        className={itemListIconStyle}
                        src={CurrentLocationIcon}
                        alt=""
                      />
                      {itemList?.currentLocation}
                    </div>
                    <div className={itemListName}>{CurrentLocation}</div>
                  </div>
                </div>
                <div className={trailerAreaStyle}>
                  <div className={trailerLocationStyle}>
                    <img
                      src={SourceIcon}
                      alt="SourceIcon"
                      className={trailerLocationIconStyle}
                    />
                    <h3 className={trailerLocationTextStyle}>{source}</h3>
                  </div>
                  <div className={trailerArrowStyle}>
                    <img src={ArrowIcon} alt="ArrowIcon" />
                  </div>
                  <div className={trailerLocationStyle}>
                    <img
                      src={DestinationIcon}
                      alt="DestinationIcon"
                      className={trailerLocationIconStyle}
                    />
                    <h3 className={trailerLocationTextStyle}>{destination}</h3>
                  </div>
                </div>
                <>
                  <div className={displayFlex}>
                    <Button
                      variant="contained"
                      handleClick={() => handleViewDetail(itemList?.trailerId)}
                      buttonStyles={selectedButtonStyles}
                    >
                      {listItemButtonName}
                    </Button>
                    <div className={timeStampStyle}>
                      {notificationTimeStamp}
                    </div>
                  </div>
                </>
              </div>
            ) : (
              <div className={collapsedListClass}>
                <div className={itemTitleStyle}>{title}</div>
                {/* <div className={itemTitleStyle}>TRAILER#1</div> */}
                <div className={trailerAreaStyle}>
                  <div className={trailerLocationStyle}>
                    <img
                      src={SourceIcon}
                      alt="SourceIcon"
                      className={trailerLocationIconStyle}
                    />
                    <h3 className={trailerLocationTextStyle}>{source}</h3>
                  </div>
                  <div className={trailerArrowStyle}>
                    <img src={ArrowIcon} alt="ArrowIcon" />
                  </div>
                  <div className={trailerLocationStyle}>
                    <img
                      src={DestinationIcon}
                      alt="DestinationIcon"
                      className={trailerLocationIconStyle}
                    />
                    <h3 className={trailerLocationTextStyle}>{destination}</h3>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  if (tabIndex === 0 && selectedTabButton === 2) {
    return (
      <>
        <div
          className={rootContainer}
          onClick={() => handleExpandListItem(index, id, currentTimeStamp)}
          ref={refs[id]}
        >
          <div>
            {selectedNotification === id ? (
              <div className={expandedListClass}>
                <div className={itemTitleStyle}>{title}</div>
                <div className={itemAreaStyle}>Manifest ID : {id}</div>
                <div className={itemListStyle}>
                  <div>
                    <div className={itemListCount}>
                      <img
                        className={itemListIconStyle}
                        src={CalendarIcon}
                        alt=""
                      />
                      {itemList?.deliveryDate}
                    </div>
                    <div className={itemListName}>Delivery Date</div>
                  </div>
                  <div>
                    <div className={itemListCount}>
                      <img
                        className={itemListIconStyle}
                        src={PalletsIcon}
                        alt=""
                      />
                      {itemList?.pallets}
                    </div>
                    <div className={itemListName}>{Pallets}</div>
                  </div>

                  <div>
                    <div className={itemListCount}>
                      <img
                        className={itemListIconStyle}
                        src={CurrentLocationIcon}
                        alt=""
                      />
                      {area}
                    </div>
                    <div className={itemListName}>{CurrentLocation}</div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={collapsedListClass}>
                <div className={itemTitleStyle}>{title}</div>
                <div className={itemAreaStyle}>{itemList?.area}</div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  if (tabIndex === 1) {
    return (
      <>
        <div
          className={rootContainer}
          onClick={() => handleExpandListItem(index, id, currentTimeStamp)}
          ref={refs[id]}
        >
          <div>
            {selectedNotification === id ? (
              <div className={expandedListClass}>
                <div className={itemTitleStyle}>
                  {name} |{" "}
                  {itemList?.warehouseCategory?.type === "equipment"
                    ? "Equipment ID"
                    : itemList?.warehouseCategory?.type === "box"
                    ? "BOX ID"
                    : itemList?.warehouseCategory?.type === "tracking"
                    ? "TID"
                    : "Pallet Id "}{" "}
                  :{" "}
                  {itemList?.warehouseCategory
                    ? itemList?.warehouseCategory?.id
                    : itemList?.palletId}
                </div>
                <div className={itemAreaStyle}>
                  {itemList?.warehouseCategory?.type === "equipment"
                    ? "Type"
                    : category === "trailers"
                    ? "Last Location "
                    : "Location "}
                  -{" "}
                  {itemList?.warehouseCategory
                    ? itemList?.warehouseCategory?.locationType
                    : itemList?.currentLocation}{" "}
                  {title}, {area}
                </div>
                <div className={displayFlex}>
                  <div>
                    <div className={itemListCount}>
                      <img
                        className={itemListIconStyle}
                        src={
                          itemList?.connectedStatus?.toLowerCase() ===
                          "connected"
                            ? WifiIcon
                            : WifiDisconnected
                        }
                        alt=""
                      />
                      {itemList?.connectedStatus}
                    </div>
                    <div className={itemListName}>{Status}</div>
                  </div>
                  {itemList?.utilization && (
                    <div>
                      <div className={itemListCount}>
                        <img
                          className={itemListIconStyle}
                          src={UtilizationIcon}
                          alt="UtilizationIcon"
                        />
                        {itemList?.utilization}
                      </div>
                      <div className={itemListName}>{Utilization}</div>
                    </div>
                  )}
                  <div>
                    <div className={itemListCount}>
                      <img
                        className={itemListIconStyle}
                        src={BatteryIcon}
                        alt=""
                      />
                      {itemList?.battery}
                    </div>
                    <div className={itemListName}>{Battery}</div>
                  </div>
                  <div>
                    <div className={itemListCount}>
                      <img
                        className={itemListIconStyle}
                        src={TemperatureIcon}
                        alt=""
                      />
                      {itemList?.temperature}
                    </div>
                    <div className={itemListName}>{Temperature}</div>
                  </div>
                </div>
                <div className={timeStampStyle}>{notificationTimeStamp}</div>
              </div>
            ) : (
              <div className={collapsedListClass}>
                <div className={itemTitleStyleNotification}>
                  <div>{name}</div>
                  <div className={timeStampStyle}>{currentTimeStamp}</div>
                </div>
                {category === "wareHouses" && (
                  <div className={itemAreaStyle}>
                    {itemList?.warehouseCategory?.type === "equipment"
                      ? "Equipment ID"
                      : itemList?.warehouseCategory?.type === "box"
                      ? "BOX ID"
                      : "TID"}{" "}
                    : {itemList?.warehouseCategory?.id} | {title}, {area}
                  </div>
                )}
                {category === "trailers" && (
                  <div className={itemAreaStyle}>
                    Pallet Id : {itemList?.palletId} | {title} {area}
                  </div>
                )}
                {category === "stores" && (
                  <div className={itemAreaStyle}>
                    Pallet Id : {itemList?.palletId}| Location : {title}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div
        className={rootContainer}
        onClick={() => handleExpandListItem(index, id, currentTimeStamp)}
        ref={refs[id]}
      >
        <div>
          {selectedNotification === id ? (
            <div className={expandedListClass}>
              <div className={itemTitleStyle}>{title}</div>
              <div className={itemAreaStyle}>{itemList?.area}</div>
              <div className={displayFlex}>
                <div>
                  <div className={itemListCount}>{itemList?.goodsTracked}</div>
                  <div className={itemListName}>{listItemName1}</div>
                </div>
                <div>
                  <div className={itemListCount}>
                    {itemList?.equippmentTracked}
                  </div>
                  <div className={itemListName}>{listItemName2}</div>
                </div>
                <div>
                  <div className={itemListCount}>{itemList?.accesspoint}</div>
                  <div className={itemListName}>{listItemName3}</div>
                </div>
              </div>
              <>
                <div className={displayFlex}>
                  <Button
                    variant="contained"
                    handleClick={() => handleViewDetail(itemList?.trailerId)}
                    buttonStyles={selectedButtonStyles}
                  >
                    {listItemButtonName}
                  </Button>
                  <div className={timeStampStyle}>{notificationTimeStamp}</div>
                </div>
              </>
            </div>
          ) : (
            <div className={collapsedListClass}>
              <div className={itemTitleStyle}>{title}</div>
              <div className={itemAreaStyle}>{itemList?.area}</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DashboardListItems;
