import { makeStyles } from "@mui/styles";
import waterMark from "../../assets/configurations/water-mark.svg";
import muiTheme from "theme/muiTheme";

const useStyles = makeStyles({
  rootContainer: (props: any) => ({
    fontFamily: `'Nunito Sans', sans-serif !important`,
  }),
  configurationsRight: (props: any) => ({
    background: "#F3F0FC",
    position: "relative",
    "&::before": {
      width: 164,
      height: 120,
      content: '""',
      position: "absolute",
      background: `url(${waterMark})`,
      right: 0,
      bottom: 0,
    },
  }),
  configrationContent: (props: any) => ({
    minHeight: "calc(100vh - 84px) !important",
    marginTop: "84px !important",
    "& .google-map-code": {
      height: "100%",
    },
  }),
  configurationsLeft: (props: any) => ({
    background: "#E9E6FF",
    boxShadow: "0px 4px 2px 5px rgba(0, 0, 0, 0.05)",
  }),

  dragDeviceSection: (props: any) => ({
    minWidth: 592,
    padding: "36px 0px",
    background: "#E9E5FF",
    borderRadius: "6px",
  }),
  uploadSection: (props: any) => ({
    display: "flex",
    alignItems: " center",
    justifyContent: "center",
    height: "100%",
  }),
  dragContent: (props: any) => ({
    fontFamily: "Nunito Sans",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "19px",
    color: "#8978DC",
    background: "#F3F0FC",
    border: "1px dashed #C8C0ED",
    borderRadius: "5px",
    marginBottom: 37,
    cursor: "pointer",
    minWidth: 448,
    minHeight: 116,
    display: "flex",
    alignItems: " center",
    justifyContent: "center",
  }),
  dragButton: (props: any) => ({
    pointerEvents: "none",
    "& .MuiButtonBase-root": {
      fontFamily: "Nunito Sans !important",
      fontWeight: "700 !important",
      fontSize: "14px !important",
      lineHeight: "19px !important",
      color: "#FFFFFF !important",
      background: "#C8C1ED !important",
      borderRadius: "6px !important",
      border: "1px solid #c8c1ed !important",
      padding: "14px 30px",
      marginTop: "0 !important",
    },
  }),
  dragButtonActive: (props: any) => ({
    pointer: "curser",
    "& .MuiButtonBase-root": {
      fontFamily: "Nunito Sans !important",
      fontWeight: "700 !important",
      fontSize: "14px !important",
      lineHeight: "19px !important",
      color: "#FFFFFF !important",
      background: "#7E6ECC !important",
      borderRadius: "6px !important",
      border: "1px solid #c8c1ed !important",
      padding: "14px 30px",
      marginTop: "0 !important",
    },
  }),
  innerDragSection: (props: any) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: " center",
    justifyContent: "center",
    height: "100%",
  }),
  dragImageSection: (props: any) => ({
    marginBottom: 20,
  }),
  muiChipStyle: (props: any) => ({
    background: "#9083D1",
    color: "white",
  }),
  innerDragSectionConfigured: (props: any) => ({
    background: "#E9E5FF",
    borderRadius: "6px",
    fontFamily: "Nunito Sans",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "19px",
    color: "#8978DC",
    cursor: "pointer",
    width: 591,
    height: 332,
    display: "flex",
    flexDirection: "column",
    alignItems: " center",
    justifyContent: "center",
  }),
  uploadSectionConfigured: () => ({
    display: "flex",
    alignItems: " center",
    justifyContent: "center",
    height: "calc(100vh - 84px)",
    [muiTheme.breakpoints.down(900)]: {
      height: "calc(100vh - 600px) !important",
    },
  }),
  customNotificationTabs: (props: any) => ({
    "& .MuiButtonBase-root": {
      marginRight: "25px !important",
      minWidth: "64px !important",
    },
  }),
  tabSection: (props: any) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: " 8px 16px 0 0px",
    borderBottom: `1px solid #b1a6eaad`,
    marginBottom: 38,
    "& .MuiButtonBase-root": {
      marginRight: "0 !important",
    },
    "& .MuiTabs-root": {
      marginTop: "6px !important",
    },
    "& .MuiTab-root": {
      fontSize: 18,
      padding: "0px 20px !important",
      paddingBottom: "8px !important",
      fontWeight: 600,
      color: "#B1A6EA",
      fontFamily: `'Nunito Sans', sans-serif !important`,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#6858B7",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#6858B7",
      borderRadius: "0px 0px 50px 50px !important",
      transform: "rotate(-180deg)",
      height: 4,
      left: "114.0938px",
    },
  }),
  configuredRightPanelSection: (props: any) => ({
    margin: 48,
  }),
  rightPanelList: (props: any) => ({
    height: "calc(100vh - 281px)",
    overflow: "auto",
  }),
  insideRightPanelList: (props: any) => ({
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    "& :last-child": {
      marginBottom: 0,
    },
  }),
  resetButtonClass: (props: any) => ({
    margin: "-33px 0px 9px 0px",
    "& .MuiButtonBase-root": {
      padding: "0 30px",
      background: "transparent !important",
      color: "#7E6ECC !important",
      border: "1px solid #7E6ECC !important",
      letterSpacing: "0.5px",
    },
  }),
  cancelUpdateButtonContainer: (props: any) => ({
    display: "flex",
    marginLeft: 20,
    justifyContent: "space-around",
  }),
  cancelButtonClass: (props: any) => ({
    margin: "-33px 0px 9px 0px",
    "& .MuiButtonBase-root": {
      padding: "0 30px",
      background: "transparent !important",
      color: "#CBCBCB !important",
      border: "1px solid #CBCBCB !important",
      letterSpacing: "0.5px",
    },
  }),
  updateButtonClass: (props: any) => ({
    margin: "-33px 0px 9px 20px",
    "& .MuiButtonBase-root": {
      padding: "0 30px",
      background: "#7E6ECC !important",
      color: "white !important",
      border: "1px solid #7E6ECC !important",
      letterSpacing: "0.5px",
    },
  }),
  addIconStyle: (props: any) => ({
    margin: "0px 32px 4px 0px",
    cursor: "pointer",
  }),
});
export default useStyles;
