import { put } from "redux-saga/effects";
import { setDashboardList } from "../../actions/dashboardListAction";
// import fetchAPIServices from "../../../services/fetchAPIServices";
// import { loginApi } from "../../../services/endPoints";
import dashBoardLogisticsData from "../../../mockdata/dashBoardLogisticsData";

export function* handleDashboardList(action: any) {
  try {
    // const { fetchLogin } = fetchAPIServices;
    // const response = yield fetchLogin(loginApi, action.payload);

    const response = dashBoardLogisticsData;
    if (response) {
      yield put(setDashboardList(response));
    } else {
      yield put(setDashboardList({}));
    }
  } catch (error) {
    console.log(error);
  }
}
