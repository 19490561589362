import { makeStyles } from "@mui/styles";
import muiTheme from "theme/muiTheme";

const useStyles = makeStyles(() => ({
  customNotificationTabs: (props: any) => ({
    "& .MuiTabs-flexContainer": {
      // borderBottom: "2px solid #3e3e3e",
    },

    "& .MuiButtonBase-root": {
      marginRight: "0 !important",
      minWidth: "64px !important",
    },

    "& .MuiTab-root": {
      "&:first-child": {
        marginRight: "0 !important",
        minWidth: "64px !important",
        color: `${props?.palette?.infoDialogueComponent?.darkGrayShades}!important`,
      },
      "&:first-child.Mui-selected": {
        color: `${props?.palette?.infoDialogueComponent?.greenBlackShades}!important`,
      },
      "&:nth-child(2)": {
        marginRight: "0 !important",
        minWidth: "64px !important",
        color: `${props?.palette?.infoDialogueComponent?.darkGrayShades}!important`,
      },
      "&:nth-child(2).Mui-selected": {
        color: `${props?.palette?.infoDialogueComponent?.greenBlackShades}!important`,
      },
    },
    "& .MuiTabs-root .MuiTabs-indicator": {
      background: props?.palette?.infoDialogueComponent?.greenBlackShades,
    },
  }),
  chipButtonSection: (props: any) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "14px",
  }),
  headerStyle: (props: any) => ({
    fontWeight: 700,
    fontSize: 20,
    lineHeight: "27px",
    textTransform: "uppercase",
    marginBottom: 30,
    fontFamily: `'Nunito Sans', sans-serif !important`,
    [muiTheme.breakpoints.down(1437)]: {
      fontSize: 14,
      lineHeight: "20px",
      marginBottom: 10,
    },
  }),
  tripsElementSection: (props: any) => ({
    background: "rgba(0, 0, 0, 0.37)",
    display: "flex",
    flexDirection: "column",
    width: "50%",
    border: `1px solid ${props?.palette?.infoDialogueComponent?.lightShadeGray}`,
    padding: "25px 20px 10px 20px",
    // borderRight: `1px solid ${props?.palette?.infoDialogueComponent?.colorWhite}`,
    [muiTheme.breakpoints.down(1437)]: {
      padding: 16,
    },
  }),
  detailsSection: (props: any) => ({
    display: "flex",
    marginBottom: "20px",
    fontFamily: `'Nunito Sans', sans-serif !important`,
    [muiTheme.breakpoints.down(1437)]: {
      fontSize: 11,
      lineHeight: "16px",
      marginBottom: 10,
      fontFamily: `'Nunito Sans', sans-serif !important`,
    },
  }),
  labelSection: (props: any) => ({
    width: "40%",
  }),

  infoListItem: (props: any) => ({
    borderBottomLeftRadius: "6px",
    borderTopLeftRadius: "6px",
    padding: "8px 0px 2px 8px",
    flex: 1,
  }),

  tripsSection2: (props: any) => ({
    // border: `1px solid ${props?.palette?.infoDialogueComponent?.lightShadeGray}`,
    // borderBottomLeftRadius: "6px",
    // borderTopLeftRadius: "6px",
    // marginRight: 4,
    // height: "210px",
    // overflow: "auto",
    // position: "relative",
    // marginBottom: "50px",
    display: "flex",
    justifyContent: "space-between",
    background: "#040404",
    padding: "30px",
    [muiTheme.breakpoints.down(1437)]: {
      padding: "12px !important",
    },
  }),
  tripsSection3: (props: any) => ({
    border: `1px solid ${props?.palette?.infoDialogueComponent?.lightShadeGray}`,
    borderBottomLeftRadius: "6px",
    borderTopLeftRadius: "6px",
    height: "264px",
    // overflow: "auto",
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 16,
  }),

  tripsSection1: (props: any) => ({
    // border: `1px solid ${props?.palette?.infoDialogueComponent?.lightShadeGray}`,
    // borderBottomLeftRadius: "6px",
    // borderTopLeftRadius: "6px",
    // marginRight: 4,

    // overflow: "auto",
    // position: "relative",
    // marginBottom: "50px",
    display: "flex",
    justifyContent: "space-between",
  }),
  tripsSection: (props: any) => ({
    // border: `1px solid ${props?.palette?.infoDialogueComponent?.lightShadeGray}`,
    // borderBottomLeftRadius: "6px",
    // borderTopLeftRadius: "6px",
    // marginRight: 4,
    // overflow: "auto",
    // position: "relative",
  }),

  colorBox: (props: any) => ({
    width: "25px",
    height: "25px",
    marginRight: "15px",
    borderRadius: "5px",
    [muiTheme.breakpoints.down(1437)]: {
      width: "15px",
      height: "15px",
      marginRight: "12px",
      borderRadius: "2px",
    },
  }),
  colorBoxTitle: (props: any) => ({
    fontFamily: `'Nunito Sans', sans-serif !important`,
    [muiTheme.breakpoints.down(1437)]: {
      fontSize: 14,
    },
  }),

  subInfoMiddleSection: (props: any) => ({
    display: "flex",
    justifyContent: "space-between",
  }),

  violationElementSection: (props: any) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px",
    fontFamily: `'Nunito Sans', sans-serif !important`,
    border: `1px solid ${props?.palette?.infoDialogueComponent?.lightShadeGray}`,
    background: props?.palette?.infoDialogueComponent?.blackShades,
    "& span": {
      fontFamily: "Nunito Sans",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: " 19px",
      color: "#FFFFFF",
    },
  }),

  violationListSection: (props: any) => ({
    // height: "calc(100vh - 400px) !important",
    // overflow: "auto",
  }),

  violationSection: (props: any) => ({
    background: props?.palette?.infoDialogueComponent?.blackShades,
    // marginLeft: "10px",
    // height: "calc(100vh - 400px) !important",
    // overflow: "auto",
  }),

  violationSectionLeft: (props: any) => ({
    background: props?.palette?.infoDialogueComponent?.blackShades,

    // height: "calc(100vh - 400px) !important",
    // overflow: "auto",
  }),

  notificationList: (props: any) => ({
    color: props?.palette?.infoDialogueComponent?.colorWhite,
    height: "calc(100vh - 570px)",
    overflow: "auto",
  }),

  subListRow: (props: any) => ({
    marginBottom: "12px !important",
  }),
  subListSection: (props: any) => ({
    border: `1px solid ${props?.palette?.infoDialogueComponent?.lightShadeGray}`,
    background: "#333333",
    padding: 20,
    height: "calc(100vh - 420px)",
    [muiTheme.breakpoints.down(1437)]: {
      height: "auto",
    },
    [muiTheme.breakpoints.down(1281)]: {
      height: "calc(100vh - 420px)",
    },
    [muiTheme.breakpoints.down(1026)]: {
      height: "calc(100vh - 283px)",
    },
    [muiTheme.breakpoints.down(963)]: {
      height: "auto",
    },
    "@media (max-height: 801px)": {
      height: "calc(100vh - 337px)",
    },
  }),
  infoVideoTitle: (props: any) => ({
    marginLeft: 8,
  }),

  videoMapSection: (props: any) => ({
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 355px)",
  }),
  infoMainListClassName: (props: any) => ({
    height: "100%",

    borderBottomLeftRadius: "6px",
    borderTopLeftRadius: "6px",
    margin: "8px 0px 2px 8px",
    flex: 1,
  }),
  infoMainListClassNameOne: (props: any) => ({
    borderBottomLeftRadius: "6px",
    borderTopLeftRadius: "6px",
    margin: "8px 0px 2px 8px",
    flex: 1,
    overflow: "hidden",
  }),
  infoDialogClassName: (props: any) => ({
    display: "flex",

    margin: 6,
    borderRadius: 4,
  }),
  pastViolationsClass: (props: any) => ({
    display: "flex",

    margin: 16,
    borderRadius: 4,
  }),

  violationElementSectionLeft: (props: any) => ({
    // height: "calc(100vh - 373px)",
  }),

  searchContainer: (props: any) => ({
    // height: "calc(100vh - 872px)",
  }),
  subListSectionContainer: () => ({
    paddingRight: "14px !important",
  }),

  violationElementSectionLeftInner: (props: any) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px",
    fontFamily: `'Nunito Sans', sans-serif !important`,
  }),

  infoDialogHeading: (props: any) => ({
    fontFamily: "Nunito Sans",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "25px",
  }),

  searchBoxClass: (props: any) => ({
    padding: "12px",
  }),

  searchClass: (props: any) => ({
    border: `1px solid #fff`,
    // background: props?.palette?.dashboardList?.white,
    color: "#fff !important",
    borderRadius: 6,
    height: "40px",
    "& .MuiIconButton-root": {
      marginRight: 7,
    },
    "& .MuiInputBase-root": {
      // fontWeight: 500,
    },
  }),

  notificationSearchIcon: (props: any) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "12px",
  }),
}));

export default useStyles;
