import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  customNotificationTabs: (props: any) => ({
    "& .MuiTabs-flexContainer": {
      // borderBottom: "2px solid #3e3e3e",
    },

    "& .MuiButtonBase-root": {
      marginRight: "0 !important",
      minWidth: "64px !important",
    },

    "& .MuiTab-root": {
      "&:first-child": {
        marginRight: "0 !important",
        minWidth: "64px !important",
        color: `${props?.palette?.infoDialogueComponent?.darkGrayShades}!important`,
        fontSize: "18px",
        fontFamily: `'Nunito Sans', sans-serif !important`,
      },
      "&:first-child.Mui-selected": {
        color: `${props?.palette?.infoDialogueComponent?.greenBlackShades}!important`,
      },
      "&:nth-child(2)": {
        marginRight: "0 !important",
        minWidth: "64px !important",
        color: `${props?.palette?.infoDialogueComponent?.darkGrayShades}!important`,
      },
      "&:nth-child(2).Mui-selected": {
        color: `${props?.palette?.infoDialogueComponent?.greenBlackShades}!important`,
      },
    },
    "& .MuiTabs-root .MuiTabs-indicator": {
      background: props?.palette?.infoDialogueComponent?.greenBlackShades,
      height: "4px !important",
      borderRadius: "2px",
    },
  }),

  headerStyle: (props: any) => ({
    fontWeight: 700,
    fontSize: 20,
    fontFamily: `'Nunito Sans', sans-serif !important`,
    lineHeight: "27px",
    textTransform: "uppercase",
    marginBottom: 30,
  }),

  uploadManifestStyle: (props: any) => ({
    margin: "15px 15px 0px 15px",
    padding: 25,
    border: "1.5px dashed #B2A5F1",
    background: "#333333",
    borderRadius: 7,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .MuiButtonBase-root": {
      background: "#8F7DE9 !important",
      fontSize: 14,
      fontFamily: `'Nunito Sans', sans-serif !important`,
      fontWeight: 600,
      padding: "10px 15px",
    },
  }),
  uploadIconStyle: (props: any) => ({
    display: "flex",
    justifyContent: "center",
  }),
  uploadTextStyle: (props: any) => ({
    marginTop: 20,
    textAlign: "center",
  }),
  uploadButtonStyle: (props: any) => ({
    marginTop: 20,
    textAlign: "center",
  }),
  progressBarStyle: (props: any) => ({
    marginTop: 20,
    width: "60%",
    position: "relative",

    "& .progressbar-progress": {
      borderRadius: 5,
      transition: "width 300ms ease 0s",
    },
  }),
  uploadCancelStyle: (props: any) => ({
    cursor: "pointer",
    position: "absolute",
    right: "-36px",
    top: "20px",
  }),
  uploadedProgressStyle: (props: any) => ({
    marginTop: 10,
    fontSize: 12,
    fontFamily: `'Nunito Sans', sans-serif !important`,
    fontWeight: 600,
    color: "#ccc",
  }),
  fileNameStyle: (props: any) => ({
    marginBottom: 10,
    fontSize: 14,
    fontFamily: `'Nunito Sans', sans-serif !important`,
    fontWeight: 500,
    color: "#fff",
    display: "flex",
  }),
  fileIconStyle: (props: any) => ({
    marginRight: 5,
  }),
}));

export default useStyles;
