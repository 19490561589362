import { styled } from "@mui/material/styles";
import theme from "theme/theme";

export const RootContainer = styled("div")`
  width: 100%;
  height: calc(100vh - 357px);
  overflow-y: auto;
  background-color: ${theme.defaultTheme.palette.infoDialogueComponent
    .halfBlack};
  border: 1px solid
    ${theme.defaultTheme.palette.infoDialogueComponent.greyBorder};
  box-sizing: border-box;

  @media (max-width: 5760px) {
    height: calc(100vh - 480px);
  }
  @media (max-width: 1344px) {
    height: calc(100vh - 357px);
  }
  @media (max-width: 962px) {
    height: calc(100vh - 291px);
  }
  //Height
  @media (max-height: 801px) {
    height: calc(100vh - 396px);
  }
`;

export const StoreContainer = styled("div")`
  margin-bottom: 25px;
`;

export const StoreHeader = styled("div")<{ focused: boolean }>`
  background-color: ${({ focused }) =>
    focused
      ? theme.defaultTheme.palette.infoDialogueComponent.storeHeaderFocused
      : theme.defaultTheme.palette.infoDialogueComponent.storeHeaderUnfocused};
  padding: 10px 16px 23px 26px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
`;

export const StoreHeaderContent = styled("div")`
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const StoreTitle = styled("h3")`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: ${theme.defaultTheme.palette.infoDialogueComponent.colorWhite};
`;

export const Icon = styled("img")`
  width: 10px;
  height: auto;
`;

export const StoreLocation = styled("h4")`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.defaultTheme.palette.infoDialogueComponent.colorWhite};
  display: flex;
  align-items: center;
`;

export const PalletContainer = styled("div")<{ focused: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 1000;
  background-color: ${({ focused }) =>
    focused
      ? theme.defaultTheme.palette.infoDialogueComponent.palletBackgroundFocused
      : "transparent"};
  border: 0.5px solid
    ${theme.defaultTheme.palette.infoDialogueComponent.palletBorderColor};
  border-right: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
`;

export const PalletTitle = styled("p")`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.defaultTheme.palette.infoDialogueComponent.colorWhite};
  margin-left: 19px;
`;

export const PalletStatusIndicator = styled("div")<{ color: string }>`
  width: 6.2px;
  height: 36.14px;
  left: 338.48px;
  top: 489.34px;
  margin: 5px 0px;

  background: ${({ color }) => color};
  border-radius: 0px 2px 2px 0px;
`;
