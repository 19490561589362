import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLanguage } from "../../redux/actions/loginActions";
import theme from "../../theme/theme";
import useTranslation from "../../localization/translations";
import { useLanguageContext } from "../../localization/languageContext";
import useStyles from "./styles";

import Header from "../../components/Header";
const Profile = () => {
  const { changeTheme, changeLang } = useTranslation();
  const { language, changeLanguage } = useLanguageContext();
  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);
  const { root, formSection } = useStyles(appTheme);
  const handleTheme = (selectedTheme: any) => {
    switch (selectedTheme) {
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
    localStorage.setItem("theme", JSON.stringify(selectedTheme));
    setSelectedTheme(selectedTheme);
  };
  const dispatch = useDispatch();
  const handleLanguage = (lang: any) => {
    changeLanguage(lang);
    dispatch(setLanguage({ lang }));
  };
  const [selectedValues, setSelectedValues] = useState<any>({
    fontSize: "h1",
    fontColor: "#000000",
    fontFamily: `'Nunito Sans', sans-serif !important`,
  });
  const { fontSize, fontColor, fontFamily } = selectedValues;
  const handleSubmit = (e: any) => {
    e.preventDefault();
    localStorage.setItem("items", JSON.stringify(selectedValues));
  };
  return (
    <>
      <Header /> <div className={root}>profile</div> <br />
      <div>{changeTheme}</div>
      <select
        value={selectedTheme}
        onChange={(e) => handleTheme(e.target.value)}
      >
        <option value="green">Green theme</option>
        <option value="red">Red theme</option>
        <option value="yellow">Yellow theme</option>
        <option value="default">Default theme</option>
      </select>
      <br /> <br /> <div>{changeLang}</div>
      <select value={language} onChange={(e) => handleLanguage(e.target.value)}>
        <option value="en">English</option> <option value="it">Italian</option>
      </select>
      {/* <InfoDialog /> */}
      <form onSubmit={handleSubmit} className={formSection}>
        <div>
          <div>Font Size</div>
          <select
            value={fontSize}
            onChange={(e) =>
              setSelectedValues({ ...selectedValues, fontSize: e.target.value })
            }
          >
            <option value="h1">H1</option> <option value="h2"> H2</option>
            <option value="h3">H3</option> <option value="h4">H4</option>
            <option value="h5">H5</option> <option value="h6">H6</option>
          </select>
        </div>
        <div>
          <div>Font Color</div>
          <select
            value={fontColor}
            onChange={(e) =>
              setSelectedValues({
                ...selectedValues,
                fontColor: e.target.value,
              })
            }
          >
            <option value="#00a170">Green</option>
            <option value="#e6000d">Red</option>
            <option value="#f6e25f">Yellow</option>
            <option value="#000000">Default</option>
          </select>
        </div>
        <div>
          <div>Font Family</div>
          <select
            value={fontFamily}
            onChange={(e) =>
              setSelectedValues({
                ...selectedValues,
                fontFamily: e.target.value,
              })
            }
          >
            <option value="poppins">Poppins</option>
            <option value="roboto">Roboto</option>
          </select>
        </div>
        <button type="submit" value="submit">
          Save
        </button>
      </form>
    </>
  );
};
export default Profile;
