const trailersInfoWindowData = {
  trailersInfolist: [
    {
      trailerId: "23123",
      mainfestId: "TID#123",
      totalPallets: "20",
      deliveryStores: "02",
      startLocation: "Idaho Falls, IDHAO, US",
      endLocation: "Idaho Falls, IDHAO, US",
      stores: [
        {
          name: "STORE#1",
          area: "",
          count: "04",
          pallets: [
            {
              id: "",
              name: "PID#34522",
              trackerId: "TID#3421",
              palletId: "PID#34522",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",

              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
            {
              id: "",
              name: "PID#34523",
              trackerId: "TID#3421",
              palletId: "PID#34523",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",
              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
            {
              id: "",
              name: "PID#34524",
              trackerId: "TID#3421",
              palletId: "PID#34524",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",

              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
            {
              id: "",
              name: "PID#34525",
              trackerId: "TID#3421",
              palletId: "PID#34525",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",
              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
          ],
        },
        {
          name: "STORE#2",
          area: "",
          count: "04",
          pallets: [
            {
              id: "",
              name: "PID#34524",
              trackerId: "TID#3421",
              palletId: "PID#34524",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",
              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
            {
              id: "",
              name: "PID#34525",
              trackerId: "TID#3421",
              palletId: "PID#34525",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",
              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
            {
              id: "",
              name: "PID#34526",
              trackerId: "TID#3421",
              palletId: "PID#34526",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",
              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
            {
              id: "",
              name: "PID#34527",
              trackerId: "TID#3421",
              palletId: "PID#34527",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",
              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
          ],
        },
      ],
      trailerRoutes: [
        {
          name: "WAREHOUSE#1",
          observation: "15 Min Delay",
          area: "Idaho Falls, IDHAO, US ",
          coOrdinates: {
            lat: "",
            lng: "",
          },
          timeStamp: "02-13-2023 | 9:00 AM",
        },
        {
          name: "WYOMING",
          observation: "Over Speeding",
          area: "San Carlos, Costa Rica",
          coOrdinates: {
            lat: "",
            lng: "",
          },
          timeStamp: "03-13-2023 | 10:30 AM",
        },
        {
          name: "STORE#1",
          observation: "Next Stop",
          area: "Nebraska, 80831, US ",
          coOrdinates: {
            lat: "",
            lng: "",
          },
          timeStamp: "03-13-2023 | 11:00 AM",
        },
        {
          name: "STORE#2",
          observation: "",
          area: "Idaho Falls, IDHAO, US ",
          coOrdinates: {
            lat: "",
            lng: "",
          },
          timeStamp: "03-13-2023 | 1:30 PM",
        },
      ],
    },
    {
      trailerId: "18875",
      mainfestId: "TID#123",
      totalPallets: "20",
      deliveryStores: "02",
      startLocation: "Idaho Falls, IDHAO, US",
      endLocation: "Idaho Falls, IDHAO, US",
      stores: [
        {
          name: "STORE#1",
          area: "",
          count: "04",
          pallets: [
            {
              id: "",
              name: "PID#34522",
              trackerId: "TID#3421",
              palletId: "PID#34522",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",

              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
            {
              id: "",
              name: "PID#34523",
              trackerId: "TID#3421",
              palletId: "PID#34523",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",
              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
            {
              id: "",
              name: "PID#34524",
              trackerId: "TID#3421",
              palletId: "PID#34524",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",

              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
            {
              id: "",
              name: "PID#34525",
              trackerId: "TID#3421",
              palletId: "PID#34525",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",
              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
          ],
        },
        {
          name: "STORE#2",
          area: "",
          count: "04",
          pallets: [
            {
              id: "",
              name: "PID#34524",
              trackerId: "TID#3421",
              palletId: "PID#34524",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",
              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
            {
              id: "",
              name: "PID#34525",
              trackerId: "TID#3421",
              palletId: "PID#34525",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",
              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
            {
              id: "",
              name: "PID#34526",
              trackerId: "TID#3421",
              palletId: "PID#34526",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",
              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
            {
              id: "",
              name: "PID#34527",
              trackerId: "TID#3421",
              palletId: "PID#34527",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",
              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
          ],
        },
      ],
      trailerRoutes: [
        {
          name: "WAREHOUSE#1",
          observation: "15 Min Delay",
          area: "Idaho Falls, IDHAO, US ",
          coOrdinates: {
            lat: "",
            lng: "",
          },
          timeStamp: "02-13-2023 | 9:00 AM",
        },
        {
          name: "WYOMING",
          observation: "Over Speeding",
          area: "San Carlos, Costa Rica",
          coOrdinates: {
            lat: "",
            lng: "",
          },
          timeStamp: "03-13-2023 | 10:30 AM",
        },
        {
          name: "STORE#1",
          observation: "Next Stop",
          area: "Nebraska, 80831, US ",
          coOrdinates: {
            lat: "",
            lng: "",
          },
          timeStamp: "03-13-2023 | 11:00 AM",
        },
        {
          name: "STORE#2",
          observation: "",
          area: "Idaho Falls, IDHAO, US ",
          coOrdinates: {
            lat: "",
            lng: "",
          },
          timeStamp: "03-13-2023 | 1:30 PM",
        },
      ],
    },
    {
      trailerId: "46454",
      mainfestId: "TID#123",
      totalPallets: "20",
      deliveryStores: "02",
      startLocation: "Idaho Falls, IDHAO, US",
      endLocation: "Idaho Falls, IDHAO, US",
      stores: [
        {
          name: "STORE#1",
          area: "",
          count: "04",
          pallets: [
            {
              id: "",
              name: "PID#34522",
              trackerId: "TID#3421",
              palletId: "PID#34522",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",

              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
            {
              id: "",
              name: "PID#34523",
              trackerId: "TID#3421",
              palletId: "PID#34523",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",
              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
            {
              id: "",
              name: "PID#34524",
              trackerId: "TID#3421",
              palletId: "PID#34524",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",

              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
            {
              id: "",
              name: "PID#34525",
              trackerId: "TID#3421",
              palletId: "PID#34525",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",
              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
          ],
        },
        {
          name: "STORE#2",
          area: "",
          count: "04",
          pallets: [
            {
              id: "",
              name: "PID#34524",
              trackerId: "TID#3421",
              palletId: "PID#34524",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",
              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
            {
              id: "",
              name: "PID#34525",
              trackerId: "TID#3421",
              palletId: "PID#34525",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",
              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
            {
              id: "",
              name: "PID#34526",
              trackerId: "TID#3421",
              palletId: "PID#34526",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",
              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
            {
              id: "",
              name: "PID#34527",
              trackerId: "TID#3421",
              palletId: "PID#34527",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",
              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
          ],
        },
      ],
      trailerRoutes: [
        {
          name: "WAREHOUSE#1",
          observation: "15 Min Delay",
          area: "Idaho Falls, IDHAO, US ",
          coOrdinates: {
            lat: "",
            lng: "",
          },
          timeStamp: "02-13-2023 | 9:00 AM",
        },
        {
          name: "WYOMING",
          observation: "Over Speeding",
          area: "San Carlos, Costa Rica",
          coOrdinates: {
            lat: "",
            lng: "",
          },
          timeStamp: "03-13-2023 | 10:30 AM",
        },
        {
          name: "STORE#1",
          observation: "Next Stop",
          area: "Nebraska, 80831, US ",
          coOrdinates: {
            lat: "",
            lng: "",
          },
          timeStamp: "03-13-2023 | 11:00 AM",
        },
        {
          name: "STORE#2",
          observation: "",
          area: "Idaho Falls, IDHAO, US ",
          coOrdinates: {
            lat: "",
            lng: "",
          },
          timeStamp: "03-13-2023 | 1:30 PM",
        },
      ],
    },
    {
      trailerId: "32132",
      mainfestId: "TID#123",
      totalPallets: "20",
      deliveryStores: "02",
      startLocation: "Idaho Falls, IDHAO, US",
      endLocation: "Idaho Falls, IDHAO, US",
      stores: [
        {
          name: "STORE#1",
          area: "",
          count: "04",
          pallets: [
            {
              id: "",
              name: "PID#34522",
              trackerId: "TID#3421",
              palletId: "PID#34522",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",

              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
            {
              id: "",
              name: "PID#34523",
              trackerId: "TID#3421",
              palletId: "PID#34523",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",
              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
            {
              id: "",
              name: "PID#34524",
              trackerId: "TID#3421",
              palletId: "PID#34524",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",

              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
            {
              id: "",
              name: "PID#34525",
              trackerId: "TID#3421",
              palletId: "PID#34525",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",
              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
          ],
        },
        {
          name: "STORE#2",
          area: "",
          count: "04",
          pallets: [
            {
              id: "",
              name: "PID#34524",
              trackerId: "TID#3421",
              palletId: "PID#34524",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",
              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
            {
              id: "",
              name: "PID#34525",
              trackerId: "TID#3421",
              palletId: "PID#34525",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",
              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
            {
              id: "",
              name: "PID#34526",
              trackerId: "TID#3421",
              palletId: "PID#34526",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",
              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
            {
              id: "",
              name: "PID#34527",
              trackerId: "TID#3421",
              palletId: "PID#34527",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",
              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
          ],
        },
      ],
      trailerRoutes: [
        {
          name: "WAREHOUSE#1",
          observation: "15 Min Delay",
          area: "Idaho Falls, IDHAO, US ",
          coOrdinates: {
            lat: "",
            lng: "",
          },
          timeStamp: "02-13-2023 | 9:00 AM",
        },
        {
          name: "WYOMING",
          observation: "Over Speeding",
          area: "San Carlos, Costa Rica",
          coOrdinates: {
            lat: "",
            lng: "",
          },
          timeStamp: "03-13-2023 | 10:30 AM",
        },
        {
          name: "STORE#1",
          observation: "Next Stop",
          area: "Nebraska, 80831, US ",
          coOrdinates: {
            lat: "",
            lng: "",
          },
          timeStamp: "03-13-2023 | 11:00 AM",
        },
        {
          name: "STORE#2",
          observation: "",
          area: "Idaho Falls, IDHAO, US ",
          coOrdinates: {
            lat: "",
            lng: "",
          },
          timeStamp: "03-13-2023 | 1:30 PM",
        },
      ],
    },
    {
      trailerId: "321265",
      mainfestId: "TID#123",
      totalPallets: "20",
      deliveryStores: "02",
      startLocation: "Idaho Falls, IDHAO, US",
      endLocation: "Idaho Falls, IDHAO, US",
      stores: [
        {
          name: "STORE#1",
          area: "",
          count: "04",
          pallets: [
            {
              id: "",
              name: "PID#34522",
              trackerId: "TID#3421",
              palletId: "PID#34522",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",

              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
            {
              id: "",
              name: "PID#34523",
              trackerId: "TID#3421",
              palletId: "PID#34523",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",
              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
            {
              id: "",
              name: "PID#34524",
              trackerId: "TID#3421",
              palletId: "PID#34524",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",

              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
            {
              id: "",
              name: "PID#34525",
              trackerId: "TID#3421",
              palletId: "PID#34525",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",
              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
          ],
        },
        {
          name: "STORE#2",
          area: "",
          count: "04",
          pallets: [
            {
              id: "",
              name: "PID#34524",
              trackerId: "TID#3421",
              palletId: "PID#34524",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",
              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
            {
              id: "",
              name: "PID#34525",
              trackerId: "TID#3421",
              palletId: "PID#34525",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",
              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
            {
              id: "",
              name: "PID#34526",
              trackerId: "TID#3421",
              palletId: "PID#34526",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",
              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
            {
              id: "",
              name: "PID#34527",
              trackerId: "TID#3421",
              palletId: "PID#34527",
              status: "In Transit",
              dispatchedDate: "03-13-2023 | 9:00 AM",
              deliveredDate: "13-03-2023 | 11:00 AM",
              temparature: "68°C",
              humidity: "24%",
              pressure: "987 kPa",
              palletShipmentData: [
                {
                  id: "",
                  packageStage: "Pallet Ready For Shipment",
                  timeStamp: "1-11-2023 9.00AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Left Grocery Warehouse#1",
                  timeStamp: "1-11-2023 10.30AM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "In Transit",
                  timeStamp: "1-11-2023 12.30PM",
                  status: "",
                },
                {
                  id: "",
                  packageStage: "Store#1",
                  timeStamp: "1-11-2023 1.30PM",
                  status: "",
                },
              ],
            },
          ],
        },
      ],
      trailerRoutes: [
        {
          name: "WAREHOUSE#1",
          observation: "15 Min Delay",
          area: "Idaho Falls, IDHAO, US ",
          coOrdinates: {
            lat: "",
            lng: "",
          },
          timeStamp: "02-13-2023 | 9:00 AM",
        },
        {
          name: "WYOMING",
          observation: "Over Speeding",
          area: "San Carlos, Costa Rica",
          coOrdinates: {
            lat: "",
            lng: "",
          },
          timeStamp: "03-13-2023 | 10:30 AM",
        },
        {
          name: "STORE#1",
          observation: "Next Stop",
          area: "Nebraska, 80831, US ",
          coOrdinates: {
            lat: "",
            lng: "",
          },
          timeStamp: "03-13-2023 | 11:00 AM",
        },
        {
          name: "STORE#2",
          observation: "",
          area: "Idaho Falls, IDHAO, US ",
          coOrdinates: {
            lat: "",
            lng: "",
          },
          timeStamp: "03-13-2023 | 1:30 PM",
        },
      ],
    },
  ],
};

export default trailersInfoWindowData;
