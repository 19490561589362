import { useState, useEffect, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Tabs from "../../elements/Tabs";
import SearchBox from "../../elements/SearchBox";
import theme from "../../theme/theme";
import useTranslation from "../../localization/translations";

import dashboardViewDetails from "../../mockdata/dashboardViewDetails";
import ChipButton from "../../elements/ChipButton";
import DashboardViewDetailsListItems from "../DashboardViewDetailListItems";
import SearchIcon from "../../assets/searchIcon.svg";
import CloseIcon from "../../assets/closeIcon.svg";
import RightArrow from "../../assets/rightArrow.svg";
import {
  formattedViewDetailData,
  formattedViewDetailList,
  chipDataCount,
} from "utils/utils";
import useStyles from "./styles";
import InfoDialog from "components/InfoDialog";

interface isButtonClickedType {
  button1: boolean;
  button2: boolean;
  button3: boolean;
}

const DashboardViewDetailList: React.FC<any> = (props) => {
  const {
    tabIndex,
    setTabIndex,
    selectedNotification,
    setSelectedNotification,
    searchOpen,
    setSearchOpen,
    notificationTimeStamp,
    setViewDetailActive,
    setChipButtonIndex,
    notificationData,
    chipButtonIndex,
    viewDetailData,
    listCount,
    setRightPanelActive,
    selectedId,
    isMarkerClicked,
  } = props;

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  const {
    customNotificationTabs,
    tabSection,
    unSelectedButtonStyles,
    selectedButtonStyles,
    tabButtonSection,
    dashboardListSection,
    searchIconStyle,
    rightArrowStyle,
    searchClass,
    searchContainerStyle,
    noResultStyle,
    chipButtonSection,
  } = useStyles(appTheme);

  const { chipButtonEvents, chipButtonIncidents, chipButtonOprAlerts } =
    useTranslation();

  const tabsList = [
    { name: "Goods", val: 0 },
    { name: "Equipments", val: 1 },
    { name: "Notification", val: 2 },
  ];

  const [selectedRefId, setSelectedRefId] = useState("");

  const [cardData, setCardData] = useState<any>(
    formattedViewDetailList(tabIndex, viewDetailData, chipButtonIndex)
  );

  const [searchValue, setSearchValue] = useState<any>(cardData);
  const [chipButtonCount, setChipButtonCount] = useState<any>("");

  useEffect(() => {
    setCardData(
      formattedViewDetailList(tabIndex, viewDetailData, chipButtonIndex)
    );
    setSearchValue(
      formattedViewDetailList(tabIndex, viewDetailData, chipButtonIndex)
    );

    const eventCount = chipDataCount(viewDetailData, "events");

    const incidentsCount = chipDataCount(viewDetailData, "incidents");

    const oprAlertsCount = chipDataCount(viewDetailData, "oprAlerts");
    const chipButtonListCount = [
      `${eventCount}`,
      `${incidentsCount}`,
      `${oprAlertsCount}`,
    ];
    setChipButtonCount(chipButtonListCount);
  }, []);

  useEffect(() => {
    setCardData(
      formattedViewDetailList(tabIndex, viewDetailData, chipButtonIndex)
    );
    setSearchValue(
      formattedViewDetailList(tabIndex, viewDetailData, chipButtonIndex)
    );
  }, [tabIndex, viewDetailData, chipButtonIndex]);

  useEffect(() => {
    setSearchOpen(false);
  }, [tabIndex]);

  const handleTabs = (index: number) => {
    setTabIndex(index);
    setSearchOpen(false);
    setSelectedNotification("");
    setChipButtonIndex(1);
  };

  useEffect(() => {
    setTabIndex(tabIndex);
    isMarkerClicked && tabIndex === 2 && setTabIndex(2);
  }, [tabIndex, isMarkerClicked]);

  const handleExpandListItem = (id: any) => {
    setSelectedNotification(selectedNotification === id ? "" : id);
    setSelectedRefId(id);
  };

  const handleSearch = (searchValue: any) => {
    let searchResult = cardData?.filter((value: any) => {
      return (
        value?.title
          ?.toLowerCase()
          .includes(searchValue?.toString()?.toLowerCase()) ||
        value?.id
          ?.toLowerCase()
          .includes(searchValue?.toString()?.toLowerCase()) ||
        value?.trackerId
          ?.toLowerCase()
          .includes(searchValue?.toString()?.toLowerCase())
      );
    });
    setSearchValue(searchResult);
    setSearchOpen(true);
    setSelectedNotification("");
  };

  const handleSearchClose = () => {
    setSearchOpen(false);
    setSearchValue(cardData);
    setSelectedNotification("");
  };

  const refs =
    searchValue && searchValue.length > 0
      ? searchValue.reduce((acc: any, value: any) => {
          acc[value.id] = createRef<any>();
          return acc;
        }, {})
      : "";

  useEffect(() => {
    if ((selectedNotification || selectedRefId) && refs) {
      setTimeout(() => {
        refs[
          selectedNotification ? selectedNotification : selectedRefId
        ]?.current?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }, 300);
    }
  }, [refs, selectedRefId, selectedNotification]);

  const [chipButtons, setChipButtons] = useState<any>([
    {
      label: chipButtonEvents,
      count: chipButtonCount && `${chipButtonCount[0]}`,
      focused: false,
      color: "green",
    },
    {
      label: chipButtonIncidents,
      count: chipButtonCount && `${chipButtonCount[1]}`,
      focused: true,
      color: "red",
    },
    {
      label: chipButtonOprAlerts,
      count: chipButtonCount && `${chipButtonCount[2]}`,
      focused: false,
      color: "orange",
    },
  ]);

  useEffect(() => {
    // if (notifcationChipButtons) {
    // setChipButtons(notifcationChipButtons);
    //   setChipButtonIndex(1);
    // }
    setChipButtons([
      {
        label: chipButtonEvents,
        count:
          searchOpen && chipButtonIndex === 0
            ? `${searchValue?.length}`
            : chipButtonCount && `${chipButtonCount[0]}`,
        focused: chipButtonIndex === 0 ? true : false,
        color: "green",
      },
      {
        label: chipButtonIncidents,
        count:
          searchOpen && chipButtonIndex === 1
            ? `${searchValue?.length}`
            : chipButtonCount && `${chipButtonCount[1]}`,
        focused: chipButtonIndex === 1 ? true : false,
        color: "red",
      },
      {
        label: chipButtonOprAlerts,
        count:
          searchOpen && chipButtonIndex === 2
            ? `${searchValue?.length}`
            : chipButtonCount && `${chipButtonCount[2]}`,
        focused: chipButtonIndex === 2 ? true : false,
        color: "orange",
      },
    ]);
  }, [chipButtonCount, chipButtonIndex, searchValue, searchOpen]);

  const getFormattedChipButtonsData = (list: any, chipBtnIndex: number) => {
    for (const [index] of list.entries()) {
      if (chipBtnIndex === index) list[index].focused = true;
      else list[index].focused = false;
    }

    return list;
  };

  const handleChipButton = (data: any, index: number) => {
    setChipButtons([
      ...chipButtons,
      getFormattedChipButtonsData(chipButtons, index),
    ]);
    setChipButtonIndex(index);
    setSelectedNotification("");
  };

  useEffect(() => {
    getFormattedChipButtonsData(chipButtons, 1);
  }, [tabIndex]);

  useEffect(() => {
    setSearchOpen(false);
  }, [chipButtonIndex]);

  const [showInfoDialogue, setShowInfoDialogue] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [selectedListId, setSelectedListId] = useState<any>();

  useEffect(() => {
    setSelectedListId(selectedId);
  }, [selectedId]);

  const handleInfoDialogue = (data: string) => {
    setViewDetailActive(true);
    setShowInfoDialogue(true);
    setSelectedItem(data);
  };

  const handleClose = () => {
    props.handleClose();
  };

  const handleTabChange = () => {
    props.handleTabChange();
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div className={tabSection}>
            <Tabs
              initialIndex={tabIndex}
              tabsList={tabsList}
              handleTabs={handleTabs}
              dashboardNotificationClassName={customNotificationTabs}
            />
            <img
              src={searchOpen ? CloseIcon : SearchIcon}
              alt="search"
              onClick={searchOpen ? handleSearchClose : handleSearch}
              className={searchIconStyle}
              width={30}
            />
            <img
              src={RightArrow}
              alt="RightArrow"
              className={rightArrowStyle}
              onClick={() => {
                setRightPanelActive(false);
                setTabIndex(0);
                setSelectedNotification("");
              }}
            />
          </div>
        </Grid>
        {searchOpen && (
          <div className={searchContainerStyle}>
            <SearchBox
              searchInput={searchClass}
              placeHolder={"Search"}
              handleSearch={handleSearch}
              searchIsOpen={true}
              fontColor={appTheme?.palette?.dashboardList?.darkGrey2}
            />
          </div>
        )}

        <Grid item xs={12}>
          <div className={dashboardListSection}>
            {tabIndex === 2 && (
              <div className={chipButtonSection}>
                {chipButtons && chipButtons.length > 0
                  ? chipButtons.map((item: any, index: number) => {
                      if (index <= 2) {
                        return (
                          <ChipButton
                            key={index}
                            data={item}
                            index={index}
                            handleClick={handleChipButton}
                          />
                        );
                      }
                    })
                  : null}
              </div>
            )}

            {searchValue && searchValue.length > 0 ? (
              searchValue?.map((item: any, index: number) => {
                return (
                  <>
                    <DashboardViewDetailsListItems
                      // refs={refs}
                      // buttonIndex={selectedTabButton}
                      data={item}
                      handleExpandListItem={handleExpandListItem}
                      index={index}
                      selectedNotification={selectedNotification}
                      key={index}
                      notificationTimeStamp={notificationTimeStamp}
                      setViewDetailActive={setViewDetailActive}
                      notificationData={notificationData}
                      handleInfoDialogue={handleInfoDialogue}
                      handleClose={handleClose}
                    />
                  </>
                );
              })
            ) : (
              <div className={noResultStyle}>No Result Found</div>
            )}
          </div>
        </Grid>
      </Grid>
      <div>
        {showInfoDialogue && (
          <InfoDialog
            selectedItem={selectedItem}
            setShowInfoDialogue={setShowInfoDialogue}
            parentTabIndex={tabIndex}
            handleClose={handleClose}
            markerArray={viewDetailData}
            selectedId={selectedListId}
            handleTabChange={handleTabChange}
            pageName={"viewdetailInfoWindow"}
          />
        )}
      </div>
    </>
  );
};
export default DashboardViewDetailList;
