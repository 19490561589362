import { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import EditIcon from "assets/EditIcon.svg";
import DeleteIcon from "assets/DeleteIcon.svg";
import theme from "theme/theme";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenifestInfoDialog from "components/MenifestInfoDialog";
import MenifestEditInfoDialog from "components/MenifestEditInfoDialog";
// @ts-ignore
import { doc, setDoc, updateDoc } from "firebase/firestore/lite";
// @ts-ignore
import { db } from "services/firebase";
import Skeleton from "@mui/material/Skeleton";
import LogisticsUXDLoaderAnimation from "../../assets/Logistics-UXD-Loader-Animation-Updated.gif";
import useStyles from "./styles";

interface TableProps {
  tableHeadData: [];
  tableData: [];
  tabIndex?: number;
  handleTableRow?: any;
  setIsInfoDialogueOpen: any;
  setTableData: any;
  setAlertDialogOpen: any;
  alertDialogOpen: any;
  deleteConfirmation: any;
  setDeleteConfirmation: any;
  setShowManifestDialogue: any;
  setShowEditManifestWindow: any;
  showEditManifestWindow: any;
  isDataLoaded: any;
  setIsDataLoaded: any;
  selectedTableRowIndex: any;
  setSelectedTableRowIndex: any;
  searchOpen: any;
}
const INF_Table: React.FC<any> = (props: TableProps) => {
  const {
    tableHeadData,
    tableData,
    tabIndex,
    handleTableRow,
    setIsInfoDialogueOpen,
    setTableData,
    setAlertDialogOpen,
    alertDialogOpen,
    deleteConfirmation,
    setDeleteConfirmation,
    setShowManifestDialogue,
    setShowEditManifestWindow,
    showEditManifestWindow,
    isDataLoaded,
    setIsDataLoaded,
    selectedTableRowIndex,
    setSelectedTableRowIndex,
    searchOpen,
  } = props;

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const { actionIcons, editIcon, deleteIcon, tableHeight, loaderStyle } =
    useStyles(appTheme);

  const [isDeleteButtonClicked, setIsDeleteButtonClicked] =
    useState<boolean>(false);

  // const [selectedTableRowIndex, setSelectedTableRowIndex] = useState<number>();

  const handleClose = (event: any) => {
    setAlertDialogOpen(false);
    setIsInfoDialogueOpen(false);
    setDeleteConfirmation(false);
    event.stopPropagation();
  };

  const handleAlertCancel = (event: any) => {
    setAlertDialogOpen(false);
    setIsInfoDialogueOpen(false);
    setDeleteConfirmation(false);
    event.stopPropagation();
  };

  const handleAlertOk = (event: any) => {
    setDeleteConfirmation(true);
    setAlertDialogOpen(false);
    setIsInfoDialogueOpen(false);
    if (selectedTableRowIndex !== undefined) {
      const newTableData = [...tableData];
      const newTableDataDelete = newTableData.splice(selectedTableRowIndex, 1);
      setTableData(newTableData);
      const ref = doc(db, "manifestUpcoming", "upcomingData");
      updateDoc(ref, { data: newTableData })
        .then((success: any) => {
          setIsDataLoaded(true);
        })
        .catch((error: any) => console.log(error));
    }
    event.stopPropagation();
  };

  const handleDeleteIcon = (event: any, index: number) => {
    setAlertDialogOpen(true);
    setIsDeleteButtonClicked(true);
    setSelectedTableRowIndex(index);
    setIsInfoDialogueOpen(false);
    event.stopPropagation();
  };

  const handleEditIcon = (event: any, index: number) => {
    setSelectedTableRowIndex(index);
    setShowEditManifestWindow(true);
    event.stopPropagation();
  };

  // const tableDataIndex = tableData.map((value: any, index: number) => ({
  //   ...value,
  //   index: index,
  // }));

  // create an array to fill empty items
  const skeletonArray = Array(5).fill("");
  const skeletonInternalCellsArray = Array(8).fill("");
  return (
    <>
      <TableContainer className={tableHeight}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {tableHeadData?.length > 0 &&
                tableHeadData?.map((item: any) => (
                  <TableCell key={item.id}>{item.title}</TableCell>
                ))}
            </TableRow>
          </TableHead>
          {isDataLoaded ? (
            <TableBody>
              {tableData?.length > 0 ? (
                tableData
                  // ?.sort((a, b) => b.index - a.index)
                  .map((item: any, index: number) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={index}
                        onClick={() => {
                          handleTableRow(item);
                        }}
                      >
                        <TableCell>{item && item?.manifestId}</TableCell>
                        <TableCell>{item && item?.startDateTime}</TableCell>
                        <TableCell>{item && item?.trailerId}</TableCell>
                        <TableCell>{item && item?.sourceWarehouse}</TableCell>
                        <TableCell>{item && item?.deliveryStores}</TableCell>
                        <TableCell>{item && item?.noOfPallets}</TableCell>
                        <TableCell>{item && item?.endLocation}</TableCell>
                        <TableCell>{item && item?.endDateTime}</TableCell>
                        {tabIndex === 2 && (
                          <TableCell>{item && item?.wrongDelivery}</TableCell>
                        )}
                        {tabIndex === 0 && (
                          <TableCell>
                            <div className={actionIcons}>
                              <div className={editIcon}>
                                <img
                                  src={EditIcon}
                                  onClick={(e) => handleEditIcon(e, index)}
                                />
                              </div>
                              <div className={deleteIcon}>
                                <img
                                  src={DeleteIcon}
                                  onClick={(e) => handleDeleteIcon(e, index)}
                                />

                                <Dialog
                                  open={alertDialogOpen}
                                  onClose={(e) => handleClose(e)}
                                  aria-labelledby="alert-dialog-title"
                                  aria-describedby="alert-dialog-description"
                                >
                                  <DialogTitle id="alert-dialog-title"></DialogTitle>
                                  <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                      Are you Sure want to Delete this Manifest
                                    </DialogContentText>
                                  </DialogContent>
                                  <DialogActions>
                                    <Button
                                      onClick={(e) => handleAlertCancel(e)}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      onClick={(e) => handleAlertOk(e)}
                                      autoFocus
                                    >
                                      Ok
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </div>
                            </div>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell colSpan={9} style={{ textAlign: "center" }}>
                    No Data Available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          ) : (
            //Smaert Logistic logo Loader animation GIF
            <div className={loaderStyle}>
              <img src={LogisticsUXDLoaderAnimation} width={100} />
            </div>

            // skeleton Loader Functionality MUI
            // <TableBody>

            //   {skeletonArray?.map((item: any, index: number) => {
            //     return (
            //       <TableRow tabIndex={-1} key={index}>

            //         {skeletonInternalCellsArray?.map((item: any) => {
            //           return (
            //             <TableCell>
            //               <Skeleton animation="wave" />
            //             </TableCell>
            //           );
            //         })}

            //         {tabIndex === 2 && (
            //           <TableCell>
            //             <Skeleton animation="wave" />
            //           </TableCell>
            //         )}
            //         {tabIndex === 0 && (
            //           <TableCell>
            //             <div className={actionIcons}>
            //               <div className={editIcon}>
            //                 <Skeleton
            //                   animation="wave"
            //                   variant="circular"
            //                   width={40}
            //                   height={40}
            //                 />
            //               </div>
            //               <div className={deleteIcon}>
            //                 <Skeleton
            //                   animation="wave"
            //                   variant="circular"
            //                   width={40}
            //                   height={40}
            //                 />
            //               </div>
            //             </div>
            //           </TableCell>
            //         )}
            //       </TableRow>
            //     );
            //   })}
            // </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};

export default INF_Table;
