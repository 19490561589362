import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(
  {
    progressBarStyle: {
      borderInlineStartWidth: 5,
    },
    progressMainStyle: {
      display: "inline-flex",
      position: "absolute",
    },
    progressInnerText: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      background: "#FFF",
      borderRadius: "50%",
      width: "50%",
      height: "50%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    },
    progressValueStyle: {
      fontSize: "25px",
      fontFamily: `'Nunito Sans', sans-serif !important`,
      fontWeight: 700,
      color: "#000",
      textAlign: "center",
    },
    progressTextStyle: {
      fontSize: "13px",
      fontFamily: `'Nunito Sans', sans-serif !important`,
      fontWeight: 700,
      color: "#000",
      textAlign: "center",
      margin: "0 20px",
    },
  },
  { index: 1 }
);
export default useStyles;
