import { useState, useEffect } from "react";
//@ts-ignore
import { TreeItem, TreeView, TreeItemProps } from "@material-ui/lab";
import theme from "theme/theme";
import ChevronRightIcon from "assets/chevronRight.svg";
import ExpandMoreIcon from "assets/chevronDownIcon.svg";
import Tooltip from "elements/Tooltip";
import useStyles from "./styles";

interface TreeViewListProps {
  data?: any;
  handleOnDrag?: any;
  draggingCategory?: any;
  draggingSubCategory?: any;
  handleWareHouseLabelClick?: any;
  handleWareHouseSubListLabelClick?: any;
  handleWareHouseInnerSubListLabelClick?: any;
  searchExpanded?: any;
  handleToggle?: any;
  newDraggingSubCategory?: any;
  searchSelected?: any;
}

const TreeViewList: React.FC<any> = (props: TreeViewListProps) => {
  const {
    data,
    handleOnDrag,
    draggingCategory,
    draggingSubCategory,
    handleWareHouseLabelClick,
    handleWareHouseSubListLabelClick,
    handleWareHouseInnerSubListLabelClick,
    searchExpanded,
    newDraggingSubCategory,
    searchSelected,
  } = props;

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const {
    rootContainer,
    treeViewContainer,
    treeViewListContainer,
    treeViewListMainContainer,
    treeViewSubListMainContainer,
    treeViewSubListContainer,
    treeViewListContainerDragged,
    treeViewSubListContainerDragged,
    treeViewListMainContainerDragged,
    noRecords,
  } = useStyles(appTheme);

  const handleToggle = (e: any, nodeIds: any) => {
    props.handleToggle(nodeIds);
  };

  const handleAllLabelClick = (e: any) => {
    e.preventDefault();
  };

  return (
    <div className={rootContainer}>
      {searchExpanded?.length >= 1 ? (
        <TreeView
          multiSelect
          style={{ height: 240, flexGrow: 1, margin: 3 }}
          expanded={searchExpanded}
          onNodeToggle={handleToggle}
          selected={searchSelected}
        >
          <TreeItem
            nodeId="0"
            label="All"
            onLabelClick={handleAllLabelClick}
            className={treeViewContainer}
          >
            {data?.map((item: any) => {
              return (
                <TreeItem
                  nodeId={item?.searchId}
                  label={item?.title}
                  className={treeViewSubListMainContainer}
                  expandIcon={<img src={ChevronRightIcon} />}
                  collapseIcon={<img src={ExpandMoreIcon} />}
                  onLabelClick={() => handleWareHouseLabelClick(item?.title)}
                >
                  {item?.subList?.map((subListItem: any) => {
                    if (
                      (subListItem.category === "tracker" &&
                        subListItem?.list?.some(
                          (i: any) => i.configured !== true
                        )) ||
                      (subListItem.category === "equipment" &&
                        !subListItem?.configured)
                    ) {
                      return (
                        <TreeItem
                          nodeId={subListItem?.searchId}
                          label={
                            subListItem?.category === "equipment" ? (
                              <Tooltip
                                tooltipValue={"Drag below list to Configure"}
                              >
                                <div style={{ cursor: "no-drop" }}>
                                  {subListItem?.title}
                                </div>
                              </Tooltip>
                            ) : (
                              subListItem?.title
                            )
                          }
                          draggable={
                            (draggingCategory === subListItem?.category ||
                              draggingCategory === "") &&
                            !subListItem?.selected &&
                            subListItem?.category === "tracker"
                              ? "true"
                              : "false"
                          }
                          onDragStart={(e: any) =>
                            handleOnDrag(
                              e,
                              item?.id,
                              subListItem?.title,
                              subListItem?.id,
                              subListItem?.category
                            )
                          }
                          className={
                            !subListItem?.selected
                              ? treeViewListMainContainer
                              : treeViewListMainContainerDragged
                          }
                          expandIcon={<img src={ChevronRightIcon} />}
                          collapseIcon={<img src={ExpandMoreIcon} />}
                          onLabelClick={() =>
                            handleWareHouseSubListLabelClick(subListItem?.title)
                          }
                        >
                          {subListItem?.list?.map((listItem: any) => {
                            if (
                              (!listItem?.configured &&
                                subListItem.category === "tracker") ||
                              (!listItem?.configured &&
                                listItem?.subListItem?.some(
                                  (i: any) => i.configured !== true
                                ))
                            ) {
                              return (
                                <TreeItem
                                  // draggable={!listItem?.selected ? "true" : "false"}
                                  draggable={
                                    (draggingCategory ===
                                      subListItem?.category ||
                                      draggingCategory === "") &&
                                    !listItem?.selected
                                      ? "true"
                                      : "false"
                                  }
                                  // draggable={
                                  //   !listItem?.selected ? "true" : "false"
                                  // }
                                  onDragStart={(e: any) =>
                                    handleOnDrag(
                                      e,
                                      item?.id,
                                      listItem?.title,
                                      listItem?.id,
                                      subListItem?.category,
                                      listItem?.category,
                                      "nestedChildren"
                                    )
                                  }
                                  className={
                                    !listItem?.selected
                                      ? treeViewListContainer
                                      : treeViewListContainerDragged
                                  }
                                  nodeId={listItem?.searchId}
                                  label={listItem?.title}
                                  expandIcon={<img src={ChevronRightIcon} />}
                                  collapseIcon={<img src={ExpandMoreIcon} />}
                                  onLabelClick={() =>
                                    handleWareHouseInnerSubListLabelClick(
                                      listItem?.title
                                    )
                                  }
                                >
                                  {listItem?.subListItem?.map(
                                    (listItemSub: any) => {
                                      if (!listItemSub?.configured) {
                                        return (
                                          <TreeItem
                                            className={
                                              !listItemSub?.selected
                                                ? treeViewSubListContainer
                                                : treeViewSubListContainerDragged
                                            }
                                            nodeId={listItemSub?.searchId}
                                            label={listItemSub?.title}
                                            draggable={
                                              (draggingCategory ===
                                                subListItem?.category ||
                                                draggingCategory === "") &&
                                              !listItemSub?.selected
                                                ? "true"
                                                : "false"
                                            }
                                            // draggable={
                                            //   !listItemSub?.selected
                                            //     ? "true"
                                            //     : "false"
                                            // }
                                            onDragStart={(e: any) =>
                                              handleOnDrag(
                                                e,
                                                item?.id,
                                                listItemSub?.title,
                                                listItemSub?.id,
                                                subListItem?.category,
                                                listItem?.category,
                                                "subNestedChildren"
                                              )
                                            }
                                          />
                                        );
                                      }
                                    }
                                  )}
                                </TreeItem>
                              );
                            }
                          })}
                        </TreeItem>
                      );
                    }
                  })}
                </TreeItem>
              );
            })}
          </TreeItem>
        </TreeView>
      ) : (
        <div className={noRecords}>{"No Devices Found"}</div>
      )}
    </div>
  );
};

export default TreeViewList;
