import { useState, useEffect, Fragment } from "react";
import Grid from "@mui/material/Grid";
import theme from "../../theme/theme";
import CircularChart from "elements/CircularChart";
import Select from "elements/Select";
import useStyles from "./styles";
import moment from "moment";
import Chart from "elements/Chart";

const AnalyticsEquipment: React.FC<any> = (props) => {
  const { selectedEquipmentGraphObjData, selectedEquipmentWareHouseValue } =
    props;

   
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const {
    graphSection,
    graphAreaStyle,
    graphHeaderSection,
    graphTitle,
    graphSelect,
    graphCustomSelectDropDown,
    graphFooterSection,
    footerContentList,
    graphCount,
    graphLineColor,
    graphFooterTitle,
    graphLineColorEvent,
    graphLineColorIncident,
    graphLineColorOprAlert,
  } = useStyles(appTheme);

  const [selectedValue, setSelectedValue] = useState<string>("Week");
  const [circularGraphData, setCircularGraphData] = useState<any>([]);
  const [circularInnerValue, setCircularInnerValue] = useState<number>();
const [notificationGraphData, setNotificationGraphData] = useState<any>(()=>selectedEquipmentGraphObjData?.graphAnalytics?.filter(
  (notifications: any) => {
    return notifications?.category?.toLowerCase().includes("notifications");
  }
)
);
 

  const getNotificationGraphData = (selectedValue: any) => {
    let graphData;
    switch (selectedValue) {
      case "Day":
        graphData = notificationGraphData[0]?.analytics?.day;
        break;
      case "Week":
        graphData = notificationGraphData[0]?.analytics?.weekly;
        break;
      case "Month":
        graphData = notificationGraphData[0]?.analytics?.monthly;
        break;
      case "Year":
        graphData = notificationGraphData[0]?.analytics?.yearly;
        break;
      default:
        graphData = notificationGraphData[0]?.analytics?.weekly;
        break;
    }

    setCircularGraphData([
      { title: "Event", value: graphData?.events, color: "#8BDEAE" },
      { title: "Incident", value: graphData?.incidents, color: "#DE8282" },
      { title: "Ops. Alert", value: graphData?.opralerts, color: "#FFAC5F" },
    ]);
    setCircularInnerValue(graphData?.totalNotifications);
  };

  useEffect(() => {
     getNotificationGraphData(selectedValue);
  }, [selectedValue, notificationGraphData]);

  useEffect(() => {
     getNotificationGraphData(selectedValue);
  }, []);

  //new changes for graph equipment
  const [operatingHrsGraphData, setOperatingHrsGraphData] = useState<any>();

  const [hoursOfOperationGraphData, setHoursOfOperationGraphData] =
    useState<any>();

  const [temperatureGraphData, setTemperatureGraphData] = useState<any>();

  const [equipmentUtilGraphData, setEquipmentUtilGraphData] = useState<any>();

  const [totalGoodsGraphData, setTotalGoodsGraphData] = useState<any>();

  const [workersGraphData, setWorkersGraphData] = useState<any>();

  const [
    operatingHrsGraphDataStateUpdates,
    setOperatingHrsGraphDataStateUpdates,
  ] = useState<any>();

  const [
    totalDeliveriesUpcomingGraphDataStateUpdates,
    setTotalDeliveriesUpcomingGraphDataStateUpdates,
  ] = useState<any>();

  const [
    temperatureGraphDataStateUpdates,
    setTemperatureGraphDataStateUpdates,
  ] = useState<any>();

  const [
    equipmentUtilGraphDataStateUpdates,
    setEquipmentUtilGraphDataStateUpdates,
  ] = useState<any>();

  const [totalGoodsGraphDataStateUpdates, setTotalGoodsGraphDataStateUpdates] =
    useState<any>();
  const [workersGraphDataStateUpdates, setWorkersGraphDataStateUpdates] =
    useState<any>();

  const [graphOneTitle, setGraphOneTitle] = useState<string>();
  const [graphTwoTitle, setGraphTwoTitle] = useState<string>();
  const [graphThreeTitle, setGraphThreeTitle] = useState<string>();
  const [graphFourTitle, setGraphFourTitle] = useState<string>();
  const [graphFiveTitle, setGraphFiveTitle] = useState<string>();
  const [graphSixTitle, setGraphSixTitle] = useState<string>();

  // const { width, height } = useWindowDimensions();
  const [chartWidth, setChartWidth] = useState<number>(500);
  const [chartHeight, setChartHeight] = useState<number>(270);

  useEffect(() => {
    selectedEquipmentGraphObjData?.graphAnalytics?.map(
      (data: any, index: number) => {
        switch (data?.category) {
          case "operatingHrs":
            setOperatingHrsGraphDataStateUpdates(
              data?.analytics[selectedFormatGraph1]?.analyticsData
            );

            setOperatingHrsGraphData(data?.analytics);
            setGraphOneTitle(data?.title);

            break;
          case "totalTrips":
            setTotalDeliveriesUpcomingGraphDataStateUpdates(
              data?.analytics[selectedFormatGraph2]?.analyticsData
            );
            setHoursOfOperationGraphData(data?.analytics);
            setGraphTwoTitle(data?.title);

            break;
          case "temperature":
            setTemperatureGraphDataStateUpdates(
              data?.analytics[selectedFormatGraph3]?.analyticsData
            );
            setTemperatureGraphData(data?.analytics);
            setGraphThreeTitle(data?.title);

            break;
          case "energyConsumed":
            setEquipmentUtilGraphDataStateUpdates(
              data?.analytics[selectedFormatGraph4]?.analyticsData
            );
            setEquipmentUtilGraphData(data?.analytics);
            setGraphFourTitle(data?.title);

            break;
          case "equipmentEfficiency":
            setTotalGoodsGraphDataStateUpdates(
              data?.analytics[selectedFormatGraph5]?.analyticsData
            );
            setTotalGoodsGraphData(data?.analytics);
            setGraphFiveTitle(data?.title);

            break;
          case "IdleHrs":
            setWorkersGraphDataStateUpdates(
              data?.analytics[selectedFormatGraph6]?.analyticsData
            );
            setWorkersGraphData(data?.analytics);
            setGraphSixTitle(data?.title);

            break;

          default:
          // setTempratureGraphDataStateUpdates(data?.data?.weekly?.analyticsData);
          // setTempratureGraphData(data?.data);
        }
      }
    );
 if(selectedEquipmentWareHouseValue === "ALL"){
  setNotificationGraphData(()=>selectedEquipmentGraphObjData?.graphAnalytics?.filter(
    (notifications: any) => {
      return notifications?.category?.toLowerCase().includes("notifications");
    }
  )
  
  )
 }
    
  }, [selectedEquipmentGraphObjData]);

  useEffect(() => {
    getOperatingHrsGraphData();
    getWorkersGraphData();
    getTemperatureGraphData();
    getTotalDeliveriesGraphData();
    getEquipmentUtilGraphData();
    getTotalGoodsGraphData();
  }, [
    operatingHrsGraphDataStateUpdates,

    totalDeliveriesUpcomingGraphDataStateUpdates,
    temperatureGraphDataStateUpdates,
    equipmentUtilGraphDataStateUpdates,

    totalGoodsGraphDataStateUpdates,
    workersGraphDataStateUpdates,
  ]);

  const [updatedOperatingHrsGraphData, setUpdatedOperatingHrsGraphData] =
    useState<any>();

  const [updatedTotalDeliveriesGraphData, setUpdatedTotalDeliveriesGraphData] =
    useState<any>();

  const [updatedTemperatureGraphData, setUpdatedTemperatureGraphData] =
    useState<any>();

  const [updatedEquipmentUtilGraphData, setUpdatedEquipmentUtilGraphData] =
    useState<any>();

  const [updatedTotalGoodsGraphData, setUpdatedTotalGoodsGraphData] =
    useState<any>();

  const [updatedWorkersGraphData, setUpdatedWorkersGraphData] = useState<any>();

  const getOperatingHrsGraphData = () => {
    let data = [
      {
        data: graphDataManipulation(operatingHrsGraphDataStateUpdates),
        color: "#9BAEF4",
        borderRadiusTopLeft: "50%",
        borderRadiusTopRight: "50%",
        borderRadiusBottomLeft: "50%",
        borderRadiusBottomRight: "50%",
      },
    ];

    setUpdatedOperatingHrsGraphData(data);
  };

  const getTotalDeliveriesGraphData = () => {
    const data = [
      {
        data: graphDataManipulation(
          totalDeliveriesUpcomingGraphDataStateUpdates
        ),

        color: "#2CAD39",
      },
    ];
    setUpdatedTotalDeliveriesGraphData(data);
  };


  const getTemperatureGraphData = () => {
    const data = [
      {
        data: graphDataManipulation(temperatureGraphDataStateUpdates),

        color: "#D94C7D",
      },
    ];
    setUpdatedTemperatureGraphData(data);
  };

  const getEquipmentUtilGraphData = () => {
    let data = [
      {
        data: graphDataManipulation(equipmentUtilGraphDataStateUpdates),

        color: "#219DDB",
      },
    ];

    setUpdatedEquipmentUtilGraphData(data);
  };

  const getTotalGoodsGraphData = () => {
    let data = [
      {
        data: graphDataManipulation(totalGoodsGraphDataStateUpdates),

        color: "#4334CD",
      },
    ];

    setUpdatedTotalGoodsGraphData(data);
  };

  const getWorkersGraphData = () => {
    let data = [
      {
        data: graphDataManipulation(workersGraphDataStateUpdates),

        color: "#F4BA69",
      },
    ];

    setUpdatedWorkersGraphData(data);
  };

  const graphDataManipulation = (analyticsGraphData: any) => {
    let manipulatedGraphData = [];
    if (analyticsGraphData) {
      if (analyticsGraphData) {
        for (let i = 0; i < analyticsGraphData?.length; i++) {
          manipulatedGraphData.push([
            // new Date(analyticsGraphData[i]?.node).getTime(),
            analyticsGraphData[i]?.count,
          ]);
        }
      }
    }
    return manipulatedGraphData;
  };

  // Updated Time 12Hrs Format - Day

  let times: any = [],
    periods: any = ["AM", "PM"],
    hours: any = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    prop: any = null,
    hour: any = null,
    min: any = null;

  for (prop in periods) {
    for (hour in hours) {
      for (min = 0; min < 60; min += 60) {
        times.push(
          ("0" + hours[hour]).slice(-2) +
            ":" +
            ("0" + min).slice(-2) +
            " " +
            periods[prop]
        );
      }
    }
  }

  const currentTime = moment().format("h A");
  const xAxisArray = times.filter(
    (value: any) =>
      moment(value, ["h A"]).format("HH") <
      moment(currentTime, ["h A"]).format("HH")
  );
  const xAxisTimeArray = xAxisArray.slice(
    xAxisArray.length - 10,
    xAxisArray.length
  );
  const xAxisNewValueTime = Array.from(xAxisTimeArray, (ps) => ps);

  // Updated Time 24Hrs Format - Day

  var hoursPerDay: any = 24;
  var xAxisNewtime: any = [];

  function timeOneDay() {
    var formattedTime;
    var newTimeFormat;
    for (let i = 0; i < hoursPerDay + 1; i++) {
      formattedTime = moment().subtract(i, "hours").format("HH");
      newTimeFormat = formattedTime + ":00";
      xAxisNewtime.unshift(newTimeFormat);
    }
    const newTimePop = xAxisNewtime.pop();
  }
  timeOneDay();

  //Updated Day List - Week

  const today: any = moment();
  const xAxisWeek: any = Array(7)
    .fill(7)
    .map(() => today.subtract(1, "d").format("MM/DD"));

  const xAxisNewValueWeek = xAxisWeek.reverse();

  //Updated Day List - Month

  const todayMonth: any = moment();
  const xAxisMonth: any = Array(30)
    .fill(30)
    .map(() => todayMonth.subtract(1, "d").format("MM/DD"));

  const xAxisNewValueMonth = xAxisMonth.reverse();

  // Year

  let monthName: any = new Array(
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  );
  let currentDate: any = new Date();

  let result: any = [];
  currentDate.setDate(1);
  for (let i = 0; i <= 11; i++) {
    result.push(
      monthName[currentDate.getMonth()]
      // +
      //   " " +
      //   currentDate.getFullYear().toString().substr(-2)
    );
    currentDate.setMonth(currentDate.getMonth() - 1);
  }
  const xAxisNewValue: any = result.reverse();
  const xAxisValueYear: any = xAxisNewValue.slice(
    xAxisNewValue.length - 12,
    xAxisNewValue.length
  );

  const [xAxisChartDataGraph1, setXAxisChartDataGraph1] =
    useState<any>(xAxisNewValueWeek);
  const [xAxisChartDataGraph2, setXAxisChartDataGraph2] =
    useState<any>(xAxisNewValueWeek);
  const [xAxisChartDataGraph3, setXAxisChartDataGraph3] =
    useState<any>(xAxisNewValueWeek);
  const [xAxisChartDataGraph4, setXAxisChartDataGraph4] =
    useState<any>(xAxisNewValueWeek);
  const [xAxisChartDataGraph5, setXAxisChartDataGraph5] =
    useState<any>(xAxisNewValueWeek);
  const [xAxisChartDataGraph6, setXAxisChartDataGraph6] =
    useState<any>(xAxisNewValueWeek);

  const [xAxisIntervalGraph1, setXAxisIntervalGraph1] = useState(0);
  const [xAxisIntervalGraph2, setXAxisIntervalGraph2] = useState(0);
  const [xAxisIntervalGraph3, setXAxisIntervalGraph3] = useState(0);
  const [xAxisIntervalGraph4, setXAxisIntervalGraph4] = useState(0);
  const [xAxisIntervalGraph5, setXAxisIntervalGraph5] = useState(0);
  const [xAxisIntervalGraph6, setXAxisIntervalGraph6] = useState(0);

  const selectList = [
    { label: "Day", value: "Day" },
    { label: "Week", value: "Week" },
    { label: "Month", value: "Month" },
    { label: "Year", value: "Year" },
  ];

  const monthFomrat = "{value:%m/%e}";
  const dayFormat = "{value:%H:00}";
  const yearFormat = "{value:%b}";

  const [formatGraph1, setFormatGraph1] = useState(monthFomrat);
  const [formatGraph2, setFormatGraph2] = useState(monthFomrat);
  const [formatGraph3, setFormatGraph3] = useState(monthFomrat);
  const [formatGraph4, setFormatGraph4] = useState(monthFomrat);
  const [formatGraph5, setFormatGraph5] = useState(monthFomrat);
  const [formatGraph6, setFormatGraph6] = useState(monthFomrat);

  const [selectedFormatGraph1, setSelectedFormatGraph1] = useState("weekly");
  const [selectedFormatGraph2, setSelectedFormatGraph2] = useState("weekly");
  const [selectedFormatGraph3, setSelectedFormatGraph3] = useState("weekly");
  const [selectedFormatGraph4, setSelectedFormatGraph4] = useState("weekly");
  const [selectedFormatGraph5, setSelectedFormatGraph5] = useState("weekly");
  const [selectedFormatGraph6, setSelectedFormatGraph6] = useState("weekly");

  const [selectedRealTimeGraph, setSelectedRealTimeGraph] =
    useState("Real Time");

  const handleSelect = (val: string, graphName: string) => {
    if (graphName === "graph1") {
      switch (val) {
        case "Day":
          setFormatGraph1(dayFormat);
          setSelectedFormatGraph1("day");
          setOperatingHrsGraphDataStateUpdates(
            operatingHrsGraphData?.day?.analyticsData
          );

          setXAxisChartDataGraph1(xAxisNewtime);
          setXAxisIntervalGraph1(4);

          break;
        case "Week":
          setFormatGraph1(monthFomrat);
          setSelectedFormatGraph1("weekly");
          setOperatingHrsGraphDataStateUpdates(
            operatingHrsGraphData?.weekly?.analyticsData
          );

          setXAxisChartDataGraph1(xAxisNewValueWeek);
          setXAxisIntervalGraph1(0);
          break;
        case "Month":
          setFormatGraph1(monthFomrat);
          setSelectedFormatGraph1("monthly");
          setOperatingHrsGraphDataStateUpdates(
            operatingHrsGraphData?.monthly?.analyticsData
          );

          setXAxisChartDataGraph1(xAxisNewValueMonth);
          setXAxisIntervalGraph1(5);
          break;
        case "Year":
          setFormatGraph1(yearFormat);
          setSelectedFormatGraph1("yearly");
          setOperatingHrsGraphDataStateUpdates(
            operatingHrsGraphData?.yearly?.analyticsData
          );

          setXAxisChartDataGraph1(xAxisValueYear);
          setXAxisIntervalGraph1(1);
          break;
        default:
          setFormatGraph1(dayFormat);
          setOperatingHrsGraphDataStateUpdates(
            operatingHrsGraphData?.day?.analyticsData
          );

          setXAxisChartDataGraph1(xAxisNewtime);
          setXAxisIntervalGraph1(4);
          break;
      }
    }

    if (graphName === "graph2") {
      switch (val) {
        case "Day":
          setFormatGraph2(dayFormat);
          setSelectedFormatGraph2("day");
          setTotalDeliveriesUpcomingGraphDataStateUpdates(
            hoursOfOperationGraphData?.day?.analyticsData
          );

          setXAxisChartDataGraph2(xAxisNewtime);
          setXAxisIntervalGraph2(4);
          break;
        case "Week":
          setFormatGraph2(monthFomrat);
          setSelectedFormatGraph2("weekly");

          setTotalDeliveriesUpcomingGraphDataStateUpdates(
            hoursOfOperationGraphData?.weekly?.analyticsData
          );

          setXAxisChartDataGraph2(xAxisNewValueWeek);
          setXAxisIntervalGraph2(0);
          break;
        case "Month":
          setFormatGraph2(monthFomrat);
          setSelectedFormatGraph2("monthly");

          setTotalDeliveriesUpcomingGraphDataStateUpdates(
            hoursOfOperationGraphData?.monthly?.analyticsData
          );

          setXAxisChartDataGraph2(xAxisNewValueMonth);
          setXAxisIntervalGraph2(5);
          break;
        case "Year":
          setFormatGraph2(yearFormat);
          setSelectedFormatGraph2("yearly");

          setTotalDeliveriesUpcomingGraphDataStateUpdates(
            hoursOfOperationGraphData?.yearly?.analyticsData
          );

          setXAxisChartDataGraph2(xAxisValueYear);
          setXAxisIntervalGraph2(1);
          break;
        default:
          setFormatGraph2(dayFormat);
          setSelectedFormatGraph2("day");
          setTotalDeliveriesUpcomingGraphDataStateUpdates(
            hoursOfOperationGraphData?.day?.analyticsData
          );

          setXAxisChartDataGraph2(xAxisNewtime);
          setXAxisIntervalGraph2(4);
          break;
      }
    }

    if (graphName === "graph3") {
      if (selectedEquipmentWareHouseValue === "ALL") {
        setSelectedValue(val);
      } else {
        switch (val) {
          case "Day":
            setFormatGraph3(dayFormat);
            setSelectedFormatGraph3("day");
            setTemperatureGraphDataStateUpdates(
              temperatureGraphData?.day?.analyticsData
            );
            setXAxisChartDataGraph3(xAxisNewtime);
            setXAxisIntervalGraph3(4);
            break;
          case "Week":
            setFormatGraph3(monthFomrat);
            setSelectedFormatGraph3("weekly");
            setTemperatureGraphDataStateUpdates(
              temperatureGraphData?.weekly?.analyticsData
            );
            setXAxisChartDataGraph3(xAxisNewValueWeek);
            setXAxisIntervalGraph3(0);
            break;
          case "Month":
            setFormatGraph3(monthFomrat);
            setSelectedFormatGraph3("monthly");
            setTemperatureGraphDataStateUpdates(
              temperatureGraphData?.monthly?.analyticsData
            );
            setXAxisChartDataGraph3(xAxisNewValueMonth);
            setXAxisIntervalGraph3(5);
            break;
          case "Year":
            setFormatGraph3(yearFormat);
            setSelectedFormatGraph3("yearly");
            setTemperatureGraphDataStateUpdates(
              temperatureGraphData?.yearly?.analyticsData
            );
            setXAxisChartDataGraph3(xAxisValueYear);
            setXAxisIntervalGraph3(1);
            break;
          default:
            setFormatGraph3(dayFormat);
            setSelectedFormatGraph3("day");
            setTemperatureGraphDataStateUpdates(
              temperatureGraphData?.day?.analyticsData
            );
            setXAxisChartDataGraph3(xAxisNewtime);
            setXAxisIntervalGraph3(4);
            break;
        }
      }
    }

    if (graphName === "graph4") {
      switch (val) {
        case "Day":
          setFormatGraph4(dayFormat);
          setSelectedFormatGraph4("day");
          setEquipmentUtilGraphDataStateUpdates(
            equipmentUtilGraphData?.day?.analyticsData
          );
          setXAxisChartDataGraph4(xAxisNewtime);
          setXAxisIntervalGraph4(4);

          break;
        case "Week":
          setFormatGraph4(monthFomrat);
          setSelectedFormatGraph4("weekly");
          setEquipmentUtilGraphDataStateUpdates(
            equipmentUtilGraphData?.weekly?.analyticsData
          );
          setXAxisChartDataGraph4(xAxisNewValueWeek);
          setXAxisIntervalGraph4(0);

          break;
        case "Month":
          setFormatGraph4(monthFomrat);
          setSelectedFormatGraph4("monthly");
          setEquipmentUtilGraphDataStateUpdates(
            equipmentUtilGraphData?.monthly?.analyticsData
          );
          setXAxisChartDataGraph4(xAxisNewValueMonth);
          setXAxisIntervalGraph4(5);

          break;
        case "Year":
          setFormatGraph4(yearFormat);
          setSelectedFormatGraph4("yearly");
          setEquipmentUtilGraphDataStateUpdates(
            equipmentUtilGraphData?.yearly?.analyticsData
          );
          setXAxisChartDataGraph4(xAxisValueYear);
          setXAxisIntervalGraph4(1);

          break;
        default:
          setFormatGraph4(dayFormat);
          setEquipmentUtilGraphDataStateUpdates(
            equipmentUtilGraphData?.day?.analyticsData
          );
          break;
      }
    }

    if (graphName === "graph5") {
      switch (val) {
        case "Day":
          setFormatGraph5(dayFormat);
          setSelectedFormatGraph5("day");
          setTotalGoodsGraphDataStateUpdates(
            totalGoodsGraphData?.day?.analyticsData
          );
          setXAxisChartDataGraph5(xAxisNewtime);
          setXAxisIntervalGraph5(4);

          break;
        case "Week":
          setFormatGraph5(monthFomrat);
          setSelectedFormatGraph5("weekly");
          setTotalGoodsGraphDataStateUpdates(
            totalGoodsGraphData?.weekly?.analyticsData
          );
          setXAxisChartDataGraph5(xAxisNewValueWeek);
          setXAxisIntervalGraph5(0);

          break;
        case "Month":
          setFormatGraph5(monthFomrat);
          setSelectedFormatGraph5("monthly");
          setTotalGoodsGraphDataStateUpdates(
            totalGoodsGraphData?.monthly?.analyticsData
          );
          setXAxisChartDataGraph5(xAxisNewValueMonth);
          setXAxisIntervalGraph5(5);

          break;
        case "Year":
          setFormatGraph5(yearFormat);
          setSelectedFormatGraph5("yearly");
          setTotalGoodsGraphDataStateUpdates(
            totalGoodsGraphData?.yearly?.analyticsData
          );
          setXAxisChartDataGraph5(xAxisValueYear);
          setXAxisIntervalGraph5(1);

          break;
        default:
          setFormatGraph5(dayFormat);
          setTotalGoodsGraphDataStateUpdates(
            totalGoodsGraphData?.day?.analyticsData
          );
          break;
      }
    }

    if (graphName === "graph6") {
      switch (val) {
        case "Day":
          setFormatGraph6(dayFormat);
          setSelectedFormatGraph6("day");
          setWorkersGraphDataStateUpdates(workersGraphData?.day?.analyticsData);
          setXAxisChartDataGraph6(xAxisNewtime);
          setXAxisIntervalGraph6(4);
          break;
        case "Week":
          setFormatGraph6(monthFomrat);
          setSelectedFormatGraph6("weekly");
          setWorkersGraphDataStateUpdates(
            workersGraphData?.weekly?.analyticsData
          );
          setXAxisChartDataGraph6(xAxisNewValueWeek);
          setXAxisIntervalGraph6(0);
          break;
        case "Month":
          setFormatGraph6(monthFomrat);
          setSelectedFormatGraph6("monthly");
          setWorkersGraphDataStateUpdates(
            workersGraphData?.monthly?.analyticsData
          );
          setXAxisChartDataGraph6(xAxisNewValueMonth);
          setXAxisIntervalGraph6(5);
          break;
        case "Year":
          setFormatGraph6(yearFormat);
          setSelectedFormatGraph6("yearly");
          setWorkersGraphDataStateUpdates(
            workersGraphData?.yearly?.analyticsData
          );
          setXAxisChartDataGraph6(xAxisValueYear);
          setXAxisIntervalGraph6(1);
          break;
        default:
          setFormatGraph6(dayFormat);
          setWorkersGraphDataStateUpdates(workersGraphData?.day?.analyticsData);
          break;
      }
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <div className={graphSection}>
            <div className={graphHeaderSection}>
              <div className={graphTitle}>Operating Hrs (Hrs) </div>
              <div className={graphSelect}>
                <Select
                  selectList={selectList}
                  handleSelect={handleSelect}
                  // customWidth={"40%"}
                  customHeight={"40px"}
                  customSelectCustom={graphCustomSelectDropDown}
                  pageName={"analyticsPage"}
                  drowpDownTextColor={"#837DAB"}
                  dropDownBgColor={"#EEE9FF"}
                  dropDownSelectedBgColor={"#9087C8"}
                  dropDownSelectedTextColor={"#FFF"}
                  graphName={"graph1"}
                  // selectedDropDownValue={selectedValue}
                  // placeholder={"Select Trailer"}
                />
              </div>
            </div>
            <Chart
              type={"column"}
              minWidth={chartWidth}
              height={chartHeight}
              dataPoints={updatedOperatingHrsGraphData}
              format={formatGraph1}
              toolTipShared={false}
              splineWidth={2}
              graphSequence={"graph1"}
              tooltipShow={true}
              isCrosshair={false}
              dataLabels={false}
              tabIdentity={"equipment"}
              pageName={"analyticsPage"}
              xAxisArray={xAxisChartDataGraph1}
              xAxisInterval={xAxisIntervalGraph1}
              graphTitle={graphOneTitle}
              selectedAnalyticListItem={"Overall Analytics"}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <div className={graphSection}>
            <div className={graphHeaderSection}>
              <div className={graphTitle}>Total Trips</div>
              <div className={graphSelect}>
                <Select
                  selectList={selectList}
                  handleSelect={handleSelect}
                  // customWidth={"40%"}
                  customHeight={"40px"}
                  customSelectCustom={graphCustomSelectDropDown}
                  pageName={"analyticsPage"}
                  drowpDownTextColor={"#837DAB"}
                  dropDownBgColor={"#EEE9FF"}
                  dropDownSelectedBgColor={"#9087C8"}
                  dropDownSelectedTextColor={"#FFF"}
                  graphName={"graph2"}
                  // selectedDropDownValue={selectedValue}
                  // placeholder={"Select Trailer"}
                />
              </div>
            </div>
            <Chart
              type={"spline"}
              minWidth={chartWidth}
              height={chartHeight}
              dataPoints={updatedTotalDeliveriesGraphData}
              format={formatGraph2}
              toolTipShared={false}
              splineWidth={2}
              graphSequence={"graph2"}
              tooltipShow={true}
              isCrosshair={true}
              dataLabels={false}
              tabIdentity={"drivers"}
              pageName={"analyticsPage"}
              xAxisArray={xAxisChartDataGraph2}
              xAxisInterval={xAxisIntervalGraph2}
              graphTitle={graphTwoTitle}
              selectedAnalyticListItem={"Overall Analytics"}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <div className={graphSection}>
            <div className={graphHeaderSection}>
              <div className={graphTitle}>
                {selectedEquipmentWareHouseValue === "ALL"
                  ? "Notifications"
                  : "Temperature (°F)"}
              </div>
              <div className={graphSelect}>
                <Select
                  selectList={selectList}
                  handleSelect={handleSelect}
                  // customWidth={"40%"}
                  customHeight={"40px"}
                  customSelectCustom={graphCustomSelectDropDown}
                  pageName={"analyticsPage"}
                  drowpDownTextColor={"#837DAB"}
                  dropDownBgColor={"#EEE9FF"}
                  dropDownSelectedBgColor={"#9087C8"}
                  dropDownSelectedTextColor={"#FFF"}
                  graphName={"graph3"}
                  // selectedDropDownValue={selectedValue}
                  // placeholder={"Select Trailer"}
                />
              </div>
            </div>
            {selectedEquipmentWareHouseValue === "ALL" ? (
              <>
                <div className={graphAreaStyle}>
                  <CircularChart
                    data={circularGraphData}
                    circularInnerText={"Total Notifications"}
                    circularInnerValue={circularInnerValue}
                  />
                </div>
                <div className={graphFooterSection}>
                  {circularGraphData &&
                    circularGraphData?.length > 0 &&
                    circularGraphData?.map((item: any) => {
                      return (
                        <div className={footerContentList}>
                          <div className={graphCount}>{item?.value}</div>
                          <div
                            className={
                              item?.title === "Event"
                                ? graphLineColorEvent
                                : item?.title === "Incident"
                                ? graphLineColorIncident
                                : graphLineColorOprAlert
                            }
                          ></div>
                          <div className={graphFooterTitle}>{item?.title}</div>
                        </div>
                      );
                    })}
                </div>
              </>
            ) : (
              <Chart
                type={"spline"}
                minWidth={chartWidth}
                height={chartHeight}
                dataPoints={updatedTemperatureGraphData}
                format={formatGraph3}
                toolTipShared={false}
                splineWidth={2}
                graphSequence={"graph3"}
                tooltipShow={true}
                isCrosshair={true}
                dataLabels={false}
                tabIdentity={"drivers"}
                pageName={"analyticsPage"}
                xAxisArray={xAxisChartDataGraph3}
                xAxisInterval={xAxisIntervalGraph3}
                graphTitle={graphThreeTitle}
                selectedAnalyticListItem={"Overall Analytics"}
              />
            )}
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <div className={graphSection}>
            <div className={graphHeaderSection}>
              <div className={graphTitle}>Energy Consumed (Kw)</div>
              <div className={graphSelect}>
                <Select
                  selectList={selectList}
                  handleSelect={handleSelect}
                  // customWidth={"40%"}
                  customHeight={"40px"}
                  customSelectCustom={graphCustomSelectDropDown}
                  pageName={"analyticsPage"}
                  drowpDownTextColor={"#837DAB"}
                  dropDownBgColor={"#EEE9FF"}
                  dropDownSelectedBgColor={"#9087C8"}
                  dropDownSelectedTextColor={"#FFF"}
                  graphName={"graph4"}
                  // selectedDropDownValue={selectedValue}
                  // placeholder={"Select Trailer"}
                />
              </div>
            </div>
            <Chart
              type={"areaspline"}
              minWidth={chartWidth}
              height={chartHeight}
              dataPoints={updatedEquipmentUtilGraphData}
              format={formatGraph4}
              toolTipShared={false}
              splineWidth={2}
              graphSequence={"graph4"}
              tooltipShow={true}
              isCrosshair={true}
              dataLabels={false}
              tabIdentity={"drivers"}
              pageName={"analyticsPage"}
              xAxisArray={xAxisChartDataGraph4}
              xAxisInterval={xAxisIntervalGraph4}
              graphTitle={graphFourTitle}
              selectedAnalyticListItem={"Overall Analytics"}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <div className={graphSection}>
            <div className={graphHeaderSection}>
              <div className={graphTitle}>Equipment Efficiency (%)</div>
              <div className={graphSelect}>
                <Select
                  selectList={selectList}
                  handleSelect={handleSelect}
                  // customWidth={"40%"}
                  customHeight={"40px"}
                  customSelectCustom={graphCustomSelectDropDown}
                  pageName={"analyticsPage"}
                  drowpDownTextColor={"#837DAB"}
                  dropDownBgColor={"#EEE9FF"}
                  dropDownSelectedBgColor={"#9087C8"}
                  dropDownSelectedTextColor={"#FFF"}
                  graphName={"graph5"}
                  // selectedDropDownValue={selectedValue}
                  // placeholder={"Select Trailer"}
                />
              </div>
            </div>
            <Chart
              type={"spline"}
              minWidth={chartWidth}
              height={chartHeight}
              dataPoints={updatedTotalGoodsGraphData}
              format={formatGraph5}
              toolTipShared={false}
              splineWidth={2}
              graphSequence={"graph5"}
              tooltipShow={true}
              isCrosshair={true}
              dataLabels={false}
              tabIdentity={"drivers"}
              pageName={"analyticsPage"}
              xAxisArray={xAxisChartDataGraph5}
              xAxisInterval={xAxisIntervalGraph5}
              graphTitle={graphFiveTitle}
              selectedAnalyticListItem={"Overall Analytics"}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <div className={graphSection}>
            <div className={graphHeaderSection}>
              <div className={graphTitle}>Idle Hrs (Hrs)</div>
              <div className={graphSelect}>
                <Select
                  selectList={selectList}
                  handleSelect={handleSelect}
                  // customWidth={"40%"}
                  customHeight={"40px"}
                  customSelectCustom={graphCustomSelectDropDown}
                  pageName={"analyticsPage"}
                  drowpDownTextColor={"#837DAB"}
                  dropDownBgColor={"#EEE9FF"}
                  dropDownSelectedBgColor={"#9087C8"}
                  dropDownSelectedTextColor={"#FFF"}
                  graphName={"graph6"}
                  // selectedDropDownValue={selectedValue}
                  // placeholder={"Select Trailer"}
                />
              </div>
            </div>
            <Chart
              type={"spline"}
              minWidth={chartWidth}
              height={chartHeight}
              dataPoints={updatedWorkersGraphData}
              format={formatGraph6}
              toolTipShared={false}
              splineWidth={2}
              graphSequence={"graph6"}
              tooltipShow={true}
              isCrosshair={true}
              dataLabels={false}
              tabIdentity={"drivers"}
              pageName={"analyticsPage"}
              xAxisArray={xAxisChartDataGraph6}
              xAxisInterval={xAxisIntervalGraph6}
              graphTitle={graphSixTitle}
              selectedAnalyticListItem={"Overall Analytics"}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default AnalyticsEquipment;
