import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import useTranslation from "../../localization/translations";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InfoSubList from "../InfoSubList";
import CustomizedSteppers from "../Stepper";
import ChipButton from "../../elements/ChipButton";
import Tabs from "../../elements/Tabs";
import { SearchIconInfo } from "../../assets/buttonIcons";
import theme from "../../theme/theme";
import colorCodes from "../../theme/colors";
import Geofence from "components/Geofence";
import FloorMap from "components/Map/FloorMap";
import Map from "components/Map";
import InfoNotificationListItem from "components/InfoNotificationListItem";
import TimeLineStepper from "elements/TimeLineStepper";
import trailersInfoWindowData from "mockdata/trailersInfoWindowData";
import Accordian from "components/InfoWindowAccordian";
import SearchBox from "elements/SearchBox";
import Select from "elements/Select";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import useStyles from "./styles";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import Calendar from "elements/Calendar";
import PalletList from "components/PalletList";
import PalletEditList from "components/PalletEditList";
import { useDispatch, useSelector } from "react-redux";
// @ts-ignore
import { doc, getDoc, updateDoc } from "firebase/firestore/lite";
// @ts-ignore
import { db } from "services/firebase";
import moment from "moment";
import Loading_icon from "../../assets/Loading_icon.gif";

const DialogWrapper = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiBackdrop-root": {
    marginTop: "84px !important",
  },
  "& .MuiPaper-root": {
    maxHeight: "calc(100% - 160px)",
    minHeight: "calc(100% - 160px)",
    width: "80vw",
    maxWidth: "1772px",
    background: `#000 !important`,
    opacity: 0.9,
    color: colorCodes.colorWhite,
    padding: 24,
    borderRadius: "12px",
  },
  "& .MuiDialog-container": {
    marginTop: "60px !important",
    background: "rgb(1 1 1 / 0%)",
    backdropFilter: "blur(4px)",
    height: "calc(100vh - 65px) !important",
  },
}));

const MenifestEditInfoDialog: React.FC<any> = (props) => {
  const {
    selectedItemID,
    setShowInfoDialogue,
    actionName,
    setShowEditManifestWindow,
    tableData,
    selectedTableRowIndex,
    setTableData,
    getTableData,
    isDataLoaded,
    setIsDataLoaded,
  } = props;

  const [selectedItem, setSelectedItem] = useState<any>();

  const [isLoaded, setIsLoaded] = useState<any>(true);

  useEffect(() => {
    if (selectedItemID) {
      trailersInfoWindowData?.trailersInfolist?.map((item) => {
        if (item?.trailerId === selectedItemID) {
          setSelectedItem(item);
        }
      });
    }
  }, [selectedItemID]);

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const {
    headerStyle,

    subListRow,

    customNotificationTabs,
    dateTimePickerStyle,
    displayFlex,
    timePickerStyle,
    timeInputStyle,
    wareHouseClass,
    trailerClass,
    loaderStyle,
    alertMsgStyle,
  } = useStyles(appTheme);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "GET_TRAILER_LIST",
    });
  }, []);

  const apiDate = useSelector(
    (state: any) => state?.trailerList.trailerDataValue
  );

  const [trailerData, setTrailerData] = useState(apiDate.trailers);

  useEffect(() => {
    setTrailerData(apiDate.trailers);
  }, [apiDate]);

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );

  const {} = useTranslation();

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  const [open, setOpen] = useState(!false);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [wareHouse, setWareHouse] = useState<string>();
  const [trailer, setTrailer] = useState<string>();
  const [startDate, setStartDate] = useState<any>();
  const [startTime, setStartTime] = useState<any>();

  const handleClose = () => {
    setOpen(!open);
    setShowEditManifestWindow(false);
  };

  const tabsList = [
    {
      name: "Edit Manifest",
      val: 0,
    },
  ];

  const handleTabs = (index: number) => {
    setTabIndex(index);
  };

  const selectListWareHouse = [
    { label: "WAREHOUSE#1", value: "WAREHOUSE#1" },
    { label: "WAREHOUSE#2", value: "WAREHOUSE#2" },
    { label: "WAREHOUSE#3", value: "WAREHOUSE#3" },
    { label: "WAREHOUSE#4", value: "WAREHOUSE#4" },
    { label: "WAREHOUSE#5", value: "WAREHOUSE#5" },
  ];

  const selectListTrailer = [
    { label: "TRAILER#1", value: "TRAILER#1" },
    { label: "TRAILER#2", value: "TRAILER#2" },
    { label: "TRAILER#3", value: "TRAILER#3" },
    { label: "TRAILER#4", value: "TRAILER#4" },
    { label: "TRAILER#5", value: "TRAILER#5" },
  ];

  const handleSelect = (val: string) => {
    setWareHouse(val);
  };

  const handleTrailerSelect = (val: string) => {
    setTrailer(val);
  };

  const [isSelectedDateChanged, setIsSelectedDateChanged] =
    useState<any>(false);

  const handleSelectedDate = (value: any) => {
    setStartDate(value);
    setIsSelectedDateChanged(true);
  };

  const handleSelectedTime = (e: any) => {
    setStartTime(e.target.innerText);
  };

  let times: any = [],
    periods = ["AM", "PM"],
    hours = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    prop = null,
    hour = null,
    min = null;

  for (prop in periods) {
    for (hour in hours) {
      for (min = 0; min < 60; min += 60) {
        times.push(
          ("0" + hours[hour]).slice(-2) +
            ":" +
            ("0" + min).slice(-2) +
            " " +
            periods[prop]
        );
      }
    }
  }

  const [alertMsg, setAlertMsg] = useState<any>(false);
  const [warehoueseAlert, setWareHouseAlert] = useState<any>(false);
  const [trailerAlert, setTrailerAlert] = useState<any>(false);
  const [startDateAlert, setStartDateAlert] = useState<any>(false);
  const [startTimeAlert, setStartTimeAlert] = useState<any>(false);
  const [storeAlert, setStoreAlert] = useState<any>(false);
  const [deliveryDateAlert, setDeliveryDateAlert] = useState<any>(false);
  const [deliveryTimeAlert, setDeliveryTimeAlert] = useState<any>(false);
  const [palletAlert, setPalletAlert] = useState<any>(false);

  useEffect(() => {
    setTimeout(() => {
      setAlertMsg(false);
      setDeliveryDateAlert(false);
      setDeliveryTimeAlert(false);
      setStoreAlert(false);
      setWareHouseAlert(false);
      setTrailerAlert(false);
      setStartDateAlert(false);
      setStartTimeAlert(false);
      setPalletAlert(false);
    }, 5000);
  }, [alertMsg]);

  const getAlertMessage = () => {
    return (
      <div className={alertMsgStyle}>
        Please Select "{warehoueseAlert && "WareHouse,"}{" "}
        {trailerAlert && "Trailer,"} {startDateAlert && "Start Date, "}{" "}
        {startTimeAlert && "Start Time,"} {storeAlert && "Store, "}{" "}
        {deliveryDateAlert && "Delivery Date,"}{" "}
        {deliveryTimeAlert && "Delivery Time,"}
        {palletAlert && "Pallet"}"
      </div>
    );
  };

  const handleSave = (stores: any) => {
    const storesMap = stores?.map((item: any) => {
      return item?.deliveryDate !== "" &&
        (item?.deliveryTime !== "" || undefined) &&
        item?.name !== "" &&
        item?.pallets?.length > 0
        ? true
        : false;
    });
    const storesMapMessage = stores?.map((item: any) => {
      item?.deliveryDate === "" && setDeliveryDateAlert(true);
      (item?.deliveryTime === "" || undefined) && setDeliveryTimeAlert(true);
      item?.name === "" && setStoreAlert(true);
      wareHouse === undefined && setWareHouseAlert(true);
      trailer === undefined && setTrailerAlert(true);
      startTime === undefined && setStartTimeAlert(true);
      item?.pallets?.length === 0 && setPalletAlert(true);
    });

    if (
      wareHouse !== undefined &&
      trailer !== undefined &&
      startTime !== undefined &&
      !storesMap?.includes(false)
    ) {
      setIsDataLoaded(false);
      const payload = {
        wareHouse: wareHouse,
        trailer: trailer,
        startDate: moment(startDate).format("DD-MM-YYYY"),
        startTime: startTime,
        stores: stores,
      };

      const autoMID =
        "MID#" + Math.floor(Math.random() * (999 - 100 + 1) + 100);

      const getLatestDate = (data: any) => {
        const sorted_ms = data
          .map((item: any) => {
            return new Date(item.deliveryDate).getTime();
          })
          .sort();

        const latest_ms = sorted_ms[sorted_ms.length - 1];

        return new Date(latest_ms);
      };

      const latestDate = getLatestDate(stores);

      const endLocation = stores?.find(
        (date: any) =>
          moment(date.deliveryDate).format("DD-MM-YYYY") ===
          moment(latestDate).format("DD-MM-YYYY")
      );

      const numberOfPallets: any[] = [];
      stores?.map((item: any) => {
        item?.pallets?.map((pallet: any) => {
          numberOfPallets.push(pallet);
        });
      });

      const createManifestData = {
        manifestId: tableData[selectedTableRowIndex].manifestId,
        startDateTime:
          moment(startDate).format("DD-MM-YYYY") + " | " + startTime,
        trailerId: trailer,
        sourceWarehouse: wareHouse,
        deliveryStores: stores.length,
        noOfPallets: numberOfPallets?.length,
        endLocation: endLocation?.name,
        endDateTime:
          moment(latestDate).format("DD-MM-YYYY") +
          " | " +
          stores[0]?.deliveryTime,
        manifestData: payload,
      };

      const createManifestArray = [createManifestData];

      const editValue = tableData[selectedTableRowIndex].manifestId;
      const modifiedTableData = tableData.map(
        (data: any) =>
          createManifestArray.find(
            (item) => item.manifestId === data.manifestId
          ) || data
      );

      const ref = doc(db, "manifestUpcoming", "upcomingData");
      updateDoc(ref, { data: modifiedTableData })
        .then((success: any) => {
          {
            setTableData(modifiedTableData);
            setShowEditManifestWindow(false);
            setIsDataLoaded(true);
          }
        })
        .catch((error: any) => console.log(error));
    } else {
      setAlertMsg(true);
    }
  };

  const [selectedTableManifest, setSelectedTableManifest] = useState(
    actionName === "edit" && tableData[selectedTableRowIndex]?.manifestData
  );
  const [newStartDate, setNewStartDate] = useState<any>();

  useEffect(() => {
    setWareHouse(selectedTableManifest?.wareHouse);
    setTrailer(selectedTableManifest?.trailer);
    setStartTime(selectedTableManifest?.startTime);
    setNewStartDate(selectedTableManifest?.startDate);
  }, []);

  return (
    <>
      {" "}
      {isLoaded && tableData ? (
        <DialogWrapper open={open} sx={{ top: "22px" }}>
          <div className={headerStyle}>
            <Tabs
              initialIndex={0}
              tabsList={tabsList}
              handleTabs={handleTabs}
              dashboardNotificationClassName={customNotificationTabs}
            />
          </div>

          <div>
            {alertMsg && getAlertMessage()}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 15,
                top: 12,
                color: appTheme.palette.infoDialogueComponent.colorWhite,
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>

          <Grid container>
            <Grid item xs={12} className={subListRow}>
              {/* <InfoSubList
            highlighted={"safetyScore"}
            infoSubList={infoSubList}
          /> */}
              <Grid container>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={3}
                  lg={3}
                  xl={3}
                  className={wareHouseClass}
                >
                  <Select
                    selectList={selectListWareHouse}
                    handleSelect={handleSelect}
                    customWidth={"100%"}
                    customHeight={"60px"}
                    graphName={"graph9"}
                    // customSelectCustom={customSelect}
                    pageName={"analyticsPage"}
                    drowpDownTextColor={"#fff"}
                    dropDownBgColor={"#000000"}
                    dropDownSelectedBgColor={"#9486DA"}
                    selectedDropDownValue={wareHouse}
                    // placeholder={"Select WareHouse"}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={3}
                  lg={3}
                  xl={3}
                  className={trailerClass}
                >
                  <Select
                    selectList={selectListTrailer}
                    handleSelect={handleTrailerSelect}
                    customWidth={"100%"}
                    customHeight={"60px"}
                    graphName={"graph9"}
                    // customSelectCustom={customSelect}
                    pageName={"analyticsPage"}
                    drowpDownTextColor={"#fff"}
                    dropDownBgColor={"#000000"}
                    dropDownSelectedBgColor={"#9486DA"}
                    selectedDropDownValue={trailer}
                    // placeholder={"Select Trailer"}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={3}
                  lg={3}
                  xl={3}
                  className={dateTimePickerStyle}
                >
                  <Calendar
                    customWidth={"100%"}
                    selectedDate={startDate}
                    handleSelectedDate={handleSelectedDate}
                    newStartDate={newStartDate}
                    isSelectedDateChanged={isSelectedDateChanged}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={3}
                  lg={3}
                  xl={3}
                  className={dateTimePickerStyle}
                >
                  <Autocomplete
                    id="disabled-options-demo"
                    disableClearable
                    options={times}
                    value={
                      tableData[selectedTableRowIndex]?.manifestData?.startTime
                    }
                    onChange={handleSelectedTime}
                    sx={{
                      width: "100%",
                      color: "white !important",
                      border: "1px solid white",
                      borderRadius: 1,
                      marginLeft: 1,
                    }}
                    popupIcon={<AccessTimeOutlinedIcon />}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Start Time"
                        sx={{ "::placeholder": { color: "white !important" } }}
                        InputLabelProps={{
                          style: { color: "#fff" },
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              {/* <div className={dateTimePickerStyle}></div> */}
            </Grid>
            <Grid item xs={12}>
              <PalletEditList
                handleCancel={handleClose}
                handleSave={handleSave}
                actionName={actionName}
                tableData={tableData}
                selectedTableRowIndex={selectedTableRowIndex}
              />
            </Grid>
          </Grid>
        </DialogWrapper>
      ) : (
        <DialogWrapper open={open} sx={{ top: "22px" }}>
          <div className={loaderStyle}>
            <img src={Loading_icon} alt="loading" width={45} />
          </div>
        </DialogWrapper>
      )}
    </>
  );
};

export default MenifestEditInfoDialog;
