import React, { useEffect, useState } from "react";
import theme from "../../theme/theme";
import Button from "../../elements/Button";
import useTranslation from "../../localization/translations";
import { useNavigate } from "react-router-dom";
import WifiIcon from "../../assets/wifiIcon.svg";
import WifiDisconnected from "../../assets/wifiDisconnected.svg";
import BatteryIcon from "../../assets/BatteryIcon.svg";
import BatteryRedIcon from "../../assets/BatteryRedIcon.svg";
import TemperatureIcon from "../../assets/TemperatureIcon.svg";
import UtilizationIcon from "../../assets/UtilizationIcon.svg";
import useStyles from "./styles";
interface dashboardListProps {
  buttonIndex: number;
  data: any;
  handleExpandListItem: any;
  index: number;
  selectedNotification: number;
}

const DashboardViewDetailsListItems: React.FC<any> = (props: any) => {
  const {
    refs,
    data,
    data: {
      id,
      connectedStatus,
      utilization,
      type,
      area,
      trackerId,
      battery,
      temperature,
      category,
      currentTimeStamp,
    },
    handleExpandListItem,
    index,
    selectedNotification,
    setViewDetailActive,
    notificationData,
    notificationTimeStamp,

    handleInfoDialogue,
    tabIndex,
    setTabIndex,
  } = props;

  const navigate = useNavigate();

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);

  const {
    listItemButtonName,
    BoxId,
    TrackerId,
    EquipmentId,
    Status,
    Battery,
    Temperature,
    Utilization,
    LocationText,
  } = useTranslation();

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  const {
    rootContainer,
    itemTitleStyle,
    itemAreaStyle,
    expandedListClass,
    collapsedListClass,
    displayFlex,
    itemListCount,
    itemListName,
    selectedButtonStyles,
    timeStampStyle,
    itemListIconStyle,
    connectedStatusButtonStyle,
    connectedButtonPosition,
    disConnectedStatusButtonStyle,
  } = useStyles(appTheme);

  return (
    <>
      <div
        className={rootContainer}
        onClick={() => handleExpandListItem(id)}
        // ref={refs[id]}
      >
        <div>
          {selectedNotification === id ? (
            <div className={expandedListClass}>
              <div className={itemTitleStyle}>
                {/* {type === "goods" ? (
                  <>
                    {BoxId} : {id}
                  </>
                ) : (
                  <>
                    {EquipmentId} : {id}
                  </>
                )} */}
                {utilization ? `Equipment ID : ${id}` : `Box ID : ${id}`}
                {""} | {TrackerId} : {trackerId}
              </div>
              <div className={itemAreaStyle}>
                {type === "notifications" && category && (
                  <span>Type - {category} | </span>
                )}
                {area}
              </div>
              <div className={displayFlex}>
                <div>
                  <div className={itemListCount}>
                    <img
                      className={itemListIconStyle}
                      src={
                        connectedStatus?.toLowerCase() === "connected"
                          ? WifiIcon
                          : WifiDisconnected
                      }
                      alt=""
                    />
                    {connectedStatus}
                  </div>
                  <div className={itemListName}>{Status}</div>
                </div>
                {utilization && (
                  <div>
                    <div className={itemListCount}>
                      <img
                        className={itemListIconStyle}
                        src={UtilizationIcon}
                        alt="UtilizationIcon"
                      />
                      {utilization}
                    </div>
                    <div className={itemListName}>{Utilization}</div>
                  </div>
                )}
                <div>
                  <div className={itemListCount}>
                    <img
                      className={itemListIconStyle}
                      src={battery <= 20 ? BatteryRedIcon : BatteryIcon}
                      alt=""
                    />
                    {battery}%
                  </div>
                  <div className={itemListName}>{Battery}</div>
                </div>
                <div>
                  <div className={itemListCount}>
                    <img
                      className={itemListIconStyle}
                      src={TemperatureIcon}
                      alt=""
                    />
                    {temperature}
                  </div>
                  <div className={itemListName}>{Temperature}</div>
                </div>
              </div>
              <>
                {type !== "notifications" ? (
                  <div className={displayFlex}>
                    <Button
                      variant="contained"
                      handleClick={() => handleInfoDialogue(data)}
                      buttonStyles={selectedButtonStyles}
                    >
                      {listItemButtonName}
                    </Button>
                    <div className={timeStampStyle}>{currentTimeStamp}</div>
                  </div>
                ) : (
                  <div className={timeStampStyle}>{currentTimeStamp}</div>
                )}
              </>
            </div>
          ) : (
            <div className={collapsedListClass}>
              <div className={itemTitleStyle}>
                {type !== "notifications" && (
                  <div className={connectedButtonPosition}>
                    <div
                      className={
                        connectedStatus?.toLowerCase() === "connected"
                          ? connectedStatusButtonStyle
                          : disConnectedStatusButtonStyle
                      }
                    >
                      {connectedStatus}
                    </div>
                  </div>
                )}
                {type === "goods" ? (
                  <>
                    {BoxId} : {id}
                  </>
                ) : type === "notifications" ? (
                  <>{data?.title}</>
                ) : (
                  <>{utilization ? `Equipment ID : ${id}` : `Box ID : ${id}`}</>
                )}
              </div>
              <div className={itemAreaStyle}>
                {type === "notifications" ? (
                  <>
                    {utilization ? `EQUIPMENT ID : ${id}` : `BOX ID : ${id}`} |{" "}
                    {LocationText} : {area}
                  </>
                ) : (
                  <>
                    {TrackerId} - {trackerId} | {area}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DashboardViewDetailsListItems;
