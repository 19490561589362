import colorCodes from "./colors";

let theme = {
  defaultTheme: {
    palette: {
      login: {
        pageBackgroundColor: "#300254",
        loginBtnColor: "",
        titleColor: "",
        forgotPwdColor: "",
        loginBannerTitle: colorCodes.colorWhite,
        loginFormBg: colorCodes.colorWhite,
        loginBannerDescription: colorCodes.colorWhite,
        welcomeContent: colorCodes.welcomeContent,
        loginFormTitle: colorCodes.loginFormTitle,
        inputTitleColor: colorCodes.inputTitleColor,
        inputPlaceholder: colorCodes.inputPlaceholder,
        copyRight: colorCodes.colorBlack,
        loginButton: colorCodes.colorDark,
      },
      dashboard: { pageBackgroundColor: colorCodes.darkBlueThemeColor },
      map: {
        markerProgressRed: colorCodes.paleRed,
        markerProgressYellow: colorCodes.paleYellow,
        markerProgressGreen: colorCodes.paleGreen,
        progressTrack: colorCodes.colorWhite,
      },
      dashboardList: {
        darkGrey2: colorCodes.darkGrey2,
        lightGrey: colorCodes.lightGrey,
        darkGreen: colorCodes.darkGreen2,
        lightPurple: colorCodes.lightPurple,
        darkGrey: colorCodes.darkGrey,
        green: colorCodes.colorGreen,
        red: colorCodes.colorRed,
        orange: colorCodes.colorOrange,
        white: colorCodes.colorWhite,
        darkPurple: colorCodes.darkPurple,
        darkGrey3: colorCodes.darkGrey3,
        darkGrey4: colorCodes.darkGrey4,
        black: colorCodes.colorBlack,
        lightPurple2: colorCodes.lightPurple2,
        darkPurple2: colorCodes.darkPurple2,
        lightWhitePurple: colorCodes.lightWhitePurple,
        accessButtonInactive: colorCodes.accessButtonInactive,
        floormapHeaderBg: colorCodes.floormapHeaderBg,
        viewDetailHeaderButton: colorCodes.viewDetailHeaderButton,
        markerInfoWindowBg: colorCodes.lightWhitePurple,
        connectedButtonBackground: colorCodes.lightGreenShade,
        connectedButtonFont: colorCodes.darkGreenShade,
        disconnectedButtonBackground: colorCodes.lightOrangeRedShade,
        disconnectedButtonFont: colorCodes.darkOrangeRedShade,
        collapsedListSubtitle: colorCodes.lightBlackGreyShade,
      },

      profile: {
        pageBackgroundColor: "#300254",
        lightGrey: colorCodes.lightGrey,
        darkGrey: colorCodes.darkGrey,
      },
      header: {
        darkBlueHeader: colorCodes.darkBlue,
        colorWhite: colorCodes.colorWhite,
        avatarBgColorViolet: colorCodes.violet,
        darkGrayLogoutText: colorCodes.darkGray1,
        lightGrayLogoutText: colorCodes.lightGray,
        darkGreenHeaderButton: colorCodes.darkGreen,
        disableGrayHeaderButtonText: colorCodes.disableGray,
      },
      buttonElement: {
        darkBlue1: colorCodes.darkBlue1,
        deepDarkBlue: colorCodes.deepDarkBlue,
        paleGray: colorCodes.paleGray,
        colorWhite: colorCodes.colorWhite,
      },
      infoSubListElement: {
        lightShadeGray: colorCodes.lightShadeGray,
        lightShadeGray1: colorCodes.lightShadeGray1,
        colorWhite: colorCodes.colorWhite,
      },
      stepperComponent: {
        purpleShades: colorCodes.purpleShades,
        colorWhite: colorCodes.colorWhite,
        greenShade: colorCodes.greenShade,
      },
      infoDialogueComponent: {
        darkGrayShades: colorCodes.darkGrayShades,
        greenBlackShades: colorCodes.greenBlackShades,
        blackShades: colorCodes.blackShades,
        colorWhite: colorCodes.colorWhite,
        lightShadeGray: colorCodes.lightShadeGray,
        halfBlack: colorCodes.halfBlack,
        greyBorder: colorCodes.greyBorder,
        storeHeaderUnfocused: colorCodes.raisinBlack,
        storeHeaderFocused: colorCodes.spaceCadet,
        palletBackgroundFocused: colorCodes.jetGrey,
        palletBorderColor: colorCodes.silverGrey,
      },
      chipButtonElement: {
        violet: colorCodes.violet,
        lightPurple: colorCodes.lightPurple,
        greyShade: colorCodes.greyShade,
        whiteShade: colorCodes.colorWhite,
        colorWhite: colorCodes.colorWhite,
      },
      infoAlertListItemComponent: {
        colorBlack: colorCodes.colorBlack,
        colorWhite: colorCodes.colorWhite,
      },
    },
    spacing: 5,
    typography: { fontFamily: "Arial" },
  },
  greenTheme: {
    palette: {
      login: {
        pageBackgroundColor: "#68F73A",
        loginBtnColor: "",
        titleColor: "",
        forgotPwdColor: "",
        loginBannerTitle: colorCodes.colorWhite,
        loginFormBg: colorCodes.colorWhite,
        loginBannerDescription: colorCodes.colorWhite,
        welcomeContent: colorCodes.welcomeContent,
        loginFormTitle: colorCodes.loginFormTitle,
        inputTitleColor: colorCodes.inputTitleColor,
        inputPlaceholder: colorCodes.inputPlaceholder,
        copyRight: colorCodes.colorBlack,
        loginButton: colorCodes.colorDark,
      },
      dashboard: { pageBackgroundColor: colorCodes.greenThemeColor },
      map: {
        markerProgressRed: colorCodes.paleRed,
        markerProgressYellow: colorCodes.paleYellow,
        markerProgressGreen: colorCodes.paleGreen,
        progressTrack: colorCodes.colorWhite,
      },
      dashboardList: {
        darkGrey2: colorCodes.darkGrey2,
        lightGrey: colorCodes.lightGrey,
        darkGreen: colorCodes.darkGreen2,
        lightPurple: colorCodes.lightPurple,
        darkGrey: colorCodes.darkGrey,
        green: colorCodes.colorGreen,
        red: colorCodes.colorRed,
        orange: colorCodes.colorOrange,
        white: colorCodes.colorWhite,
        darkPurple: colorCodes.darkPurple,
        darkGrey3: colorCodes.darkGrey3,
        darkGrey4: colorCodes.darkGrey4,
        black: colorCodes.colorBlack,
        lightPurple2: colorCodes.lightPurple2,
        darkPurple2: colorCodes.darkPurple2,
        lightWhitePurple: colorCodes.lightWhitePurple,
        accessButtonInactive: colorCodes.accessButtonInactive,
        floormapHeaderBg: colorCodes.floormapHeaderBg,
        viewDetailHeaderButton: colorCodes.viewDetailHeaderButton,
        markerInfoWindowBg: colorCodes.lightWhitePurple,
        connectedButtonBackground: colorCodes.lightGreenShade,
        connectedButtonFont: colorCodes.darkGreenShade,
        disconnectedButtonBackground: colorCodes.lightOrangeRedShade,
        disconnectedButtonFont: colorCodes.darkOrangeRedShade,
        collapsedListSubtitle: colorCodes.lightBlackGreyShade,
      },
      profile: {
        pageBackgroundColor: "#68F73A",
        lightGrey: colorCodes.lightGrey,
        darkGrey: colorCodes.darkGrey,
      },
      header: {
        darkBlueHeader: colorCodes.greenThemeColor,
        colorWhite: colorCodes.colorWhite,
        avatarBgColorViolet: colorCodes.violet,
        darkGrayLogoutText: colorCodes.darkGray1,
        lightGrayLogoutText: colorCodes.lightGray,
        darkGreenHeaderButton: colorCodes.blueDarkColor,
        disableGrayHeaderButtonText: colorCodes.disableGray,
      },
      buttonElement: {
        darkBlue1: colorCodes.darkBlue1,
        deepDarkBlue: colorCodes.deepDarkBlue,
        paleGray: colorCodes.paleGray,
        colorWhite: colorCodes.colorWhite,
      },
      infoSubListElement: {
        lightShadeGray: colorCodes.lightShadeGray,
        lightShadeGray1: colorCodes.lightShadeGray1,
        colorWhite: colorCodes.colorWhite,
      },
      stepperComponent: {
        purpleShades: colorCodes.purpleShades,
        colorWhite: colorCodes.colorWhite,
        greenShade: colorCodes.greenShade,
      },
      infoDialogueComponent: {
        darkGrayShades: colorCodes.darkGrayShades,
        greenBlackShades: colorCodes.greenBlackShades,
        blackShades: colorCodes.blackShades,
        colorWhite: colorCodes.colorWhite,
        lightShadeGray: colorCodes.lightShadeGray,
        halfBlack: colorCodes.halfBlack,
        greyBorder: colorCodes.greyBorder,
        storeHeaderUnfocused: colorCodes.raisinBlack,
        storeHeaderFocused: colorCodes.spaceCadet,
        palletBackgroundFocused: colorCodes.jetGrey,
        palletBorderColor: colorCodes.silverGrey,
      },
      chipButtonElement: {
        violet: colorCodes.violet,
        lightPurple: colorCodes.lightPurple,
        greyShade: colorCodes.greyShade,
        whiteShade: colorCodes.colorWhite,
        colorWhite: colorCodes.colorWhite,
      },
      infoAlertListItemComponent: {
        colorBlack: colorCodes.colorBlack,
        colorWhite: colorCodes.colorWhite,
      },
    },
    spacing: 5,
    typography: { fontFamily: "Arial" },
  },
  yellowTheme: {
    palette: {
      login: {
        pageBackgroundColor: "#D1F557",
        loginBtnColor: "",
        titleColor: "",
        forgotPwdColor: "",
        loginBannerTitle: colorCodes.colorBlack,
        loginFormBg: colorCodes.colorWhite,
        loginBannerDescription: colorCodes.colorWhite,
        welcomeContent: colorCodes.welcomeContent,
        loginFormTitle: colorCodes.loginFormTitle,
        inputTitleColor: colorCodes.inputTitleColor,
        inputPlaceholder: colorCodes.inputPlaceholder,
        copyRight: colorCodes.colorBlack,
        loginButton: colorCodes.colorDark,
      },
      dashboard: { pageBackgroundColor: colorCodes.yellowThemeColor },
      map: {
        markerProgressRed: colorCodes.paleRed,
        markerProgressYellow: colorCodes.paleYellow,
        markerProgressGreen: colorCodes.paleGreen,
        progressTrack: colorCodes.colorWhite,
      },
      dashboardList: {
        darkGrey2: colorCodes.darkGrey2,
        lightGrey: colorCodes.lightGrey,
        darkGreen: colorCodes.darkGreen2,
        lightPurple: colorCodes.lightPurple,
        darkGrey: colorCodes.darkGrey,
        green: colorCodes.colorGreen,
        red: colorCodes.colorRed,
        orange: colorCodes.colorOrange,
        white: colorCodes.colorWhite,
        darkPurple: colorCodes.darkPurple,
        darkGrey3: colorCodes.darkGrey3,
        darkGrey4: colorCodes.darkGrey4,
        black: colorCodes.colorBlack,
        lightPurple2: colorCodes.lightPurple2,
        darkPurple2: colorCodes.darkPurple2,
        lightWhitePurple: colorCodes.lightWhitePurple,
        accessButtonInactive: colorCodes.accessButtonInactive,
        floormapHeaderBg: colorCodes.floormapHeaderBg,
        viewDetailHeaderButton: colorCodes.viewDetailHeaderButton,
        markerInfoWindowBg: colorCodes.lightWhitePurple,
        connectedButtonBackground: colorCodes.lightGreenShade,
        connectedButtonFont: colorCodes.darkGreenShade,
        disconnectedButtonBackground: colorCodes.lightOrangeRedShade,
        disconnectedButtonFont: colorCodes.darkOrangeRedShade,
        collapsedListSubtitle: colorCodes.lightBlackGreyShade,
      },
      profile: {
        pageBackgroundColor: "#D1F557",
        lightGrey: colorCodes.lightGrey,
        darkGrey: colorCodes.darkGrey,
      },
      header: {
        darkBlueHeader: colorCodes.yellowThemeColor,
        colorWhite: colorCodes.colorWhite,
        avatarBgColorViolet: colorCodes.violet,
        darkGrayLogoutText: colorCodes.darkGray1,
        lightGrayLogoutText: colorCodes.lightGray,
        darkGreenHeaderButton: colorCodes.darkGreen,
        disableGrayHeaderButtonText: colorCodes.disableGray,
      },
      buttonElement: {
        darkBlue1: colorCodes.darkBlue1,
        deepDarkBlue: colorCodes.deepDarkBlue,
        paleGray: colorCodes.paleGray,
        colorWhite: colorCodes.colorWhite,
      },
      infoSubListElement: {
        lightShadeGray: colorCodes.lightShadeGray,
        lightShadeGray1: colorCodes.lightShadeGray1,
        colorWhite: colorCodes.colorWhite,
      },
      stepperComponent: {
        purpleShades: colorCodes.purpleShades,
        colorWhite: colorCodes.colorWhite,
        greenShade: colorCodes.greenShade,
      },
      infoDialogueComponent: {
        darkGrayShades: colorCodes.darkGrayShades,
        greenBlackShades: colorCodes.greenBlackShades,
        blackShades: colorCodes.blackShades,
        colorWhite: colorCodes.colorWhite,
        lightShadeGray: colorCodes.lightShadeGray,
        halfBlack: colorCodes.halfBlack,
        greyBorder: colorCodes.greyBorder,
        storeHeaderUnfocused: colorCodes.raisinBlack,
        storeHeaderFocused: colorCodes.spaceCadet,
        palletBackgroundFocused: colorCodes.jetGrey,
        palletBorderColor: colorCodes.silverGrey,
      },
      chipButtonElement: {
        violet: colorCodes.violet,
        lightPurple: colorCodes.lightPurple,
        greyShade: colorCodes.greyShade,
        whiteShade: colorCodes.colorWhite,
        colorWhite: colorCodes.colorWhite,
      },
      infoAlertListItemComponent: {
        colorBlack: colorCodes.colorBlack,
        colorWhite: colorCodes.colorWhite,
      },
    },
    spacing: 5,
    typography: { fontFamily: "Arial" },
  },
  redTheme: {
    palette: {
      login: {
        pageBackgroundColor: "#C10132",
        loginBtnColor: "",
        titleColor: "",
        forgotPwdColor: "",
        loginBannerTitle: colorCodes.colorBlack,
        loginFormBg: colorCodes.colorWhite,
        loginBannerDescription: colorCodes.colorWhite,
        welcomeContent: colorCodes.welcomeContent,
        loginFormTitle: colorCodes.loginFormTitle,
        inputTitleColor: colorCodes.inputTitleColor,
        inputPlaceholder: colorCodes.inputPlaceholder,
        copyRight: colorCodes.colorBlack,
        loginButton: colorCodes.colorDark,
      },
      dashboard: { pageBackgroundColor: colorCodes.redThemeColor },
      map: {
        markerProgressRed: colorCodes.paleRed,
        markerProgressYellow: colorCodes.paleYellow,
        markerProgressGreen: colorCodes.paleGreen,
        progressTrack: colorCodes.colorWhite,
      },
      dashboardList: {
        darkGrey2: colorCodes.darkGrey2,
        lightGrey: colorCodes.lightGrey,
        darkGrey: colorCodes.darkGrey,
        darkGreen: colorCodes.darkGreen2,
        lightPurple: colorCodes.lightPurple,
        green: colorCodes.colorGreen,
        red: colorCodes.colorRed,
        orange: colorCodes.colorOrange,
        white: colorCodes.colorWhite,
        darkPurple: colorCodes.darkPurple,
        darkGrey3: colorCodes.darkGrey3,
        darkGrey4: colorCodes.darkGrey4,
        black: colorCodes.colorBlack,
        lightPurple2: colorCodes.lightPurple2,
        darkPurple2: colorCodes.darkPurple2,
        lightWhitePurple: colorCodes.lightWhitePurple,
        accessButtonInactive: colorCodes.accessButtonInactive,
        floormapHeaderBg: colorCodes.floormapHeaderBg,
        viewDetailHeaderButton: colorCodes.viewDetailHeaderButton,
        markerInfoWindowBg: colorCodes.lightWhitePurple,
        connectedButtonBackground: colorCodes.lightGreenShade,
        connectedButtonFont: colorCodes.darkGreenShade,
        disconnectedButtonBackground: colorCodes.lightOrangeRedShade,
        disconnectedButtonFont: colorCodes.darkOrangeRedShade,
        collapsedListSubtitle: colorCodes.lightBlackGreyShade,
      },
      profile: {
        pageBackgroundColor: "#C10132",
        lightGrey: colorCodes.lightGrey,
        darkGrey: colorCodes.darkGrey,
      },
      header: {
        darkBlueHeader: colorCodes.redThemeColor,
        colorWhite: colorCodes.colorWhite,
        avatarBgColorViolet: colorCodes.violet,
        darkGrayLogoutText: colorCodes.darkGray1,
        lightGrayLogoutText: colorCodes.lightGray,
        darkGreenHeaderButton: colorCodes.blackColor,
        disableGrayHeaderButtonText: colorCodes.disableGray,
      },
      buttonElement: {
        darkBlue1: colorCodes.darkBlue1,
        deepDarkBlue: colorCodes.deepDarkBlue,
        paleGray: colorCodes.paleGray,
        colorWhite: colorCodes.colorWhite,
      },
      infoSubListElement: {
        lightShadeGray: colorCodes.lightShadeGray,
        lightShadeGray1: colorCodes.lightShadeGray1,
        colorWhite: colorCodes.colorWhite,
      },
      stepperComponent: {
        purpleShades: colorCodes.purpleShades,
        colorWhite: colorCodes.colorWhite,
        greenShade: colorCodes.greenShade,
      },
      infoDialogueComponent: {
        darkGrayShades: colorCodes.darkGrayShades,
        greenBlackShades: colorCodes.greenBlackShades,
        blackShades: colorCodes.blackShades,
        colorWhite: colorCodes.colorWhite,
        lightShadeGray: colorCodes.lightShadeGray,
        halfBlack: colorCodes.halfBlack,
        greyBorder: colorCodes.greyBorder,
        storeHeaderUnfocused: colorCodes.raisinBlack,
        storeHeaderFocused: colorCodes.spaceCadet,
        palletBackgroundFocused: colorCodes.jetGrey,
        palletBorderColor: colorCodes.silverGrey,
      },
      chipButtonElement: {
        violet: colorCodes.violet,
        lightPurple: colorCodes.lightPurple,
        greyShade: colorCodes.greyShade,
        whiteShade: colorCodes.colorWhite,
        colorWhite: colorCodes.colorWhite,
      },
      infoAlertListItemComponent: {
        colorBlack: colorCodes.colorBlack,
        colorWhite: colorCodes.colorWhite,
      },
    },
    spacing: 5,
    typography: { fontFamily: "Arial" },
  },
};

export default theme;
