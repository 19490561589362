
const userList =  {
  userName: "John.snow@gmail.com",
  userId: 3,
  loginTime: "2022-11-21T10:49:58.088+00:00",
  roles: ["Admin"],
  phoneNum: "+15852826892",
  email: "John.snow@gmail.com",
  currentRoleType: "ADMIN",
  firstName: "John",
  lastName: "Snow",
  password: "John@1234",
};

export default userList;
