import theme from "../../theme/theme";
import { Chip, ChipLabel, ChipBadgeContainer } from "./styles";

const ChipButton: React.FC<any> = (props) => {
  const { data, index, handleClick, pageName } = props;

  const handleChip = () => {
    handleClick(data, index);
  };

  return (
    <Chip
      whiteShade={theme?.defaultTheme?.palette?.chipButtonElement?.whiteShade}
      focused={data.focused}
      highlight={data.color}
      lightPurple={
        pageName === "infoDialog"
          ? "#000"
          : theme?.defaultTheme?.palette?.chipButtonElement?.lightPurple
      }
      onClick={handleChip}
    >
      <ChipLabel
        greyShade={theme?.defaultTheme?.palette?.chipButtonElement?.greyShade}
        colorWhite={theme?.defaultTheme?.palette?.chipButtonElement?.colorWhite}
        focused={data.focused}
      >
        {data.label}
      </ChipLabel>
      <ChipBadgeContainer
        whiteShade={theme?.defaultTheme?.palette?.chipButtonElement?.whiteShade}
        greyShade={theme?.defaultTheme?.palette?.chipButtonElement?.greyShade}
        violet={
          pageName === "infoDialog"
            ? theme?.defaultTheme?.palette?.chipButtonElement?.greyShade
            : theme?.defaultTheme?.palette?.chipButtonElement?.violet
        }
        focused={data.focused}
        highlight={data.color}
      >
        {data.count}
      </ChipBadgeContainer>
    </Chip>
  );
};

export default ChipButton;
