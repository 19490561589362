import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";

import useStyles from "./styles";

const CheckBox = (props: any) => {
  const { disabled, isChecked, label, color, handleCheck, name, id } = props;
  const { customCheckbox } = useStyles();

  

  return (
    
    <FormGroup>
      <FormControlLabel sx={{marginLeft: "0px",}}
        control={
          <Checkbox
            className={customCheckbox}
            checked={isChecked ? isChecked : false}
            onChange={(e) => handleCheck(e, id)}
            // color={isChecked ?"primary" : "primary"}
            disabled={disabled ? disabled : false}
            name={name}
            id={id}
            icon={<CheckBoxOutlineBlankOutlinedIcon />}
            checkedIcon={<CheckBoxOutlinedIcon />}
            sx={{
              color: disabled ? '#000' : '#D2CCF2',
              '&.Mui-checked': {
                color: disabled ? '#000' : '#D2CCF2',
              },
              
            }}
          />
        }
        label={label}
      />
    </FormGroup>
    
  );
};

export default CheckBox;
