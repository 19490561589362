import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RemoveIcon from "../../assets/removeIcon.svg";
import CheckBox from "elements/Checkbox";
import TextField from "elements/TextField";
import CircleActiveIcon from "../../assets/Circular_Active.svg";
import CircleInActiveIcon from "../../assets/Circular_InActive.svg";
import PolygonActiveIcon from "../../assets/Polygon_Active.svg";
import PolygonInActiveIcon from "../../assets/Polygon_Inactive.svg";
import useTranslation from "../../localization/translations";
import ExclamationIcon from "../../assets/exclamationIcon.svg";
import theme from "../../theme/theme";
import useStyles from "./styles";
import Typography from "@mui/material/Typography";
// import { getGeofence } from "redux/actions/geofenceActions";

// class Point {
//   constructor(x: string, y: string) {
//     this.x = x;
//     this.y = y;
//   }
// }

const Geofence: React.FC<any> = (props: any) => {
  const {
    handleGeofenceCircleClick,
    handleGeofencePolygonClick,
    circleCenter,
    circleRadius,
    setCircleRadius,
    setCircleCenter,
    geoFenceName,
    setGeofenceName,
    geoFenceType,
    setPolygonPath,
    polygonPath,
    selectedItem,
    isGeofenceLocation,
    selectedId,
    tabIndex,
    setTabIndex,
    setIsUpdateEnable,
  } = props;

  const geofenceData = useSelector(
    (state: any) => state?.geofenceList?.geofenceData
  );

  const dispatch = useDispatch();

  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isOutsideGeofenceChecked, setIsOutsideGeofenceChecked] =
    useState<boolean>();
  const [isBackGeofenceChecked, setIsBackGeofenceChecked] = useState<boolean>();
  const [isCircleEnbled, setIsCircleEnbled] = useState<boolean>(true);
  const [isPolygonEnbled, setIsPolygonEnbled] = useState<boolean>(true);
  const [isPreviousPolygonPaths, setIsPreviousPolygonPaths] = useState<any>();
  const [isPreviousName, setIsPreviousName] = useState<any>();
  const [isPreviousRadius, setIsPreviousRadius] = useState<any>();
  const [isPreviousCircleCenter, setIsPreviousCircleCenter] = useState<any>();
  const [
    isPreviousOutsideGeofenceChecked,
    setIsPreviousOutsideGeofenceChecked,
  ] = useState<boolean>();
  const [isPreviousBackGeofenceChecked, setIsPreviousBackGeofenceChecked] =
    useState<boolean>();

  const [isSelectedItem, setIsSelectedItem] = useState<any>();
  const [isDisabledButton, setIsDisabledButton] = useState<any>();

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);

  const {
    customCheckbox,
    customTextField,
    rootContainer,
    mainContainer,
    geofenceContainer,
    geofenceTypes,
    geofenceMainTypes,
    geofenceCircleType,
    circularText,
    polygonContainer,
    polygonImage,
    geofenceTextContainer,
    circleContainer,
    circularRadius,
    notifyContainer,
    polygonTextContainer,
    polygonText,
    buttonContainer,
    cancelButtonContainer,
    updateButtonContainer,
    resetButtonContainer,
  } = useStyles(appTheme);

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );
  const [radius, setRadius] = useState<any>();
  const [circleLatLng, setCircleLatLng] = useState<any>();
  const {} = useTranslation();

  // useEffect(() => {
  //   dispatch(getGeofence({}));
  // }, []);

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("dashboardViewDetails") || "{}");

    data?.map((item: any) => {
      if (item?.id === selectedId) {
        for (let i = 0; i < item[selectedItem?.type].length; i++) {
          if (item[selectedItem?.type][i]["id"] === selectedItem?.id) {
            setIsSelectedItem(item[selectedItem?.type][i]);
          }
        }
      }
    });
  }, [tabIndex, selectedItem]);

  useEffect(() => {
    setIsOutsideGeofenceChecked(isSelectedItem?.geoFence?.outsideGeofence);
    setIsBackGeofenceChecked(isSelectedItem?.geoFence?.backToGeofence);
    setIsPreviousPolygonPaths(isSelectedItem?.geoFence.coOrdinates);
    setIsPreviousName(isSelectedItem?.geoFence.name);
    setIsPreviousRadius(isSelectedItem?.geoFence.radius);
    setIsPreviousCircleCenter(isSelectedItem?.geoFence.coOrdinates[0]);
    setIsPreviousOutsideGeofenceChecked(
      isSelectedItem?.geoFence?.outsideGeofence
    );
    setIsPreviousBackGeofenceChecked(isSelectedItem?.geoFence?.backToGeofence);
  }, [isSelectedItem]);

  useEffect(() => {
    setRadius(circleRadius);
    setCircleLatLng(circleCenter);
  }, [circleCenter, circleRadius]);

  useEffect(() => {
    if (geoFenceType === "circle") {
      setIsCircleEnbled(true);
      setIsPolygonEnbled(false);
    } else {
      setIsCircleEnbled(false);
      setIsPolygonEnbled(true);
    }
  }, [geoFenceType]);

  const handleOutsideGeofenceCheck = (event: any) => {
    setIsOutsideGeofenceChecked(event.target.checked);
  };

  const handleBacktoGeofenceCheck = (event: any) => {
    setIsBackGeofenceChecked(event.target.checked);
  };

  const handleResetClick = () => {
    // if (geoFenceType === "circle") {
    //   setIsCircleEnbled(true);
    //   setIsPolygonEnbled(false);
    // } else {
    //   setIsCircleEnbled(false);
    //   setIsPolygonEnbled(true);
    // }
    setIsDisabled(false);
    props.handleResetClick();
  };

  const handleGeofenceClose = () => {
    setIsDisabled(true);
    setCircleRadius(isPreviousRadius);
    setGeofenceName(isPreviousName);
    if (geoFenceType === "circle") {
      setPolygonPath(null);
      setCircleCenter(isPreviousCircleCenter);
      setIsCircleEnbled(true);
      setIsPolygonEnbled(false);
    } else {
      setPolygonPath(isPreviousPolygonPaths);
      setCircleCenter(null);
      setIsCircleEnbled(false);
      setIsPolygonEnbled(true);
    }
    setIsOutsideGeofenceChecked(isPreviousOutsideGeofenceChecked);
    setIsBackGeofenceChecked(isPreviousBackGeofenceChecked);
    props.handleCancelClick();
  };

  const handleCircleClick = () => {
    setIsCircleEnbled(true);
    setIsPolygonEnbled(false);
    handleGeofenceCircleClick();
    setPolygonPath(null);
    setIsDisabledButton("circle");
  };

  const handlePolygonClick = () => {
    setIsPolygonEnbled(true);
    setIsCircleEnbled(false);
    handleGeofencePolygonClick();
    setRadius(null);
    setCircleCenter(null);
    setCircleRadius(null);
    setIsDisabledButton("polygon");
  };

  const handleRadiusChange = (e: any) => {
    setCircleRadius(Number(e.target.value));
  };

  const handleCircleLatChange = (e: any) => {
    setCircleCenter({ ...circleCenter, lat: Number(e.target.value) });
    props.handleCircleLatChange();
  };

  const handleCircleLngChange = (e: any) => {
    setCircleCenter({ ...circleCenter, lng: Number(e.target.value) });
    props.handleCircleLatChange();
  };

  const handleGeofenceNameChange = (e: any) => {
    setGeofenceName(e.target.value);
  };

  const handleCancelClick = () => {
    setCircleRadius(isPreviousRadius);
    setGeofenceName(isPreviousName);
    if (geoFenceType === "circle") {
      setPolygonPath(null);
      setCircleCenter(isPreviousCircleCenter);
      setIsCircleEnbled(true);
      setIsPolygonEnbled(false);
    } else {
      setPolygonPath(isPreviousPolygonPaths);
      setCircleCenter(null);
      setIsCircleEnbled(false);
      setIsPolygonEnbled(true);
    }
    setIsOutsideGeofenceChecked(isPreviousOutsideGeofenceChecked);
    setIsBackGeofenceChecked(isPreviousBackGeofenceChecked);
    setIsDisabled(true);
    props.handleCancelClick();
  };

  const handleUpdateClick = () => {
    setIsDisabled(true);
    setIsPolygonEnbled(false);
    props.handleUpdateClick();
    let data = JSON.parse(localStorage.getItem("dashboardViewDetails") || "{}");

    data?.map((item: any) => {
      if (item?.id === selectedId) {
        for (let i = 0; i < item[selectedItem?.type].length; i++) {
          if (item[selectedItem?.type][i]["id"] === selectedItem?.id) {
            item[selectedItem?.type][i]["geoFence"].backToGeofence =
              isBackGeofenceChecked;
            item[selectedItem?.type][i]["geoFence"].outsideGeofence =
              isOutsideGeofenceChecked;
            item[selectedItem?.type][i]["geoFence"].name = geoFenceName;
            item[selectedItem?.type][i]["geoFence"].type =
              circleRadius !== null ? "circle" : "polygon";
            item[selectedItem?.type][i]["geoFence"].radius = circleRadius;
            item[selectedItem?.type][i]["geoFence"].isInsideGeofence =
              isGeofenceLocation;
            if (circleRadius !== null) {
              item[selectedItem?.type][i]["geoFence"].coOrdinates = [
                circleCenter,
              ];
            } else {
              item[selectedItem?.type][i]["geoFence"].coOrdinates = polygonPath;
            }
          }
        }
      }
    });

    localStorage.setItem("dashboardViewDetails", JSON.stringify(data));
    setTabIndex(0);
    setIsUpdateEnable(true);
  };

  return (
    <>
      <div className={rootContainer}>
        <Typography variant="h5">Geofence Settings</Typography>
        <div>
          {isDisabled ? (
            <div className={resetButtonContainer}>
              <Button variant="outlined" onClick={handleResetClick}>
                Reset
              </Button>
            </div>
          ) : (
            <IconButton
              aria-label="close"
              onClick={handleGeofenceClose}
              sx={{
                color: appTheme.palette.infoDialogueComponent.colorWhite,
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </div>
      </div>
      <div className={mainContainer}>
        {isDisabled ? (
          <div className={geofenceContainer}>
            <div>
              {circleRadius !== null ? "Circular" : "Polygon"} - {geoFenceName}
            </div>
            <div>
              <img src={RemoveIcon} />
            </div>
          </div>
        ) : (
          <div className={geofenceTypes}>
            <div>Geofence Type</div>
            <div className={geofenceMainTypes}>
              <div>
                <div className={geofenceCircleType} onClick={handleCircleClick}>
                  <img
                    src={
                      !isCircleEnbled ? CircleInActiveIcon : CircleActiveIcon
                    }
                  />
                </div>
                <div className={circularText}>Circle</div>
              </div>
              <div className={polygonContainer}>
                <div className={polygonImage} onClick={handlePolygonClick}>
                  <img
                    src={
                      !isPolygonEnbled ? PolygonInActiveIcon : PolygonActiveIcon
                    }
                  />
                </div>
                <div className={circularText}>Polygon</div>
              </div>
            </div>
            {(isCircleEnbled || isPolygonEnbled) && (
              <div className={geofenceTextContainer}>
                <div>Geofence Name</div>
                <div className={customTextField}>
                  <TextField
                    value={geoFenceName}
                    type={"text"}
                    disabled={isDisabled}
                    onChange={handleGeofenceNameChange}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {isCircleEnbled && (
          <>
            <div className={circleContainer}>
              <div>
                <div>Center Latitude</div>
                <div className={customTextField}>
                  <TextField
                    value={circleLatLng?.lat}
                    type={"number"}
                    disabled={isDisabled}
                    onChange={handleCircleLatChange}
                  />
                </div>
              </div>
              <div>
                <div>Center Longitude</div>
                <div className={customTextField}>
                  <TextField
                    value={circleLatLng?.lng}
                    type={"number"}
                    disabled={isDisabled}
                    onChange={handleCircleLngChange}
                  />
                </div>
              </div>
            </div>
            <div className={circularRadius}>
              <div>Radius</div>
              <div className={customTextField}>
                <TextField
                  value={radius}
                  type={"number"}
                  disabled={isDisabled}
                  onChange={handleRadiusChange}
                />
              </div>
            </div>
          </>
        )}
        <div className={notifyContainer}>
          <div>Notify</div>
          <div className={geofenceMainTypes}>
            <div className={customCheckbox}>
              <CheckBox
                id={"outside-0"}
                name={"outside-0"}
                label={"Outside of Geofence"}
                isChecked={isOutsideGeofenceChecked}
                disabled={isDisabled}
                handleCheck={(e: any) => handleOutsideGeofenceCheck(e)}
              />
            </div>
            <div className={customCheckbox}>
              <CheckBox
                id={"back-0"}
                name={"back-0"}
                label={"Back to Geofence"}
                isChecked={isBackGeofenceChecked}
                disabled={isDisabled}
                handleCheck={(e: any) => handleBacktoGeofenceCheck(e)}
              />
            </div>
          </div>
        </div>
        {isPolygonEnbled &&
          (polygonPath === undefined || polygonPath === null) && (
            <div className={polygonTextContainer}>
              <div>
                <img src={ExclamationIcon} />
              </div>
              <div className={polygonText}>
                {"Click on the map to create polygon geofence"}
              </div>
            </div>
          )}
        {!isDisabled && (
          <div className={buttonContainer}>
            <div className={cancelButtonContainer}>
              <Button variant="outlined" onClick={handleCancelClick}>
                Cancel
              </Button>
            </div>
            <div className={updateButtonContainer}>
              <Button
                variant="contained"
                onClick={handleUpdateClick}
                disabled={
                  (isDisabledButton === "circle" && circleCenter === null) ||
                  (isDisabledButton === "polygon" && polygonPath === null)
                }
              >
                Update
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Geofence;
