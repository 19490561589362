import { makeStyles } from "@mui/styles";
import muiTheme from "theme/muiTheme";

const useStyles = makeStyles(() => ({
  customNotificationTabs: (props: any) => ({
    "& .MuiTabs-flexContainer": {
      // borderBottom: "2px solid #3e3e3e",
    },

    "& .MuiButtonBase-root": {
      marginRight: "0 !important",
      minWidth: "64px !important",
    },

    "& .MuiTab-root": {
      "&:first-child": {
        marginRight: "0 !important",
        minWidth: "64px !important",
        color: `${props?.palette?.infoDialogueComponent?.darkGrayShades}!important`,
        fontSize: "18px",
        fontFamily: `'Nunito Sans', sans-serif !important`,
      },
      "&:first-child.Mui-selected": {
        color: `${props?.palette?.infoDialogueComponent?.greenBlackShades}!important`,
      },
      "&:nth-child(2)": {
        marginRight: "0 !important",
        minWidth: "64px !important",
        color: `${props?.palette?.infoDialogueComponent?.darkGrayShades}!important`,
      },
      "&:nth-child(2).Mui-selected": {
        color: `${props?.palette?.infoDialogueComponent?.greenBlackShades}!important`,
      },
    },
    "& .MuiTabs-root .MuiTabs-indicator": {
      background: props?.palette?.infoDialogueComponent?.greenBlackShades,
      height: "4px !important",
      borderRadius: "2px",
    },
  }),

  headerStyle: (props: any) => ({
    fontWeight: 700,
    fontSize: 20,
    lineHeight: "27px",
    textTransform: "uppercase",
    marginBottom: 30,
    fontFamily: `'Nunito Sans', sans-serif !important`,
  }),

  subListRow: (props: any) => ({
    marginBottom: "12px !important",
    display: "flex",
  }),
  dateTimePickerStyle: (props: any) => ({
    paddingRight: 16,
    display: "flex",
    "& .MuiOutlinedInput-input": {
      color: "white !important",
      opacity: 1,
      fontFamily: "Nunito Sans",
      "& ::placeholder": {
        color: "white !important",
        opacity: 1,
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
      outline: "none !important",
    },

    "& .MuiSvgIcon-root": {
      fill: "white !important",
    },
    "& .MuiInputLabel-root": {
      color: "white !important",
      lineHeight: "22px",
    },
    "::-webkit-input-placeholder": { color: "white !important" },
    "& .MuiAutocomplete-root": {
      marginLeft: "0 !important",
    },
  }),
  displayFlex: (props: any) => ({
    display: "flex",
  }),
  timePickerStyle: (props: any) => ({
    display: "flex",
    marginLeft: 15,
    borderRadius: 5,
    padding: 0,
  }),

  timeInputStyle: (props: any) => ({
    borderRadius: 5,
    // padding: 25,
    background: "transparent",
    border: "1px solid white",
  }),
  wareHouseClass: (props: any) => ({
    paddingRight: "16px",
  }),
  trailerClass: (props: any) => ({
    paddingRight: "16px",
  }),
  alertMsgStyle: (props: any) => ({
    textAlign: "center",
    marginTop: "-60px",
    fontWeight: 700,
    fontSize: "15px",
    fontFamily: `'Nunito Sans', sans-serif !important`,
    color: "red",
  }),
}));

export default useStyles;
