import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  rootContainer: (props: any) => ({
    position: "relative",
    backgroundColor: "#7E6ECC", //lightWhitePurple
    width: "100%",
  }),
  expandedListClass: (props: any) => ({
    padding: "15px 15px 15px 15px",
    borderRadius: 5,
    cursor: "pointer",
  }),
  itemTitleStyle: (props: any) => ({
    textTransform: "uppercase",
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: 800,
  }),
  displayFlex: (props: any) => ({
    display: "flex",
    justifyContent: "space-between",
  }),
  markerCloseIcon: (props: any) => ({
    margin: "-10px 0px 0px 0px",
    fontSize: 13,
    fontFamily: `'Nunito Sans', sans-serif !important`,
    padding: "0px 0px 0px 15px",
  }),
});
export default useStyles;
