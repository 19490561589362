import { useState, useEffect, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Tabs from "../../elements/Tabs";
import SearchBox from "../../elements/SearchBox";
import theme from "../../theme/theme";
import useTranslation from "../../localization/translations";
import { useLanguageContext } from "../../localization/languageContext";
import Button from "../../elements/Button";
import DashboardListItems from "../DashboardListItems";
import {
  formattedCardListData,
  formattedDashboardListData,
  formattedDashboardNotificationList,
  chipDataCount,
} from "../../utils/utils";
import { AppContext } from "../../context/appContext";
import SearchIcon from "../../assets/searchIcon.svg";
import CloseIcon from "../../assets/closeIcon.svg";
import RightArrow from "../../assets/rightArrow.svg";
import useStyles from "./styles";
import TrailersInfoDialog from "components/TrailersInfoDialog";
import ChipButton from "../../elements/ChipButton";

interface isButtonClickedType {
  button1: boolean;
  button2: boolean;
  button3: boolean;
}

const DashboardList: React.FC<any> = (props) => {
  const {
    tabIndex,
    setTabIndex,
    selectedTabButton,
    setSelectedTabButton,
    selectedNotification,
    setSelectedNotification,
    markerArray,
    selectedMarkerId,
    searchOpen,
    setSearchOpen,
    isMarkerClicked,
    setIsMarkerClicked,
    notificationTimeStamp,
    setRightPanelActive,
    setViewDetailActive,
    setChipButtonIndex,
    chipButtonIndex,
    rightPanelActive,
    showInfoDialogue,
    setShowInfoDialogue,
  } = props;

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  const {
    customNotificationTabs,
    tabSection,
    unSelectedButtonStyles,
    selectedButtonStyles,
    tabButtonSection,
    dashboardListSection,
    searchIconStyle,
    rightArrowStyle,
    searchClass,
    searchContainerStyle,
    noResultStyle,
    dashboardListSectionHeight,
    displayFlex,
    chipButtonSection,
  } = useStyles(appTheme);

  const [isButtonClicked, setIsButtonClicked] = useState<isButtonClickedType>({
    button1: true,
    button2: false,
    button3: false,
  });

  const { button1, button2, button3 } = isButtonClicked;

  const {
    tabListName1,
    tabListName2,
    trackingListButton1,
    trackingListButton2,
    trackingListButton3,
    chipButtonEvents,
    chipButtonIncidents,
    chipButtonOprAlerts,
  } = useTranslation();

  useEffect(() => {
    setIsButtonClicked(() => ({
      button1: true,
      button2: false,
      button3: false,
    }));
    setSelectedTabButton(0);
    setSelectedNotification("");
  }, [tabIndex]);

  const tabsList = [
    { name: tabListName1, val: 0 },
    { name: tabListName2, val: 1 },
  ];

  const [dataArray, setDataArray] = useState([]);
  const [selectedRefId, setSelectedRefId] = useState("");
  const [chipButtonCount, setChipButtonCount] = useState<any>("");

  useEffect(() => {
    setDataArray(
      tabIndex === 0
        ? formattedDashboardListData(selectedTabButton, markerArray)
        : formattedDashboardNotificationList(
            selectedTabButton,
            formattedDashboardListData(selectedTabButton, markerArray),
            chipButtonIndex
          )
    );
  }, [
    selectedTabButton,
    markerArray,
    tabIndex,
    chipButtonIndex,
    rightPanelActive,
  ]);

  useEffect(() => {
    setSearchValue(
      tabIndex === 0
        ? formattedDashboardListData(selectedTabButton, markerArray)
        : formattedDashboardNotificationList(
            selectedTabButton,
            formattedDashboardListData(selectedTabButton, markerArray),
            chipButtonIndex
          )
    );
  }, [selectedTabButton, tabIndex, chipButtonIndex, rightPanelActive]);

  useEffect(() => {
    const eventCount = chipDataCount(
      formattedDashboardListData(selectedTabButton, markerArray),
      "events"
    );

    const incidentsCount = chipDataCount(
      formattedDashboardListData(selectedTabButton, markerArray),
      "incidents"
    );

    const oprAlertsCount = chipDataCount(
      formattedDashboardListData(selectedTabButton, markerArray),
      "oprAlerts"
    );
    const chipButtonListCount = [
      `${eventCount}`,
      `${incidentsCount}`,
      `${oprAlertsCount}`,
    ];
    setChipButtonCount(chipButtonListCount);
  }, [selectedTabButton, chipButtonIndex]);

  useEffect(() => {
    setDataArray(
      tabIndex === 0
        ? formattedDashboardListData(selectedTabButton, markerArray)
        : formattedDashboardNotificationList(
            selectedTabButton,
            formattedDashboardListData(selectedTabButton, markerArray),
            chipButtonIndex
          )
    );
    setSearchValue(
      tabIndex === 0
        ? formattedDashboardListData(selectedTabButton, markerArray)
        : formattedDashboardNotificationList(
            selectedTabButton,
            formattedDashboardListData(selectedTabButton, markerArray),
            chipButtonIndex
          )
    );
  }, []);

  const [searchValue, setSearchValue] = useState<any>(dataArray);

  const trackingListButton = [
    { id: 0, label: trackingListButton1 },
    { id: 1, label: trackingListButton2 },
    { id: 2, label: trackingListButton3 },
  ];

  const handleTabs = (index: number) => {
    setTabIndex(index);
    setSearchOpen(false);
  };

  useEffect(() => {
    switch (selectedTabButton) {
      case 0:
        setIsButtonClicked(() => ({
          button1: true,
          button2: false,
          button3: false,
        }));
        break;
      case 1:
        setIsButtonClicked(() => ({
          button1: false,
          button2: true,
          button3: false,
        }));
        break;
      case 2:
        setIsButtonClicked(() => ({
          button1: false,
          button2: false,
          button3: true,
        }));
        break;
      default:
        setIsButtonClicked(() => ({
          button1: true,
          button2: false,
          button3: false,
        }));
        break;
    }
  }, [selectedTabButton]);

  const handleButtonClick = (buttonId: number) => {
    switch (buttonId) {
      case 0:
        setIsButtonClicked(() => ({
          button1: true,
          button2: false,
          button3: false,
        }));
        break;
      case 1:
        setIsButtonClicked(() => ({
          button1: false,
          button2: true,
          button3: false,
        }));
        break;
      case 2:
        setIsButtonClicked(() => ({
          button1: false,
          button2: false,
          button3: true,
        }));
        break;
      default:
        setIsButtonClicked(() => ({
          button1: true,
          button2: false,
          button3: false,
        }));
        break;
    }
    setSelectedTabButton(buttonId);
    setSearchOpen(false);
    setSelectedNotification("");
  };

  const handleExpandListItem = (
    index: number,
    id: any,
    timeStamp: any,
    data: any
  ) => {
    setSelectedNotification(selectedNotification === id ? "" : id);
    setSelectedRefId(id);
    props.handleExpandListItem(id, data);
  };

  const handleSearch = (searchValue: any) => {
    let searchResult = dataArray?.filter((value: any) => {
      return (
        value?.title
          ?.toLowerCase()
          .includes(searchValue?.toString()?.toLowerCase()) ||
        value?.itemList?.area
          ?.toLowerCase()
          .includes(searchValue?.toString()?.toLowerCase()) ||
        value?.name
          ?.toLowerCase()
          .includes(searchValue?.toString()?.toLowerCase()) ||
        (tabIndex === 1 &&
          value?.itemList?.warehouseCategory?.id
            ?.toLowerCase()
            .includes(searchValue?.toString()?.toLowerCase())) ||
        (tabIndex === 1 &&
          value?.itemList?.palletId
            ?.toLowerCase()
            .includes(searchValue?.toString()?.toLowerCase()))
      );
    });
    setSearchValue(searchResult);
    setSearchOpen(true);
    setSelectedNotification("");
  };

  const handleSearchClose = () => {
    setSearchOpen(false);
    setSearchValue(dataArray);
    setSelectedNotification("");
  };

  const refs =
    searchValue && searchValue.length > 0
      ? searchValue.reduce((acc: any, value: any) => {
          acc[value.id] = createRef<any>();
          return acc;
        }, {})
      : "";

  useEffect(() => {
    if ((selectedNotification || selectedRefId) && refs) {
      setTimeout(() => {
        refs[
          selectedNotification ? selectedNotification : selectedRefId
        ]?.current?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }, 300);
    }
  }, [refs, selectedRefId, selectedNotification]);

  const [selectedItem, setSelectedItem] = useState<any>();

  const handleViewDetail = (dataID: string) => {
    if (button2) {
      setShowInfoDialogue(true);
      setSelectedItem(dataID);
    } else {
      setViewDetailActive(true);
    }
  };

  // const notifcationChipButtons = [
  //   {
  //     label: chipButtonEvents,
  //     count: chipButtonCount && `${chipButtonCount[0]}`,
  //     focused: false,
  //     color: "green",
  //   },
  //   {
  //     label: chipButtonIncidents,
  //     count: chipButtonCount && `${chipButtonCount[1]}`,
  //     focused: true,
  //     color: "red",
  //   },
  //   {
  //     label: chipButtonOprAlerts,
  //     count: chipButtonCount && `${chipButtonCount[2]}`,
  //     focused: false,
  //     color: "orange",
  //   },
  // ];

  // useEffect(() => {
  //   if (notifcationChipButtons) {
  //     setChipButtons(notifcationChipButtons);
  //     // setChipButtonIndex(1);
  //   }
  // }, [chipButtonCount]);

  const [chipButtons, setChipButtons] = useState<any>();

  useEffect(() => {
    setChipButtons([
      {
        label: chipButtonEvents,
        count: chipButtonCount && `${chipButtonCount[0]}`,
        focused: false,
        color: "green",
      },
      {
        label: chipButtonIncidents,
        count: chipButtonCount && `${chipButtonCount[1]}`,
        focused: true,
        color: "red",
      },
      {
        label: chipButtonOprAlerts,
        count: chipButtonCount && `${chipButtonCount[2]}`,
        focused: false,
        color: "orange",
      },
    ]);
  }, []);

  useEffect(() => {
    setChipButtons([
      {
        label: chipButtonEvents,
        count:
          searchOpen && chipButtonIndex === 0
            ? `${searchValue?.length}`
            : chipButtonCount && `${chipButtonCount[0]}`,
        focused: chipButtonIndex === 0 ? true : false,
        color: "green",
      },
      {
        label: chipButtonIncidents,
        count:
          searchOpen && chipButtonIndex === 1
            ? `${searchValue?.length}`
            : chipButtonCount && `${chipButtonCount[1]}`,
        focused: chipButtonIndex === 1 ? true : false,
        color: "red",
      },
      {
        label: chipButtonOprAlerts,
        count:
          searchOpen && chipButtonIndex === 2
            ? `${searchValue?.length}`
            : chipButtonCount && `${chipButtonCount[2]}`,
        focused: chipButtonIndex === 2 ? true : false,
        color: "orange",
      },
    ]);
  }, [chipButtonCount, chipButtonIndex, searchValue, searchOpen]);

  const getFormattedChipButtonsData = (list: any, chipBtnIndex: number) => {
    for (const [index] of list?.entries()) {
      if (chipBtnIndex === index) list[index].focused = true;
      else list[index].focused = false;
    }

    return list;
  };

  // useEffect(() => {
  //   getFormattedChipButtonsData(chipButtons, 1);
  // }, []);

  useEffect(() => {
    if (isMarkerClicked) {
      getFormattedChipButtonsData(chipButtons, chipButtonIndex);
    }
  }, [chipButtonIndex, isMarkerClicked, selectedNotification]);

  useEffect(() => {
    setSearchOpen(false);
  }, [chipButtonIndex]);

  const handleChipButton = (data: any, index: number) => {
    setChipButtons(getFormattedChipButtonsData(chipButtons, index));
    setChipButtonIndex(index);
    setSelectedNotification("");
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div className={tabSection}>
            <Tabs
              initialIndex={0}
              tabsList={tabsList}
              handleTabs={handleTabs}
              dashboardNotificationClassName={customNotificationTabs}
            />
            <img
              src={searchOpen ? CloseIcon : SearchIcon}
              alt="search"
              onClick={searchOpen ? handleSearchClose : handleSearch}
              className={searchIconStyle}
              width={30}
            />
            <img
              src={RightArrow}
              alt="RightArrow"
              className={rightArrowStyle}
              onClick={() => {
                setRightPanelActive(false);
                setSelectedNotification("");
                setIsMarkerClicked(false);
                setTabIndex(0);
              }}
            />
          </div>
        </Grid>
        {searchOpen && (
          <div className={searchContainerStyle}>
            <SearchBox
              searchInput={searchClass}
              placeHolder={"Search"}
              handleSearch={handleSearch}
              searchIsOpen={true}
              fontColor={appTheme?.palette?.dashboardList?.darkGrey2}
            />
          </div>
        )}

        <>
          <div className={tabButtonSection}>
            {trackingListButton &&
              trackingListButton.map((value, index) => {
                return (
                  <Button
                    key={index}
                    variant="contained"
                    handleClick={() => handleButtonClick(value?.id)}
                    buttonStyles={
                      (
                        value?.id === 0
                          ? !isButtonClicked.button1
                          : value?.id === 1
                          ? !isButtonClicked.button2
                          : !isButtonClicked.button3
                      )
                        ? unSelectedButtonStyles
                        : selectedButtonStyles
                    }
                  >
                    {value?.label}
                  </Button>
                );
              })}
          </div>
        </>

        <Grid item xs={12}>
          <div
            className={
              searchOpen ? dashboardListSectionHeight : dashboardListSection
            }
          >
            {tabIndex === 0 ? (
              searchValue.length > 0 ? (
                searchValue.map((item: any, index: number) => {
                  return (
                    <DashboardListItems
                      refs={refs}
                      selectedTabButton={selectedTabButton}
                      data={item}
                      handleExpandListItem={handleExpandListItem}
                      index={index}
                      selectedNotification={selectedNotification}
                      key={index}
                      notificationTimeStamp={notificationTimeStamp}
                      handleViewDetail={handleViewDetail}
                      tabIndex={tabIndex}
                    />
                  );
                })
              ) : (
                <div className={noResultStyle}>No Result Found</div>
              )
            ) : (
              <>
                <div className={chipButtonSection}>
                  {tabIndex === 1 && chipButtons && chipButtons.length > 0
                    ? chipButtons.map((item: any, index: number) => {
                        if (index <= 2) {
                          return (
                            <ChipButton
                              key={index}
                              data={item}
                              index={index}
                              handleClick={handleChipButton}
                            />
                          );
                        }
                      })
                    : null}
                </div>
                {searchValue.length > 0 ? (
                  searchValue.map((item: any, index: number) => {
                    return (
                      <DashboardListItems
                        refs={refs}
                        selectedTabButton={selectedTabButton}
                        data={item}
                        handleExpandListItem={handleExpandListItem}
                        index={index}
                        selectedNotification={selectedNotification}
                        key={index}
                        notificationTimeStamp={notificationTimeStamp}
                        handleViewDetail={handleViewDetail}
                        tabIndex={tabIndex}
                      />
                    );
                  })
                ) : (
                  <div className={noResultStyle}>No Result Found</div>
                )}
              </>
            )}
          </div>
        </Grid>
      </Grid>
      <div>
        {showInfoDialogue && (
          <TrailersInfoDialog
            selectedItemID={selectedItem}
            setShowInfoDialogue={setShowInfoDialogue}
            pageName={"dashboard"}
          />
        )}
      </div>
    </>
  );
};
export default DashboardList;
