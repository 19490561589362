import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import { useNavigate, useLocation } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import useTranslation from "../../localization/translations";
import theme from "../../theme/theme";
import Grid from "@material-ui/core/Grid";
import Avatar from "@mui/material/Avatar";
import MoreIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Logo from "../../assets/header-logo.svg";
import LogoTitle from "../../data/appData";
import Logout from "../../assets/logoutIcon.svg";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "../../elements/Button";
import {
  DashboardBtnIcon,
  ManifestsBtnIcon,
  ConfigBtnIcon,
  DashboardBtnIconActive,
  ManifestsBtnIconActive,
  ConfigBtnIconActive,
  AnalyticsButtonIcon,
  AnalyticsButtonIconActive,
} from "../../assets/buttonIcons";
import { getUserLogout, setUserLogin } from "../../redux/actions/loginActions";
import useStyles from "./styles";

interface isButtonClickedType {
  button1: boolean;
  button2: boolean;
  button3: boolean;
  button4: boolean;
}

const Header: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);

  const {
    logoImg,
    header,
    headerRight,
    avatharName,
    avatharSection,
    avatharIcon,
    logoSection,
    avatharBackground,
    logoutImg,
    logoutSection,
    customMenu,
    selectedButtonStyles,
    unSelectedButtonStyles,
    buttonStyle,
    btnTextClass,
    logoutText,
    tabStyle,
    personIconClass,
  } = useStyles(appTheme);

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );

  const {
    dashboardButton,
    manifestsButton,
    configurationsButton,
    supervisorDefalutDomain,
    analyticsButton,
  } = useTranslation();

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  const [name, setName] = useState({
    firstName: "TEST",
    lastName: "USER",
    initials: "TU",
  });

  const user = useSelector((state: RootState) => state.login.loginData);

  const [isButtonClicked, setIsButtonClicked] = useState<isButtonClickedType>({
    button1: true,
    button2: false,
    button3: false,
    button4: false,
  });

  useEffect(() => {
    setIsButtonClicked({
      button1: location?.pathname === "/dashboard",
      button2: location?.pathname === "/menifests",
      button3: location?.pathname === "/configurations",
      button4: location?.pathname === "/analytics",
    });
  }, [location]);

  useEffect(() => {
    if (user) {
      let userFirstName = "",
        userLastName = "",
        initial1 = "",
        initial2 = "";
      let fname = user?.firstName;
      let lname = user?.lastName;
      if (fname && fname?.split("")?.length > 0) {
        userFirstName = fname?.split("")[0].toUpperCase() + fname.substring(1);
      }
      if (lname && lname?.split("")?.length > 0) {
        userLastName = lname?.split("")[0].toUpperCase() + lname.substring(1);
      }
      if (userFirstName && userFirstName?.split("").length > 0) {
        initial1 = userFirstName?.split("")[0].toUpperCase();
      }
      if (userLastName && userLastName?.split("").length > 0) {
        initial2 = userLastName?.split("")[0].toUpperCase();
      }
      setName({
        firstName: userFirstName,
        lastName: userLastName,
        initials: initial1 + initial2,
      });
    }
  }, [user]);
  const [anchorElUser, setAnchorElUser] = useState(null);
  // const [tabIsVisible, settabIsVisible] = useState(tabVisibility);
  const menuOptions = ["Logout", "Profile"];
  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = (menuOptions: string) => {
    if (menuOptions === "Logout") {
      localStorage.removeItem("user");
      localStorage.clear();
      const payload = {
        token: "aaa",
      };
      dispatch(getUserLogout(payload));
      dispatch(setUserLogin({}));
      navigate("/login");
    }
    setAnchorElUser(null);
  };

  const handleload = () => {
    // navigate("/test")
  };

  const handleButtonClick = (buttonId: number) => {
    switch (buttonId) {
      case 1:
        setIsButtonClicked((prevState) => ({
          ...prevState,
          button1: true,
          button2: false,
          button3: false,
          button4: false,
        }));
        navigate("/dashboard");
        break;
      case 2:
        setIsButtonClicked((prevState) => ({
          ...prevState,
          button1: false,
          button2: true,
          button3: false,
          button4: false,
        }));
        navigate("/menifests");
        break;
      case 3:
        setIsButtonClicked((prevState) => ({
          ...prevState,
          button1: false,
          button2: false,
          button3: true,
          button4: false,
        }));
        navigate("/configurations");
        break;
      case 4:
        setIsButtonClicked((prevState) => ({
          ...prevState,
          button1: false,
          button2: false,
          button3: false,
          button4: true,
        }));
        navigate("/analytics");
        break;
      default:
        break;
    }
    localStorage.setItem("tabIndex", JSON.stringify(buttonId));
  };

  const handleAvatar = () => {
    // navigate("");
  };

  return (
    <Fragment>
      <Grid container className={header}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <div className={logoSection}>
            <div className={logoImg}>
              <img
                src={Logo}
                alt="logo"
                width={48}
                height={56}
                onClick={handleload}
              />
              <Typography variant="h5">
                {LogoTitle.dashBoardLogoName}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid className={headerRight} item xs={12} sm={12} md={8} lg={8} xl={8}>
          <div className={tabStyle}>
            <div className={buttonStyle}>
              <Button
                variant="contained"
                handleClick={() => handleButtonClick(1)}
                buttonStyles={
                  !isButtonClicked.button1
                    ? unSelectedButtonStyles
                    : selectedButtonStyles
                }
              >
                {isButtonClicked.button1 ? (
                  <img src={DashboardBtnIconActive} />
                ) : (
                  <img src={DashboardBtnIcon} />
                )}
                <span className={btnTextClass}>{dashboardButton}</span>
              </Button>
            </div>
            <div className={buttonStyle}>
              <Button
                variant="contained"
                handleClick={() => handleButtonClick(2)}
                buttonStyles={
                  !isButtonClicked.button2
                    ? unSelectedButtonStyles
                    : selectedButtonStyles
                }
              >
                {isButtonClicked.button2 ? (
                  <img src={ManifestsBtnIconActive} />
                ) : (
                  <img src={ManifestsBtnIcon} />
                )}
                <span className={btnTextClass}>{manifestsButton}</span>
              </Button>
            </div>
            <div className={buttonStyle}>
              <Button
                variant="contained"
                handleClick={() => handleButtonClick(3)}
                buttonStyles={
                  !isButtonClicked.button3
                    ? unSelectedButtonStyles
                    : selectedButtonStyles
                }
              >
                {isButtonClicked.button3 ? (
                  <img src={ConfigBtnIconActive}></img>
                ) : (
                  <img src={ConfigBtnIcon}></img>
                )}
                <span className={btnTextClass}>{configurationsButton}</span>
              </Button>
            </div>
            {/* Analytics Header Icon */}
            <div className={buttonStyle}>
              <Button
                variant="contained"
                handleClick={() => handleButtonClick(4)}
                buttonStyles={
                  !isButtonClicked.button4
                    ? unSelectedButtonStyles
                    : selectedButtonStyles
                }
              >
                {isButtonClicked.button4 ? (
                  <img src={AnalyticsButtonIconActive}></img>
                ) : (
                  <img src={AnalyticsButtonIcon}></img>
                )}
                <span className={btnTextClass}>{analyticsButton}</span>
              </Button>
            </div>
          </div>
          <div>
            <div className={avatharSection}>
              <Avatar className={avatharBackground} onClick={handleAvatar}>
                {name.initials}
              </Avatar>
              <div className={avatharName}>
                <p>{name?.firstName + " " + name?.lastName}</p>
                <p>{supervisorDefalutDomain}</p>
              </div>
              <IconButton onClick={handleOpenUserMenu} className={avatharIcon}>
                <MoreIcon sx={{ fontSize: 36 }} />
              </IconButton>
              <Menu
                className={customMenu}
                sx={{ mt: "25px" }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                anchorEl={anchorElUser}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {menuOptions &&
                  menuOptions.length > 0 &&
                  menuOptions.map((menuOptions) => (
                    <MenuItem
                      key={menuOptions}
                      onClick={() => handleCloseUserMenu(menuOptions)}
                    >
                      <div className={logoutSection}>
                        {menuOptions && menuOptions === "Logout" ? (
                          <img
                            className={logoutImg}
                            src={Logout}
                            alt="logout"
                          />
                        ) : (
                          <PersonIcon className={personIconClass} />
                        )}

                        <Typography className={logoutText} textAlign="center">
                          {menuOptions}
                        </Typography>
                      </div>
                    </MenuItem>
                  ))}
              </Menu>
            </div>
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
};
export default Header;
