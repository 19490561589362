import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import { Button, IconButton } from "@mui/material";
import useTranslation from "../../localization/translations";
import CloseIcon from "@mui/icons-material/Close";
import Tabs from "../../elements/Tabs";
import theme from "../../theme/theme";
import colorCodes from "../../theme/colors";
import UploadIcon from "../../assets/UploadImage.svg";
import tableExportIcon from "../../assets/tableExportIcon.svg";
// @ts-ignore
import * as XLSX from "xlsx/xlsx.mjs";
// @ts-ignore
import ProgressBarLine from "react-progressbar";
import UploadCompletedIcon from "../../assets/UploadCompleted.svg";
import UploadCancelIcon from "../../assets/UploadCancel.svg";
import FileIcon from "../../assets/FileIcon.svg";
// @ts-ignore
import { doc, setDoc, updateDoc } from "firebase/firestore/lite";
// @ts-ignore
import { db } from "services/firebase";
import useStyles from "./styles";

const DialogWrapper = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiBackdrop-root": {
    marginTop: "84px !important",
  },
  "& .MuiPaper-root": {
    maxHeight: "calc(100% - 100px)",
    minHeight: "calc(100% - 507px)",
    width: "39vw",
    maxWidth: "1772px",
    background: `rgba(0, 0, 0, 0.87) !important`,
    opacity: 0.95,
    color: colorCodes.colorWhite,
    padding: 24,
    borderRadius: "12px",
  },
  "& .MuiDialog-container": {
    marginTop: "60px !important",
    background: "rgb(1 1 1 / 0%)",
    backdropFilter: "blur(4px)",
    height: "calc(100vh - 65px) !important",
  },
}));

const UploadInfoDialog: React.FC<any> = (props) => {
  const {
    setShowUploadDialog,
    setTableData,
    tableData,
    tabIndex,
    combinedData,
    setCombinedData,
  } = props;

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const {
    headerStyle,
    customNotificationTabs,
    uploadManifestStyle,
    uploadIconStyle,
    uploadTextStyle,
    uploadButtonStyle,
    progressBarStyle,
    uploadCancelStyle,
    uploadedProgressStyle,
    fileNameStyle,
    fileIconStyle,
  } = useStyles(appTheme);

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );

  const {} = useTranslation();

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  const [open, setOpen] = useState(!false);
  const [uploadTabIndex, setUploadTabIndex] = useState<number>(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileName, setFileName] = useState();

  const handleClose = () => {
    setOpen(!open);
    setShowUploadDialog(false);
  };

  const tabsList = [{ name: "Upload Manifest", val: 0 }];

  const handleTabs = (index: number) => {
    setUploadTabIndex(index);
  };

  var xlsx = require("xlsx");

  const [excelData, setExcelData] = useState<any>([]);

  const [count, setCount] = useState(0);

  const [uploadedData, setUploadedData] = useState<any>(tableData);

  useEffect(() => {
    if (uploadProgress === 100) {
      const interval = setInterval(() => {
        if (count < 100) {
          setCount(count + 1);
        }
        if (count === 100) {
          setTableData(combinedData);
          setUploadedData(combinedData);
          setTimeout(() => {
            setUploadProgress(0);
            setCount(0);
          }, 2000);
        }
      }, 10);

      return () => clearInterval(interval);
    }
  }, [count, uploadProgress]);

  const readUploadFile = (e: any) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data: any = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        setExcelData(json);
        const copyTableData = [...tableData];
        json?.map((val: any) => {
          copyTableData.unshift(val);
        });
        setCombinedData(copyTableData);
      };
      reader.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentUploaded = (event.loaded / event.total) * 100;
          setUploadProgress(percentUploaded);
        }
      };
      reader.readAsArrayBuffer(e.target.files[0]);
      const fileNameSplit = e?.target?.files[0]?.name?.split(".");
      setFileName(fileNameSplit && fileNameSplit[0]);
    }
    if (e.target.files[0]) {
    }
  };

  const handleUploadCancel = () => {
    setUploadProgress(0);
    setCount(0);
    setTableData(tableData);
    setUploadedData(tableData);
  };

  useEffect(() => {
    if (tableData.length < uploadedData.length) {
      switch (tabIndex) {
        case 0:
          const ref = doc(db, "manifestUpcoming", "upcomingData");
          updateDoc(ref, { data: uploadedData })
            .then((success: any) => console.log("updated"))
            .catch((error: any) => console.log(error));
          break;
        case 1:
          const ref3 = doc(db, "manifestInProgress", "inProgressData");
          updateDoc(ref3, { data: uploadedData })
            .then((success: any) => console.log("updated"))
            .catch((error: any) => console.log(error));
          break;
        case 2:
          const ref2 = doc(db, "manifestCompleted", "completedData");
          updateDoc(ref2, { data: uploadedData })
            .then((success: any) => console.log("updated"))
            .catch((error: any) => console.log(error));
          break;
        default:
          break;
      }
    } else {
      switch (tabIndex) {
        case 0:
          const ref = doc(db, "manifestUpcoming", "upcomingData");
          updateDoc(ref, { data: uploadedData })
            .then((success: any) => console.log("updated"))
            .catch((error: any) => console.log(error));
          break;
        case 1:
          const ref3 = doc(db, "manifestInProgress", "inProgressData");
          updateDoc(ref3, { data: uploadedData })
            .then((success: any) => console.log("updated"))
            .catch((error: any) => console.log(error));
          break;
        case 2:
          const ref2 = doc(db, "manifestCompleted", "completedData");
          updateDoc(ref2, { data: uploadedData })
            .then((success: any) => console.log("updated"))
            .catch((error: any) => console.log(error));
          break;
        default:
          break;
      }
    }
  }, [uploadedData]);

  return (
    <>
      <DialogWrapper open={open} sx={{ top: "22px" }}>
        <div className={headerStyle}>
          <Tabs
            initialIndex={0}
            tabsList={tabsList}
            handleTabs={handleTabs}
            dashboardNotificationClassName={customNotificationTabs}
          />
        </div>

        <div>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 15,
              top: 12,
              color: appTheme.palette.infoDialogueComponent.colorWhite,
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Grid>
          <div className={uploadManifestStyle}>
            <div className={uploadIconStyle}>
              <img src={UploadIcon} alt="UploadIcon" />
            </div>
            <div className={uploadTextStyle}>Upload the Manifest file </div>

            {count === 0 ? (
              <div className={uploadButtonStyle}>
                <label htmlFor="upload">
                  <Button variant="contained" component="span">
                    Choose File
                  </Button>
                </label>
                <input
                  type="file"
                  name="upload"
                  id="upload"
                  onChange={readUploadFile}
                  style={{ display: "none" }}
                />
              </div>
            ) : (
              <>
                <div className={progressBarStyle}>
                  <div className={fileNameStyle}>
                    <img
                      src={FileIcon}
                      alt="FileIcon"
                      className={fileIconStyle}
                    />
                    {fileName}
                  </div>
                  <ProgressBarLine completed={count} />

                  <img
                    src={count === 100 ? UploadCompletedIcon : UploadCancelIcon}
                    onClick={handleUploadCancel}
                    className={uploadCancelStyle}
                  />
                  <div className={uploadedProgressStyle}>Uploaded {count}%</div>
                </div>
              </>
            )}
          </div>
        </Grid>
      </DialogWrapper>
    </>
  );
};

export default UploadInfoDialog;
