import React, { useState } from "react";
import theme from "../../theme/theme";
import useTranslation from "../../localization/translations";
import useStyles from "./styles";

const GeofenceInfoWindow: React.FC<any> = (props) => {
  const { handleMarkerClose, boxId } = props;

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );

  const {
    listItemName1,
    listItemName2,
    listItemName3,
    listItemButtonName,
    BoxId,
    TrackerId,
    EquipmentId,
    Status,
    Battery,
    Temperature,
    Utilization,
    LocationText,
    Pallets,
    Stores,
    CurrentLocation,
  } = useTranslation();

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const { rootContainer, itemTitleStyle, expandedListClass, displayFlex } =
    useStyles(appTheme);

  return (
    <div className={rootContainer}>
      <div className={expandedListClass}>
        <div className={displayFlex}>
          <div className={itemTitleStyle}>{boxId}</div>
        </div>
      </div>
    </div>
  );
};

export default GeofenceInfoWindow;
