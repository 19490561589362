import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import TextField from "../TextField";
import theme from "theme/theme";
import useStyles from "./styles";

const Pagination: React.FC<any> = (props) => {
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);

  const {
    arrowBox,
    countNum,
    arrowNumberBox,
    arrowDisableBox,
    countNumRow,
    customPaginationSection,
    pageNumSection,
    paginationSection,
  } = useStyles(appTheme);

  const {
    rowsPerPageOptions,
    onPageChange,
    page,
    rowsPerPage,
    onRowsPerPageChange,
    onPageNoChange,
    value,
    count,
  } = props;

  const [selectedValue, setselectedValue] = useState<any>(rowsPerPage);
  const [newPage, setNewPage] = useState<any>(page);
  const [tooltip, setTooltip] = useState<any>(false);
  const [totalPage, setTotalPage] = useState<any>();

  useEffect(() => {
    const pageCount = Math.ceil(count / rowsPerPage);
    setTotalPage(pageCount);
  }, [count]);

  const handleChange = (e: any) => {
    onRowsPerPageChange(e.target.value);
    setselectedValue(e.target.value);
  };

  const NextPageChange = () => {
    setNewPage(newPage + 1);
    props.handleNextChange();
  };
  const PreviousPageChange = () => {
    if (newPage > 0) {
      setNewPage(newPage - 1);
    }
    props.handlePreviousChange();
  };

  const handleInputPageChange = (e: any) => {
    if (e.target.value > 0) {
      onPageNoChange(e.target.value);
    } else {
      onPageNoChange("");
      setTooltip(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setTooltip(false);
    }, 2000);
  }, [tooltip]);

  useEffect(() => {
    onPageChange(newPage);
  }, [newPage]);

  useEffect(() => {
    setNewPage(page);
  }, [page]);

  useEffect(() => {
    setselectedValue(rowsPerPage);
  }, [rowsPerPage]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} className={paginationSection}>
          <div className="rowsperpage">Rows Per Page</div>
          <div className={customPaginationSection}>
            <div className={`${countNum} count-num`}>
              <div className={countNumRow}>
                <div>
                  <select
                    className="MuiTablePaginationUnstyled-select"
                    style={{
                      padding: "5px 2px",
                      border: "1px solid #DADAFA",
                      background: "#FBFBFF",
                      borderRadius: "5px",
                      color: "#777777",
                      fontSize: "14px",
                      fontWeight: "400",
                      textAlign: "center",
                    }}
                    name="select"
                    value={selectedValue}
                    onChange={handleChange}
                    // displayEmpty
                    // renderValue={() => (selectedValue ? selectedValue : "")}
                  >
                    {rowsPerPageOptions.map((data: any) => (
                      <option className="MuiTablePaginationUnstyled-menuItem">
                        {data}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div
              className={newPage !== 0 ? arrowBox : arrowDisableBox}
              onClick={PreviousPageChange}
            >
              <KeyboardArrowLeftIcon />
            </div>
            <div className={arrowNumberBox}>{newPage + 1}</div>
            <div
              className={newPage + 1 !== totalPage ? arrowBox : arrowDisableBox}
              onClick={NextPageChange}
            >
              <KeyboardArrowRightIcon />
            </div>
            <div className={pageNumSection}>
              <TextField
                placeholder={"Page No"}
                type={"number"}
                onChange={handleInputPageChange}
                value={value}
                tooltip={tooltip}
                tooltipText={"Exceeded Total Pages"}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
export default Pagination;
