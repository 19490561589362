import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  rootContainer: (props: any) => ({
    width: "100%",
  }),
  expandedListClass: (props: any) => ({
    margin: 10,
    padding: 10,
    borderRadius: 5,
    cursor: "pointer",
  }),
  collapsedListClass: (props: any) => ({
    border: `0.5px solid ${props?.palette?.dashboardList?.darkPurple}`,
    margin: 10,
    padding: 10,
    borderRadius: 5,
    cursor: "pointer",
  }),
  itemTitleStyle: (props: any) => ({
    textTransform: "uppercase",
    color: props?.palette?.dashboardList?.black,
    fontSize: "14px",
    fontWeight: 800,
    fontFamily: `'Nunito Sans', sans-serif !important`,
    // marginBottom: 8,
  }),
  itemAreaStyle: (props: any) => ({
    color: props?.palette?.dashboardList?.black, // darkGrey3
    fontSize: 12,
    fontFamily: `'Nunito Sans', sans-serif !important`,
    fontWeight: 700,
    marginTop: 8,
    display: "flex",
  }),
  explandedListStyle: (props: any) => ({
    background: props?.palette?.dashboardList?.white, // colorWhite
    fontSize: 12,
    fontFamily: `'Nunito Sans', sans-serif !important`,
    fontWeight: 700,
    margin: 10,
    padding: 10,
  }),
  displayFlex: (props: any) => ({
    display: "flex",
    justifyContent: "space-between",
    marginTop: 8,
  }),
  itemListStyle: (props: any) => ({
    display: "flex",
    justifyContent: "space-between",
    marginTop: 8,
  }),
  itemListCount: (props: any) => ({
    color: props?.palette?.dashboardList?.black,
    fontSize: "13px",
    fontFamily: `'Nunito Sans', sans-serif !important`,
    fontWeight: 800,
    // margin: "5px 0px 0px 0px",
    display: "inline-flex",
    marginRight: 24,
  }),
  itemListName: (props: any) => ({
    color: props?.palette?.dashboardList?.darkGrey4, // 6B6B6B
    fontSize: "12px",
    fontWeight: 700,
    fontFamily: `'Nunito Sans', sans-serif !important`,
    margin: "-5px 0px 5px",
  }),
  selectedButtonStyles: (props: any) => ({
    backgroundColor: `${props?.palette?.dashboardList?.darkPurple2} !important`,
    borderRadius: "15px !important",
    fontSize: "12px !important",
    fontFamily: `'Nunito Sans', sans-serif !important`,
    lineHeight: "16px !important",
    boxShadow: "none !important",
    fontWeight: 700,
    textTransform: "none",
  }),
  timeStampStyle: (props: any) => ({
    color: props?.palette?.dashboardList?.darkGrey3,
    fontSize: "12px !important",
    fontFamily: `'Nunito Sans', sans-serif !important`,
    fontWeight: 500,
    alignSelf: "center",
  }),
  trailerAreaStyle: (props: any) => ({
    display: "flex",
    justifyContent: "space-between",
    color: props?.palette?.dashboardList?.darkGrey3, // darkGrey3
    fontSize: 12,
    fontFamily: `'Nunito Sans', sans-serif !important`,
    fontWeight: 700,
    margin: "8px 0px",
  }),
  trailerLocationStyle: (props: any) => ({
    display: "flex",
    alignSelf: "center",
    color: props?.palette?.dashboardList?.darkGrey3, // darkGrey3
    fontSize: 12,
    fontFamily: `'Nunito Sans', sans-serif !important`,
    fontWeight: 700,
  }),
  trailerArrowStyle: (props: any) => ({
    display: "flex",
    alignItems: "center",
  }),
  trailerLocationIconStyle: (props: any) => ({
    marginRight: 5,
  }),
  trailerLocationTextStyle: (props: any) => ({
    color: props?.palette?.dashboardList?.darkGrey3, // darkGrey3
    fontSize: 13,
    fontWeight: 700,
    fontFamily: `'Nunito Sans', sans-serif !important`,
  }),
  itemListIconStyle: (props: any) => ({
    marginRight: 5,
  }),
  itemTitleStyleNotification: (props: any) => ({
    color: props?.palette?.dashboardList?.black,
    fontSize: "14px",
    fontWeight: 800,
    display: "flex",
    justifyContent: "space-between",
    fontFamily: `'Nunito Sans', sans-serif !important`,
    // marginBottom: 8,
  }),
});
export default useStyles;
