import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  dashboardContent: (props: any) => ({
    minHeight: "calc(100vh - 84px) !important",
    marginTop: "84px !important",
    "& .google-map-code": {
      height: "100%",
    },
  }),
  arrowIconStyle: (props: any) => ({
    position: "absolute",
    right: 20,
    top: 96,
    transform: "rotate(180deg)",
    cursor: "pointer",
  }),
  positionRelative: (props: any) => ({
    position: "relative",
  }),
});
export default useStyles;
