const maniFestUpcomingViewDetails = {
    manifestUpcomingList: [
        {
          id: "123456",
  trailerId: "123456",
  mainfestId: "MID#123",
  totalPallets: "20",
  deliveryStores: "02",
  startLocation: "Idaho Falls, IDHAO, US",
  endLocation: "Idaho Falls, IDHAO, US",
  
  location: {
    lat: 12.945800757573837,
    lng: 80.1237549523929,
  },
  type: "goods",
  connectedStatus: "Connected",
  stores: [
    {
      name: "STORE#1",
      area: "Nebraska, 80831, US",
      count: "04",
      pallets: [
        {
          id: "",
          name: "PID#34522",
          trackerId: "TID#3421",
          palletId: "PID#34522",
          status: "In Transit",
          dispatchedDate: "03-13-2023 | 9:00 AM",
          deliveredDate: "13-03-2023 | 11:00 AM",
          temparature: "68°C",
          humidity: "24%",
          pressure: "987 kPa",
          
        },
        {
          id: "",
          name: "PID#34523",
          trackerId: "TID#3421",
          palletId: "PID#34523",
          status: "In Transit",
          dispatchedDate: "03-13-2023 | 9:00 AM",
          deliveredDate: "13-03-2023 | 11:00 AM",
          temparature: "68°C",
          humidity: "24%",
          pressure: "987 kPa",
          
        },
        {
          id: "",
          name: "PID#34524",
          trackerId: "TID#3421",
          palletId: "PID#34524",
          status: "In Transit",
          dispatchedDate: "03-13-2023 | 9:00 AM",
          deliveredDate: "13-03-2023 | 11:00 AM",
          temparature: "68°C",
          humidity: "24%",
          pressure: "987 kPa",

         
        },
        {
          id: "",
          name: "PID#34525",
          trackerId: "TID#3421",
          palletId: "PID#34525",
          status: "In Transit",
          dispatchedDate: "03-13-2023 | 9:00 AM",
          deliveredDate: "13-03-2023 | 11:00 AM",
          temparature: "68°C",
          humidity: "24%",
          pressure: "987 kPa",
         
        },
      ],
    },
    {
      name: "STORE#2",
      area: "Missouri, 80831, US",
      count: "04",
      pallets: [
        {
          id: "",
          name: "PID#34524",
          trackerId: "TID#3421",
          palletId: "PID#34524",
          status: "In Transit",
          dispatchedDate: "03-13-2023 | 9:00 AM",
          deliveredDate: "13-03-2023 | 11:00 AM",
          temparature: "68°C",
          humidity: "24%",
          pressure: "987 kPa",
          
        },
        {
          id: "",
          name: "PID#34525",
          trackerId: "TID#3421",
          palletId: "PID#34525",
          status: "In Transit",
          dispatchedDate: "03-13-2023 | 9:00 AM",
          deliveredDate: "13-03-2023 | 11:00 AM",
          temparature: "68°C",
          humidity: "24%",
          pressure: "987 kPa",
          
        },
        {
          id: "",
          name: "PID#34526",
          trackerId: "TID#3421",
          palletId: "PID#34526",
          status: "In Transit",
          dispatchedDate: "03-13-2023 | 9:00 AM",
          deliveredDate: "13-03-2023 | 11:00 AM",
          temparature: "68°C",
          humidity: "24%",
          pressure: "987 kPa",
          
        },
        {
          id: "",
          name: "PID#34527",
          trackerId: "TID#3421",
          palletId: "PID#34527",
          status: "In Transit",
          dispatchedDate: "03-13-2023 | 9:00 AM",
          deliveredDate: "13-03-2023 | 11:00 AM",
          temparature: "68°C",
          humidity: "24%",
          pressure: "987 kPa",
         
        },
      ],
    },
  ],
  trailerRoutes: [
    {
      name: "WAREHOUSE#1",
      status: "In progress",
      area: "Idaho Falls, IDHAO, US ",
      
      timeStamp: "02-13-2023 | 9:00 AM",
    },
    {
        name: "STORE#1",
        status: "In progress",
        area: "Nebraska, 80831, US ",
        
        timeStamp: "02-13-2023 | 9:00 AM",
      },
    {
      name: "STORE#2",
      status: "In progress",
      area: "Nebraska, 80831, US ",
      
      timeStamp: "02-13-2023 | 9:00 AM",
    },
  ],
},
    ]
};

export default maniFestUpcomingViewDetails;
