import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import useTranslation from "../../localization/translations";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InfoSubList from "../InfoSubList";
import CustomizedSteppers from "../Stepper";
import ChipButton from "../../elements/ChipButton";
import Tabs from "../../elements/Tabs";
import { SearchIconInfo } from "../../assets/buttonIcons";
import theme from "../../theme/theme";
import colorCodes from "../../theme/colors";
import Geofence from "components/Geofence";
import FloorMap from "components/Map/FloorMap";
import Map from "components/Map";
import useStyles from "./styles";
import InfoNotificationListItem from "components/InfoNotificationListItem";
import TimeLineStepper from "elements/TimeLineStepper";
import trailersInfoWindowData from "mockdata/trailersInfoWindowData";
import maniFestInProgressViewDetails from "mockdata/Firebase-formats/maniFestInProgressViewDetails";
import maniFestCompletedViewDetails from "mockdata/Firebase-formats/maniFestCompletedViewDetails";
import maniFestUpcomingViewDetails from "mockdata/Firebase-formats/maniFestUpcomingViewDetails";
import Accordian from "components/InfoWindowAccordian";
import SearchBox from "elements/SearchBox";
import { CloseIconImg } from "../../assets/icons";

const DialogWrapper = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiBackdrop-root": {
    marginTop: "84px !important",
  },
  "& .MuiPaper-root": {
    maxHeight: "calc(100% - 160px)",
    minHeight: "calc(100% - 160px)",
    width: "80vw",
    maxWidth: "1772px",
    background: `${colorCodes.colorBlack} !important`,
    color: colorCodes.colorWhite,
    padding: 24,
    borderRadius: "10px !important",
  },
  "& .MuiDialog-container": {
    marginTop: "60px !important",
    background: "rgb(0 0 0 / 58%)",
    backdropFilter: "blur(10px)",
    height: "calc(100vh - 65px) !important",
  },
}));

const TrailersInfoDialog: React.FC<any> = (props) => {
  const { selectedItemID, setShowInfoDialogue, pageName, parentTabIndex } =
    props;

  const [selectedItem, setSelectedItem] = useState<any>();

  useEffect(() => {
    if (selectedItemID) {
      if (pageName === "manifest") {
        if (parentTabIndex === 0) {
          maniFestUpcomingViewDetails?.manifestUpcomingList?.map((item) => {
            // if (item?.manifestId === selectedItemID?.manifestId) {
            setSelectedItem(item);
            // }
          });
        } else if (parentTabIndex === 1) {
          maniFestInProgressViewDetails?.manifestInProgressList?.map((item) => {
            // if (item?.manifestId === selectedItemID?.manifestId) {
            setSelectedItem(item);
            // }
          });
        } else if (parentTabIndex === 2) {
          maniFestCompletedViewDetails?.manifestCompletedList?.map((item) => {
            // if (item?.manifestId === selectedItemID?.manifestId) {
            setSelectedItem(item);
            // }
          });
        }
      } else {
        trailersInfoWindowData?.trailersInfolist?.map((item) => {
          if (item?.trailerId === selectedItemID) {
            setSelectedItem(item);
          }
        });
      }
    }
  }, [selectedItemID]);

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const {
    headerStyle,

    tripsSection1,
    tripsSection,
    tripsElementSection,
    violationElementSection,
    violationSection,
    tripsSection2,
    detailsSection,
    notificationList,
    subListRow,
    subListSection,
    chipButtonSection,
    labelSection,
    customNotificationTabs,
    colorBox,
    subInfoMiddleSection,
    violationListSection,
    violationElementSectionLeft,
    notificationSearchIcon,
    searchClass,
    searchBoxClass,
    infoDialogHeading,
    violationElementSectionLeftInner,
    searchContainer,
    violationSectionLeft,
    subListSectionContainer,
    tripsSection3,
    colorBoxTitle,
  } = useStyles(appTheme);

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );

  const {
    infoDialogueCategory,
    infoDialogueMake,
    infoDialogueDimensions,
    infoDialogueTemperature,
    infoDialogueBattery,
    infoDialogueLastUpdated,
    trailerRouteDetails,
    infoSubListBoxId,
    infoSubListTrackerId,
    infoSubListStatus,
    infoSubListLocation,
    infoSubListTotalUnits,
    infoSubListSource,
    chipButtonEvents,
    chipButtonIncidents,
    chipButtonOprAlerts,
    infoTabsListDetails,
    infoTabsListGeofence,
    infoDialogueType,
    infoDialogueUtilization,
    infoTabsListEquipmentID,
    infoTabsListState,
    infoTabsListCurrentLocation,
    infoTabsListOperator,

    trailerInfoTrailerID,
    trailerInfoManifestID,
    trailerInfoStartLocation,
    trailerInfoEndLocation,
    trailerInfoTotalPallets,
    trailerInfoDeliveryStores,
  } = useTranslation();

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  const [open, setOpen] = useState(!false);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const handleClose = () => {
    setOpen(!open);
    setShowInfoDialogue(false);
  };

  const tabsList = [{ name: infoTabsListDetails, val: 0 }];

  const handleTabs = (index: number) => {
    setTabIndex(index);
  };

  const infoSubList = [
    {
      title:
        pageName === "manifest" ? trailerInfoManifestID : trailerInfoTrailerID,
      value:
        pageName === "manifest"
          ? selectedItemID?.manifestId
          : selectedItem?.trailerId,
    },
    {
      title:
        pageName === "manifest" ? trailerInfoTrailerID : trailerInfoManifestID,
      value:
        pageName === "manifest"
          ? selectedItemID?.trailerId
          : selectedItem?.mainfestId,
    },
    {
      title: trailerInfoStartLocation,
      value:
        pageName === "manifest"
          ? selectedItemID?.sourceWarehouse
          : selectedItem?.startLocation,
    },
    {
      title: trailerInfoEndLocation,
      value:
        pageName === "manifest"
          ? selectedItemID?.endLocation
          : selectedItem?.endLocation,
    },
    {
      title: trailerInfoTotalPallets,
      value:
        pageName === "manifest"
          ? selectedItemID?.noOfPallets
          : selectedItem?.totalPallets,
    },
    {
      title: trailerInfoDeliveryStores,
      value:
        pageName === "manifest"
          ? selectedItemID?.deliveryStores
          : selectedItem?.deliveryStores,
    },
  ];

  const subInfoMiddleSectionTop = [
    {
      title: "Pallet - In Transist",
      color: "#5AA700",
    },
    {
      title: "Pallet - Incident",
      color: "#FB6161",
    },
    {
      title: "Pallet - Delayed Delivery",
      color: "#F79D5E",
    },
  ];

  const [newSearchValue, setNewSearchValue] = useState<any>();

  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const [selectedPallet, setSelectedPallet] = useState<any>();

  const subInfoMiddleSectionMiddle1 = [
    {
      title: "Tracker ID",
      value: selectedPallet?.trackerId,
    },
    {
      title: "Pallet ID",
      value: selectedPallet?.palletId,
    },
    {
      title: "Status",
      value: selectedPallet?.status,
    },
    {
      title: "Dispatched Date",
      value: selectedPallet?.dispatchedDate,
    },
  ];

  const subInfoMiddleSectionMiddle2 = [
    {
      title: "Delivery Date",
      value: selectedPallet?.deliveredDate,
    },
    {
      title: "Temperature",
      value: selectedPallet?.temparature,
    },
    {
      title: "Humidity",
      value: selectedPallet?.humidity,
    },
    {
      title: "Pressure",
      value: selectedPallet?.pressure,
    },
  ];

  useEffect(() => {
    if (selectedItem) {
      setSelectedPallet(selectedItem?.stores[0]?.pallets[0]);
      setNewSearchValue(selectedItem?.stores);
    }
  }, [selectedItem]);

  const handleSearchIsOpenNotificationList = () => {
    setIsSearchOpen(!isSearchOpen);
    setNewSearchValue(selectedItem?.stores);
  };

  const handleSearch = (searchValue: any) => {  

    let matchingStores1 = [];

    if (searchValue) {
      matchingStores1 = selectedItem?.stores
        ?.filter((store: any) => {
          const matchingPallets = store.pallets.filter((pallet: any) =>
            pallet.palletId
              .toLowerCase()
              .includes(searchValue?.toString()?.toLowerCase())
          );

          return matchingPallets.length > 0 ? true : false;
        })
        .map((store: any) => {
          const matchingPallets = store.pallets.filter((pallet: any) =>
            pallet.palletId
              .toLowerCase()
              .includes(searchValue?.toString()?.toLowerCase())
          );

          return { ...store, pallets: matchingPallets };
        });
    } else {
      matchingStores1 = [...selectedItem?.stores];
    }

    setNewSearchValue(matchingStores1);
  };

  const [circleRadius, setCircleRadius] = useState<any>(null);
  const [circleCenter, setCircleCenter] = useState<any>(null);
  const [polygonPath, setPolygonPath] = useState<any>(null);
  const [geofenceFloorMapDetailsEnable, setGeofenceFloorMapDetailsEnable] =
    useState<boolean>(true);

  useEffect(() => {
    if (selectedItem?.geoFence?.type === "circle") {
      setCircleRadius(selectedItem?.geoFence?.radius);
      setCircleCenter(selectedItem?.geoFence?.coOrdinates[0]);
    } else {
      setPolygonPath(selectedItem?.geoFence?.coOrdinates);
    }
  }, [selectedItem]);

  return (
    <>
      <DialogWrapper open={open} sx={{ top: "22px" }}>
        <div className={headerStyle}>
          <Tabs
            initialIndex={0}
            tabsList={tabsList}
            handleTabs={handleTabs}
            dashboardNotificationClassName={customNotificationTabs}
          />
        </div>

        <div>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 15,
              top: 12,
              color: appTheme.palette.infoDialogueComponent.colorWhite,
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <Grid container>
          <Grid item xs={12} className={subListRow}>
            <InfoSubList
              highlighted={"safetyScore"}
              infoSubList={infoSubList}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Grid container direction="column" className={violationSectionLeft}>
              <Grid item className={violationElementSectionLeft}>
                <Grid container className={searchContainer}>
                  {!isSearchOpen && (
                    <Grid
                      item
                      xs={10}
                      className={violationElementSectionLeftInner}
                    >
                      <div>
                        <p className={infoDialogHeading}>
                          Stores & Pallet List
                        </p>
                      </div>
                    </Grid>
                  )}

                  {isSearchOpen && (
                    <Grid item xs={10}>
                      <div className={searchBoxClass}>
                        <SearchBox
                          searchInput={searchClass}
                          placeHolder={"Search"}
                          handleSearch={handleSearch}
                          borderRadius={2}
                          borderColor={"1px solid #DCDADA"}
                          searchIsOpenOne={isSearchOpen}
                          fontColor={"#fff"}
                          pageName={"trailerInfoWindow"}
                        />
                      </div>
                    </Grid>
                  )}

                  <Grid item xs={2}>
                    <div
                      style={{ marginTop: isSearchOpen ? "10px" : "5px" }}
                      className={notificationSearchIcon}
                    >
                      <img
                        src={isSearchOpen ? CloseIconImg : SearchIconInfo}
                        onClick={handleSearchIsOpenNotificationList}
                        width={isSearchOpen ? 20 : 30}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Accordian
                  selectedData={newSearchValue}
                  setSelectedPallet={setSelectedPallet}
                  selectedPallet={selectedPallet}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            className={subListSectionContainer}
          >
            <Grid
              container
              justifyContent="space-between"
              className={subListSection}
            >
              {((parentTabIndex !== 0 && pageName === "manifest") ||
                pageName === "dashboard") && (
                <Grid item xs={12}>
                  <div className={tripsSection2}>
                    {subInfoMiddleSectionTop?.map((item) => {
                      return (
                        <div className={subInfoMiddleSection}>
                          <div
                            style={{ background: item?.color }}
                            className={colorBox}
                          ></div>
                          <div className={colorBoxTitle}>{item?.title}</div>
                        </div>
                      );
                    })}
                  </div>
                </Grid>
              )}
              <Grid item xs={12}>
                <div className={tripsSection1}>
                  <div className={tripsElementSection}>
                    {subInfoMiddleSectionMiddle1?.map((item) => {
                      return (
                        <div className={detailsSection}>
                          <div className={labelSection}>{item?.title}</div>
                          <div>{item?.value}</div>
                        </div>
                      );
                    })}
                  </div>

                  <div className={tripsElementSection}>
                    {subInfoMiddleSectionMiddle2?.map((item) => {
                      return (
                        <div className={detailsSection}>
                          <div className={labelSection}>{item?.title}</div>
                          <div>{item?.value}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Grid>
              {((parentTabIndex !== 0 && pageName === "manifest") ||
                pageName === "dashboard") && (
                <Grid item xs={12}>
                  <div className={tripsSection}>
                    <CustomizedSteppers
                      packagaeData={selectedPallet?.palletShipmentData}
                      pageName={pageName}
                      parentTabIndex={parentTabIndex}
                    />
                  </div>
                </Grid>
              )}
              {parentTabIndex === 0 && pageName === "manifest" && (
                <Grid item xs={12}>
                  <div className={tripsSection3}>
                    <FloorMap
                      geofenceFloorMapDetailsEnable={true}
                      selectedNotification={selectedItem?.id}
                      markerArray={
                        maniFestUpcomingViewDetails?.manifestUpcomingList
                      }
                      selectedListItem={selectedItem}
                      pageName={"manifestDialogue"}
                      geofenceFloorMapEnableManifest={true}
                    />
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
            <Grid container direction="column" className={violationSection}>
              <Grid item className={violationElementSection}>
                <div>
                  <span>{trailerRouteDetails}</span>
                </div>
              </Grid>
              <Grid item>
                <div className={violationListSection}>
                  <TimeLineStepper
                    trailerRouteDetails={selectedItem?.trailerRoutes}
                    pageName={pageName}
                    parentTabIndex={parentTabIndex}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogWrapper>
    </>
  );
};

export default TrailersInfoDialog;
