import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    root: (props: any) => ({ background: props.palette.profile.pageBackgroundColor }),

    graphCustomSelectDropDown: (props: any) => ({

        // "& .MuiOutlinedInput-notchedOutline" : {
        //     border: "0 !important",
        // },
        minWidth: "100px !important",
        minheight: "5px !important",
        "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
          {
            // paddingRight: "150px !important",
          },
        // "& .MuiPaper-root": {
        //   border: "0px solid #F3F0FC !important",
        // },
        "& .MuiSelect-select": {
          padding: "10px 11px",
        },
        "& .MuiInputBase-root": {
            
        //    borderRadius: "4px",
          fontSize: 18,
        //   fontFamily: `'Nunito Sans', sans-serif !important`,
          // lineHeight: 21,
          textAlign: "left",
          fontWeight: "bold",
          position: "relative",
          color: "#7E6ECC",
          width: "160px",
          paddingLeft: "15px",
          "& .MuiSvgIcon-root": {
            position: "absolute",
            right: "10px",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            color: "#F3F0FC",
            border: `1px solid #7E6ECC !important`,
          },
        },
        "& .css-t0wjx6-MuiButtonBase-root-MuiMenuItem-root.Mui-selected.MuiMenuItem-root":
          {
            color: "#fff !important",
          },
    
        "& .MuiSelect-iconOutlined": {
          color: `#7E6ECC !important`,
        },
      }),
  
});
export default useStyles;
