import trailerAction from "../actions/trailerAction";

const initialState = {
  trailerDataValue: {},
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case trailerAction.SET_TRAILER_LIST:
      const { trailerDataValue } = action;
      return { ...state, trailerDataValue };

    default:
      return state;
  }
};
